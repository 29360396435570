import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const EmailsDashboard = Loadable(lazy(() => import('./MessagesDashboard')));
const EmailsComparatives = Loadable(lazy(() => import('./EmailsComparatives')));

const emailsRoutes = [
  {
    path: '/messageAnalytics',
    element: (
      <EmailsDashboard />
    )
  },
  {
    path: '/emailsComparative',
    element: (
      <EmailsComparatives />
    )
  },
  {
    path: '/emailRenewal',
    element: (
      <EmailsDashboard />
    )
  }
];

export default emailsRoutes;
