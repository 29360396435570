import Loadable from "app/components/Loadable";
import React, { lazy } from "react";

const AppScrumBoard = Loadable(lazy(() => import("./AppScrumBoard")));

const regularScrumBoardRoutes = [
  {
    path: "/boards",
    element: <AppScrumBoard />,
  },
];

export default regularScrumBoardRoutes;
