import React from 'react';
import { Dialog, Box, Typography, } from '@mui/material';
import { H3 } from 'app/components/Typography';
import { PrimaryButton } from 'app/utils/ButtonsPatras';
import { FlexBetween } from 'app/components/FlexBox';
import { useAuth0 } from '@auth0/auth0-react';
import userActions from 'app/redux/actions/userActions';
import { useParams } from 'react-router-dom';
import { workingHours, timeLocalStart } from 'app/utils/AuthGuardUtils';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';

const PermissionDialog = ({
  openPermissionDialog,
  setShowOnboarding,
  setLoading,
  closeDialog,
  firstLogin,
  updatedLoginMaded,
  logoutAfterDelay,
  setShowLoader,
  reactivating,
  account,
  welcomeBack,
}) => {

  const dispatch = useDispatch();
  const { user, loginWithPopup, getIdTokenClaims } = useAuth0();
  const { invitationLink: invitationId } = useParams();
  const page = useLocation().pathname.split('/')[1];
  const timeZone = new Date().getTimezoneOffset();

  const authenticateUser = async () => {
    await loginWithPopup({
      clientId: process.env.REACT_APP_CLIENT_ID,
      scope: 'openid',
      connection: 'google-oauth2',
      access_type: 'offline',
      approval_prompt: 'force',
      login_hint: user.email,
    });
    return await getIdTokenClaims();
  };

  const handleCreateWithPermissions = (user) => {
    dispatch(userActions.userBelongToCompany()).then(res => {
      dispatch(
        userActions.addUser({
          lastName: user.family_name,
          firstName: user.given_name,
          email: user.email,
          role: 'regular',
          CompanyId: res.data ? res.data.response?.Company.id : null,
          invitationId: invitationId && invitationId,
          timeLocalStart,
          timeZone,
          workingHours
        })
      ).then(async (res) => {
        if (res.data && res.data.success) {
          await dispatch(userActions.getUser(user.email));
          setLoading(false);
          if (page !== 'calendar') {
            setShowOnboarding(true);
            setShowLoader(false);
          }
        } else {
          toast.error('Ha ocurrido un error, por favor intenta nuevamente');
          logoutAfterDelay();
        }
      });
    })
  };

  const handleReactivation = (user) => {
    dispatch(
      userActions.addUser({
        email: user.email,
      }, account === 'reactivate' && true)
    ).then((res) => {
      if (res.data) {
        window.location.reload();
        setLoading(false);
      } else {
        toast.error('Ha ocurrido un error recuperando la cuenta, por favor intenta nuevamente');
        logoutAfterDelay();
        setLoading(false);
      }
    });
  };

  const reLogin = async () => {
    const user = await authenticateUser();
    if (user) {
      closeDialog();
      updatedLoginMaded();
      setShowLoader(true);
      reactivating ? handleReactivation(user) : handleCreateWithPermissions(user);
    }
  };

  return <Dialog maxWidth="xs"
    open={openPermissionDialog}
    fullWidth={true}>
    <Box
      p={2}
      sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 2 }}
    >
      <FlexBetween>
        <H3 sx={{ fontSize: '20px' }}>
          {
            firstLogin ? '¡Bienvenido a Patras! 😀' : welcomeBack ? '¡Bienvenido de nuevo! 😀' : 'No pudimos crear tu usuario 🙁'
          }
        </H3>
      </FlexBetween>

      <Typography sx={{ fontWeight: 400, fontSize: '14px', color: '#424142', mb: .5 }}>
        {welcomeBack ? 'Para recuperar la cuenta' : 'Para crear una cuenta'}, requerimos {welcomeBack && 'nuevamente'} tu autorización para acceder a tu calendario.
        Puedes otorgar estos permisos realizando {!firstLogin && 'nuevamente'} el proceso de inicio de sesión.
      </Typography>

      <PrimaryButton
        onClick={() => (firstLogin || welcomeBack) ? reLogin() : logoutAfterDelay()}
        sx={{ alignSelf: 'end' }}
      >
        {
          (firstLogin || welcomeBack) ? 'Dar permisos' : 'Intentar nuevamente'
        }
      </PrimaryButton>
    </Box>
  </Dialog>

};

export default PermissionDialog;

