import React from 'react';
import {
    Dialog, Link, Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography
} from '@mui/material';
import { H3 } from 'app/components/Typography';
import { FlexBetween } from 'app/components/FlexBox';
import { PrimaryLoadingButton } from 'app/utils/ButtonsPatras';
import { useDispatch } from 'react-redux';
import userActions from 'app/redux/actions/userActions';
import { toast } from 'react-hot-toast';

const AccountDialog = ({
    user,
    handleCreateNewAccount,
    openAccountDialog,
    loading,
    setLoading,
    setOpenAccountDialog,
    setOpenPermissionDialog,
    setFirstLogin,
    setCreatingNewAccount,
    setShowLoader,
    setAccount,
    account,
    setReactivating,
    logoutAfterDelay,
    setWelcomeBack
}) => {

    const dispatch = useDispatch();

    const handleModifyAccount = () => {
        setLoading(true)
        if (account === 'reactivate') {
            if (user.sub.includes('google')) {
                setReactivating(true);
                setOpenAccountDialog(false);
                setOpenPermissionDialog(true);
                setLoading(false);
                setWelcomeBack(true);
            }
            else {
                dispatch(
                    userActions.addUser({
                        email: user.email,
                    }, account === 'reactivate' && true)
                ).then((res) => {
                    if (res.data) {
                        window.location.reload();
                        setLoading(false);
                    } else {
                        toast.error('Ha ocurrido un error recuperando la cuenta, por favor intenta nuevamente');
                        logoutAfterDelay();
                        setLoading(false);
                    }
                });
            }
        } else {
            if (user.sub.includes('google')) {
                setFirstLogin(true);
                setOpenPermissionDialog(true);
                setCreatingNewAccount(true);
                setShowLoader(false);
            }
            if (user.sub.includes('windows')) {
                setShowLoader(true);
                handleCreateNewAccount();
                setCreatingNewAccount(true);
            }
            setReactivating(false);
            setLoading(false);
            setOpenAccountDialog(false);
        }
    }

    return <Dialog maxWidth="xs"
        open={openAccountDialog}
        fullWidth={true}>
        <Box
            p={2}
            sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 2 }}
        >
            <FlexBetween>
                <H3 sx={{ fontSize: '20px' }}>
                    ¡Bienvenido/a de nuevo! 😄
                </H3>
            </FlexBetween>
            <Box>
                <Typography sx={{ fontWeight: 400, fontSize: '14px', color: '#424142', mb: .5 }}>
                    Nos alegra verte nuevamente por aquí. Hemos notado que habías eliminado tu cuenta previamente. Tienes dos opciones disponibles:
                </Typography>

            </Box>
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="calendar"
                    name="radio-buttons-group"
                    onChange={(e) => setAccount(e.target.value)}
                >
                    <FormControlLabel sx={{
                        alignItems: 'flex-start', mb: .5
                    }} value="reactivate" control={<Radio sx={{ p: '2px 9px' }} />}
                        label={
                            <>
                                <Typography sx={{ fontWeight: 500, fontSize: '14px', color: '#424142', display: 'inline', mr: .5, lineHeight: 1.3 }}>
                                    Recuperar tu cuenta anterior:
                                </Typography>
                                <Typography sx={{ fontWeight: 400, fontSize: '12px', color: '#606161', display: 'inline', lineHeight: 1.3 }}>
                                    Si deseas retomar desde donde lo dejaste, puedes recuperar tu antigua cuenta junto con todos tus datos existentes.  🔄
                                </Typography>
                            </>
                        }
                    />
                    <FormControlLabel value="newAccount" sx={{
                        alignItems: 'flex-start'
                    }} control={<Radio sx={{ p: '2px 9px' }} />}
                        label={<>
                            <Typography sx={{ fontWeight: 500, fontSize: '14px', color: '#424142', display: 'inline', mr: .5, lineHeight: 1.3 }}>
                                Crear una nueva cuenta:
                            </Typography>
                            <Typography sx={{ fontWeight: 400, fontSize: '12px', color: '#606161', display: 'inline', lineHeight: 1.3 }}>Si prefieres empezar de cero, puedes crear una nueva cuenta y comenzar una nueva aventura con nosotros. 🚀</Typography>
                        </>
                        }
                    />
                </RadioGroup>
            </FormControl>
            <Typography sx={{ fontWeight: 400, fontSize: '14px', color: '#424142', letterSpacing: 'none', mb: .5 }}>
                Estamos aquí para ayudarte en cualquier elección que tomes. No dudes en seleccionar la opción que mejor se adapte a tus necesidades.
                Si tienes alguna pregunta o duda, siempre puedes contactarnos a través de nuestro equipo de {' '}
                <Link href="mailto:mariano@patras.io" target='_blank' underline="always">
                    soporte
                </Link> .
            </Typography>
            <Typography sx={{ fontWeight: 400, fontSize: '14px', color: '#424142', mb: .5 }}>
                ¡Gracias por confiar en nosotros nuevamente!  🙌
            </Typography>
            <PrimaryLoadingButton
                loading={loading}
                onClick={handleModifyAccount}
                sx={{ alignSelf: 'end' }}
            >
                Confirmar
            </PrimaryLoadingButton>
        </Box>
    </Dialog>

};

export default AccountDialog;

