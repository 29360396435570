import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const UsersAnalitycs = Loadable(lazy(() => import('./UsersAnalitycs')));
const GroupsAnalitycs = Loadable(lazy(() => import('./GroupsAnalitycs')));

const usersAnalitycsRoutes = [
  {
    path: '/individualAnalytics',
    element: (
      <UsersAnalitycs />
    )
  },
  {
    path: '/groupAnalytics',
    element: (
      <GroupsAnalitycs />
    )
  }
];

export default usersAnalitycsRoutes;
