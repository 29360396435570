import { Box, Dialog, Icon, IconButton, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FlexBetween } from './FlexBox';
import { useWindowWidth } from '@react-hook/window-size';
import notificationsActions from 'app/redux/actions/notificationsActions';
import ColorGenerator from 'app/utils/colorGenerator';
import getRandomFact from 'app/utils/randomFacts';
import userActions from 'app/redux/actions/userActions';
import '../styles/FridayQuiz.css';
import toast from 'react-hot-toast';

function FridayQuiz({ openQuiz, closeQuiz }) {

    const dispatch = useDispatch();
    const onlyWidth = useWindowWidth();
    const loggedUser = useSelector((store) => store.userReducer.user);
    const notiFridayQuiz = useSelector((store) => store.notificationsReducer.notiFridayQuiz);
    const [quizNumber, setQuizNumber] = useState(1);
    const [answer1, setAnswer1] = useState(null);
    const [answer2, setAnswer2] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [randomFact, setRandomFact] = useState(null);

    useEffect(() => {
        setRandomFact(getRandomFact());
        // eslint-disable-next-line
    }, []);

    const handleSendAnswers = (value) => {
        if (quizNumber === 1) {
            setIsActive(true);
            setQuizNumber(2);
            setAnswer1(value);
        }
        if (quizNumber === 2) {
            setIsActive(true);
            setQuizNumber(false);
            setAnswer2(value);
        }
    };

    const handleCloseModal = () => {
        setLoading(true);
        dispatch(userActions.setFridayQuiz({ satisfactionJob: answer1, satisfactionWellness: answer2 })).then(res => {
            if (res.data) {
                dispatch(notificationsActions.setViewNotifications([notiFridayQuiz])).then(res => {
                    if (res.data) {
                        setLoading(false);
                        dispatch(notificationsActions.notiFridayQuiz(false));
                        closeQuiz();
                    } else {
                        setLoading(false);
                        dispatch(notificationsActions.notiFridayQuiz(false));
                        closeQuiz();
                        toast.error('Error al enviar las respuestas');
                    }
                })
            } else {
                setLoading(false);
                toast.error('Error al enviar las respuestas, intente nuevamente');
            }
        });
    };

    return (
        <>
            <Dialog
                open={openQuiz}
                onClose={handleCloseModal}
                aria- labelledby="form-dialog-title"
                sx={{
                    '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
                        maxWidth: '100vw',
                        width: onlyWidth > 800 ? '50vw !important' : '100vw !important',
                    },
                    '& .css-uhb5lp': {
                        maxWidth: '100vw',
                        width: onlyWidth > 800 ? '50vw !important' : '100vw !important',
                    }
                }
                }
            >
                <Box sx={{ padding: '4rem' }}>
                    <Typography sx={{ fontSize: '24px', mb: 4, textAlign: 'center' }}>
                        {
                            quizNumber ?
                                `¡Buen día ${loggedUser?.User.firstName}! ¡Ya es viernes!`
                                :
                                '¡Gracias por ayudarnos a crear un entorno de trabajo más positivo y productivo!'
                        }
                    </Typography>
                    {
                        quizNumber ?
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                    <Typography sx={{ fontSize: '18px', mb: '40px', fontWeight: 500, mr: 1 }}>{quizNumber}/2</Typography>
                                    <Typography sx={{ fontSize: '16px', mb: '40px', fontWeight: 400, alignSelf: 'center' }}>
                                        {
                                            quizNumber === 1 ?
                                                '¿Cómo calificaría su semana laboral en términos de satisfacción en el trabajo?'
                                                :
                                                '¿Cómo calificaría su semana en cuanto a satisfacción y bienestar?'
                                        }
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '60% !important' }} >
                                    <IconButton className={`buttonAnimated ${isActive ? 'active' : ''}`} onClick={() => handleSendAnswers(1)} >
                                        <Icon sx={{ fontSize: '45px !important', color: ColorGenerator('primary', 60) }} >sentiment_very_dissatisfied</Icon>
                                    </IconButton>
                                    <IconButton className={`buttonAnimated ${isActive ? 'active' : ''}`} onClick={() => handleSendAnswers(2)} >
                                        <Icon sx={{ fontSize: '45px !important', color: ColorGenerator('primary', 70) }} >sentiment_dissatisfied</Icon>
                                    </IconButton>
                                    <IconButton className={`buttonAnimated ${isActive ? 'active' : ''}`} onClick={() => handleSendAnswers(3)} >
                                        <Icon sx={{ fontSize: '45px !important', color: ColorGenerator('yellow', 70) }} >sentiment_neutral</Icon>
                                    </IconButton>
                                    <IconButton className={`buttonAnimated ${isActive ? 'active' : ''}`} onClick={() => handleSendAnswers(4)} >
                                        <Icon sx={{ fontSize: '45px !important', color: ColorGenerator('green', 80) }} >sentiment_satisfied</Icon>
                                    </IconButton>
                                    <IconButton className={`buttonAnimated ${isActive ? 'active' : ''}`} onClick={() => handleSendAnswers(5)} >
                                        <Icon sx={{ fontSize: '45px !important', color: ColorGenerator('green', 60) }} >sentiment_very_satisfied</Icon>
                                    </IconButton>
                                </Box>
                                <FlexBetween sx={{ mt: 5, width: '100% !important', alignItems: 'center' }} >
                                    <Typography sx={{ fontSize: '12px', alignSelf: 'self-start' }}>* Respuestas confidenciales y anónimas.</Typography>
                                    <Typography
                                        sx={{ fontSize: '16px', fontWeight: 500, alignSelf: 'self-end', color: '#C62F4A', cursor: 'pointer' }}
                                        onClick={() => quizNumber === 1 ? setQuizNumber(2) : setQuizNumber(false)}
                                    >Omitir</Typography>
                                </FlexBetween>
                            </Box>
                            :
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography sx={{ fontSize: '16px', fontWeight: 400, alignSelf: 'flex-start', mb: 1 }}>Dato curioso de la semana 🤓:</Typography>
                                <Typography sx={{ fontSize: '16px', mb: '40px', fontWeight: 500, alignSelf: 'flex-start' }}>{randomFact}</Typography>
                                <LoadingButton
                                    loading={loading}
                                    variant="text"
                                    sx={{ textTransform: 'none', fontSize: '16px', fontWeight: 500, alignSelf: 'self-end', color: '#C62F4A', cursor: 'pointer' }}
                                    onClick={() => handleCloseModal()}
                                >Finalizar 🎉</LoadingButton>
                            </Box>
                    }
                </Box>
            </Dialog >
        </>
    )
}

export default FridayQuiz