import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const PatrasReport = Loadable(lazy(() => import('./PatrasReport')));
const WorkingHours = Loadable(lazy(() => import('./WorkingHours')));
const Profile = Loadable(lazy(() => import('./Profile')));

const settingsRoutes = [
  {
    path: '/report',
    element: (
      <PatrasReport />
    )
  },
  {
    path: '/workingHours',
    element: (
      <WorkingHours />
    )
  },
  {
    path: '/profile',
    element: (
      <Profile />
    )
  }
];

export default settingsRoutes;
