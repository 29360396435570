import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const GithubDashboard = Loadable(lazy(() => import('./GithubDashboard')));

const githubRoutes = [
  {
    path: '/github',
    element: (
      <GithubDashboard />
    )
  }
];

export default githubRoutes;