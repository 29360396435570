const initialState = {
  archiving: [],
  archivingNewUser: [],
  archivingIncomplete: []
};

let archivingStatus;

const archivingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'getArchiving':
      return {
        ...state,
        archiving: action.payload
      };
    case 'getArchivingNewUser':
      return {
        ...state,
        archivingNewUser: action.payload
      };
    case 'getArchivingStatus':
      archivingStatus = action.payload.archiving;
      return {
        ...state,
        archivingIncomplete: archivingStatus
      };
    default:
      return state;
  }
};

export default archivingReducer;
