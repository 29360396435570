import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const Dashboard = Loadable(lazy(() => import('./Dashboard')));

const dashboardRoutes = [
  {
    path: '/index',
    element: (
      <Dashboard />
    )
  }
];

export default dashboardRoutes;
