import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const ManagerAnalytics = Loadable(lazy(() => import('./AnalyticsDashboard')));

const managerAnalyticsRoutes = [
  {
    path: '/managerDashboard',
    element: (
      <ManagerAnalytics />
    )
  }
];

export default managerAnalyticsRoutes;
