import React, { useState, useEffect } from 'react'
import { FlexBox } from './FlexBox';
import { Box, Dialog, TextField, IconButton, Icon, Typography, Grid } from '@mui/material';
import { EmojiList } from 'app/utils/tasksUtils';
import { useDispatch } from 'react-redux';
import taskActions from 'app/redux/actions/taskActions';

function EmojiDialog({ openEmojiDialog }) {

    const dispatch = useDispatch();
    const [emojisFiltered, setEmojisFiltered] = useState(EmojiList());
    const [emojiCategoty, setEmojiCategory] = useState('all');

    const handleFilterEmojis = (e) => {
        const filterEmoji = [];
        EmojiList(true).forEach(emoji => {
            if (emoji?.tts?.includes(e)) {
                if (!filterEmoji.some(cat => cat.category === emoji.category)) {
                    filterEmoji.push({
                        category: emoji.category,
                        emojis: [emoji]
                    })
                } else {
                    const category = filterEmoji.find(cat => cat.category === emoji.category)
                    category.emojis.push(emoji)
                }
            }
        });
        setEmojisFiltered(filterEmoji);
    };

    useEffect(() => {
        if (emojiCategoty === 'all') {
            setEmojisFiltered(EmojiList());
        } else {
            setEmojisFiltered(EmojiList().filter(emoji => emoji.category === emojiCategoty));
        }
        // eslint-disable-next-line
    }, [emojiCategoty]);

    return (
        <>
            {
                openEmojiDialog &&
                <Dialog
                    maxWidth="xs"
                    open={openEmojiDialog}
                    onClose={() => dispatch(taskActions.openEmojiDialog(false))}
                >
                    <Box sx={{ padding: '1rem' }}>
                        <TextField
                            onChange={(e) => handleFilterEmojis(e.target.value)}
                            sx={{ mb: 1 }}
                            size="small"
                            label="Buscar emoji"
                            variant="outlined"
                            fullWidth
                        />
                        <FlexBox sx={{ justifyContent: 'space-around', alignItems: 'center' }}>
                            <IconButton onClick={() => setEmojiCategory('Smileys & People')}>
                                <Icon>insert_emoticon</Icon>
                            </IconButton>
                            <IconButton onClick={() => setEmojiCategory('Animals & Nature')}>
                                <Icon>nature</Icon>
                            </IconButton>
                            <IconButton onClick={() => setEmojiCategory('Food & Drink')}>
                                <Icon>fastfood</Icon>
                            </IconButton>
                            <IconButton onClick={() => setEmojiCategory('Travel & Places')}>
                                <Icon>flight</Icon>
                            </IconButton>
                            <IconButton onClick={() => setEmojiCategory('Activities')}>
                                <Icon>directions_bike</Icon>
                            </IconButton>
                            <IconButton onClick={() => setEmojiCategory('Objects')}>
                                <Icon>laptop</Icon>
                            </IconButton>
                            <IconButton onClick={() => setEmojiCategory('Symbols')}>
                                <Icon>accessible</Icon>
                            </IconButton>
                            <IconButton onClick={() => setEmojiCategory('Flags')}>
                                <Icon>outlined_flag</Icon>
                            </IconButton>
                            <IconButton onClick={() => setEmojiCategory('all')}>
                                <Icon>clear</Icon>
                            </IconButton>
                        </FlexBox>
                        <Box sx={{ height: '500px', overflowY: 'scroll' }}>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                {emojisFiltered.map((category, index) => (
                                    <>
                                        <Typography key={index} sx={{ my: 2 }} >{category.category}</Typography>
                                        <Grid container spacing={2} >
                                            {category?.emojis?.map((emoji, index) => (
                                                <Grid item lg={2} key={index}>
                                                    <IconButton onClick={() => dispatch(taskActions.emojiSelected(emoji))} >
                                                        <span>
                                                            {emoji.native}
                                                        </span>
                                                    </IconButton>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Dialog>
            }
        </>
    )
}

export default EmojiDialog