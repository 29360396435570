import React from 'react';
import { Box, styled } from '@mui/material';
import logo from '../../patrasLogos/logobeta.png';
import { HourGlass } from 'app/utils/svgIcons';

const LogoContainer = styled('img')(() => ({
    width: '200px',
}));

function MobileWaitingPage() {

    return (
        <Box sx={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white' }}>
            <Box sx={{ width: '100vw', height: '35vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', backgroundColor: 'white' }}>
                <LogoContainer src={logo} />
                <Box
                    sx={{
                        width: '45px',
                        height: '45px',
                        backgroundColor: '#FEE6EB',
                        borderRadius: '450%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <HourGlass width='16px' height='16px' color='#C62F4A' />
                </Box>
            </Box>
        </Box>
    )
}

export default MobileWaitingPage