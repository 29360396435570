/* eslint-disable no-case-declarations */
import { mapEvent } from "app/utils/calendarUtils/calendarReducerUtils";

const initialState = {
    companyData: [],
    usersCalendarConnections: [],
    usersEmailsConnections: [],
    usersSlackConnections: [],
    userAnalitycsSelection: null,
    teamSelection: null,
    userCalendarStats: null,
    userEmailMetricsStats: null,
    slackStats: null,
    userSlackStats: null,
    userIntegrations: null,
    userEventsManager: [],
    teamsPopUpStats: null,
    retroCardEdit: null,
    retroCardDelete: null,
    retroNewCard: null,
    retroNewColumn: null,
    retroEditColumn: null,
    newBoard: null,
    allBoards: null,
    templetesBoards: null,
    allResourceTasks: [],
    wordCloud: null
};

const managerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'getCompanyData':
            return {
                ...state,
                companyData: action.payload
            };
        case 'getUsersCalendarConnections':
            return {
                ...state,
                usersCalendarConnections: action.payload
            };
        case 'getUsersEmailsConnections':
            return {
                ...state,
                usersEmailsConnections: action.payload
            };
        case 'getUsersSlackConnections':
            return {
                ...state,
                usersSlackConnections: action.payload
            };
        case 'getUserIntegrations':
            return {
                ...state,
                userIntegrations: action.payload
            };
        case 'userAnalitycsSelection':
            return {
                ...state,
                userAnalitycsSelection: action.payload
            };
        case 'teamSelection':
            return {
                ...state,
                teamSelection: action.payload
            };
        case 'getUserCalendarStats':
            return {
                ...state,
                userCalendarStats: action.payload
            };
        case 'getUserEmailMetricsStats':
            return {
                ...state,
                userEmailMetricsStats: action.payload
            };
        case 'getTeamsSlackStats':
            return {
                ...state,
                slackStats: action.payload
            };
        case 'getUserSlackStats':
            return {
                ...state,
                userSlackStats: action.payload
            };
        case 'getUserEventsManager':
            const allEvents = action.payload.map(mapEvent);
            return {
                ...state,
                userEventsManager: allEvents
            };
        case 'getTeamsPopUpStats':
            return {
                ...state,
                teamsPopUpStats: action.payload
            };
        case 'retroCardEdit':
            return {
                ...state,
                retroCardEdit: action.payload
            };
        case 'retroCardDelete':
            return {
                ...state,
                retroCardDelete: action.payload
            };
        case 'retroNewCard':
            return {
                ...state,
                retroNewCard: action.payload
            };
        case 'retroNewColumn':
            return {
                ...state,
                retroNewColumn: action.payload
            };
        case 'retroEditColumn':
            return {
                ...state,
                retroEditColumn: action.payload
            };
        case 'addBoard':
            return {
                ...state,
                newBoard: action.payload
            };
        case 'getBoards':
            return {
                ...state,
                allBoards: action.payload
            };
        case 'getTempletesBoards':
            return {
                ...state,
                templetesBoards: action.payload
            };
        case 'getTasksByResource':
            return {
                ...state,
                allResourceTasks: action.payload
            };
        case 'getWordCloud':
            return {
                ...state,
                wordCloud: action.payload
            };
        default:
            return state;
    }
};

export default managerReducer;
