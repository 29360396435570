/* eslint-disable no-undef */
import axios from 'axios';
const urlUserService = process.env.REACT_APP_URI_U_SERVICE;

const managerActions = {
  getCompanyData: () => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token;
      try {
        const res = await axios.get(`${urlUserService}/manager`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getCompanyData', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  registerManagerAccess: () => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token;
      try {
        const res = await axios.post(`${urlUserService}/manager/register-access`, null, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getUserIntegrations: (id) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token;
      try {
        const res = await axios.get(`${urlUserService}/manager/user-integrations/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getUserIntegrations', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getUsersCalendarConnections: (from, till) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_CALENDAR}/manager/connections?from=${from}&till=${till}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getUsersCalendarConnections', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getUsersEmailsConnections: (from, till) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_EMETRICS}/manager/connections?from=${from}&till=${till}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getUsersEmailsConnections', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getUsersSlackConnections: (from, till) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_EMETRICS}/manager/slack/connections?from=${from}&till=${till}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getUsersSlackConnections', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  userAnalitycsSelection: (selection) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'userAnalitycsSelection', payload: selection });
      } catch (error) {
        return error;
      }
    };
  },
  teamSelection: (selection) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'teamSelection', payload: selection });
      } catch (error) {
        return error;
      }
    };
  },
  getUserCalendarStats: (userId, from, till) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token;
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_CALENDAR}/manager/user/${userId}?from=${from}&till=${till}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getUserCalendarStats', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getUserEventsManager: (userId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token;
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_CALENDAR}/manager/events/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getUserEventsManager', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getUserEmailMetricsStats: (userId, from, till) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token;
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_EMETRICS}/manager/user/${userId}?from=${from}&till=${till}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getUserEmailMetricsStats', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getTeamsSlackStats: (from, till) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token;
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_EMETRICS}/manager/slack/stats?from=${from}&till=${till}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getTeamsSlackStats', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getUserSlackStats: (userId, from, till) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token;
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_EMETRICS}/manager/slack/user/${userId}?from=${from}&till=${till}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getUserSlackStats', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getTeamsPopUpStats: (from, till) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token;
      try {
        const res = await axios.get(`${urlUserService}/feedback?from=${from}&to=${till}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getTeamsPopUpStats', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  retroCardEdit: (card) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'retroCardEdit', payload: card });
      } catch (error) {
        return error;
      }
    };
  },
  retroCardDelete: (card) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'retroCardDelete', payload: card });
      } catch (error) {
        return error;
      }
    };
  },
  retroNewCard: (card) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'retroNewCard', payload: card });
      } catch (error) {
        return error;
      }
    };
  },
  retroNewColumn: (column) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'retroNewColumn', payload: column });
      } catch (error) {
        return error;
      }
    };
  },
  retroEditColumn: (column) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'retroEditColumn', payload: column });
      } catch (error) {
        return error;
      }
    };
  },
  addBoard: (boardData) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token;
      try {
        const res = await axios.post(`${urlUserService}/manager/boards`, boardData, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'addBoard', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getBoards: () => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token;
      try {
        const res = await axios.get(`${urlUserService}/manager/boards?templates=${false}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getBoards', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getRegularBoards: () => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token;
      try {
        const res = await axios.get(`${urlUserService}/regular/boards`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getRegularBoards', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getTempletesBoards: () => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token;
      try {
        const res = await axios.get(`${urlUserService}/manager/boards?templates=${true}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getTempletesBoards', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  updateBoard: (boardId, boardData) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token;
      try {
        const res = await axios.put(`${urlUserService}/manager/boards/${boardId}`, boardData, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'updateBoard', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  deleteBoard: (boardId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token;
      try {
        const res = await axios.delete(`${urlUserService}/manager/boards/${boardId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'deleteBoard', payload: boardId });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getTasksByResource: (resourceId, completed) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_TASK}/task/resource/manager/${resourceId}?${completed && 'completed=yes'}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'getTasksByResource', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  sharePdf: (link) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(
          `${urlUserService}/manager/pdfLink`, { link: link }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'sharePdf', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getWordCloud: (source, from, till) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_NLP}/manager/wordCloud?source=${source}&from=${from}&till=${till}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'getWordCloud', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
};

export default managerActions;
