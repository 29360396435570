export function mapEvent(event) {
    const start = event.isAllDay
        ? new Date(
            new Date(event.start).getUTCFullYear(),
            new Date(event.start).getUTCMonth(),
            new Date(event.start).getUTCDate(),
            new Date(event.start).getUTCHours(),
            new Date(event.start).getUTCMinutes()
        )
        : new Date(event.start);

    const end = event.isAllDay
        ? new Date(
            new Date(event.end).getUTCFullYear(),
            new Date(event.end).getUTCMonth(),
            new Date(event.end).getUTCDate(),
            new Date(event.end).getUTCHours(),
            new Date(event.end).getUTCMinutes()
        )
        : new Date(event.end);

    const allDay =
        event.isAllDay || Math.abs(new Date(event.end) - new Date(event.start)) / 36e5 > 24;

    return {
        start,
        end,
        title: event.eventName ? ` ${event.eventName}` : ' Sin título',
        description: event.description,
        location: event.location,
        linkMeet: getLinkMeet(event),
        organizerEvent: getOrganizerEvent(event),
        attendees: event.attendees,
        eventId: event.id,
        eventStatus: event.eventStatus,
        status: event.status,
        calendarId: event.CalendarId,
        timeGoalId: event.TimeGoalId,
        timeGoalColor: event.TimeGoal?.color,
        recurring: event.recurring,
        recurrence: event.recurrence,
        provider: event.provider,
        allDay
    };
}

export function getLinkMeet(event) {
    if (event.conferenceData) {
        if (event.conferenceData.joinUrl) {
            return event.conferenceData.joinUrl;
        } else if (event.conferenceData.entryPoints && event.conferenceData.entryPoints[0]?.uri) {
            return event.conferenceData.entryPoints[0].uri;
        }
    }
    return null;
}

export function getOrganizerEvent(event) {
    return event.organizer.email || event.organizer.emailAddress?.address;
}