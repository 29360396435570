import theme from 'app/styles/PaletteTheme';

const ColorGenerator = (color, option) => {
  const newColor = Object.getOwnPropertyDescriptor(theme.palette, color)?.value[option] || color;
  return newColor;
};

export default ColorGenerator;

export const colorScheduler = (index) => {
  switch (index) {
    case 0:
      return '#ECD4FE';
    case 1:
      return '#D4E3FE';
    case 2:
      return '#D4FEDB';
    case 3:
      return '#FEE8D4';
    default:
      return '#FEFDD4';
  }
};