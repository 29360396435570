/* eslint-disable no-case-declarations */
const initialState = {
  newFile: null,
  file: null,
  files: [],
  bucket: [],
  bucketSize: null,
  filterByName: null
};

const fileReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'addFile':
      return {
        ...state,
        newFile: action.payload
      };
    case 'getFileById':
      return {
        ...state,
        file: action.payload
      };
    case 'getFilesByUserId':
      return {
        ...state,
        files: action.payload,
        filterByName: action.payload.files.filter((file) => file.status === true)
      };
    case 'getBucketByCompany':
      return {
        ...state,
        bucket: action.payload
      };
    case 'getBucketSize':
      return {
        ...state,
        bucketSize: action.payload
      };
    case 'getFilterByName':
      let filter;
      let filterStatusFiles;

      switch (action.payload) {
        case 'all':
          filterStatusFiles = state.files.files.filter((file) => file.status === true);
          filter = filterStatusFiles;
          break;
        case 'not_analyzed':
          filterStatusFiles = state.files.files.filter((file) => file.status === true);
          filter = filterStatusFiles.filter((file) => file.analyzed === false);
          break;
        case 'analyzed':
          filterStatusFiles = state.files.files.filter((file) => file.status === true);
          filter = filterStatusFiles.filter((file) => file.analyzed === true);
          break;
        default:
          filterStatusFiles = state.files.files.filter((file) => file.status === true);
          filter = filterStatusFiles.filter((file) =>
            file.Key.toLowerCase().includes(action.payload.trim().toLowerCase())
          );
      }

      return {
        ...state,
        filterByName: filter
      };
    default:
      return state;
  }
};

export default fileReducer;
