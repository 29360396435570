const initialState = {
  reportFrequency: [],
  newReportFrequency: [],
  notiWeeklySummary: false,
  notiFridayQuiz: false,
  maintenanceActive: { data: false, status: 'waiting' }
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'getReportFrequency':
      return {
        ...state,
        reportFrequency: action.payload
      };
    case 'createReportFrequency':
      return {
        ...state,
        newReportFrequency: action.payload
      };
    case 'notiWeeklySummary':
      return {
        ...state,
        notiWeeklySummary: action.payload
      };
    case 'notiFridayQuiz':
      return {
        ...state,
        notiFridayQuiz: action.payload
      };
    case 'maintenanceActive':
      return {
        ...state,
        maintenanceActive: action.payload
      };
    default:
      return state;
  }
};

export default notificationsReducer;
