const initialState = {
  emailData: [],
  emailsMetrics: [],
  dataAvailable: 0,
  lastEmail: {
    email: null,
    creating: false,
    dataReady: 0
  },
  emailsMetricsAverages: [],
  usersEmailStats: [],
  githubData: null,
  eMIntegrations: null,
  metricsByIntegratedApp: null,
  githubDataCreating: false,
  accessRenewed: false,
  pRData: []
};

const emailMetricsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'getEmailsByUser':
      return {
        ...state,
        emailData: action.payload
      };
    case 'getMetricsByEmailId':
      return {
        ...state,
        emailsMetrics: action.payload
      };
    case 'emailMetricsAvailable':
      return {
        ...state,
        dataAvailable: action.payload
      };
    case 'lastEmailAdded':
      return {
        ...state,
        lastEmail: action.payload
      };
    case 'emailMetricsAverage':
      return {
        ...state,
        emailsMetricsAverages: action.payload
      };
    case 'createEmailMetricsAverage':
      return {
        ...state,
        emailsMetricsAverages: action.payload
      };
    case 'getUsersEmailStats':
      return {
        ...state,
        usersEmailStats: action.payload
      };
    case 'getEMIntegrations':
      return {
        ...state,
        eMIntegrations: action.payload
      };
    case 'getMetricsByIntegratedApp':
      return {
        ...state,
        metricsByIntegratedApp: action.payload
      };
    case 'getGithubData':
      return {
        ...state,
        githubData: action.payload
      };
    case 'githubDataCreating':
      return {
        ...state,
        githubDataCreating: action.payload
      };
    case 'accessRenewed':
      return {
        ...state,
        accessRenewed: action.payload
      };
    case 'getPRData':
      return {
        ...state,
        pRData: action.payload
      };
    default:
      return state;
  }
};

export default emailMetricsReducer;
