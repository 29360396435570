import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const TasksHome = Loadable(lazy(() => import('./TasksHome')));
const TasksComparatives = Loadable(lazy(() => import('./TasksComparatives')));
const TasksPriority = Loadable(lazy(() => import('./TaskPriority')));
const ProjectsHome = Loadable(lazy(() => import('./projects/ProjectsHome')));

const TasksRoutes = [
  {
    path: '/tasksManagement',
    element: (
      <TasksHome />
    )
  },
  {
    path: '/tasksComparative',
    element: (
      <TasksComparatives />
    )
  },
  {
    path: '/tasksPriority',
    element: (
      <TasksPriority />
    )
  },
  {
    path: '/projects',
    element: (
      <ProjectsHome />
    )
  }
];

export default TasksRoutes;
