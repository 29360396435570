/* eslint-disable no-undef */
import axios from 'axios';
import { urlUserService } from '../../App';

const companyActions = {
  getCompanies: () => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${urlUserService}/company`, {
          headers: { Authorization: 'Bearer ' + token }
        });
        dispatch({ type: 'getCompanies', payload: res.data.response.companies });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getCompany: (id) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${urlUserService}/company/${id}`, {
          headers: { Authorization: 'Bearer ' + token }
        });
        dispatch({ type: 'getCompany', payload: res.data.response.company });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getCompanyByName: (name) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(`${urlUserService}/companybyname`, name, {
          headers: { Authorization: 'Bearer ' + token }
        });
        // dispatch({ type: 'getCompanyByName', payload: res.data.response.company })
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  filterUser: (value) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'filter', payload: value });
      } catch (error) {
        return error;
      }
    };
  },
  addCompany: (newCompany) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(
          `${urlUserService}/company`,
          { newCompany }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'addCompany', payload: res.data.response.company });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  updateCompany: (id, companyData) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.put(
          `${urlUserService}/company/${id}`,
          { companyData }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  removeCompany: (id) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.delete(`${urlUserService}/company/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  }
};

export default companyActions;
