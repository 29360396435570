const initialState = {
    taskFilterStates: { completed: false, rec: false }
};

const patrasStatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'taskFilterStates':
            return {
                ...state,
                taskFilterStates: action.payload
            };
        default:
            return state;
    }
};

export default patrasStatesReducer;