import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const Integrations = Loadable(lazy(() => import('./Integrations')));

const IntegrationsRoutes = [
  {
    path: '/integrations',
    element: (
      <Integrations />
    )
  },
  {
    path: '/integrations-teams',
    element: (
      <Integrations />
    )
  }
];

export default IntegrationsRoutes;
