/* eslint-disable no-undef */
import axios from 'axios';

const notificationsActions = {
  getReportFrequency: () => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_NOTIFICATIONS}/newsletter`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'getReportFrequency', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  createReportFrequency: (frequency, deliveryTime, userName) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_URI_NOTIFICATIONS}/newsletter`,
          {
            frequency: frequency,
            deliveryTime: deliveryTime,
            userName: userName
          }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'createReportFrequency', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  updateReportFrequency: (reportId, deliveryTime) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_URI_NOTIFICATIONS}/newsletter/${reportId}`,
          { deliveryTime: deliveryTime }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'updateReportFrequency', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  switchReport: (reportId, timeOffSet) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.patch(
          `${process.env.REACT_APP_URI_NOTIFICATIONS}/newsletter/${reportId}?timeOffSet=${timeOffSet}`, {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  deleteReportFrequency: () => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.delete(
          `${process.env.REACT_APP_URI_NOTIFICATIONS}/newsletter`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'deleteReportFrequency', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getViewedNotifications: () => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_NOTIFICATIONS}/notifications/old`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  setViewNotifications: (ids) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.patch(
          `${process.env.REACT_APP_URI_NOTIFICATIONS}/viewedNotifications`,
          { ids }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  openConnection: (connectionId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_NOTIFICATIONS}/openSse?connectionID=${connectionId}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  notiWeeklySummary: (notiId) => {
    return async (dispatch) => {
      try {
        const res = dispatch({ type: 'notiWeeklySummary', payload: notiId });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  notiFridayQuiz: (notiId) => {
    return async (dispatch) => {
      try {
        const res = dispatch({ type: 'notiFridayQuiz', payload: notiId });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  maintenanceActive: (maintenance) => {
    return async (dispatch) => {
      try {
        const res = dispatch({ type: 'maintenanceActive', payload: maintenance });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
};

export default notificationsActions;
