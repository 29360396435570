/* eslint-disable no-case-declarations */
const initialState = {
  companies: [],
  company: null,
  newCompany: []
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'getCompanies':
      return {
        ...state,
        companies: action.payload
      };
    case 'getCompany':
      return {
        ...state,
        company: action.payload,
        filter: action.payload.Users.filter((user) => user.status === true)
      };
    case 'addCompany':
      return {
        ...state,
        newCompany: action.payload
      };
    case 'filter':
      let filteredUser = state.company.Users.filter((user) =>
        user.Emails[0]?.email.toLowerCase().includes(action.payload.trim().toLowerCase())
      );
      return {
        ...state,
        filter: filteredUser.filter((user) => user.status === true)
      };
    default:
      return state;
  }
};

export default companyReducer;
