import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'app/components/ErrorFallback';

const MatxCalendar = Loadable(lazy(() => import('./MatxCalendar')));
const EventCalendar = Loadable(lazy(() => import('./ExistingEventDialog')));
const CalendarComparatives = Loadable(lazy(() => import('./CalendarComparatives')));

const calendarRoutes = [
  {
    path: '/calendar',
    element: (
      <MatxCalendar />
    )
  },
  {
    path: '/calendar/:eventId',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <EventCalendar />
      </ErrorBoundary>
    )
  },
  {
    path: '/calendarComparative',
    element: (
      <CalendarComparatives />
    )
  },
];

export default calendarRoutes;
