import React from 'react';
import { styled, Box } from '@mui/material';
import { FlexAlignCenter } from 'app/components/FlexBox';
import logo from '../../../patrasLogos/logo-negro-color.png';
import onboardingImage from '../../../patrasLogos/onboardingImage.png';
import { CardsContainer } from 'app/utils/Cards';
import UserInfo from './components/UserInfo';
import { useSelector } from 'react-redux';
import { useWindowWidth } from '@react-hook/window-size';

const CreateUserContainer = styled('div')(({ theme }) => ({
    minWidth: '488px',
    '& .heading': {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: theme.typography.fontWeightBold,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2rem'
}));

const Image = styled('img')(() => ({
    width: '178px',
    marginBottom: '3rem'
}));

const ImageOnboarding = styled('img')(() => ({
    width: '100%',
}));

export default function OnboardingDashboard() {
    const onlyWidth = useWindowWidth();
    const loggedUser = useSelector((store) => store.userReducer.user);

    return (
        <FlexAlignCenter sx={{ width: '100%', justifyContent: 'center', height: '90vh' }}>
            <CreateUserContainer>
                <FlexAlignCenter sx={{ width: '100%', justifyContent: 'center', hieght: '100%' }}>
                    {loggedUser &&
                        <CardsContainer my={4} width='80vw' backgroundColor='#f5f5f5'>
                            <FlexAlignCenter width='100%' height='100%'>
                                {
                                    (onlyWidth > 800) &&
                                    <Box width='65%' borderRight={'1px solid #f5f5f5'}>
                                        <ImageOnboarding className="img" src={onboardingImage} />
                                    </Box>
                                }
                                <FlexAlignCenter flexDirection={'column'} justifyContent={'center'} width='35%'>
                                    <Image className="logo" src={logo} />
                                    <UserInfo />
                                </FlexAlignCenter>
                            </FlexAlignCenter>
                        </CardsContainer>
                    }
                </FlexAlignCenter>
            </CreateUserContainer>
        </FlexAlignCenter>
    );
}
