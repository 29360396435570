import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const PatrasFeedback = Loadable(lazy(() => import('./PatrasFeedback')));

const patrasFeedbackRoutes = [
  {
    path: '/feedback',
    element: (
      <PatrasFeedback />
    )
  }
];

export default patrasFeedbackRoutes;
