import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const StatSchedule = Loadable(lazy(() => import('./StatSchedule')));

const scheduleRoutes = [
  {
    path: '/schedule',
    element: (
      <StatSchedule />
    )
  }
];

export default scheduleRoutes;
