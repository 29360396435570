import React, { useState, useEffect } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { Dialog, Box, Typography } from '@mui/material';
import { H3 } from 'app/components/Typography';
import { PrimaryButton } from 'app/utils/ButtonsPatras';
import _ from 'lodash'; 

const IdleTimer = () => {
    const [showModal, setShowModal] = useState(false);
    const { logout } = useAuth0();

    const handleUserActivity = _.debounce(() => {
        setShowModal(true);
    }, 7200000);

    const handleLogout = () => {
        logout({ returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI_LOGOUT });
        setShowModal(false);
    };

    useEffect(() => {
        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('mousedown', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener("click", handleUserActivity);
        window.addEventListener("scroll", handleUserActivity);

        return () => {
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('mousedown', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
            window.removeEventListener("click", handleUserActivity);
            window.removeEventListener("scroll", handleUserActivity);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <Dialog
            maxWidth="xs"
            open={showModal}
            fullWidth={true}
        >
            <Box
                p={2}
                sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
            >
                <H3 sx={{ fontSize: '20px', textAlign: 'center' }}>
                    Sesión expirada ⏰
                </H3>

                <Typography sx={{ fontWeight: 400, fontSize: '14px', color: '#424142', mb: .5 }}>
                    Tu sesión ha expirado debido a inactividad. Por favor, vuelve a iniciar sesión para continuar.
                </Typography>

                <PrimaryButton
                    onClick={handleLogout}
                    color="primary"
                    sx={{ alignSelf: 'end' }}
                >
                    Volver a iniciar sesión
                </PrimaryButton>
            </Box>
        </Dialog>
    );
};

export default IdleTimer;
