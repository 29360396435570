import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const WordCloud = Loadable(lazy(() => import('./WordCloud')));

const wordCloudRoutes = [
  {
    path: '/wordCloud',
    element: (
      <WordCloud />
    )
  }
];

export default wordCloudRoutes;
