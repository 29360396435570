import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const Wordly = Loadable(lazy(() => import('./Wordly')));

const recreationRoutes = [
  {
    path: '/recreation',
    element: (
      <Wordly />
    )
  }
];

export default recreationRoutes;
