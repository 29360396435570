/* eslint-disable no-undef */
import axios from 'axios';

const emailMetricsActions = {
  addEmail: (userId, userEmail) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(`${process.env.REACT_APP_URI_EMETRICS}/email/${userId}`, {
          email: userEmail
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'addEmail', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getEmailsByUser: (userId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_EMETRICS}/email/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getEmailsByUser', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  deleteEmail: (emailId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.delete(`${process.env.REACT_APP_URI_EMETRICS}/email/${emailId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getMetricsByEmailId: (emailId, from, till) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_EMETRICS}/metrics/${emailId}?from=${from}&till=${till}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'getMetricsByEmailId', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  synchronizeEmailData: (emailId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.patch(
          `${process.env.REACT_APP_URI_EMETRICS}/email/${emailId}`, {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'synchronizeEmailData', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  emailMetricsAvailable: (number) => {
    return async (dispatch) => {
      try {
        const res = number;
        dispatch({ type: 'emailMetricsAvailable', payload: res });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  lastEmailAdded: (emailInfo) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'lastEmailAdded', payload: emailInfo });
      } catch (error) {
        return error;
      }
    };
  },
  emailMetricsAverage: (period) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_EMETRICS}/average?period=${period}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'emailMetricsAverage', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  createEmailMetricsAverage: (month, year) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_URI_EMETRICS}/average`, { month: month, year: year }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'createEmailMetricsAverage', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getUsersEmailStats: ({ from, till }) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token;
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_EMETRICS}/manager/stats?from=${from}&till=${till}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getUsersEmailStats', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  addSlack: (code) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token;
      try {
        const res = await axios.post(`${process.env.REACT_APP_URI_EMETRICS}/integrations/slack?code=${code}`,
          {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'addSlack', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  addMSTeams: (code) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token;
      try {
        const res = await axios.post(`${process.env.REACT_APP_URI_EMETRICS}/integrations/MSTeams?code=${code}`,
          {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'addMSTeams', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  addGitHub: (code) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token;
      try {
        const res = await axios.post(`${process.env.REACT_APP_URI_EMETRICS}/integrations/GitHub?code=${code}`,
          {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'githubDataCreating', payload: true });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getGithubData: () => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_EMETRICS}/integrations/GitHub`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getGithubData', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  githubDataCreating: (loading) => {
    return async (dispatch) => {
      try {
        const res = dispatch({ type: 'githubDataCreating', payload: loading });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getEMIntegrations: () => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_EMETRICS}/integrations`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getEMIntegrations', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  deleteIntegration: (integrationId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.delete(`${process.env.REACT_APP_URI_EMETRICS}/integrations/${integrationId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getMetricsByIntegratedApp: (app, from, till) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_EMETRICS}/metricsByApp/${app}?from=${from}&till=${till}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getMetricsByIntegratedApp', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  renewEmailAccess: (emailId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.patch(`${process.env.REACT_APP_URI_EMETRICS}/emailRenew/${emailId}`, {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'accessRenewed', payload: true });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  beforeRenewEmailAccess: (emailId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_EMETRICS}/emailRenew/${emailId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    }
  },
  getPRData: () => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_EMETRICS}/integrations/PRData`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getPRData', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  }
};

export default emailMetricsActions;
