/* eslint-disable react/display-name */
import { Suspense } from 'react';
import Loading from './MatxLoading';
import {
  DashboardSkeleton,
  CalendarSkeleton,
  TasksManagmentSkeleton,
  TasksPrioritySkeleton,
  ProjectsSkeleton,
  ScheduleSkeleton,
  AnalyticsSkeleton,
  MessageAnalyticsSkeleton,
  HealthSkeleton,
  IntegrationsSkeleton,
  ManagerDashboardSkeleton,
  IndividualAnalyticsSkeleton,
  ConnectionsSkeleton,
  SentimentalDashboardSkeleton,
  TeamsSkeleton,
  GithubSkeleton,
  RetroBoard
} from 'app/utils/SkeletonView';

const Loadable = (Component) => (props) => {

  const currentPath = window.location.pathname.split('/')[1];

  let fallbackContent;
  switch (currentPath) {
    case 'index':
      fallbackContent = <DashboardSkeleton />;
      break;
    case 'calendar':
      fallbackContent = <CalendarSkeleton />;
      break;
    case 'tasksManagement':
      fallbackContent = <TasksManagmentSkeleton />;
      break;
    case 'tasksPriority':
      fallbackContent = <TasksPrioritySkeleton />;
      break;
    case 'projects':
      fallbackContent = <ProjectsSkeleton />;
      break;
    case 'schedule':
      fallbackContent = <ScheduleSkeleton />;
      break;
    case 'analytics':
      fallbackContent = <AnalyticsSkeleton />;
      break;
    case 'messageAnalytics':
      fallbackContent = <MessageAnalyticsSkeleton />;
      break;
    case 'health':
      fallbackContent = <HealthSkeleton />;
      break;
    case 'integrations':
      fallbackContent = <IntegrationsSkeleton />;
      break;
    case 'managerDashboard':
      fallbackContent = <ManagerDashboardSkeleton />;
      break;
    case 'individualAnalytics':
      fallbackContent = <IndividualAnalyticsSkeleton />;
      break;
    case 'connections':
      fallbackContent = <ConnectionsSkeleton />;
      break;
    case 'sentimentalDashboard':
      fallbackContent = <SentimentalDashboardSkeleton />;
      break;
    case 'teams':
      fallbackContent = <TeamsSkeleton />;
      break;
    case 'retro-board':
      fallbackContent = <RetroBoard />;
      break;
    case 'boards':
      fallbackContent = <TasksManagmentSkeleton />;
      break;
    case 'github':
      fallbackContent = <GithubSkeleton />;
      break;
    default:
      fallbackContent = <Loading />;
  }

  return (
    <Suspense
      fallback={fallbackContent}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default Loadable;

