import useSettings from 'app/hooks/useSettings';
import { MatxLayouts } from './index';
import theme from 'app/styles/PaletteTheme';
import { ThemeProvider } from '@mui/material';

const MatxLayout = (props) => {
  const { settings } = useSettings();
  const Layout = MatxLayouts[settings.activeLayout];

  return (
    <ThemeProvider theme={theme}>
      <Layout {...props} />
    </ThemeProvider>
  );
};

export default MatxLayout;
