import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const People = Loadable(lazy(() => import('../People')));
const Teams = Loadable(lazy(() => import('../Teams')));

const adminRoutes = [
  {
    path: '/people',
    element: (
      <People />
    )
  },
  {
    path: '/admin-teams',
    element: (
      <Teams />
    )
  }

];

export default adminRoutes;