export const timeLocalStart = new Date(new Date().setHours(9, 0, 0, 0))
  .toISOString()
  .split('T')[1];

export const timeLocalFinish = new Date(new Date().setHours(17, 0, 0, 0))
  .toISOString()
  .split('T')[1];

export const workingHours = [
  {
    start: '',
    end: '',
    breakStart: '',
    breakEnd: ''
  },
  {
    start: timeLocalStart,
    end: timeLocalFinish,
    breakStart: '',
    breakEnd: ''
  },
  {
    start: timeLocalStart,
    end: timeLocalFinish,
    breakStart: '',
    breakEnd: ''
  },
  {
    start: timeLocalStart,
    end: timeLocalFinish,
    breakStart: '',
    breakEnd: ''
  },
  {
    start: timeLocalStart,
    end: timeLocalFinish,
    breakStart: '',
    breakEnd: ''
  },
  {
    start: timeLocalStart,
    end: timeLocalFinish,
    breakStart: '',
    breakEnd: ''
  },
  {
    start: '',
    end: '',
    breakStart: '',
    breakEnd: ''
  }
];