import { Stack, Box, Skeleton } from '@mui/material';
import React from 'react';

export const DashboardSkeleton = () => {
    return <>
        <Stack spacing={2} sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
            alignItems: 'center', mx: 4, my: 2
        }}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'200px'} height={40} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'200px'} height={40} />
        </Stack>
        <Stack m={2} mx={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'120px'} height={40} />
        </Stack>
        <Stack sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
            alignItems: 'center', mx: 4, my: 2
        }}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'23%'} height={100} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'23%'} height={100} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'23%'} height={100} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'23%'} height={100} />
        </Stack>
        <Stack sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
            alignItems: 'center', mx: 4, my: 2
        }}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'23%'} height={360} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'48%'} height={360} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'23%'} height={360} />
        </Stack>
        <Stack m={2} mx={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'120px'} height={40} />
        </Stack>
        <Stack sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
            alignItems: 'center', mx: 4, my: 2
        }}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'75%'} height={450} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'23%'} height={450} />
        </Stack>
    </>

}

export const CalendarSkeleton = () => {
    return <>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
        >
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'160px'} height={50} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'250px'} height={50} />
            </Box>
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100px'} height={50} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'200px'} height={50} />
            </Box>
        </Stack>
        <Stack m={2} mx={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'80vh'} />
        </Stack>
    </>
}

export const TasksManagmentSkeleton = () => {
    return <>
        <Stack m={2} mx={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'200px'} height={40} />
        </Stack>
        <Stack m={2} mx={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'200px'} />
        </Stack>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
        >
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'160px'} height={50} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'160px'} height={50} />
            </Box>
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'80px'} height={50} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'80px'} height={50} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'150px'} height={50} />
            </Box>
        </Stack>
        <Stack m={2} mx={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'400px'} />
        </Stack>
    </>
}

export const TasksPrioritySkeleton = () => {
    return <>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
        >
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'160px'} height={50} />
            </Box>
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'120px'} height={50} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'150px'} height={50} />
            </Box>
        </Stack>
        <Stack
            spacing={2}
            direction="row"
            justifyContent="space-between"
            m={4}
            mt={3}
            mb={2}
        >
            <Box display={'flex'} flexDirection={'column'} gap={2} width={'30%'}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'180px'} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'180px'} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'180px'} />
            </Box>
            <Box display={'flex'} gap={2} width={'70%'}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'80vh'} />
            </Box>
        </Stack>
    </>
}

export const ProjectsSkeleton = () => {
    return <>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
        >
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'150px'} height={50} />
            </Box>
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'200px'} height={50} />
            </Box>
        </Stack>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
        >
            <Box display={'flex'} flexDirection={'column'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'40vw'} height={'250px'} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'40vw'} height={'250px'} />
            </Box>
            <Box display={'flex'} flexDirection={'column'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'40vw'} height={'250px'} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'40vw'} height={'250px'} />
            </Box>
        </Stack>
    </>
}

export const ScheduleSkeleton = () => {
    return <>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
        >
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'300px'} height={50} />
            </Box>
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'180px'} height={50} />
            </Box>
        </Stack>
        <Stack sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
            alignItems: 'center', mx: 4, my: 2
        }}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'23%'} height={100} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'23%'} height={100} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'23%'} height={100} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'23%'} height={100} />
        </Stack>
        <Stack m={2} mx={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'400px'} />
        </Stack>

        <Stack
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
        >
            <Box display={'flex'} gap={2} width={'60%'}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'250px'} />
            </Box>
            <Box display={'flex'} gap={2} width={'40%'}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'250px'} />
            </Box>
        </Stack>
    </>
}

export const AnalyticsSkeleton = () => {
    return <>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
        >
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'350px'} height={50} />
            </Box>
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'180px'} height={50} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'80px'} height={50} />
            </Box>
        </Stack>
        <Stack sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
            alignItems: 'center', mx: 4, my: 2
        }}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'20%'} height={180} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'20%'} height={180} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'20%'} height={180} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'20%'} height={180} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'12%'} height={180} />
        </Stack>
        <Stack sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
            alignItems: 'center', mx: 4, my: 2
        }}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'49%'} height={'400px'} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'49%'} height={'400px'} />
        </Stack>
        <Stack m={2} mx={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'500px'} />
        </Stack>

    </>
}

export const MessageAnalyticsSkeleton = () => {
    return <>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
        >
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'150px'} height={50} />
            </Box>
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'180px'} height={50} />
            </Box>
        </Stack>
        <Stack m={2} mx={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100px'} height={40} />
        </Stack>
        <Stack sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
            alignItems: 'center', mx: 4, my: 2
        }}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'23%'} height={100} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'23%'} height={100} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'23%'} height={100} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'23%'} height={100} />
        </Stack>
        <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            m={4}
            mt={3}
            mb={2}
        >
            <Box display={'flex'} flexDirection={'row'} gap={2} height={'100%'} width={'100%'}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'50%'} height={'80vh'} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'50%'} height={'80vh'} />
            </Box>
            <Box display={'flex'} flexDirection={'column'} gap={2} height={'100%'} width={'100%'}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'350px'} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'350px'} />
            </Box>
        </Stack>
    </>
}

export const HealthSkeleton = () => {
    return <>
        <Stack m={2} mx={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100px'} height={40} />
        </Stack>
        <Stack sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
            alignItems: 'center', mx: 4, my: 2
        }}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'23%'} height={100} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'23%'} height={100} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'23%'} height={100} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'23%'} height={100} />
        </Stack>
        <Stack m={2} mx={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'400px'} />
        </Stack>
        <Stack m={2} mx={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'400px'} />
        </Stack>

    </>
}

export const IntegrationsSkeleton = () => {
    return <>
        <Stack m={2} mx={4} spacing={1.5} mt={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'200px'} height={40} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'120px'} height={40} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'400px'} height={35} />
        </Stack>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
        >
            <Box display={'flex'} flexDirection={'column'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'40vw'} height={'150px'} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'40vw'} height={'150px'} />
            </Box>
            <Box display={'flex'} flexDirection={'column'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'40vw'} height={'150px'} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'40vw'} height={'150px'} />
            </Box>
        </Stack>
        <Stack m={2} mx={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'200px'} height={40} />
        </Stack>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
        >
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'40vw'} height={'150px'} />
            </Box>
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'40vw'} height={'150px'} />
            </Box>
        </Stack>
    </>
}

export const ManagerDashboardSkeleton = () => {
    return <>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
        >
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'200px'} height={50} />
            </Box>
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'200px'} height={50} />
            </Box>
        </Stack>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
        >
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'160px'} height={70} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'250px'} height={70} />
            </Box>
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'200px'} height={50} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'200px'} height={50} />
            </Box>
        </Stack>
        <Stack m={2} mx={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'400px'} />
        </Stack>
        <Stack m={2} mx={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'600px'} />
        </Stack>
    </>
}

export const IndividualAnalyticsSkeleton = () => {
    return <>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
        >
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'200px'} height={70} />
            </Box>
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'200px'} height={50} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'300px'} height={50} />
            </Box>
        </Stack>

        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
            spacing={2}
        >
            <Box display={'flex'} gap={2} width={'80%'}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'80px'} />
            </Box>
            <Box display={'flex'} gap={2} width={'20%'}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'80px'} />
            </Box>
        </Stack>

        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
            spacing={2}
        >
            <Box display={'flex'} gap={2} width={'25%'}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'320px'} />
            </Box>
            <Box display={'flex'} gap={2} width={'25%'}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'320px'} />
            </Box>
            <Box display={'flex'} gap={2} width={'50%'}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'320px'} />
            </Box>
        </Stack>

        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
            spacing={2}
        >
            <Box display={'flex'} gap={2} width={'70%'}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'450px'} />
            </Box>
            <Box display={'flex'} flexDirection={'column'} gap={2} width={'30%'}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'100px'} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'100px'} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'100px'} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'100px'} />
            </Box>
        </Stack>
    </>
}

export const ConnectionsSkeleton = () => {
    return <>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
        >
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'300px'} height={50} />
            </Box>
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'120px'} height={50} />
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'150px'} height={50} />
            </Box>
        </Stack>
        <Stack m={2} mx={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'80vh'} />
        </Stack>
    </>
}

export const SentimentalDashboardSkeleton = () => {
    return <>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
        >
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'250px'} height={40} />
            </Box>
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'200px'} height={40} />
            </Box>
        </Stack>
        <Stack m={2} mx={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'350px'} />
        </Stack>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
        >
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'200px'} height={40} />
            </Box>
            <Box display={'flex'} gap={2}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'400px'} height={40} />
            </Box>
        </Stack>

        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
            spacing={2}
        >
            <Box display={'flex'} gap={2} width={'33%'}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'340px'} />
            </Box>
            <Box display={'flex'} gap={2} width={'33%'}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'340px'} />
            </Box>
            <Box display={'flex'} gap={2} width={'33%'}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'340px'} />
            </Box>
        </Stack>

    </>
}

export const TeamsSkeleton = () => {
    return <>
        <Stack m={2} mx={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'120px'} height={40} />
        </Stack>

        <Stack m={2} mx={4} direction="row" alignItems="center" spacing={2}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'350px'} height={60} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'200px'} height={60} />
        </Stack>

        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
            spacing={2}
        >
            <Box display={'flex'} gap={2} width={'33%'}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'340px'} />
            </Box>
            <Box display={'flex'} gap={2} width={'33%'}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'340px'} />
            </Box>
            <Box display={'flex'} gap={2} width={'33%'}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'340px'} />
            </Box>
        </Stack>

    </>
}

export const RetroBoard = () => {
    return <>
        <Stack m={2} mx={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'120px'} height={40} />
        </Stack>

        <Stack m={2} mx={4} direction="row" alignItems="center" spacing={2}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'350px'} height={100} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'350px'} height={100} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'350px'} height={100} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'350px'} height={100} />
        </Stack>
    </>
}

export const GithubSkeleton = () => {
    return <>
        <Stack m={2} mx={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'120px'} height={40} />
        </Stack>
        <Stack m={2} mx={4}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'120px'} height={40} />
        </Stack>
        <Stack m={2} mx={4} direction="row" alignItems="center" spacing={2}>
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'350px'} height={100} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'350px'} height={100} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'350px'} height={100} />
            <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'350px'} height={100} />
        </Stack>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            m={4}
            mt={3}
            mb={2}
            spacing={2}
        >
            <Box display={'flex'} gap={2} width={'75%'}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'300px'} />
            </Box>
            <Box display={'flex'} gap={2} width={'25%'}>
                <Skeleton sx={{ bgcolor: 'grey.100' }} variant="rounded" width={'100%'} height={'300px'} />
            </Box>
        </Stack>

    </>
}