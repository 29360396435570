import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const PostMeeting = Loadable(lazy(() => import('./PostMeeting')));

const postMeetingRoutes = [
  {
    path: '/postMeeting',
    element: (
      <PostMeeting />
    )
  }
];

export default postMeetingRoutes;
