/* eslint-disable no-undef */
import axios from 'axios';
import { urlUserService } from '../../App';

const userActions = {
  getUserById: (id) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${urlUserService}/user/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getUserById', payload: res.data.response.user });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getUser: () => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${urlUserService}/user`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getUser', payload: res.data.response?.user });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  addUser: (newUser, reactivate) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(`${urlUserService}/user${reactivate ? `?reactivate=${reactivate}` : ''}`, newUser, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'addUser', payload: res.data.response.user });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  modifyUser: (id, userData) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.put(`${urlUserService}/user/${id}`, userData, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  removeUser: (userId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.delete(`${urlUserService}/user${userId ? `?id=${userId}` : ''}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  createInvitation: (newInvitation, sendEmails) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(`${urlUserService}/invitation${sendEmails ? `?sendEmails=true` : ''}`, newInvitation, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  createMassInvitations: (newInvitations) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(`${urlUserService}/mass-invitation`, newInvitations , {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getInvitation: (invitationId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${urlUserService}/invitation/${invitationId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getInvitation', payload: res.data.response?.user });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  assignEmailToAuth0: (sub, accessToken, targetUserIdToken) => {
    return async () => {
      try {
        const res = await axios.post(
          // eslint-disable-next-line no-undef
          `https://${process.env.REACT_APP_DOMAIN}/api/v2/users/${sub}/identities`,
          {
            link_with: targetUserIdToken
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + accessToken
            }
          }
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  assignEmailToUser: (userId, newEmail) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(`${urlUserService}/user/email/${userId}`, { newEmail }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  teamAssign: (id, teams) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(`${urlUserService}/team/${id}`, teams, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  domainAssign: (id, domainId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(`${urlUserService}/userDomain/${id}`, domainId, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getFeedback: (userId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${urlUserService}/feedback/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getFeedback', payload: res.data.response.feedback });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  addFeedback: (userId, question1, question2, question3, question4) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(`${urlUserService}/feedback/${userId}`, {
          question1: question1,
          question2: question2,
          question3: question3,
          question4: question4
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'addFeedback', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  updateFeedback: (userId, question1, question2, question3, question4) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.put(`${urlUserService}/feedback/${userId}`, {
          question1: question1,
          question2: question2,
          question3: question3,
          question4: question4
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'updateFeedback', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  updateUserMetadata: (userMetadata) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.patch(`${urlUserService}/user`, { userMetadata }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  userProfileSelection: (selection) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'userProfileSelection', payload: selection });
      } catch (error) {
        return error;
      }
    };
  },
  userBelongToCompany: () => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${urlUserService}/domain`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'userBelongToCompany', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  managerTableSort: (value) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'managerTableSort', payload: value });
      } catch (error) {
        return error;
      }
    };
  },
  peopleTableSort: (value) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'peopleTableSort', payload: value });
      } catch (error) {
        return error;
      }
    };
  },
  teamsTableSort: (value) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'teamsTableSort', payload: value });
      } catch (error) {
        return error;
      }
    };
  },
  emailDataTableSort: (value) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'emailDataTableSort', payload: value });
      } catch (error) {
        return error;
      }
    };
  },
  pRDataTableSort: (value) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'pRDataTableSort', payload: value });
      } catch (error) {
        return error;
      }
    };
  },
  userRetroBoardTableSort: (value) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'userRetroBoardTableSort', payload: value });
      } catch (error) {
        return error;
      }
    };
  },
  unlinkAccount: (email) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.put(`${urlUserService}/user`, { email }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  setFridayQuiz: (answers) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(`${urlUserService}/feedback`, answers, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  dailyChallenge: (boolean) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'dailyChallenge', payload: boolean });
      } catch (error) {
        return error;
      }
    };
  },
};

export default userActions;
