import { combineReducers } from 'redux';
import userReducer from './userReducer';
import companyReducer from './companyReducer';
import teamReducer from './teamReducer';
import archivingReducer from './archivingReducer';
import messageReducer from './messageReducer';
import fileReducer from './fileReducer';
import nlpReducer from './nlpReducer';
import calendarReducer from './calendarReducer';
import notificationsReducer from './notificationsReducer';
import projectsReducer from './projectsReducer';
import tasksReducer from './tasksReducer';
import emailMetricsReducer from './emailMetricsReducer';
import authReducer from './authReducer';
import managerReducer from './managerReducer';
import eventsReducer from './eventsReducer';
import patrasStatesReducer from './patrasStatesReducer';
import adminReducer from './adminReducer';

const mainReducer = combineReducers({
  userReducer,
  companyReducer,
  teamReducer,
  authReducer,
  projectsReducer,
  tasksReducer,
  archivingReducer,
  messageReducer,
  fileReducer,
  nlpReducer,
  calendarReducer,
  notificationsReducer,
  emailMetricsReducer,
  managerReducer,
  eventsReducer,
  patrasStatesReducer,
  adminReducer
});

export default mainReducer;
