import { createTheme } from '@mui/material';
const theme = createTheme({
  status: {
    danger: '#E53E3E'
  },
  palette: {
    primary: {
      main: '#C62F4A',
      primary: '#C62F4A',
      darker: '#8C0424',
      background: '#FFF1F4',
      ligth: '#FA3E6A',
      10: '#FFF1F4',
      20: '#FEE6EB',
      30: '#FED4DE',
      40: '#FDA9BD',
      50: '#FB7796',
      60: '#FA3E6A',
      70: '#EA1F4E',
      80: '#C62F4A',
      90: '#8C0424',
      100: '#32010D'
    },
    secondary: {
      main: '#FFF1F4',
      darker: '#8C0424',
      contrastText: '#C62F4A'
    },
    text: {
      main: '#424142',
      dark: '#212121',
      light: '#606161'
    },
    green: {
      primary: '#048C1A',
      background: '#F1FFF4',
      10: '#F1FFF4',
      20: '#E6FEEA',
      30: '#D4FEDB',
      40: '#A9FDB7',
      50: '#77FB8D',
      60: '#3EFA5D',
      70: '#23F946',
      80: '#06EA2C',
      90: '#048C1A',
      100: '#013209 '
    },
    red: {
      primary: '#CF0707',
      background: '#FFF6F5 ',
      10: '#FFF6F5',
      20: '#FEE3E1',
      30: '#FED1CD',
      40: '#FDAFA9',
      50: '#FB7F77',
      60: '#FA4A3E',
      70: '#F93123',
      80: '#CF0707',
      90: '#8C0C04',
      100: '#320401 '
    },
    purple: {
      primary: '#9C23F9',
      background: '#F9F1FF',
      10: '#F9F1FF',
      20: '#F4E6FE',
      30: '#ECD4FE',
      40: '#D9A9FD',
      50: '#C277FB',
      60: '#A93EFA',
      70: '#9C23F9',
      80: '#8706EA',
      90: '#50048C',
      100: '#1C0132'
    },
    blue: {
      primary: '#065AEA',
      background: '#F1F6FF',
      10: '#F1F6FF',
      20: '#E6EFFE',
      30: '#D4E3FE',
      40: '#A9C8FD',
      50: '#77A7FB',
      60: '#3E83FA',
      70: '#2371F9',
      80: '#065AEA',
      90: '#04368C',
      100: '#011332'
    },
    orange: {
      primary: '#EA7406',
      background: '#FFF8F1',
      10: '#FFF8F1',
      20: '#FEF2E6',
      30: '#FEE8D4',
      40: '#FDD2A9',
      50: '#FBB777',
      60: '#FA993E',
      70: '#F98A23',
      80: '#EA7406',
      90: '#8C4504',
      100: '#321901'
    },
    yellow: {
      primary: '#8C8804',
      background: '#FEFEE6',
      10: '#FFFEF1',
      20: '#FEFEE6',
      30: '#FEFDD4',
      40: '#FDFAA9',
      50: '#FBF777',
      60: '#FAF33E',
      70: '#F9F223',
      80: '#EAE206',
      90: '#8C8804',
      100: '#323001'
    },
    violet: {
      primary: '#FA3EDB',
      background: '#FFF1FC',
      10: '#FFF1FC',
      20: '#FEE6FA',
      30: '#FED4F7',
      40: '#FDA9EF',
      50: '#FB77E5',
      60: '#FA3EDB',
      70: '#F923D6',
      80: '#EA06C4',
      90: '#8C0476',
      100: '#32012A'
    },
    neutral: {
      main: '#424142',
      light: '#757574',
      darker: '#212121',
      contrastText: '#fff',
      primary: '#6B7485',
      background: '#F7F8F9',
      10: '#F7F8F9',
      20: '#F1F2F3',
      30: '#E7E8EB',
      40: '#CED2D8',
      50: '#B1B7C1',
      60: '#9199A7',
      70: '#828B9A',
      80: '#6B7485',
      90: '#404650',
      100: '#17191C'
    }
  }
});

export default theme;
