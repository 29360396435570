export const getMonday = (d) => {
  d = new Date(d);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  let day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(d.setDate(diff));
}

export const addDays = (days, date = new Date()) => {
  date.setDate(date.getDate() + days);
  return date;
}

export const addMonth = (months, date = new Date()) => {
  date.setMonth(date.getMonth() + months);
  return date;
}

export const datesAreOnSameDay = (first, second) => {
  if (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  ) {
    return true;
  } else {
    return false;
  }
};

export const firstDateIsBigger = (first, second) => {
  if (first.getTime() > second.getTime()) {
    return true;
  } else {
    return false;
  }
};

export let today = new Date();
today.setHours(0);
today.setMinutes(0);
today.setSeconds(0);
export const mondayDay = getMonday(new Date());
export const nextMonday = addDays(7, new Date(mondayDay));
export const sevenDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);
export const lastWeekStart = new Date(addDays(-7, new Date(mondayDay)));
export const lastWeekEnd = new Date(addDays(6, new Date(lastWeekStart)));
export const firstDayThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
export const lastDayThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
export const firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
export const firstDayLastYear = new Date(today.getFullYear(), -12, 1, 1);
export const firstDayThisYear = new Date(today.getFullYear(), 0, 1, 1);
export const endOfToday = addDays(1, new Date(today));
export const thisDayLastMonth = addMonth(-1, new Date(today));

export const datesArray = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export const handleDayOfWeek = (day) => {
  switch (day) {
    case 'Lunes':
      return 1;
    case 'Martes':
      return 2;
    case 'Miércoles':
      return 3;
    case 'Jueves':
      return 4;
    case 'Viernes':
      return 5;
    case 'Sábado':
      return 6;
    default:
      return 0;
  }
};

export const timeEventCreator = (duration) => {
  switch (duration) {
    case '15 min':
      return 15;
    case '30 min':
      return 30;
    case '1 hs':
      return 60;
    case '1 30 hs':
      return 90;
    default:
      return 120;
  }
};


export const timeFilters = [
  {
    name: 'Mes',
    icon: 'view_module',
    value: 'month'
  },
  {
    name: 'Semana',
    icon: 'view_week',
    value: 'week'
  },
  {
    name: 'Día',
    icon: 'view_day',
    value: 'day'
  }
];

export const timeFiltersCalendar = [
  {
    name: 'Elegir fecha',
    icon: 'view_module',
    value: 'chooseDate'
  },
  {
    name: 'Este mes',
    icon: 'view_module',
    value: 'month'
  },
  {
    name: 'Esta semana',
    icon: 'view_week',
    value: 'week'
  },
  {
    name: 'Hoy',
    icon: 'view_day',
    value: 'day'
  }
];

export const timeFiltersEmails = [
  {
    name: 'Elegir fecha',
    icon: 'view_module',
    value: 'chooseDate'
  },
  {
    name: 'Mes pasado',
    icon: 'view_module',
    value: 'month'
  },
  {
    name: 'Semana pasada',
    icon: 'view_week',
    value: 'week'
  },
  {
    name: 'Hoy',
    icon: 'view_day',
    value: 'day'
  }
];


export const timeFiltersAnalitycs = [
  {
    name: 'Último año',
    icon: 'view_module',
    value: 'lastYear'
  },
  {
    name: 'Este mes',
    icon: 'view_week',
    value: 'thisMonth'
  },
  {
    name: '7 días',
    icon: 'view_day',
    value: 'week'
  }
];

export const timeFiltersSentimental = [
  {
    name: 'Elegir fecha',
    icon: 'view_module',
    value: 'chooseDate'
  },

  {
    name: 'Mes pasado',
    icon: 'view_module',
    value: 'month'
  },
  {
    name: 'Semana pasada',
    icon: 'view_week',
    value: 'week'
  }
];

export const renderDay = (day) => {
  switch (day) {
    case 1: return 'Lunes';
    case 2: return 'Martes';
    case 3: return 'Miércoles';
    case 4: return 'Jueves';
    case 5: return 'Viernes';
    case 6: return 'Sábados';
    case 0: return 'Domingos';
    default: break;
  }
};

export const renderEmailDay = (higherDay) => {
  switch (higherDay) {
    case 0: return 'lunes';
    case 1: return 'martes';
    case 2: return 'miércoles';
    case 3: return 'jueves';
    case 4: return 'viernes';
    case 5: return 'sabados';
    case 6: return 'domingos';
    default: break;
  }
};

export const getPastYearDates = () => {
  const today = new Date();
  const pastYear = new Date(today);
  pastYear.setFullYear(today.getFullYear() - 1);

  const dates = [];
  for (let date = new Date(pastYear); date <= today; date.setDate(date.getDate() + 1)) {
    dates.push(new Date(date));
  }

  return dates;
};

export const getDayOfWeek = (date) => {
  const daysOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  const dayIndex = date.getDay(); // Devuelve un número (domingo: 0, lunes: 1, ..., sábado: 6)
  return daysOfWeek[dayIndex];
};
