import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'app/components/ErrorFallback';

const TGAnalytics = Loadable(lazy(() => import('./TGAnalytics')));

const TGAnalyticsRoutes = [
  {
    path: '/analytics',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <TGAnalytics />
      </ErrorBoundary>
    )
  }
];

export default TGAnalyticsRoutes;
