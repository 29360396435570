import React, { useState, useEffect } from 'react';
import {
    Box,
    CircularProgress,
    Icon,
    Typography,
    TextField,
    styled,
} from '@mui/material';
import logo from '../../patrasLogos/logo-negro-color.png';
import '../styles/PublicCalendarShared.css';
import '../styles/EventInvitation.css';
import live_tv from '../utils/iconsImages/live_tv.png';
import iconCheck from '../utils/iconsImages/task_alt_green.png';
import { PrimaryButton } from 'app/utils/ButtonsPatras';
import { CalendarMonthIcon } from 'app/utils/svgIcons';
import { useDispatch } from 'react-redux';
import publicActions from 'app/redux/actions/publicActions';
import { useErrorHandler } from 'react-error-boundary';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import _ from 'lodash';

const Image = styled('img')(() => ({
    width: '18rem',
    padding: '2rem'
}));

const IconTV = styled('img')(() => ({
    width: '25px'
}));

const TaskAlt = styled('img')(() => ({
    width: '25px',
    marginRight: '.8rem'
}));

function EventInvitation() {

    const dispatch = useDispatch();
    const handleError = useErrorHandler();
    const { eventShareId } = useParams();
    const [loading, setLoading] = useState(false);
    const [meetingConfirmation, setMeetingConfirmation] = useState(false);
    const [guestEmail, setGuestEmail] = useState(null);
    const [countDown, setCountDown] = useState(null);
    const [eventShareData, setEventShareData] = useState();

    useEffect(() => {
        dispatch(publicActions.getEventSharePublicData(eventShareId)).then(res => {
            if (!res.data) {
                handleError(res);
            } else {
                setEventShareData(res.data.response);
            }
        })
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (countDown !== null) {
            countDown > 0
                ? _.delay(() => {
                    setCountDown((prevCountDown) => prevCountDown - 1);
                }, 1000)
                : window.location.replace('https://www.patras.io');
        }
        //eslint-disable-next-line
    }, [countDown]);

    const meetingCreated = () => {
        setLoading(true);
        // eslint-disable-next-line
        let re = /^([\da-z_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;
        if (guestEmail && re.exec(guestEmail)) {
            dispatch(publicActions.addGuestFromEventShare(eventShareId, guestEmail)).then((res) => {
                if (!res.data) {
                    handleError(res);
                } else {
                    setMeetingConfirmation(true);
                    setLoading(false);
                    setCountDown(5);
                }
            });
        } else {
            toast.error('Por favor ingrese un e-mail válido');
            setLoading(false);
        }
    };

    return (
        <Box className='main-container'>
            <Box className='mainData-container'>
                <Typography className='title' sx={{ fontSize: '20px', fontWeight: '500' }}>Agendar reunión</Typography>
                <Box className='info-container' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: '30%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-around', height: '100%' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Typography sx={{ color: '#606161', fontSize: '14px' }}>{eventShareData?.ownerName}</Typography>
                            <Typography className='event-p' sx={{ fontSize: '20px', fontWeight: '500' }}>{eventShareData?.eventName}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                            <Typography
                                className="infoDateSelected"
                                sx={{ color: '#FA3E6A', fontSize: '22px', fontWeight: 600 }}
                            >
                                {new Date(eventShareData?.start).toLocaleDateString('es-AR', {
                                    month: 'long',
                                    day: 'numeric'
                                })}
                                , {`${new Date(eventShareData?.start).getHours()}:${new Date(eventShareData?.start).getMinutes() === 0 ? '00' : new Date(eventShareData?.start).getMinutes()}`} hs
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-around', height: '100%' }}>
                        {
                            eventShareData && eventShareData?.conference
                                ?
                                (
                                    eventShareData?.conference === 'google'
                                        ? (
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Box
                                                    sx={{
                                                        backgroundColor: '#FAFAFA',
                                                        width: '40px',
                                                        height: '40px',
                                                        borderRadius: '6px',
                                                        fontSize: '1.8rem !important',
                                                        display: 'flex',
                                                        marginRight: '8px'
                                                    }}
                                                >
                                                    <IconTV sx={{ margin: 'auto' }} src={live_tv} />
                                                </Box>
                                                <Typography className='location-p' sx={{ fontSize: '16px', color: '#606161' }}>
                                                    Google Meet
                                                </Typography>
                                            </Box>
                                        ) :
                                        eventShareData?.conference === 'zoom' ?
                                            (
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Box
                                                        sx={{
                                                            backgroundColor: '#FAFAFA',
                                                            width: '40px',
                                                            height: '40px',
                                                            borderRadius: '6px',
                                                            fontSize: '1.8rem !important',
                                                            display: 'flex',
                                                            marginRight: '8px'
                                                        }}
                                                    >
                                                        <IconTV sx={{ margin: 'auto' }} src={live_tv} />
                                                    </Box>
                                                    <Typography sx={{ fontSize: '16px', color: '#606161' }}>
                                                        Zoom
                                                    </Typography>
                                                </Box>
                                            )
                                            :
                                            (
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Box
                                                        sx={{
                                                            backgroundColor: '#FAFAFA',
                                                            width: '40px',
                                                            height: '40px',
                                                            borderRadius: '6px',
                                                            fontSize: '1.8rem !important',
                                                            display: 'flex',
                                                            marginRight: '8px'
                                                        }}
                                                    >
                                                        <IconTV sx={{ margin: 'auto' }} src={live_tv} />
                                                    </Box>
                                                    <Typography sx={{ fontSize: '16px', color: '#606161' }}>
                                                        Microsoft Teams/Skype
                                                    </Typography>
                                                </Box>
                                            )
                                ) : (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Icon
                                            sx={{
                                                backgroundColor: '#FAFAFA',
                                                color: '#606161',
                                                width: '40px',
                                                height: '40px',
                                                borderRadius: '6px',
                                                marginRight: '8px',
                                                paddingTop: '5px',
                                                fontSize: '1.8rem !important'
                                            }}
                                        >
                                            room
                                        </Icon>
                                        <Typography
                                            sx={{ fontSize: '16px', color: '#606161', textTransform: 'capitalize' }}
                                        >
                                            {eventShareData?.location
                                                ? eventShareData?.location
                                                :
                                                'A convenir'
                                            }
                                        </Typography>
                                    </Box>
                                )}
                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                            <Icon
                                sx={{
                                    backgroundColor: '#FAFAFA',
                                    color: '#606161',
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '6px',
                                    marginRight: '8px',
                                    paddingTop: '5px',
                                    fontSize: '1.8rem !important'
                                }}
                            >
                                access_time
                            </Icon>
                            <Typography className="subInfoMeet" sx={{ fontSize: '16px', color: '#606161' }}>
                                {eventShareData?.duration} min de duración
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {meetingConfirmation ? (
                    <>
                        <Box
                            className="boxTypoMeet"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#F1FFF4',
                                padding: '1.5rem 6rem',
                                borderRadius: '6px',
                                width: '100%',
                                marginTop: '.5rem'
                            }}
                        >
                            <TaskAlt src={iconCheck} />
                            <Typography
                                sx={{
                                    color: '#048C1A',
                                    textAlign: 'center',
                                    fontSize: '1rem',
                                    fontWeight: '600'
                                }}
                            >
                                Reunión agendada correctamente.
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                padding: '1.5rem 6rem'
                            }}
                        >
                            <Typography
                                className='redirection'
                                sx={{
                                    color: '#757575',
                                    textAlign: 'center',
                                    fontSize: '18px',
                                    fontWeight: '500'
                                }}
                            >
                                Redireccionando a my.patras.io en {countDown}s
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <Box
                        className="boxTypoMeet"
                        sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <TextField
                            sx={{ width: '100%', margin: 'auto' }}
                            margin="dense"
                            id="name"
                            label="Tu correo electrónico"
                            type="text"
                            fullWidth
                            value={guestEmail && guestEmail}
                            onChange={(e) => setGuestEmail(e.target.value)}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                margin: '1.4rem 0 0'
                            }}
                        >
                            <Box sx={{ width: '50px', height: '50px' }}></Box>
                            <PrimaryButton
                                onClick={meetingCreated}
                            >
                                {loading ?
                                    <CircularProgress size={20} sx={{ marginRight: '10px', color: '#FA3E6A' }} />
                                    :
                                    <CalendarMonthIcon color='white' width='18px' style={{ marginRight: '5px' }} />
                                }
                                Agendar
                            </PrimaryButton>
                        </Box>
                    </Box>

                )}
            </Box>
            <Image className="logo" src={logo} />
        </Box>
    )
}

export default EventInvitation