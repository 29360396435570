/* eslint-disable no-undef */
/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
const initialState = {
  messageByUser: [],
  messagesByCompany: [],
  getSentiments: [],
  messagesByTeam: []
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'getMessageByTeamID':
      return {
        ...state,
        messagesByTeam: action.payload
      };
    case 'getMessageByUserID':
      {
        let userSentiments = {
          neutralArray: [],
          mixedArray: [],
          positiveArray: [],
          negativeArray: []
        };
        let averageUserSentiments = {
          neutral: '',
          mixed: '',
          positive: '',
          negative: ''
        };
      }
      action.payload.forEach((message) => {
        if (message.Sentiment !== null) {
          userSentiments.neutralArray.push(message.Sentiment.NeutralSentiment);
          userSentiments.mixedArray.push(message.Sentiment.MixedSentiment);
          userSentiments.positiveArray.push(message.Sentiment.PositiveSentiment);
          userSentiments.negativeArray.push(message.Sentiment.NegativeSentiment);
        }
      });

      averageUserSentiments = {
        neutral: (
          (userSentiments.neutralArray?.reduce((a, n) => (a += n), 0) /
            userSentiments.neutralArray?.length) *
          100
        ).toFixed(2),
        mixed: (
          (userSentiments.mixedArray?.reduce((a, n) => (a += n), 0) /
            userSentiments.mixedArray?.length) *
          100
        ).toFixed(2),
        positive: (
          (userSentiments.positiveArray?.reduce((a, n) => (a += n), 0) /
            userSentiments.positiveArray?.length) *
          100
        ).toFixed(2),
        negative: (
          (userSentiments.negativeArray?.reduce((a, n) => (a += n), 0) /
            userSentiments.negativeArray?.length) *
          100
        ).toFixed(2)
      };

      return {
        ...state,
        messageByUser: action.payload,
        userSentiments,
        averageUserSentiments
      };
    case 'getMessageByCompanyID':
      let companySentiments = {
        neutralArray: [],
        mixedArray: [],
        positiveArray: [],
        negativeArray: []
      };
      let averageCompanySentiments = {
        neutral: '',
        mixed: '',
        positive: '',
        negative: ''
      };
      action.payload.forEach((message) => {
        if (message.Sentiment !== null) {
          companySentiments.neutralArray.push(message.Sentiment.NeutralSentiment);
          companySentiments.mixedArray.push(message.Sentiment.MixedSentiment);
          companySentiments.positiveArray.push(message.Sentiment.PositiveSentiment);
          companySentiments.negativeArray.push(message.Sentiment.NegativeSentiment);
        }
      });

      averageCompanySentiments = {
        neutral: (
          (companySentiments.neutralArray?.reduce((a, n) => (a += n), 0) /
            companySentiments.neutralArray?.length) *
          100
        ).toFixed(2),
        mixed: (
          (companySentiments.mixedArray?.reduce((a, n) => (a += n), 0) /
            companySentiments.mixedArray?.length) *
          100
        ).toFixed(2),
        positive: (
          (companySentiments.positiveArray?.reduce((a, n) => (a += n), 0) /
            companySentiments.positiveArray?.length) *
          100
        ).toFixed(2),
        negative: (
          (companySentiments.negativeArray?.reduce((a, n) => (a += n), 0) /
            companySentiments.negativeArray?.length) *
          100
        ).toFixed(2)
      };
      return {
        ...state,
        messagesByCompany: action.payload,
        companySentiments,
        averageCompanySentiments
      };
    case 'getSentiments':
      return {
        ...state,
        getSentiments: action.payload
      };

    default:
      return state;
  }
};

export default messageReducer;
