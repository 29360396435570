import { Button, styled, Switch } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const PrimaryButton = styled(Button)(({ theme }) => ({
  color: '#FFF1F4',
  backgroundColor: '#C62F4A',
  border: '1px solid #C62F4A',
  fontWeight: 500,
  fontSize: '15px',
  gap: '.5rem',
  '&:hover': {
    backgroundColor: '#8C0424',
    border: '1px solid #FFF1F4'
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '13px',
    gap: '.4rem'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    gap: '.3rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
    gap: '.2rem'
  }
}));

const PrimaryButtonNoHover = styled(Button)(({ theme }) => ({
  color: '#FFF1F4',
  backgroundColor: '#C62F4A',
  border: '1px solid #C62F4A',
  fontWeight: 500,
  fontSize: '15px',
  gap: '.5rem',
  padding: '8px 18px',
  '&:hover': {
    backgroundColor: '#C62F4A',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '13px',
    gap: '.4rem'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    gap: '.3rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
    gap: '.2rem'
  }
}));

export const PrimaryBorderButton = styled(Button)(({ theme }) => ({
  color: '#C62F4A',
  border: '1px solid #C62F4A',
  fontWeight: 500,
  fontSize: '15px',
  gap: '.5rem',
  padding: '8px 18px',
  '&:hover': {
    backgroundColor: '#FFF1F4',
  },
  '&:disabled': {
    // backgroundColor: '#F5F5F5',
    // color: '#BEBDBD',
    border: '1px solid #BEBDBD',
    cursor: 'not-allowed',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '13px',
    gap: '.4rem'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    gap: '.3rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
    gap: '.2rem'
  }
}));

const PrimaryLoadingButton = styled(LoadingButton)(({ theme }) => ({
  color: '#FFF1F4',
  backgroundColor: '#C62F4A',
  border: '1px solid #C62F4A',
  fontSize: '15px',
  fontWeight: 500,
  gap: '.5rem',
  '&:hover': {
    backgroundColor: '#C62F4A',
    border: '1px solid #FFF1F4'
  },
  '&:disabled': {
    backgroundColor: '#F5F5F5',
    color: '#BEBDBD',
    border: '1px solid #F5F5F5',
    cursor: 'not-allowed',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '13px',
    gap: '.4rem'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    gap: '.3rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
    gap: '.2rem'
  }
}));

const SecondaryButton = styled(Button)(({ theme }) => ({
  color: '#C62F4A',
  backgroundColor: '#FFF1F4',
  border: '1px solid #FFF1F4',
  fontWeight: 500,
  fontSize: '15px',
  gap: '.5rem',
  '&:hover': {
    backgroundColor: '#FFF1F4',
    border: '1px solid #C62F4A'
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '13px',
    gap: '.4rem'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    gap: '.3rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
    gap: '.2rem'
  }
}));

const SecondaryButtonNoBorder = styled(Button)(({ theme }) => ({
  color: '#C62F4A',
  backgroundColor: '#FFF1F4',
  border: '1px solid #FFF1F4',
  fontWeight: 500,
  fontSize: '15px',
  gap: '.5rem',
  '&:hover': {
    backgroundColor: '#FFF1F4',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '13px',
    gap: '.4rem'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    gap: '.3rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
    gap: '.2rem'
  }
}));

const SecondaryLoadingButton = styled(LoadingButton)(({ theme }) => ({
  color: '#C62F4A',
  backgroundColor: '#FFF1F4',
  border: '1px solid #FFF1F4',
  fontWeight: 500,
  fontSize: '15px',
  gap: '.5rem',
  '&:hover': {
    backgroundColor: '#FFF1F4',
    border: '1px solid #C62F4A'
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '13px',
    gap: '.4rem'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    gap: '.3rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
    gap: '.2rem'
  }
}));

const NeutralButton = styled(Button)(({ theme }) => ({
  color: theme.palette.neutral.main,
  backgroundColor: '#F5F5F5',
  border: '1px solid #F5F5F5',
  fontWeight: 500,
  fontSize: '15px',
  '&:hover': {
    backgroundColor: '#F5F5F5',
    border: '1px solid #606161'
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '13px',
    gap: '.4rem'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    gap: '.3rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
    gap: '.2rem'
  }
}));

const OutlineNeutralButton = styled(Button)(({ theme }) => ({
  variant: 'outlined',
  fontWeight: 500,
  fontSize: '15px',
  '&:hover': {
    backgroundColor: '#F5F5F5'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
    gap: '.2rem'
  }
}));

const OutlinePrimaryButton = styled(Button)(({ theme }) => ({
  variant: 'outlined',
  fontWeight: 500,
  fontSize: '15px',
  '&:hover': {
    backgroundColor: '#FFF1F4'
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '13px',
    gap: '.4rem'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    gap: '.3rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
    gap: '.2rem'
  }
}));

const OutlinePrimaryLoadingButton = styled(LoadingButton)(({ theme }) => ({
  variant: 'outlined',
  fontWeight: 500,
  fontSize: '15px',
  '&:hover': {
    backgroundColor: '#FFF1F4'
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '13px',
    gap: '.4rem'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    gap: '.3rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
    gap: '.2rem'
  }
}));

const SwitchCustom = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 56,
  height: 30,
  padding: 0,
  '& .css-1y0wiao-MuiButtonBase-root-MuiSwitch-switchBase': {
    color: '#828B9A'
  },
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 3,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(26px)',
      color: '#C62F4A',
      '& + .MuiSwitch-track': {
        backgroundColor: '#FFF1F4',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#C62F4A',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#C62F4A',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 24,
    height: 24,
  },
  '& .MuiSwitch-track': {
    borderRadius: 30 / 2,
    backgroundColor: '#FFF1F4',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export {
  PrimaryButton,
  PrimaryButtonNoHover,
  PrimaryLoadingButton,
  SecondaryLoadingButton,
  SecondaryButton,
  SecondaryButtonNoBorder,
  NeutralButton,
  OutlinePrimaryLoadingButton,
  OutlineNeutralButton,
  OutlinePrimaryButton,
  SwitchCustom
};


