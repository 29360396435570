import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const AnalyticsMobile = Loadable(lazy(() => import('./Analytics')));
const AnalyticsManager = Loadable(lazy(() => import('./AnalyticsManagerDashboard')));

const AnalyticsMobileRoutes = [
  {
    path: '/analytics',
    element: (
      <AnalyticsMobile />
    )
  },
  {
    path: '/analyticsDashboard',
    element: (
      <AnalyticsManager />
    )
  }
];

export default AnalyticsMobileRoutes;
