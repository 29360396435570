// import logo from '../../patrasLogos/logo-negro-color.png';
import logo from '../../patrasLogos/logobeta.png';

import { styled } from '@mui/material';

const PatrasLogo = () => {
  const LogoContainer = styled('img')(() => ({
    width: '80%',
    margin: '10px auto 0px',
  }));

  return <LogoContainer src={logo} />;
};

export default PatrasLogo;
