/* eslint-disable no-case-declarations */
const initialState = {
    editingEvent: false,
  };
  
  const eventsReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'editingEvent':
        return {
          ...state,
          editingEvent: action.payload
        };
      default:
        return state;
    }
  };
  
  export default eventsReducer;
  