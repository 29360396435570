const getRandomFact = () => {
    const funFacts = [
        "Batman y Robin originalmente llevaban trajes de color morado y rosa.",
        "La maratón moderna se inspiró en la leyenda de un soldado griego llamado Filípides, que corrió desde Maratón hasta Atenas para anunciar la victoria, y luego murió.",
        "La marca Nutella es una combinación de las palabras 'nuez' (en italiano, 'nocciola') y 'ella'.",
        "Se ha calculado el valor de pi hasta más de un billón de dígitos.",
        "La bebida nacional de Canadá es el caesar, un cóctel hecho con vodka, salsa picante, jugo de tomate y condimentos.",
        "Los Cuackeadores de la Fortuna tienen pequeños mensajes impresos porque el creador pensó que era una forma divertida de promover la conversación en la mesa.",
        "Tu cerebro consume aproximadamente el 20% de tu ingesta diaria de calorías.",
        "Aunque no vuelan, los pingüinos pueden saltar hasta 6 pies de altura en el agua.",
        "Las orejas de Mickey Mouse siempre se mantienen en la misma posición, sin importar en qué dirección esté mirando.",
        "Las abejas duermen cinco veces al día, durante cinco minutos cada vez.",
        "Un gusto tiene una vida útil de alrededor de 10 a 14 días.",
        "Un día en Venus (un giro completo) es más largo que un año en Venus (una órbita alrededor del sol).",
        "El término 'emoji' proviene de las palabras japonesas 'e' (imagen) y 'moji' (carácter).",
        "Los hipopótamos son buenos nadadores y pueden correr más rápido que los humanos en tierra.",
        "Los tiburones duermen con los ojos abiertos porque no tienen párpados.",
        "Los koalas tienen orejas únicas que pueden cerrar para protegerse de la entrada de polvo y suciedad.",
        "Se necesitan alrededor de 200 músculos para parpadear un ojo.",
        "Los tiburones han existido más tiempo que los dinosaurios; datan de hace al menos 420 millones de años.",
        "Las moscas de la fruta que son vírgenes se embriagan más fácilmente que las que han tenido sexo.",
        "Los flamencos rosados no nacen con ese color; obtienen su color rosado de su dieta.",
        "Las abejas pueden reconocer rostros humanos.",
        "El rey Luis XIV de Francia tenía una cama con dosel tan grande que 60 personas podían dormir en ella al mismo tiempo.",
        "En Japón, hay una isla habitada únicamente por conejos.",
        "El corazón de una ballena azul es tan grande que una persona podría nadar en sus arterias.",
        "Los pingüinos tienen una sola pareja de por vida y regalan piedras como muestra de amor.",
        "El alfabeto más largo del mundo es el camboyano, que consta de 74 letras.",
        "Los osos polares son zurdos.",
        "En la antigua Roma, los platos de cocina se hacían con orina humana, que contenía amoníaco.",
        "Las ovejas pueden recordar rostros humanos hasta por dos años.",
        "El aguacate y la banana son berries, pero la fresa no lo es.",
        "La Mona Lisa no tiene cejas ni pestañas.",
        "Un grupo de flamencos se llama 'parada'.",
        "El 'Q' en Q-tips significa 'calidad'.",
        "La miel nunca se echa a perder; arqueólogos han encontrado miel en tumbas egipcias que tiene más de 3,000 años y aún es comestible.",
        "Los pulpos tienen tres corazones y azul es su color natural de sangre.",
        "En promedio, una persona pasa seis meses de su vida esperando semáforos en rojo.",
        "Las jirafas tienen la misma cantidad de vértebras en el cuello que los humanos.",
        "El oído humano es capaz de distinguir entre 400,000 y 500,000 tonos diferentes.",
        "El búho es el único pájaro que puede ver el color azul.",
        "Las cucarachas pueden vivir varias semanas sin su cabeza.",
        "La miel es el único alimento que no se echa a perder.",
        "En una habitación completamente oscura, si frotas tus ojos, verás una luz de color llamada 'fosfenos'.",
        "El 40% de las personas que visitan un parque temático en EE.UU. pierden sus cosas en una montaña rusa.",
        "Los canguros no pueden saltar hacia atrás.",
        "Un grupo de canguros se llama 'tropa' o 'trote'.",
        "Los flamencos pueden volar, pero prefieren caminar o nadar.",
        "Las hormigas se estiran cuando se despiertan por la mañana.",
        "Las cebras son negras con rayas blancas, no blancas con rayas negras.",
        "En promedio, las personas gastan seis meses de su vida esperando en semáforos rojos.",
        "El agujero en las lapiceras tiene un propósito: permite que el aire fluya hacia el interior para evitar que la tinta se seque.",
        "Los pandas recién nacidos son más pequeños que un ratón.",
        "El murmullo de las abejas puede predecir tormentas.",
        "Un grupo de leones se llama 'manada'.",
        "Las arañas son aracnofóbicas, lo que significa que temen a otras arañas.",
        "El primer animal que se clonó fue una oveja llamada Dolly.",
        "Los pingüinos tienen una glándula especial que les permite beber agua salada.",
        "Las hormigas no duermen.",
        "Un pulpo tiene tres corazones y azul es su color natural de sangre.",
        "La risa es más contagiosa que la gripe.",
        "El elefante es el único animal con cuatro rodillas.",
        "Las vacas tienen mejores amigas y pueden sentirse estresadas cuando se separan.",
        "Los patos tienen un órgano especial llamado 'glándula uropígea' que secreta aceite para mantener sus plumas impermeables.",
        "En promedio, los seres humanos pasan dos semanas de su vida besándose.",
        "La mayoría de las bolas de bolos están hechas de una madera llamada arce blanco del norte.",
        "El término 'nerd' se originó en el libro 'If I Ran the Zoo' de Dr. Seuss.",
        "Las serpientes pueden ver a través de sus párpados.",
        "Los elefantes son los únicos animales que no pueden saltar.",
        "La pupila de una cabra tiene forma rectangular.",
        "En Japón, hay una isla llena de conejos que se llaman Ōkunoshima.",
        "Los caballos tienen expresiones faciales que los humanos pueden reconocer.",
        "Los delfines tienen nombres propios y pueden llamar a otros delfines por su nombre.",
        "Las abejas pueden reconocer flores humanas y las evitan porque suelen picar.",
        "Los flamencos pueden dormir mientras están parados.",
        "El árbol más antiguo del mundo tiene 4,800 años y se llama 'Matusalén'.",
        "El vuelo más largo registrado de una gallina es de 13 segundos.",
        "El agujero en la parte superior de las galletas Ritz tiene un propósito: permite que el vapor escape durante la cocción.",
        "La letra 'Q' es la única letra que no aparece en el nombre de ninguno de los estados de EE.UU.",
        "Un grupo de pájaros se llama 'bandada'.",
        "Las cucarachas pueden vivir hasta nueve días sin su cabeza antes de morir de hambre.",
        "En Hawái, es ilegal que una persona coloque monedas en sus orejas.",
        "Las nutrias pueden dormir flotando en el agua.",
        "Los delfines son el único animal, además de los humanos, que puede tener sexo por placer.",
        "Las hormigas pueden transportar objetos que son 50 veces su propio peso corporal.",
        "Los pingüinos tienen una única pareja de por vida y a menudo se les regalan piedras como muestra de amor.",
        "Un grupo de peces se llama 'cardumen'.",
        "El canto de las ballenas jorobadas es tan complejo que cada grupo tiene su propio dialecto.",
        "Si pudieras escuchar en el espacio, podrías escuchar un zumbido constante. Este es el ruido de fondo del universo y se llama radiación cósmica de fondo.",
        "El día más largo registrado en la historia de la Tierra fue solo 1.7 milisegundos más largo debido a un terremoto.",
        "El corazón de un camarón está ubicado en su cabeza.",
        "En el mundo hay más flamencos de plástico que flamencos reales.",
        "Las papas fueron la primera verdura cultivada en el espacio.",
        "La luz del sol toma aproximadamente 8 minutos y 20 segundos en llegar a la Tierra.",
        "El agujero en una aguja se llama 'ojo'.",
        "Hay más combinaciones posibles de un juego de ajedrez que átomos en el universo observable.",
        "La miel nunca se echa a perder. Arqueólogos han encontrado miel en tumbas egipcias que tiene más de 3,000 años y aún es comestible.",
        "Las avestruces pueden correr más rápido que los caballos.",
        "El alfabeto más corto es el de los trazadores de lenguaje de señas, que tiene solo 22 letras.",
        "Los flamencos rosados no son naturalmente rosados; su color proviene de la dieta rica en betacarotenos.",
        "Las jirafas tienen lenguas azules y pueden alcanzar cosas altas sin levantar sus patas del suelo.",
        "Los elefantes son los únicos mamíferos que no pueden saltar.",
        "En Venecia, Italia, no hay coches; todos los desplazamientos se realizan en botes.",
        "El ajedrez se originó en la India hace más de 1,400 años.",
        "Los pulpos tienen tres corazones y azul es su color natural de sangre.",
        "El término 'nerd' se originó en el libro 'If I Ran the Zoo' de Dr. Seuss.",
        "Los delfines tienen nombres propios y pueden reconocerse a través de silbidos únicos.",
        "Las arañas no son insectos; son arácnidos.",
        "Las cebras son negras con rayas blancas.",
        "Los patos tienen papilas gustativas en sus picos.",
        "Los tigres tienen rayas en la piel, no solo en el pelaje.",
        "La nariz de un humano tiene una huella única, similar a las huellas dactilares.",
        "Los pingüinos no tienen glándulas sudoríparas, así que se sobrecalientan fácilmente.",
        "Los osos polares son zurdos.",
        "Las abejas pueden ver colores ultravioletas.",
        "Los camellos tienen tres párpados para protegerse de las tormentas de arena.",
        "Las serpientes pueden 'oler' con sus lenguas bifurcadas.",
        "La letra 'Q' es la única letra que no aparece en el nombre de ningún estado de EE.UU.",
        "El agujero en las lapiceras tiene un propósito: permite que el aire fluya hacia el interior para evitar que la tinta se seque.",
        "Las nutrias tienen una 'bolsa' en su cuerpo donde almacenan su comida favorita.",
        "Los canguros no pueden saltar hacia atrás.",
        "Las abejas tienen cinco ojos.",
        "Los lobos aúllan para comunicarse con otros miembros de su manada.",
        "Las luciérnagas no son realmente moscas; son escarabajos.",
        "El ojo humano puede distinguir aproximadamente 10 millones de colores.",
        "Las iguanas tienen un tercer ojo en la parte superior de sus cabezas que detecta la luz y la sombra.",
        "Las mariposas pueden probar con sus patas.",
        "Los pandas recién nacidos son más pequeños que un ratón."
    ];

    const randomIndex = Math.floor(Math.random() * funFacts.length);

    return funFacts[randomIndex];
}

export default getRandomFact;
