const initialState = {
  userById: [],
  user: null,
  newUser: [],
  userByEmail: [],
  feedback: [],
  userProfile: 'personal',
  managerTableSort: null,
  peopleTableSort: null,
  teamsTableSort: null,
  emailDataTableSort: null,
  userRetroBoardTableSort: null,
  dailyChallenge: true,
  regularBoards: [],
  pRDataTableSort: ['date', true]
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'getUserById':
      return {
        ...state,
        userById: action.payload
      };
    case 'getUser':
      return {
        ...state,
        user: action.payload
      };
    case 'getUserByEmail':
      return {
        ...state,
        userByEmail: action.payload
      };
    case 'addUser':
      return {
        ...state,
        newUser: action.payload
      };
    case 'getFeedback':
      return {
        ...state,
        feedback: action.payload
      };
    case 'userProfileSelection':
      return {
        ...state,
        userProfile: action.payload
      }
    case 'managerTableSort':
      return {
        ...state,
        managerTableSort: action.payload
      }
    case 'peopleTableSort':
      return {
        ...state,
        peopleTableSort: action.payload
      }
    case 'teamsTableSort':
      return {
        ...state,
        teamsTableSort: action.payload
      }
    case 'emailDataTableSort':
      return {
        ...state,
        emailDataTableSort: action.payload
      }
    case 'userRetroBoardTableSort':
      return {
        ...state,
        userRetroBoardTableSort: action.payload
      }
    case 'dailyChallenge':
      return {
        ...state,
        dailyChallenge: action.payload
      }
    case 'getRegularBoards':
      return {
        ...state,
        regularBoards: action.payload
      }
    case 'pRDataTableSort':
      return {
        ...state,
        pRDataTableSort: action.payload
      }
    default:
      return state;
  }
};

export default userReducer;
