import { Box, styled } from '@mui/material';
import { Paragraph, Span } from 'app/components/Typography';
import Scrollbar from 'react-perfect-scrollbar';

export const pageSelectedConvert = (pageByURL, company) => {
    switch (pageByURL) {
        case 'index':
            return 'Principal';
        case 'schedule':
            return 'Agenda';
        case 'calendar':
            return 'Calendario';
        case 'messageAnalytics':
            return 'Mensajería';
        case 'comparatives':
            return 'Comparativas';
        case 'tasksManagement':
            return 'Gestión de tareas';
        case 'tasksPriority':
            return 'Priorizar';
        case 'analytics':
            return 'Objetivo de tiempo';
        case 'integrations':
            return 'Integraciones';
        case 'report':
            return 'Reporte de Patras';
        case 'workingHours':
            return 'Horario Laboral';
        case 'managerDashboard':
            return 'Compañía';
        case 'individualAnalytics':
            return 'Individual';
        case 'groupAnalytics':
            return company?.name === 'Mujer impacta' ? 'Por fundación' :  'Por equipo';
        case 'managerCalendars':
            return 'Agendas';
        case 'connections':
            return 'Conexiones';
        case 'sentimentalDashboard':
            return 'Sentimental';
        case 'teams':
            return company?.name === 'Mujer impacta' ? 'Fundaciones' : 'Equipos';
        case 'postMeeting':
            return 'Post reunión';
        case 'profile':
            return 'Perfil';
        case 'health':
            return 'Salud';
        case 'github':
            return 'Github';
        case 'retro-board':
            return 'Retrospectiva';
        case 'boards':
            return 'Retrospectivas';
        case 'feedback':
            return 'Sugerencias';
        case 'recreation':
            return 'Recreación';
        case 'people':
            return 'Personas';
        case 'admin-teams':
            return company?.name === 'Mujer impacta' ? 'Fundaciones' : 'Equipos';
        case 'wordCloud':
            return 'Nube de palabras';
        default: return 'none';
    }
};

export const ListLabel = styled(Paragraph)(({ theme, mode }) => ({
    fontSize: '12px',
    marginTop: '20px',
    marginLeft: '15px',
    marginBottom: '10px',
    textTransform: 'uppercase',
    display: mode === 'compact' && 'none',
    color: theme.palette.text.secondary
}));

export const ExtAndIntCommon = {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '8px',
    height: 44,
    whiteSpace: 'pre',
    marginBottom: '8px',
    textDecoration: 'none',
    justifyContent: 'space-between',
    transition: 'all 150ms ease-in',
    '&:hover': { background: 'rgba(255, 255, 255, 0.08)' },
    '&.compactNavItem': {
        overflow: 'hidden',
        justifyContent: 'center !important'
    },
    '& .icon': {
        fontSize: '18px',
        paddingLeft: '16px',
        paddingRight: '16px',
        verticalAlign: 'middle'
    }
};
export const ExternalLink = styled('a')(({ theme }) => ({
    ...ExtAndIntCommon,
    color: theme.palette.text.primary
}));

export const InternalLink = styled(Box)(({ theme }) => ({
    '& a': {
        ...ExtAndIntCommon,
        color: theme.palette.text.primary
    },
    '& .navItemActive': {
        backgroundColor: 'rgba(255, 255, 255, 0.16)'
    }
}));

export const StyledText = styled(Span)(({ mode }) => ({
    fontSize: '0.875rem',
    paddingLeft: '0.8rem',
    display: mode === 'compact' && 'none'
}));

export const BulletIcon = styled('div')(({ theme }) => ({
    padding: '2px',
    marginLeft: '24px',
    marginRight: '8px',
    overflow: 'hidden',
    borderRadius: '300px',
    background: theme.palette.text.primary
}));

export const BadgeValue = styled('div')(() => ({
    padding: '1px 8px',
    overflow: 'hidden',
    borderRadius: '300px'
}));

export const StyledScrollBar = styled(Scrollbar)(() => ({
    paddingLeft: '1rem',
    paddingRight: '1rem',
    position: 'relative'
}));

export const SideNavMobile = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100vw',
    background: 'rgba(0, 0, 0, 0.54)',
    zIndex: -1,
    [theme.breakpoints.up('lg')]: { display: 'none' }
}));
