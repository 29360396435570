import React from 'react';
import { Dialog, Box, Typography, } from '@mui/material';
import { H3 } from 'app/components/Typography';
import { PrimaryButton } from 'app/utils/ButtonsPatras';

const FiredDialog = ({openFiredDialog, user, logoutAfterDelay }) => {

    return <Dialog maxWidth="xs"
        open={openFiredDialog}
        fullWidth={true}>
        <Box
            p={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
            <H3 sx={{ fontSize: '20px', textAlign: 'center' }}>
                ¡Cuenta Eliminada! 🚨
            </H3>
            <H3 sx={{ fontSize: '16px', textAlign: 'start', fontWeight: 500, color: '#424142' }}>
                ¡Hola {user?.name}! 😞
            </H3>

            <Typography sx={{ fontWeight: 400, fontSize: '14px', color: '#424142', mb: .5 }}>
                Lamentamos informarte que tu cuenta asociada al correo electrónico {user?.email}
                ha sido eliminada por alguien en tu compañía. No podrás acceder a tu cuenta con ese correo electrónico.
            </Typography>
            <Typography sx={{ fontWeight: 400, fontSize: '14px', color: '#424142', mb: .5 }}>
                Lamentablemente, no tenemos acceso a los detalles específicos sobre la eliminación. 😔
                Por favor, contacta con el área de recursos humanos de tu compañía para obtener más información.
            </Typography>
            <PrimaryButton
                onClick={() => logoutAfterDelay()}
                sx={{ alignSelf: 'end' }}
            >
                Aceptar
            </PrimaryButton>
        </Box>
    </Dialog>

};

export default FiredDialog;

