import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { LinearProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import userActions from 'app/redux/actions/userActions';
import { useWindowWidth } from '@react-hook/window-size';
import MobileWaitingPage from 'app/components/MobileWaitingPage';
import OnboardingDashboard from 'app/views/onboarding/OnboardingDashboard';
import WaitingVerification from './WaitingVerification';
import { MatxLoading } from 'app/components';
import { useLocation } from 'react-router-dom';
import { workingHours, timeLocalStart } from 'app/utils/AuthGuardUtils';
import { useParams } from 'react-router-dom';
import FiredDialog from './components/FiredDialog';
import PermissionDialog from './components/PermissionDialog';
import AccountDialog from './components/AccountDialog';
import toast from 'react-hot-toast';

const AuthGuard = ({ children, res, loginMaded, setLoginMaded }) => {
  const dispatch = useDispatch();
  const { invitationLink: invitationId } = useParams();
  const { isAuthenticated, isLoading, loginWithRedirect, user, logout } = useAuth0();
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const authenticated = isAuthenticated;
  const timeZone = new Date().getTimezoneOffset();
  const onlyWidth = useWindowWidth();
  const page = useLocation().pathname.split('/')[1];
  const [openAccountDialog, setOpenAccountDialog] = useState(false);
  const [openFiredDialog, setOpenFiredDialog] = useState(false);
  const [openPermissionDialog, setOpenPermissionDialog] = useState(false);
  const [creatingNewAccount, setCreatingNewAccount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstLogin, setFirstLogin] = useState(false);
  const [welcomeBack, setWelcomeBack] = useState(false);
  const [reactivating, setReactivating] = useState(false);
  const [account, setAccount] = useState(null);

  function logoutAfterDelay() {
    logout({ returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI_LOGOUT });
  }

  const handleCreateNewAccount = () => {
    setShowLoader(true);
    dispatch(userActions.userBelongToCompany()).then(res => {
      dispatch(
        userActions.addUser({
          lastName: user.family_name,
          firstName: user.given_name,
          email: user.email,
          role: 'regular',
          CompanyId: res.data ? res.data.response?.Company.id : null,
          invitationId: invitationId && invitationId,
          timeLocalStart,
          timeZone,
          workingHours
        })
      ).then(async (res) => {
        if (res.data && res.data.success) {
          await dispatch(userActions.getUser(user.email));
          setLoading(false);
          if (page !== 'calendar') {
            setShowOnboarding(true);
            setShowLoader(false);
          }
        } else {
          if (res.response.status === 403) {
            setOpenAccountDialog(false);
            setOpenPermissionDialog(true);
            setShowLoader(false);
          } else {
            toast.error('Ha ocurrido un error, por favor intenta nuevamente');
            logoutAfterDelay();
          }
        }
      });
    })
  }

  useEffect(() => {
    if (!isLoading) {
      if (authenticated) {
        if (!user?.email_verified) {
          return;
        } else {
          if (!res) {
            return;
          } else {
            if (res?.data?.success) {
              return;
            } else {
              if (!res?.data?.success && res.data?.error === 'User found but inactive' && !creatingNewAccount && !reactivating) {
                setOpenAccountDialog(true);
                setShowLoader(false);
              } else if (!res?.data?.success && res.data?.error === 'Fired user') {
                setOpenFiredDialog(true);
              } else {
                if (!loginMaded) {
                  if (user.sub.includes('google')) {
                    setFirstLogin(true);
                    setOpenPermissionDialog(true);
                    setShowLoader(false);
                  }
                  if (user.sub.includes('windows')) {
                    handleCreateNewAccount();
                  }
                }
              }
            }
          }
        }
      } else {
        loginWithRedirect({
          access_type: 'offline',
          appState: { returnTo: window.location.pathname }
        });
      }
    }
    //eslint-disable-next-line
  }, [isLoading, authenticated, user, res]);

  if (!isLoading) {
    if (authenticated) {
      if (!user?.email_verified) {
        return <WaitingVerification user={user} />;
      } else {
        if (!res) {
          return onlyWidth < 800 ? <MobileWaitingPage /> : <LinearProgress color="primary" />;
        } else {
          if (res?.data?.success) {
            return <>{children}</>;
          }
        }
      }
    } else {
      return null;
    }
  } else {
    return null;
  }

  return <>
    {
      showOnboarding && <OnboardingDashboard />
    }
    <AccountDialog
      openAccountDialog={openAccountDialog}
      setOpenAccountDialog={setOpenAccountDialog}
      setOpenPermissionDialog={setOpenPermissionDialog}
      setCreatingNewAccount={setCreatingNewAccount}
      setFirstLogin={setFirstLogin}
      handleCreateNewAccount={handleCreateNewAccount}
      loading={loading}
      setLoading={setLoading}
      setShowLoader={setShowLoader}
      user={user}
      account={account}
      setAccount={setAccount}
      setReactivating={setReactivating}
      setWelcomeBack={setWelcomeBack}
    />
    <FiredDialog
      openFiredDialog={openFiredDialog}
      setShowLoader={setShowLoader}
      user={user}
      logoutAfterDelay={logoutAfterDelay}
    />
    <PermissionDialog
      openPermissionDialog={openPermissionDialog}
      closeDialog={() => setOpenPermissionDialog(false)}
      firstLogin={firstLogin}
      updatedLoginMaded={() => setLoginMaded(true)}
      logoutAfterDelay={logoutAfterDelay}
      setShowOnboarding={setShowOnboarding}
      setLoading={setLoading}
      setShowLoader={setShowLoader}
      account={account}
      reactivating={reactivating}
      welcomeBack={welcomeBack}
    />
    {
      showLoader
      &&
      <MatxLoading />
    }
  </>

};

export default AuthGuard;

