const initialState = {
  analizedFile: [],
  analizedText: [],
  entities: [],
  keyPr: [],
  pii: [],
  sentiment: null
};

const nlpReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'analyzeText':
      return {
        ...state,
        analizedText: action.payload
      };
    case 'analyzeFile':
      return {
        ...state,
        analizedFile: action.payload
      };
    case 'getEntitiesFile':
      return {
        ...state,
        entities: action.payload
      };
    case 'getKeyPrFile':
      return {
        ...state,
        keyPr: action.payload
      };
    case 'getPiiFile':
      return {
        ...state,
        pii: action.payload
      };
    case 'getSentimentFile':
      return {
        ...state,
        sentiment: action.payload
      };
    default:
      return state;
  }
};

export default nlpReducer;
