import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const SentimentalDashboard = Loadable(lazy(() => import('./SentimentalDashboard')));

const sentimentalRoutes = [
  {
    path: '/sentimentalDashboard',
    element: (
      <SentimentalDashboard />
    )
  }
];

export default sentimentalRoutes;
