/* eslint-disable no-undef */
import axios from 'axios';

const projectActions = {
  getProject: (projectId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_TASK}/project/${projectId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getProject', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getProjectsByUser: (userId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_TASK}/project/user/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getProjectsByUser', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  addProject: (userId, newProject) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_URI_TASK}/project/${userId}`,
          newProject, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'addProject', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  updateProject: (projectId, projectUpdated) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_URI_TASK}/project/${projectId}`,
          projectUpdated, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'updateProject', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  deleteProject: (projectId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.delete(`${process.env.REACT_APP_URI_TASK}/project/${projectId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'deleteProject', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  migrateProjects: (oldUserId, newUser) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.patch(
          `${process.env.REACT_APP_URI_TASK}/project/${oldUserId}?newUser=${newUser}`, {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  }
};

export default projectActions;
