import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const TeamsDashboard = Loadable(lazy(() => import('./TeamsDashboard')));

const managerTeamsRoutes = [
  {
    path: '/teams',
    element: (
      <TeamsDashboard />
    )
  }
];

export default managerTeamsRoutes;
