const initialState = {
  teams: [],
  team: null,
  newTeam: [],
  teamSelected: null
};

const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'getTeamByCompany':
      return {
        ...state,
        teams: action.payload
      };
    case 'getTeam':
      return {
        ...state,
        team: action.payload
      };
    case 'addTeam':
      return {
        ...state,
        newTeam: action.payload
      };
    case 'teamSelected':
      return {
        ...state,
        teamSelected: action.payload
      };
    default:
      return state;
  }
};

export default teamReducer;
