/* eslint-disable no-case-declarations */
const initialState = {
  projectsByUser: []
};

const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'getProjectsByUser':
      return {
        ...state,
        projectsByUser: action.payload
      };
    default:
      return state;
  }
};

export default projectsReducer;
