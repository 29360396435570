/* eslint-disable no-case-declarations */
const initialState = {
  taskById: {},
  allTasks: [],
  tasksAverages: [],
  confetti: false,
  sortChoice: null,
  taskView: null,
  taskIntegrations: null,
  deletedTasks: [],
  recurrenceData: null,
  customTaskRec: null,
  taskWithPreDate: null,
  openEmojiDialog: false,
  emojiSelected: null,
  tasksWithClickUpStatuses: [],
};

const tasksReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'getTask':
      return {
        ...state,
        taskById: action.payload
      };
    case 'getTasksByUser':
      const taskSorted = (action.payload).sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      return {
        ...state,
        allTasks: taskSorted
      };
    case 'getDeletedTasks':
      return {
        ...state,
        deletedTasks: action.payload
      };
    case 'getOrCreateAverage':
      return {
        ...state,
        tasksAverages: action.payload
      };
    case 'activateConfetti':
      return {
        ...state,
        confetti: action.payload
      };
    case 'openEmojiDialog':
      return {
        ...state,
        openEmojiDialog: action.payload
      };
    case 'emojiSelected':
      return {
        ...state,
        emojiSelected: action.payload
      };
    case 'sortChoice':
      return {
        ...state,
        sortChoice: action.payload
      };
    case 'getTaskView':
      return {
        ...state,
        taskView: action.payload
      };
    case 'getTaskIntegrations':
      return {
        ...state,
        taskIntegrations: action.payload
      };
    case 'recurrenceDataChoicen':
      return {
        ...state,
        recurrenceData: action.payload
      };
    case 'addTaskRecurrence':
      return {
        ...state,
        customTaskRec: action.payload
      };
    case 'taskWithPreDate':
      return {
        ...state,
        taskWithPreDate: action.payload
      };
    case 'tasksWithClickUpStatuses':
      return {
        ...state,
        tasksWithClickUpStatuses: action.payload
      };
    default:
      return state;
  }
};

export default tasksReducer;
