import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const Connections = Loadable(lazy(() => import('./ConnectionsDash')));

const connectionsRoutes = [
  {
    path: '/connections',
    element: (
      <Connections />
    )
  }
];

export default connectionsRoutes;