import { Box, Dialog, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FlexBetween } from './FlexBox';
import { PrimaryButtonNoHover } from 'app/utils/ButtonsPatras';
import { capitalizeFirstLetter } from 'app/utils/stringMethods';
import { Link } from 'react-router-dom';
import { useWindowWidth } from '@react-hook/window-size';
import notificationsActions from 'app/redux/actions/notificationsActions';

function WeekResume({ openResume, closeResume }) {

    const dispatch = useDispatch();
    const onlyWidth = useWindowWidth();
    const loggedUser = useSelector((store) => store.userReducer.user);
    const weekMeets = useSelector((store) => store.calendarReducer.calendarWeekly);
    const notiWeeklySummary = useSelector((store) => store.notificationsReducer.notiWeeklySummary);
    const overlapping = [];
    const [eventsOverlapping, setEventsOverlapping] = useState(false);
    const importantEvents = weekMeets.allEvents?.filter((event) => {
        if (!event.recurring) {
            return true;
        }
        const recurrence = event.recurrence?.pattern;
        return recurrence?.type !== 'daily' && recurrence?.daysOfWeek?.length < 3;
    })
        .sort((a, b) => new Date(a.start) - new Date(b.start));

    function hasOverlappingEvents(events) {
        const sortedEvents = events?.filter(event => event?.TimeGoal.name !== 'Focus Time').sort((a, b) => new Date(a.start) - new Date(b.start));

        for (let i = 1; i < sortedEvents?.length; i++) {
            const currentEvent = sortedEvents[i];
            const previousEvent = sortedEvents[i - 1];

            if (new Date(currentEvent.start) < new Date(previousEvent.end)) {
                overlapping.push([previousEvent, currentEvent]);
            }
        }
    }

    useEffect(() => {
        if (weekMeets?.allEvents) {
            hasOverlappingEvents(weekMeets?.allEvents);
            setEventsOverlapping(overlapping);
        }
        //eslint-disable-next-line
    }, [weekMeets?.allEvents]);

    const handleCloseModal = () => {
        dispatch(notificationsActions.notiWeeklySummary(false));
        dispatch(notificationsActions.setViewNotifications([notiWeeklySummary]));
        closeResume();
    };

    return (
        <>
            {
                eventsOverlapping &&
                <Dialog
                    open={openResume}
                    onClose={handleCloseModal}
                    aria- labelledby="form-dialog-title"
                    sx={{
                        '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
                            maxWidth: '100vw',
                            width: onlyWidth > 800 ? '60vw !important' : '100vw !important',
                        },
                        '& .css-uhb5lp': {
                            maxWidth: '100vw',
                            width: onlyWidth > 800 ? '60vw !important' : '100vw !important',
                        }
                    }
                    }
                >
                    <Box sx={{ padding: '4rem' }}>
                        <Typography sx={{ fontSize: '24px', mb: 4, textAlign: 'center' }}>¡Buen día {loggedUser?.User.firstName}, buen comienzo de semana!</Typography>
                        {
                            (eventsOverlapping?.length > 0) &&
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography sx={{ fontSize: '18px', mb: '20px', fontWeight: 500, alignSelf: 'self-start' }}>Hemos encontrado que durante esta semana tienes reuniones solapadas:</Typography>
                                {
                                    eventsOverlapping?.map((event, index) => (
                                        <FlexBetween key={index} sx={{ my: .5, backgroundColor: index % 2 === 0 ? '#fbfafb' : 'transparent', padding: 1, width: '100%' }}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={3}>
                                                    <Typography sx={{ fontSize: '16px', mr: 1 }}>{capitalizeFirstLetter(new Date(event[0]?.start).toLocaleDateString('es-AR', { weekday: 'long' }))}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Box>
                                                        {
                                                            event.map((e, i) => (
                                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} key={i}>
                                                                    <Typography sx={{
                                                                        maxWidth: '100%',
                                                                        fontSize: '14px',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden'
                                                                    }}>{e.eventName}</Typography>
                                                                </Box>
                                                            ))
                                                        }
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Box>
                                                        {
                                                            event.map((ev, ind) => (
                                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }} key={ind}>
                                                                    <Typography sx={{ fontSize: '14px' }}>
                                                                        {new Date(ev.start).toLocaleTimeString([], {
                                                                            hour: '2-digit',
                                                                            minute: '2-digit',
                                                                            hour12: false
                                                                        })} - {new Date(ev.end).toLocaleTimeString([], {
                                                                            hour: '2-digit',
                                                                            minute: '2-digit',
                                                                            hour12: false
                                                                        })}
                                                                    </Typography>
                                                                </Box>
                                                            ))
                                                        }
                                                    </Box>
                                                </Grid>

                                            </Grid>
                                        </FlexBetween>
                                    ))
                                }
                                <Link to={'/calendar'}>
                                    <PrimaryButtonNoHover
                                        variant="contained"
                                        sx={{ padding: '4px', width: '18rem', mt: 2, fontSize: '14px' }}
                                        onClick={() => closeResume()}
                                    >
                                        Resolver solapamiento
                                    </PrimaryButtonNoHover>
                                </Link>
                            </Box>
                        }
                        {
                            (importantEvents?.length > 0) &&
                            <Box>
                                <Typography sx={{ fontSize: '18px', m: '30px 0px 20px 0px', fontWeight: 500 }}>Estas son algunas reuniones de tu semana que podrían ser importantes:</Typography>
                                {
                                    importantEvents?.map((event, index) => (
                                        <FlexBetween key={index} sx={{ my: .5, backgroundColor: index % 2 === 0 ? '#fbfafb' : 'transparent', padding: 1 }}>
                                            <Typography item xs={5} sx={{ width: '15%', textAlign: 'start' }}>
                                                {capitalizeFirstLetter(new Date(event?.start).toLocaleDateString('es-AR', { weekday: 'long' }))}
                                            </Typography>
                                            <Typography sx={{
                                                maxWidth: '70%',
                                                fontSize: '14px',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textAlign: 'center',
                                            }}>{event.eventName}</Typography>
                                            <Typography sx={{ fontSize: '14px', width: '15%', textAlign: 'end' }}>
                                                {new Date(event.start).toLocaleTimeString([], {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: false
                                                })} - {new Date(event.end).toLocaleTimeString([], {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: false
                                                })}
                                            </Typography>
                                        </FlexBetween>
                                    ))
                                }
                            </Box>
                        }
                    </Box>
                </Dialog >
            }
        </>
    )
}

export default WeekResume