import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from '../src/app/App';
import { Provider } from 'react-redux';
import mainReducer from './app/redux/reducers/mainReducer';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { Auth0ProviderWithNavigate } from 'app/auth/auth0-provider-with-navigate';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/styled-engine';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { SettingsProvider } from 'app/contexts/SettingsContext';
import { NotificationProvider } from 'app/contexts/NotificationContext';

const middleware = [...getDefaultMiddleware({
  immutableCheck: false,
  serializableCheck: false,
})];

const reduxStore = configureStore({ reducer: mainReducer, middleware });
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <StyledEngineProvider injectFirst>
    <Provider store={reduxStore}>
      <SettingsProvider>
        <BrowserRouter>
          <Auth0ProviderWithNavigate
          >
            <CssBaseline />
            <NotificationProvider>
              <App />
            </NotificationProvider>
          </Auth0ProviderWithNavigate>
        </BrowserRouter>
      </SettingsProvider>
    </Provider>
  </StyledEngineProvider>
);
