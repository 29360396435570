import matrixUL from './taskMatrix/matrixUL.png';
import matrixUR from './taskMatrix/matrixUR.png';
import matrixDL from './taskMatrix/matrixDL.png';
import matrixDR from './taskMatrix/matrixDR.png';

export const renderTags = (value) => {
    switch (value) {
        case 1:
            return 'Muy leve';
        case 2:
            return 'Leve';
        case 3:
            return 'Moderado';
        case 4:
            return 'Medio';
        case 5:
            return 'Alto';
        default:
            return 'Muy alto';
    }
};

export const renderPriorityImage = (value) => {
    switch (value) {
        case 1:
            return matrixUL;
        case 2:
            return matrixDL;
        case 3:
            return matrixUR;
        default:
            return matrixDR;
    }
};

export const renderPriorityDescription = (value) => {
    switch (value) {
        case 1:
            return 'La tarea brindará resultados en un período corto con muy poco esfuerzo.';
        case 2:
            return 'La tarea es pequeña, pero no genera gran impacto. Se sugiere delegarla o priorizarla en los momentos tranquilos de la semana.';
        case 3:
            return 'La tarea otorga importantes beneficios pero a costa de un enorme esfuerzo.';
        default:
            return 'La tarea implica un gran esfuerzo y no entrega beneficios en el corto plazo.';
    }
};

export const renderPriorityAction = (value) => {
    switch (value) {
        case 1:
            return 'Realizarlas rapidamente';
        case 2:
            return 'Delegar o repensarlas';
        case 3:
            return 'Descomprimir en tareas más pequeñas';
        default:
            return 'Descomprimir para secuenciar su finalización';
    }
};

export const renderPriorityName = (value) => {
    switch (value) {
        case 1:
            return 'Ganancias rápidas';
        case 2:
            return 'Actividades menores';
        case 3:
            return 'Proyectos a largo plazo';
        default:
            return 'Tareas ingratas';
    }
};

export const renderTrelloTagColors = (value) => {
    switch (value) {
        case 'green_light':
            return '#164b36';
        case 'green':
            return '#216e4e';
        case 'green_dark':
            return '#4bcd97';
        case 'blue_light':
            return '#09326c';
        case 'blue':
            return '#1655cb';
        case 'blue_dark':
            return '#579dff';
        case 'yellow_light':
            return '#533f03';
        case 'yellow':
            return '#7f5f00';
        case 'yellow_dark':
            return '#e2b202';
        case 'lightBlue_light':
            return '#164555';
        case 'lightBlue':
            return '#206a83';
        case 'lightBlue_dark':
            return '#6cc3e0';
        case 'orange_light':
            return '#702e01';
        case 'orange':
            return '#a44800';
        case 'orange_dark':
            return '#faa362';
        case 'lime_light':
            return '#37471e';
        case 'lime':
            return '#4b6b1f';
        case 'lime_dark':
            return '#94c748';
        case 'red_light':
            return '#5d201a';
        case 'red':
            return '#ae2e24';
        case 'red_dark':
            return '#f87167';
        case 'pink_light':
            return '#50253e';
        case 'pink':
            return '#943c73';
        case 'pink_dark':
            return '#e674bb';
        case 'purple_light':
            return '#352b63';
        case 'purple':
            return '#5e4db2';
        case 'purple_dark':
            return '#9f8fef';
        case 'black_light':
            return '#454f59';
        case 'black':
            return '#596773';
        case 'black_dark':
            return '#8c9bab';
        default:
            return 'gray';
    }
}

export const EmojiList = (withOutCat) => {
    const list = [
        {
            "unified": "1F600",
            "name": "GRINNING FACE",
            "native": "😀",
            "shortName": "grinning",
            "shortNames": [
                "grinning"
            ],
            "text": ":D",
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 24,
            "tts": "grinning face",
            "keywords": [
                "face",
                "grin",
                "grinning face"
            ]
        },
        {
            "unified": "1F601",
            "name": "GRINNING FACE WITH SMILING EYES",
            "native": "😁",
            "shortName": "grin",
            "shortNames": [
                "grin"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 25,
            "tts": "beaming face with smiling eyes",
            "keywords": [
                "beaming face with smiling eyes",
                "eye",
                "face",
                "grin",
                "smile"
            ]
        },
        {
            "unified": "1F602",
            "name": "FACE WITH TEARS OF JOY",
            "native": "😂",
            "shortName": "joy",
            "shortNames": [
                "joy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 26,
            "tts": "face with tears of joy",
            "keywords": [
                "face",
                "face with tears of joy",
                "joy",
                "laugh",
                "tear"
            ]
        },
        {
            "unified": "1F923",
            "name": "ROLLING ON THE FLOOR LAUGHING",
            "native": "🤣",
            "shortName": "rolling_on_the_floor_laughing",
            "shortNames": [
                "rolling_on_the_floor_laughing"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 38,
            "sheetY": 26,
            "tts": "rolling on the floor laughing",
            "keywords": [
                "face",
                "floor",
                "laugh",
                "rolling",
                "rolling on the floor laughing"
            ]
        },
        {
            "unified": "1F603",
            "name": "SMILING FACE WITH OPEN MOUTH",
            "native": "😃",
            "shortName": "smiley",
            "shortNames": [
                "smiley"
            ],
            "text": ":)",
            "texts": [
                "=)",
                "=-)"
            ],
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 27,
            "tts": "grinning face with big eyes",
            "keywords": [
                "face",
                "grinning face with big eyes",
                "mouth",
                "open",
                "smile"
            ]
        },
        {
            "unified": "1F604",
            "name": "SMILING FACE WITH OPEN MOUTH AND SMILING EYES",
            "native": "😄",
            "shortName": "smile",
            "shortNames": [
                "smile"
            ],
            "text": ":)",
            "texts": [
                "C:",
                "c:",
                ":D",
                ":-D"
            ],
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 28,
            "tts": "grinning face with smiling eyes",
            "keywords": [
                "eye",
                "face",
                "grinning face with smiling eyes",
                "mouth",
                "open",
                "smile"
            ]
        },
        {
            "unified": "1F605",
            "name": "SMILING FACE WITH OPEN MOUTH AND COLD SWEAT",
            "native": "😅",
            "shortName": "sweat_smile",
            "shortNames": [
                "sweat_smile"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 29,
            "tts": "grinning face with sweat",
            "keywords": [
                "cold",
                "face",
                "grinning face with sweat",
                "open",
                "smile",
                "sweat"
            ]
        },
        {
            "unified": "1F606",
            "name": "SMILING FACE WITH OPEN MOUTH AND TIGHTLY-CLOSED EYES",
            "native": "😆",
            "shortName": "laughing",
            "shortNames": [
                "laughing",
                "satisfied"
            ],
            "text": null,
            "texts": [
                ":>",
                ":->"
            ],
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 30,
            "tts": "grinning squinting face",
            "keywords": [
                "face",
                "grinning squinting face",
                "laugh",
                "mouth",
                "open",
                "satisfied",
                "smile"
            ]
        },
        {
            "unified": "1F609",
            "name": "WINKING FACE",
            "native": "😉",
            "shortName": "wink",
            "shortNames": [
                "wink"
            ],
            "text": ";)",
            "texts": [
                ";)",
                ";-)"
            ],
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 33,
            "tts": "winking face",
            "keywords": [
                "face",
                "wink",
                "winking face"
            ]
        },
        {
            "unified": "1F60A",
            "name": "SMILING FACE WITH SMILING EYES",
            "native": "😊",
            "shortName": "blush",
            "shortNames": [
                "blush"
            ],
            "text": ":)",
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 34,
            "tts": "smiling face with smiling eyes",
            "keywords": [
                "blush",
                "eye",
                "face",
                "smile",
                "smiling face with smiling eyes"
            ]
        },
        {
            "unified": "1F60B",
            "name": "FACE SAVOURING DELICIOUS FOOD",
            "native": "😋",
            "shortName": "yum",
            "shortNames": [
                "yum"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 35,
            "tts": "face savoring food",
            "keywords": [
                "delicious",
                "face",
                "face savoring food",
                "savouring",
                "smile",
                "um",
                "yum"
            ]
        },
        {
            "unified": "1F60E",
            "name": "SMILING FACE WITH SUNGLASSES",
            "native": "😎",
            "shortName": "sunglasses",
            "shortNames": [
                "sunglasses"
            ],
            "text": null,
            "texts": [
                "8)"
            ],
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 38,
            "tts": "smiling face with sunglasses",
            "keywords": [
                "bright",
                "cool",
                "eye",
                "eyewear",
                "face",
                "glasses",
                "smile",
                "smiling face with sunglasses",
                "sun",
                "sunglasses"
            ]
        },
        {
            "unified": "1F60D",
            "name": "SMILING FACE WITH HEART-SHAPED EYES",
            "native": "😍",
            "shortName": "heart_eyes",
            "shortNames": [
                "heart_eyes"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 37,
            "tts": "smiling face with heart-eyes",
            "keywords": [
                "eye",
                "face",
                "love",
                "smile",
                "smiling face with heart-eyes"
            ]
        },
        {
            "unified": "1F618",
            "name": "FACE THROWING A KISS",
            "native": "😘",
            "shortName": "kissing_heart",
            "shortNames": [
                "kissing_heart"
            ],
            "text": null,
            "texts": [
                ":*",
                ":-*"
            ],
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 48,
            "tts": "face blowing a kiss",
            "keywords": [
                "face",
                "face blowing a kiss",
                "kiss"
            ]
        },
        {
            "unified": "1F617",
            "name": "KISSING FACE",
            "native": "😗",
            "shortName": "kissing",
            "shortNames": [
                "kissing"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 47,
            "tts": "kissing face",
            "keywords": [
                "face",
                "kiss",
                "kissing face"
            ]
        },
        {
            "unified": "1F619",
            "name": "KISSING FACE WITH SMILING EYES",
            "native": "😙",
            "shortName": "kissing_smiling_eyes",
            "shortNames": [
                "kissing_smiling_eyes"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 49,
            "tts": "kissing face with smiling eyes",
            "keywords": [
                "eye",
                "face",
                "kiss",
                "kissing face with smiling eyes",
                "smile"
            ]
        },
        {
            "unified": "1F61A",
            "name": "KISSING FACE WITH CLOSED EYES",
            "native": "😚",
            "shortName": "kissing_closed_eyes",
            "shortNames": [
                "kissing_closed_eyes"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 50,
            "tts": "kissing face with closed eyes",
            "keywords": [
                "closed",
                "eye",
                "face",
                "kiss",
                "kissing face with closed eyes"
            ]
        },
        {
            "unified": "263A-FE0F",
            "name": "WHITE SMILING FACE",
            "native": "☺️",
            "shortName": "relaxed",
            "shortNames": [
                "relaxed"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 47,
            "sheetY": 41
        },
        {
            "unified": "1F642",
            "name": "SLIGHTLY SMILING FACE",
            "native": "🙂",
            "shortName": "slightly_smiling_face",
            "shortNames": [
                "slightly_smiling_face"
            ],
            "text": null,
            "texts": [
                ":)",
                "(:",
                ":-)"
            ],
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 38,
            "tts": "slightly smiling face",
            "keywords": [
                "face",
                "slightly smiling face",
                "smile"
            ]
        },
        {
            "unified": "1F917",
            "name": "HUGGING FACE",
            "native": "🤗",
            "shortName": "hugging_face",
            "shortNames": [
                "hugging_face"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 37,
            "sheetY": 31,
            "tts": "hugging face",
            "keywords": [
                "face",
                "hug",
                "hugging"
            ]
        },
        {
            "unified": "1F929",
            "name": "GRINNING FACE WITH STAR EYES",
            "native": "🤩",
            "shortName": "star-struck",
            "shortNames": [
                "star-struck",
                "grinning_face_with_star_eyes"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 38,
            "sheetY": 49,
            "tts": "star-struck",
            "keywords": [
                "eyes",
                "face",
                "grinning",
                "star",
                "star-struck"
            ]
        },
        {
            "unified": "1F914",
            "name": "THINKING FACE",
            "native": "🤔",
            "shortName": "thinking_face",
            "shortNames": [
                "thinking_face"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 37,
            "sheetY": 28,
            "tts": "thinking face",
            "keywords": [
                "face",
                "thinking"
            ]
        },
        {
            "unified": "1F928",
            "name": "FACE WITH ONE EYEBROW RAISED",
            "native": "🤨",
            "shortName": "face_with_raised_eyebrow",
            "shortNames": [
                "face_with_raised_eyebrow",
                "face_with_one_eyebrow_raised"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 38,
            "sheetY": 48,
            "tts": "face with raised eyebrow",
            "keywords": [
                "distrust",
                "face with raised eyebrow",
                "skeptic"
            ]
        },
        {
            "unified": "1F610",
            "name": "NEUTRAL FACE",
            "native": "😐",
            "shortName": "neutral_face",
            "shortNames": [
                "neutral_face"
            ],
            "text": null,
            "texts": [
                ":|",
                ":-|"
            ],
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 40,
            "tts": "neutral face",
            "keywords": [
                "deadpan",
                "face",
                "neutral"
            ]
        },
        {
            "unified": "1F611",
            "name": "EXPRESSIONLESS FACE",
            "native": "😑",
            "shortName": "expressionless",
            "shortNames": [
                "expressionless"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 41,
            "tts": "expressionless face",
            "keywords": [
                "expressionless",
                "face",
                "inexpressive",
                "unexpressive"
            ]
        },
        {
            "unified": "1F636",
            "name": "FACE WITHOUT MOUTH",
            "native": "😶",
            "shortName": "no_mouth",
            "shortNames": [
                "no_mouth"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 26,
            "tts": "face without mouth",
            "keywords": [
                "face",
                "face without mouth",
                "mouth",
                "quiet",
                "silent"
            ]
        },
        {
            "unified": "1F644",
            "name": "FACE WITH ROLLING EYES",
            "native": "🙄",
            "shortName": "face_with_rolling_eyes",
            "shortNames": [
                "face_with_rolling_eyes"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 40,
            "tts": "face with rolling eyes",
            "keywords": [
                "eyes",
                "face",
                "face with rolling eyes",
                "rolling"
            ]
        },
        {
            "unified": "1F60F",
            "name": "SMIRKING FACE",
            "native": "😏",
            "shortName": "smirk",
            "shortNames": [
                "smirk"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 39,
            "tts": "smirking face",
            "keywords": [
                "face",
                "smirk",
                "smirking face"
            ]
        },
        {
            "unified": "1F623",
            "name": "PERSEVERING FACE",
            "native": "😣",
            "shortName": "persevere",
            "shortNames": [
                "persevere"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 7,
            "tts": "persevering face",
            "keywords": [
                "face",
                "persevere",
                "persevering face"
            ]
        },
        {
            "unified": "1F625",
            "name": "DISAPPOINTED BUT RELIEVED FACE",
            "native": "😥",
            "shortName": "disappointed_relieved",
            "shortNames": [
                "disappointed_relieved"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 9,
            "tts": "sad but relieved face",
            "keywords": [
                "disappointed",
                "face",
                "relieved",
                "sad but relieved face",
                "whew"
            ]
        },
        {
            "unified": "1F62E",
            "name": "FACE WITH OPEN MOUTH",
            "native": "😮",
            "shortName": "open_mouth",
            "shortNames": [
                "open_mouth"
            ],
            "text": null,
            "texts": [
                ":o",
                ":-o",
                ":O",
                ":-O"
            ],
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 18,
            "tts": "face with open mouth",
            "keywords": [
                "face",
                "face with open mouth",
                "mouth",
                "open",
                "sympathy"
            ]
        },
        {
            "unified": "1F910",
            "name": "ZIPPER-MOUTH FACE",
            "native": "🤐",
            "shortName": "zipper_mouth_face",
            "shortNames": [
                "zipper_mouth_face"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 37,
            "sheetY": 24,
            "tts": "zipper-mouth face",
            "keywords": [
                "face",
                "mouth",
                "zipper",
                "zipper-mouth face"
            ]
        },
        {
            "unified": "1F62F",
            "name": "HUSHED FACE",
            "native": "😯",
            "shortName": "hushed",
            "shortNames": [
                "hushed"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 19,
            "tts": "hushed face",
            "keywords": [
                "face",
                "hushed",
                "stunned",
                "surprised"
            ]
        },
        {
            "unified": "1F62A",
            "name": "SLEEPY FACE",
            "native": "😪",
            "shortName": "sleepy",
            "shortNames": [
                "sleepy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 14,
            "tts": "sleepy face",
            "keywords": [
                "face",
                "sleep",
                "sleepy face"
            ]
        },
        {
            "unified": "1F62B",
            "name": "TIRED FACE",
            "native": "😫",
            "shortName": "tired_face",
            "shortNames": [
                "tired_face"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 15,
            "tts": "tired face",
            "keywords": [
                "face",
                "tired"
            ]
        },
        {
            "unified": "1F634",
            "name": "SLEEPING FACE",
            "native": "😴",
            "shortName": "sleeping",
            "shortNames": [
                "sleeping"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 24,
            "tts": "sleeping face",
            "keywords": [
                "face",
                "sleep",
                "sleeping face",
                "zzz"
            ]
        },
        {
            "unified": "1F60C",
            "name": "RELIEVED FACE",
            "native": "😌",
            "shortName": "relieved",
            "shortNames": [
                "relieved"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 36,
            "tts": "relieved face",
            "keywords": [
                "face",
                "relieved"
            ]
        },
        {
            "unified": "1F61B",
            "name": "FACE WITH STUCK-OUT TONGUE",
            "native": "😛",
            "shortName": "stuck_out_tongue",
            "shortNames": [
                "stuck_out_tongue"
            ],
            "text": ":p",
            "texts": [
                ":p",
                ":-p",
                ":P",
                ":-P",
                ":b",
                ":-b"
            ],
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 51,
            "tts": "face with tongue",
            "keywords": [
                "face",
                "face with tongue",
                "tongue"
            ]
        },
        {
            "unified": "1F61C",
            "name": "FACE WITH STUCK-OUT TONGUE AND WINKING EYE",
            "native": "😜",
            "shortName": "stuck_out_tongue_winking_eye",
            "shortNames": [
                "stuck_out_tongue_winking_eye"
            ],
            "text": ";p",
            "texts": [
                ";p",
                ";-p",
                ";b",
                ";-b",
                ";P",
                ";-P"
            ],
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 0,
            "tts": "winking face with tongue",
            "keywords": [
                "eye",
                "face",
                "joke",
                "tongue",
                "wink",
                "winking face with tongue"
            ]
        },
        {
            "unified": "1F61D",
            "name": "FACE WITH STUCK-OUT TONGUE AND TIGHTLY-CLOSED EYES",
            "native": "😝",
            "shortName": "stuck_out_tongue_closed_eyes",
            "shortNames": [
                "stuck_out_tongue_closed_eyes"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 1,
            "tts": "squinting face with tongue",
            "keywords": [
                "eye",
                "face",
                "horrible",
                "squinting face with tongue",
                "taste",
                "tongue"
            ]
        },
        {
            "unified": "1F924",
            "name": "DROOLING FACE",
            "native": "🤤",
            "shortName": "drooling_face",
            "shortNames": [
                "drooling_face"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 38,
            "sheetY": 27,
            "tts": "drooling face",
            "keywords": [
                "drooling",
                "face"
            ]
        },
        {
            "unified": "1F612",
            "name": "UNAMUSED FACE",
            "native": "😒",
            "shortName": "unamused",
            "shortNames": [
                "unamused"
            ],
            "text": ":(",
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 42,
            "tts": "unamused face",
            "keywords": [
                "face",
                "unamused",
                "unhappy"
            ]
        },
        {
            "unified": "1F613",
            "name": "FACE WITH COLD SWEAT",
            "native": "😓",
            "shortName": "sweat",
            "shortNames": [
                "sweat"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 43,
            "tts": "downcast face with sweat",
            "keywords": [
                "cold",
                "downcast face with sweat",
                "face",
                "sweat"
            ]
        },
        {
            "unified": "1F614",
            "name": "PENSIVE FACE",
            "native": "😔",
            "shortName": "pensive",
            "shortNames": [
                "pensive"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 44,
            "tts": "pensive face",
            "keywords": [
                "dejected",
                "face",
                "pensive"
            ]
        },
        {
            "unified": "1F615",
            "name": "CONFUSED FACE",
            "native": "😕",
            "shortName": "confused",
            "shortNames": [
                "confused"
            ],
            "text": null,
            "texts": [
                ":\\",
                ":-\\",
                ":/",
                ":-/"
            ],
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 45,
            "tts": "confused face",
            "keywords": [
                "confused",
                "face"
            ]
        },
        {
            "unified": "1F643",
            "name": "UPSIDE-DOWN FACE",
            "native": "🙃",
            "shortName": "upside_down_face",
            "shortNames": [
                "upside_down_face"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 39,
            "tts": "upside-down face",
            "keywords": [
                "face",
                "upside-down"
            ]
        },
        {
            "unified": "1F911",
            "name": "MONEY-MOUTH FACE",
            "native": "🤑",
            "shortName": "money_mouth_face",
            "shortNames": [
                "money_mouth_face"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 37,
            "sheetY": 25,
            "tts": "money-mouth face",
            "keywords": [
                "face",
                "money",
                "money-mouth face",
                "mouth"
            ]
        },
        {
            "unified": "1F632",
            "name": "ASTONISHED FACE",
            "native": "😲",
            "shortName": "astonished",
            "shortNames": [
                "astonished"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 22,
            "tts": "astonished face",
            "keywords": [
                "astonished",
                "face",
                "shocked",
                "totally"
            ]
        },
        {
            "unified": "2639-FE0F",
            "name": null,
            "native": "☹️",
            "shortName": "white_frowning_face",
            "shortNames": [
                "white_frowning_face"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 47,
            "sheetY": 40
        },
        {
            "unified": "1F641",
            "name": "SLIGHTLY FROWNING FACE",
            "native": "🙁",
            "shortName": "slightly_frowning_face",
            "shortNames": [
                "slightly_frowning_face"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 37,
            "tts": "slightly frowning face",
            "keywords": [
                "face",
                "frown",
                "slightly frowning face"
            ]
        },
        {
            "unified": "1F616",
            "name": "CONFOUNDED FACE",
            "native": "😖",
            "shortName": "confounded",
            "shortNames": [
                "confounded"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 46,
            "tts": "confounded face",
            "keywords": [
                "confounded",
                "face"
            ]
        },
        {
            "unified": "1F61E",
            "name": "DISAPPOINTED FACE",
            "native": "😞",
            "shortName": "disappointed",
            "shortNames": [
                "disappointed"
            ],
            "text": ":(",
            "texts": [
                "):",
                ":(",
                ":-("
            ],
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 2,
            "tts": "disappointed face",
            "keywords": [
                "disappointed",
                "face"
            ]
        },
        {
            "unified": "1F61F",
            "name": "WORRIED FACE",
            "native": "😟",
            "shortName": "worried",
            "shortNames": [
                "worried"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 3,
            "tts": "worried face",
            "keywords": [
                "face",
                "worried"
            ]
        },
        {
            "unified": "1F624",
            "name": "FACE WITH LOOK OF TRIUMPH",
            "native": "😤",
            "shortName": "triumph",
            "shortNames": [
                "triumph"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 8,
            "tts": "face with steam from nose",
            "keywords": [
                "face",
                "face with steam from nose",
                "triumph",
                "won"
            ]
        },
        {
            "unified": "1F622",
            "name": "CRYING FACE",
            "native": "😢",
            "shortName": "cry",
            "shortNames": [
                "cry"
            ],
            "text": ":'(",
            "texts": [
                ":'("
            ],
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 6,
            "tts": "crying face",
            "keywords": [
                "cry",
                "crying face",
                "face",
                "sad",
                "tear"
            ]
        },
        {
            "unified": "1F62D",
            "name": "LOUDLY CRYING FACE",
            "native": "😭",
            "shortName": "sob",
            "shortNames": [
                "sob"
            ],
            "text": ":'(",
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 17,
            "tts": "loudly crying face",
            "keywords": [
                "cry",
                "face",
                "loudly crying face",
                "sad",
                "sob",
                "tear"
            ]
        },
        {
            "unified": "1F626",
            "name": "FROWNING FACE WITH OPEN MOUTH",
            "native": "😦",
            "shortName": "frowning",
            "shortNames": [
                "frowning"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 10,
            "tts": "frowning face with open mouth",
            "keywords": [
                "face",
                "frown",
                "frowning face with open mouth",
                "mouth",
                "open"
            ]
        },
        {
            "unified": "1F627",
            "name": "ANGUISHED FACE",
            "native": "😧",
            "shortName": "anguished",
            "shortNames": [
                "anguished"
            ],
            "text": null,
            "texts": [
                "D:"
            ],
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 11,
            "tts": "anguished face",
            "keywords": [
                "anguished",
                "face"
            ]
        },
        {
            "unified": "1F628",
            "name": "FEARFUL FACE",
            "native": "😨",
            "shortName": "fearful",
            "shortNames": [
                "fearful"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 12,
            "tts": "fearful face",
            "keywords": [
                "face",
                "fear",
                "fearful",
                "scared"
            ]
        },
        {
            "unified": "1F629",
            "name": "WEARY FACE",
            "native": "😩",
            "shortName": "weary",
            "shortNames": [
                "weary"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 13,
            "tts": "weary face",
            "keywords": [
                "face",
                "tired",
                "weary"
            ]
        },
        {
            "unified": "1F92F",
            "name": "SHOCKED FACE WITH EXPLODING HEAD",
            "native": "🤯",
            "shortName": "exploding_head",
            "shortNames": [
                "exploding_head",
                "shocked_face_with_exploding_head"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 39,
            "sheetY": 3,
            "tts": "exploding head",
            "keywords": [
                "exploding head",
                "shocked"
            ]
        },
        {
            "unified": "1F62C",
            "name": "GRIMACING FACE",
            "native": "😬",
            "shortName": "grimacing",
            "shortNames": [
                "grimacing"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 16,
            "tts": "grimacing face",
            "keywords": [
                "face",
                "grimace",
                "grimacing face"
            ]
        },
        {
            "unified": "1F630",
            "name": "FACE WITH OPEN MOUTH AND COLD SWEAT",
            "native": "😰",
            "shortName": "cold_sweat",
            "shortNames": [
                "cold_sweat"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 20,
            "tts": "anxious face with sweat",
            "keywords": [
                "anxious face with sweat",
                "blue",
                "cold",
                "face",
                "mouth",
                "open",
                "rushed",
                "sweat"
            ]
        },
        {
            "unified": "1F631",
            "name": "FACE SCREAMING IN FEAR",
            "native": "😱",
            "shortName": "scream",
            "shortNames": [
                "scream"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 21,
            "tts": "face screaming in fear",
            "keywords": [
                "face",
                "face screaming in fear",
                "fear",
                "fearful",
                "munch",
                "scared",
                "scream"
            ]
        },
        {
            "unified": "1F633",
            "name": "FLUSHED FACE",
            "native": "😳",
            "shortName": "flushed",
            "shortNames": [
                "flushed"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 23,
            "tts": "flushed face",
            "keywords": [
                "dazed",
                "face",
                "flushed"
            ]
        },
        {
            "unified": "1F92A",
            "name": "GRINNING FACE WITH ONE LARGE AND ONE SMALL EYE",
            "native": "🤪",
            "shortName": "zany_face",
            "shortNames": [
                "zany_face",
                "grinning_face_with_one_large_and_one_small_eye"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 38,
            "sheetY": 50,
            "tts": "zany face",
            "keywords": [
                "eye",
                "goofy",
                "large",
                "small",
                "zany face"
            ]
        },
        {
            "unified": "1F635",
            "name": "DIZZY FACE",
            "native": "😵",
            "shortName": "dizzy_face",
            "shortNames": [
                "dizzy_face"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 25,
            "tts": "dizzy face",
            "keywords": [
                "dizzy",
                "face"
            ]
        },
        {
            "unified": "1F621",
            "name": "POUTING FACE",
            "native": "😡",
            "shortName": "rage",
            "shortNames": [
                "rage"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 5,
            "tts": "pouting face",
            "keywords": [
                "angry",
                "face",
                "mad",
                "pouting",
                "rage",
                "red"
            ]
        },
        {
            "unified": "1F620",
            "name": "ANGRY FACE",
            "native": "😠",
            "shortName": "angry",
            "shortNames": [
                "angry"
            ],
            "text": null,
            "texts": [
                ">:(",
                ">:-("
            ],
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 4,
            "tts": "angry face",
            "keywords": [
                "angry",
                "face",
                "mad"
            ]
        },
        {
            "unified": "1F92C",
            "name": "SERIOUS FACE WITH SYMBOLS COVERING MOUTH",
            "native": "🤬",
            "shortName": "face_with_symbols_on_mouth",
            "shortNames": [
                "face_with_symbols_on_mouth",
                "serious_face_with_symbols_covering_mouth"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 39,
            "sheetY": 0,
            "tts": "face with symbols on mouth",
            "keywords": [
                "face with symbols on mouth",
                "swearing"
            ]
        },
        {
            "unified": "1F637",
            "name": "FACE WITH MEDICAL MASK",
            "native": "😷",
            "shortName": "mask",
            "shortNames": [
                "mask"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 27,
            "tts": "face with medical mask",
            "keywords": [
                "cold",
                "doctor",
                "face",
                "face with medical mask",
                "mask",
                "medicine",
                "sick"
            ]
        },
        {
            "unified": "1F912",
            "name": "FACE WITH THERMOMETER",
            "native": "🤒",
            "shortName": "face_with_thermometer",
            "shortNames": [
                "face_with_thermometer"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 37,
            "sheetY": 26,
            "tts": "face with thermometer",
            "keywords": [
                "face",
                "face with thermometer",
                "ill",
                "sick",
                "thermometer"
            ]
        },
        {
            "unified": "1F915",
            "name": "FACE WITH HEAD-BANDAGE",
            "native": "🤕",
            "shortName": "face_with_head_bandage",
            "shortNames": [
                "face_with_head_bandage"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 37,
            "sheetY": 29,
            "tts": "face with head-bandage",
            "keywords": [
                "bandage",
                "face",
                "face with head-bandage",
                "hurt",
                "injury"
            ]
        },
        {
            "unified": "1F922",
            "name": "NAUSEATED FACE",
            "native": "🤢",
            "shortName": "nauseated_face",
            "shortNames": [
                "nauseated_face"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 38,
            "sheetY": 25,
            "tts": "nauseated face",
            "keywords": [
                "face",
                "nauseated",
                "vomit"
            ]
        },
        {
            "unified": "1F92E",
            "name": "FACE WITH OPEN MOUTH VOMITING",
            "native": "🤮",
            "shortName": "face_vomiting",
            "shortNames": [
                "face_vomiting",
                "face_with_open_mouth_vomiting"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 39,
            "sheetY": 2,
            "tts": "face vomiting",
            "keywords": [
                "face vomiting",
                "sick",
                "vomit"
            ]
        },
        {
            "unified": "1F927",
            "name": "SNEEZING FACE",
            "native": "🤧",
            "shortName": "sneezing_face",
            "shortNames": [
                "sneezing_face"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 38,
            "sheetY": 47,
            "tts": "sneezing face",
            "keywords": [
                "face",
                "gesundheit",
                "sneeze",
                "sneezing face"
            ]
        },
        {
            "unified": "1F607",
            "name": "SMILING FACE WITH HALO",
            "native": "😇",
            "shortName": "innocent",
            "shortNames": [
                "innocent"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 31,
            "tts": "smiling face with halo",
            "keywords": [
                "angel",
                "face",
                "fairy tale",
                "fantasy",
                "halo",
                "innocent",
                "smile",
                "smiling face with halo"
            ]
        },
        {
            "unified": "1F920",
            "name": "FACE WITH COWBOY HAT",
            "native": "🤠",
            "shortName": "face_with_cowboy_hat",
            "shortNames": [
                "face_with_cowboy_hat"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 38,
            "sheetY": 23,
            "tts": "cowboy hat face",
            "keywords": [
                "cowboy",
                "cowgirl",
                "face",
                "hat"
            ]
        },
        {
            "unified": "1F925",
            "name": "LYING FACE",
            "native": "🤥",
            "shortName": "lying_face",
            "shortNames": [
                "lying_face"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 38,
            "sheetY": 28,
            "tts": "lying face",
            "keywords": [
                "face",
                "lie",
                "lying face",
                "pinocchio"
            ]
        },
        {
            "unified": "1F92B",
            "name": "FACE WITH FINGER COVERING CLOSED LIPS",
            "native": "🤫",
            "shortName": "shushing_face",
            "shortNames": [
                "shushing_face",
                "face_with_finger_covering_closed_lips"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 38,
            "sheetY": 51,
            "tts": "shushing face",
            "keywords": [
                "quiet",
                "shush",
                "shushing face"
            ]
        },
        {
            "unified": "1F92D",
            "name": "SMILING FACE WITH SMILING EYES AND HAND COVERING MOUTH",
            "native": "🤭",
            "shortName": "face_with_hand_over_mouth",
            "shortNames": [
                "face_with_hand_over_mouth",
                "smiling_face_with_smiling_eyes_and_hand_covering_mouth"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 39,
            "sheetY": 1,
            "tts": "face with hand over mouth",
            "keywords": [
                "face with hand over mouth",
                "whoops"
            ]
        },
        {
            "unified": "1F9D0",
            "name": "FACE WITH MONOCLE",
            "native": "🧐",
            "shortName": "face_with_monocle",
            "shortNames": [
                "face_with_monocle"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 42,
            "sheetY": 49,
            "tts": "face with monocle",
            "keywords": [
                "face with monocle",
                "stuffy"
            ]
        },
        {
            "unified": "1F913",
            "name": "NERD FACE",
            "native": "🤓",
            "shortName": "nerd_face",
            "shortNames": [
                "nerd_face"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 37,
            "sheetY": 27,
            "tts": "nerd face",
            "keywords": [
                "face",
                "geek",
                "nerd"
            ]
        },
        {
            "unified": "1F608",
            "name": "SMILING FACE WITH HORNS",
            "native": "😈",
            "shortName": "smiling_imp",
            "shortNames": [
                "smiling_imp"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 32,
            "tts": "smiling face with horns",
            "keywords": [
                "face",
                "fairy tale",
                "fantasy",
                "horns",
                "smile",
                "smiling face with horns"
            ]
        },
        {
            "unified": "1F47F",
            "name": "IMP",
            "native": "👿",
            "shortName": "imp",
            "shortNames": [
                "imp"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 22,
            "sheetY": 51,
            "tts": "angry face with horns",
            "keywords": [
                "angry face with horns",
                "demon",
                "devil",
                "face",
                "fairy tale",
                "fantasy",
                "imp"
            ]
        },
        {
            "unified": "1F921",
            "name": "CLOWN FACE",
            "native": "🤡",
            "shortName": "clown_face",
            "shortNames": [
                "clown_face"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 38,
            "sheetY": 24,
            "tts": "clown face",
            "keywords": [
                "clown",
                "face"
            ]
        },
        {
            "unified": "1F479",
            "name": "JAPANESE OGRE",
            "native": "👹",
            "shortName": "japanese_ogre",
            "shortNames": [
                "japanese_ogre"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 22,
            "sheetY": 40,
            "tts": "ogre",
            "keywords": [
                "creature",
                "face",
                "fairy tale",
                "fantasy",
                "monster",
                "ogre"
            ]
        },
        {
            "unified": "1F47A",
            "name": "JAPANESE GOBLIN",
            "native": "👺",
            "shortName": "japanese_goblin",
            "shortNames": [
                "japanese_goblin"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 22,
            "sheetY": 41,
            "tts": "goblin",
            "keywords": [
                "creature",
                "face",
                "fairy tale",
                "fantasy",
                "goblin",
                "monster"
            ]
        },
        {
            "unified": "1F480",
            "name": "SKULL",
            "native": "💀",
            "shortName": "skull",
            "shortNames": [
                "skull"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 23,
            "sheetY": 0,
            "tts": "skull",
            "keywords": [
                "death",
                "face",
                "fairy tale",
                "monster",
                "skull"
            ]
        },
        {
            "unified": "2620-FE0F",
            "name": null,
            "native": "☠️",
            "shortName": "skull_and_crossbones",
            "shortNames": [
                "skull_and_crossbones"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 47,
            "sheetY": 32
        },
        {
            "unified": "1F47B",
            "name": "GHOST",
            "native": "👻",
            "shortName": "ghost",
            "shortNames": [
                "ghost"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 22,
            "sheetY": 42,
            "tts": "ghost",
            "keywords": [
                "creature",
                "face",
                "fairy tale",
                "fantasy",
                "ghost",
                "monster"
            ]
        },
        {
            "unified": "1F47D",
            "name": "EXTRATERRESTRIAL ALIEN",
            "native": "👽",
            "shortName": "alien",
            "shortNames": [
                "alien"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 22,
            "sheetY": 49,
            "tts": "alien",
            "keywords": [
                "alien",
                "creature",
                "extraterrestrial",
                "face",
                "fairy tale",
                "fantasy",
                "monster",
                "ufo"
            ]
        },
        {
            "unified": "1F47E",
            "name": "ALIEN MONSTER",
            "native": "👾",
            "shortName": "space_invader",
            "shortNames": [
                "space_invader"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 22,
            "sheetY": 50,
            "tts": "alien monster",
            "keywords": [
                "alien",
                "creature",
                "extraterrestrial",
                "face",
                "fairy tale",
                "fantasy",
                "monster",
                "ufo"
            ]
        },
        {
            "unified": "1F916",
            "name": "ROBOT FACE",
            "native": "🤖",
            "shortName": "robot_face",
            "shortNames": [
                "robot_face"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 37,
            "sheetY": 30,
            "tts": "robot face",
            "keywords": [
                "face",
                "monster",
                "robot"
            ]
        },
        {
            "unified": "1F4A9",
            "name": "PILE OF POO",
            "native": "💩",
            "shortName": "hankey",
            "shortNames": [
                "hankey",
                "poop",
                "shit"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 25,
            "sheetY": 15,
            "tts": "pile of poo",
            "keywords": [
                "comic",
                "dung",
                "face",
                "monster",
                "pile of poo",
                "poo",
                "poop"
            ]
        },
        {
            "unified": "1F63A",
            "name": "SMILING CAT FACE WITH OPEN MOUTH",
            "native": "😺",
            "shortName": "smiley_cat",
            "shortNames": [
                "smiley_cat"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 30,
            "tts": "grinning cat face",
            "keywords": [
                "cat",
                "face",
                "grinning cat face",
                "mouth",
                "open",
                "smile"
            ]
        },
        {
            "unified": "1F638",
            "name": "GRINNING CAT FACE WITH SMILING EYES",
            "native": "😸",
            "shortName": "smile_cat",
            "shortNames": [
                "smile_cat"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 28,
            "tts": "grinning cat face with smiling eyes",
            "keywords": [
                "cat",
                "eye",
                "face",
                "grin",
                "grinning cat face with smiling eyes",
                "smile"
            ]
        },
        {
            "unified": "1F639",
            "name": "CAT FACE WITH TEARS OF JOY",
            "native": "😹",
            "shortName": "joy_cat",
            "shortNames": [
                "joy_cat"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 29,
            "tts": "cat face with tears of joy",
            "keywords": [
                "cat",
                "cat face with tears of joy",
                "face",
                "joy",
                "tear"
            ]
        },
        {
            "unified": "1F63B",
            "name": "SMILING CAT FACE WITH HEART-SHAPED EYES",
            "native": "😻",
            "shortName": "heart_eyes_cat",
            "shortNames": [
                "heart_eyes_cat"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 31,
            "tts": "smiling cat face with heart-eyes",
            "keywords": [
                "cat",
                "eye",
                "face",
                "love",
                "smile",
                "smiling cat face with heart-eyes"
            ]
        },
        {
            "unified": "1F63C",
            "name": "CAT FACE WITH WRY SMILE",
            "native": "😼",
            "shortName": "smirk_cat",
            "shortNames": [
                "smirk_cat"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 32,
            "tts": "cat face with wry smile",
            "keywords": [
                "cat",
                "cat face with wry smile",
                "face",
                "ironic",
                "smile",
                "wry"
            ]
        },
        {
            "unified": "1F63D",
            "name": "KISSING CAT FACE WITH CLOSED EYES",
            "native": "😽",
            "shortName": "kissing_cat",
            "shortNames": [
                "kissing_cat"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 33,
            "tts": "kissing cat face",
            "keywords": [
                "cat",
                "eye",
                "face",
                "kiss",
                "kissing cat face"
            ]
        },
        {
            "unified": "1F640",
            "name": "WEARY CAT FACE",
            "native": "🙀",
            "shortName": "scream_cat",
            "shortNames": [
                "scream_cat"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 36,
            "tts": "weary cat face",
            "keywords": [
                "cat",
                "face",
                "oh",
                "surprised",
                "weary"
            ]
        },
        {
            "unified": "1F63F",
            "name": "CRYING CAT FACE",
            "native": "😿",
            "shortName": "crying_cat_face",
            "shortNames": [
                "crying_cat_face"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 35,
            "tts": "crying cat face",
            "keywords": [
                "cat",
                "cry",
                "crying cat face",
                "face",
                "sad",
                "tear"
            ]
        },
        {
            "unified": "1F63E",
            "name": "POUTING CAT FACE",
            "native": "😾",
            "shortName": "pouting_cat",
            "shortNames": [
                "pouting_cat"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 34,
            "tts": "pouting cat face",
            "keywords": [
                "cat",
                "face",
                "pouting"
            ]
        },
        {
            "unified": "1F648",
            "name": "SEE-NO-EVIL MONKEY",
            "native": "🙈",
            "shortName": "see_no_evil",
            "shortNames": [
                "see_no_evil"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 32,
            "sheetY": 43,
            "tts": "see-no-evil monkey",
            "keywords": [
                "evil",
                "face",
                "forbidden",
                "gesture",
                "monkey",
                "no",
                "not",
                "prohibited",
                "see",
                "see-no-evil monkey"
            ]
        },
        {
            "unified": "1F649",
            "name": "HEAR-NO-EVIL MONKEY",
            "native": "🙉",
            "shortName": "hear_no_evil",
            "shortNames": [
                "hear_no_evil"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 32,
            "sheetY": 44,
            "tts": "hear-no-evil monkey",
            "keywords": [
                "evil",
                "face",
                "forbidden",
                "gesture",
                "hear",
                "hear-no-evil monkey",
                "monkey",
                "no",
                "not",
                "prohibited"
            ]
        },
        {
            "unified": "1F64A",
            "name": "SPEAK-NO-EVIL MONKEY",
            "native": "🙊",
            "shortName": "speak_no_evil",
            "shortNames": [
                "speak_no_evil"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 32,
            "sheetY": 45,
            "tts": "speak-no-evil monkey",
            "keywords": [
                "evil",
                "face",
                "forbidden",
                "gesture",
                "monkey",
                "no",
                "not",
                "prohibited",
                "speak",
                "speak-no-evil monkey"
            ]
        },
        {
            "unified": "1F476",
            "name": "BABY",
            "native": "👶",
            "shortName": "baby",
            "shortNames": [
                "baby"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 22,
            "sheetY": 10,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F476-1F3FB",
                    "native": "👶🏻",
                    "sheetX": 22,
                    "sheetY": 11
                },
                "1F3FC": {
                    "unified": "1F476-1F3FC",
                    "native": "👶🏼",
                    "sheetX": 22,
                    "sheetY": 12
                },
                "1F3FD": {
                    "unified": "1F476-1F3FD",
                    "native": "👶🏽",
                    "sheetX": 22,
                    "sheetY": 13
                },
                "1F3FE": {
                    "unified": "1F476-1F3FE",
                    "native": "👶🏾",
                    "sheetX": 22,
                    "sheetY": 14
                },
                "1F3FF": {
                    "unified": "1F476-1F3FF",
                    "native": "👶🏿",
                    "sheetX": 22,
                    "sheetY": 15
                }
            },
            "tts": "baby",
            "keywords": [
                "baby",
                "young"
            ]
        },
        {
            "unified": "1F9D2",
            "name": "CHILD",
            "native": "🧒",
            "shortName": "child",
            "shortNames": [
                "child"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 43,
            "sheetY": 4,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F9D2-1F3FB",
                    "native": "🧒🏻",
                    "sheetX": 43,
                    "sheetY": 5
                },
                "1F3FC": {
                    "unified": "1F9D2-1F3FC",
                    "native": "🧒🏼",
                    "sheetX": 43,
                    "sheetY": 6
                },
                "1F3FD": {
                    "unified": "1F9D2-1F3FD",
                    "native": "🧒🏽",
                    "sheetX": 43,
                    "sheetY": 7
                },
                "1F3FE": {
                    "unified": "1F9D2-1F3FE",
                    "native": "🧒🏾",
                    "sheetX": 43,
                    "sheetY": 8
                },
                "1F3FF": {
                    "unified": "1F9D2-1F3FF",
                    "native": "🧒🏿",
                    "sheetX": 43,
                    "sheetY": 9
                }
            },
            "tts": "child",
            "keywords": [
                "child",
                "gender-neutral",
                "young"
            ]
        },
        {
            "unified": "1F466",
            "name": "BOY",
            "native": "👦",
            "shortName": "boy",
            "shortNames": [
                "boy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 42,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F466-1F3FB",
                    "native": "👦🏻",
                    "sheetX": 15,
                    "sheetY": 43
                },
                "1F3FC": {
                    "unified": "1F466-1F3FC",
                    "native": "👦🏼",
                    "sheetX": 15,
                    "sheetY": 44
                },
                "1F3FD": {
                    "unified": "1F466-1F3FD",
                    "native": "👦🏽",
                    "sheetX": 15,
                    "sheetY": 45
                },
                "1F3FE": {
                    "unified": "1F466-1F3FE",
                    "native": "👦🏾",
                    "sheetX": 15,
                    "sheetY": 46
                },
                "1F3FF": {
                    "unified": "1F466-1F3FF",
                    "native": "👦🏿",
                    "sheetX": 15,
                    "sheetY": 47
                }
            },
            "tts": "boy",
            "keywords": [
                "boy",
                "young"
            ]
        },
        {
            "unified": "1F467",
            "name": "GIRL",
            "native": "👧",
            "shortName": "girl",
            "shortNames": [
                "girl"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 48,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F467-1F3FB",
                    "native": "👧🏻",
                    "sheetX": 15,
                    "sheetY": 49
                },
                "1F3FC": {
                    "unified": "1F467-1F3FC",
                    "native": "👧🏼",
                    "sheetX": 15,
                    "sheetY": 50
                },
                "1F3FD": {
                    "unified": "1F467-1F3FD",
                    "native": "👧🏽",
                    "sheetX": 15,
                    "sheetY": 51
                },
                "1F3FE": {
                    "unified": "1F467-1F3FE",
                    "native": "👧🏾",
                    "sheetX": 16,
                    "sheetY": 0
                },
                "1F3FF": {
                    "unified": "1F467-1F3FF",
                    "native": "👧🏿",
                    "sheetX": 16,
                    "sheetY": 1
                }
            },
            "tts": "girl",
            "keywords": [
                "girl",
                "Virgo",
                "young",
                "zodiac"
            ]
        },
        {
            "unified": "1F9D1",
            "name": "ADULT",
            "native": "🧑",
            "shortName": "adult",
            "shortNames": [
                "adult"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 42,
            "sheetY": 50,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F9D1-1F3FB",
                    "native": "🧑🏻",
                    "sheetX": 42,
                    "sheetY": 51
                },
                "1F3FC": {
                    "unified": "1F9D1-1F3FC",
                    "native": "🧑🏼",
                    "sheetX": 43,
                    "sheetY": 0
                },
                "1F3FD": {
                    "unified": "1F9D1-1F3FD",
                    "native": "🧑🏽",
                    "sheetX": 43,
                    "sheetY": 1
                },
                "1F3FE": {
                    "unified": "1F9D1-1F3FE",
                    "native": "🧑🏾",
                    "sheetX": 43,
                    "sheetY": 2
                },
                "1F3FF": {
                    "unified": "1F9D1-1F3FF",
                    "native": "🧑🏿",
                    "sheetX": 43,
                    "sheetY": 3
                }
            },
            "tts": "adult",
            "keywords": [
                "adult",
                "gender-neutral"
            ]
        },
        {
            "unified": "1F468",
            "name": "MAN",
            "native": "👨",
            "shortName": "man",
            "shortNames": [
                "man"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 18,
            "sheetY": 11,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F468-1F3FB",
                    "native": "👨🏻",
                    "sheetX": 18,
                    "sheetY": 12
                },
                "1F3FC": {
                    "unified": "1F468-1F3FC",
                    "native": "👨🏼",
                    "sheetX": 18,
                    "sheetY": 13
                },
                "1F3FD": {
                    "unified": "1F468-1F3FD",
                    "native": "👨🏽",
                    "sheetX": 18,
                    "sheetY": 14
                },
                "1F3FE": {
                    "unified": "1F468-1F3FE",
                    "native": "👨🏾",
                    "sheetX": 18,
                    "sheetY": 15
                },
                "1F3FF": {
                    "unified": "1F468-1F3FF",
                    "native": "👨🏿",
                    "sheetX": 18,
                    "sheetY": 16
                }
            },
            "tts": "man",
            "keywords": [
                "man"
            ]
        },
        {
            "unified": "1F471-200D-2642-FE0F",
            "name": null,
            "native": "👱‍♂️",
            "shortName": "blond-haired-man",
            "shortNames": [
                "blond-haired-man"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 21,
            "sheetY": 14,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F471-1F3FB-200D-2642-FE0F",
                    "native": "👱🏻‍♂️",
                    "sheetX": 21,
                    "sheetY": 15
                },
                "1F3FC": {
                    "unified": "1F471-1F3FC-200D-2642-FE0F",
                    "native": "👱🏼‍♂️",
                    "sheetX": 21,
                    "sheetY": 16
                },
                "1F3FD": {
                    "unified": "1F471-1F3FD-200D-2642-FE0F",
                    "native": "👱🏽‍♂️",
                    "sheetX": 21,
                    "sheetY": 17
                },
                "1F3FE": {
                    "unified": "1F471-1F3FE-200D-2642-FE0F",
                    "native": "👱🏾‍♂️",
                    "sheetX": 21,
                    "sheetY": 18
                },
                "1F3FF": {
                    "unified": "1F471-1F3FF-200D-2642-FE0F",
                    "native": "👱🏿‍♂️",
                    "sheetX": 21,
                    "sheetY": 19
                }
            },
            "tts": "blond-haired man",
            "keywords": [
                "blond",
                "blond-haired person",
                "blond-haired man",
                "man"
            ]
        },
        {
            "unified": "1F9D4",
            "name": "BEARDED PERSON",
            "native": "🧔",
            "shortName": "bearded_person",
            "shortNames": [
                "bearded_person"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 43,
            "sheetY": 16,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F9D4-1F3FB",
                    "native": "🧔🏻",
                    "sheetX": 43,
                    "sheetY": 17
                },
                "1F3FC": {
                    "unified": "1F9D4-1F3FC",
                    "native": "🧔🏼",
                    "sheetX": 43,
                    "sheetY": 18
                },
                "1F3FD": {
                    "unified": "1F9D4-1F3FD",
                    "native": "🧔🏽",
                    "sheetX": 43,
                    "sheetY": 19
                },
                "1F3FE": {
                    "unified": "1F9D4-1F3FE",
                    "native": "🧔🏾",
                    "sheetX": 43,
                    "sheetY": 20
                },
                "1F3FF": {
                    "unified": "1F9D4-1F3FF",
                    "native": "🧔🏿",
                    "sheetX": 43,
                    "sheetY": 21
                }
            },
            "tts": "bearded person",
            "keywords": [
                "beard",
                "bearded person"
            ]
        },
        {
            "unified": "1F469",
            "name": "WOMAN",
            "native": "👩",
            "shortName": "woman",
            "shortNames": [
                "woman"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 20,
            "sheetY": 23,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F469-1F3FB",
                    "native": "👩🏻",
                    "sheetX": 20,
                    "sheetY": 24
                },
                "1F3FC": {
                    "unified": "1F469-1F3FC",
                    "native": "👩🏼",
                    "sheetX": 20,
                    "sheetY": 25
                },
                "1F3FD": {
                    "unified": "1F469-1F3FD",
                    "native": "👩🏽",
                    "sheetX": 20,
                    "sheetY": 26
                },
                "1F3FE": {
                    "unified": "1F469-1F3FE",
                    "native": "👩🏾",
                    "sheetX": 20,
                    "sheetY": 27
                },
                "1F3FF": {
                    "unified": "1F469-1F3FF",
                    "native": "👩🏿",
                    "sheetX": 20,
                    "sheetY": 28
                }
            },
            "tts": "woman",
            "keywords": [
                "woman"
            ]
        },
        {
            "unified": "1F471-200D-2640-FE0F",
            "name": null,
            "native": "👱‍♀️",
            "shortName": "blond-haired-woman",
            "shortNames": [
                "blond-haired-woman"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 21,
            "sheetY": 8,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F471-1F3FB-200D-2640-FE0F",
                    "native": "👱🏻‍♀️",
                    "sheetX": 21,
                    "sheetY": 9
                },
                "1F3FC": {
                    "unified": "1F471-1F3FC-200D-2640-FE0F",
                    "native": "👱🏼‍♀️",
                    "sheetX": 21,
                    "sheetY": 10
                },
                "1F3FD": {
                    "unified": "1F471-1F3FD-200D-2640-FE0F",
                    "native": "👱🏽‍♀️",
                    "sheetX": 21,
                    "sheetY": 11
                },
                "1F3FE": {
                    "unified": "1F471-1F3FE-200D-2640-FE0F",
                    "native": "👱🏾‍♀️",
                    "sheetX": 21,
                    "sheetY": 12
                },
                "1F3FF": {
                    "unified": "1F471-1F3FF-200D-2640-FE0F",
                    "native": "👱🏿‍♀️",
                    "sheetX": 21,
                    "sheetY": 13
                }
            },
            "tts": "blond-haired woman",
            "keywords": [
                "blond",
                "blond-haired person",
                "blond-haired woman",
                "blonde",
                "woman"
            ]
        },
        {
            "unified": "1F9D3",
            "name": "OLDER ADULT",
            "native": "🧓",
            "shortName": "older_adult",
            "shortNames": [
                "older_adult"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 43,
            "sheetY": 10,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F9D3-1F3FB",
                    "native": "🧓🏻",
                    "sheetX": 43,
                    "sheetY": 11
                },
                "1F3FC": {
                    "unified": "1F9D3-1F3FC",
                    "native": "🧓🏼",
                    "sheetX": 43,
                    "sheetY": 12
                },
                "1F3FD": {
                    "unified": "1F9D3-1F3FD",
                    "native": "🧓🏽",
                    "sheetX": 43,
                    "sheetY": 13
                },
                "1F3FE": {
                    "unified": "1F9D3-1F3FE",
                    "native": "🧓🏾",
                    "sheetX": 43,
                    "sheetY": 14
                },
                "1F3FF": {
                    "unified": "1F9D3-1F3FF",
                    "native": "🧓🏿",
                    "sheetX": 43,
                    "sheetY": 15
                }
            },
            "tts": "older adult",
            "keywords": [
                "gender-neutral",
                "old",
                "older adult"
            ]
        },
        {
            "unified": "1F474",
            "name": "OLDER MAN",
            "native": "👴",
            "shortName": "older_man",
            "shortNames": [
                "older_man"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 21,
            "sheetY": 50,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F474-1F3FB",
                    "native": "👴🏻",
                    "sheetX": 21,
                    "sheetY": 51
                },
                "1F3FC": {
                    "unified": "1F474-1F3FC",
                    "native": "👴🏼",
                    "sheetX": 22,
                    "sheetY": 0
                },
                "1F3FD": {
                    "unified": "1F474-1F3FD",
                    "native": "👴🏽",
                    "sheetX": 22,
                    "sheetY": 1
                },
                "1F3FE": {
                    "unified": "1F474-1F3FE",
                    "native": "👴🏾",
                    "sheetX": 22,
                    "sheetY": 2
                },
                "1F3FF": {
                    "unified": "1F474-1F3FF",
                    "native": "👴🏿",
                    "sheetX": 22,
                    "sheetY": 3
                }
            },
            "tts": "old man",
            "keywords": [
                "man",
                "old"
            ]
        },
        {
            "unified": "1F475",
            "name": "OLDER WOMAN",
            "native": "👵",
            "shortName": "older_woman",
            "shortNames": [
                "older_woman"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 22,
            "sheetY": 4,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F475-1F3FB",
                    "native": "👵🏻",
                    "sheetX": 22,
                    "sheetY": 5
                },
                "1F3FC": {
                    "unified": "1F475-1F3FC",
                    "native": "👵🏼",
                    "sheetX": 22,
                    "sheetY": 6
                },
                "1F3FD": {
                    "unified": "1F475-1F3FD",
                    "native": "👵🏽",
                    "sheetX": 22,
                    "sheetY": 7
                },
                "1F3FE": {
                    "unified": "1F475-1F3FE",
                    "native": "👵🏾",
                    "sheetX": 22,
                    "sheetY": 8
                },
                "1F3FF": {
                    "unified": "1F475-1F3FF",
                    "native": "👵🏿",
                    "sheetX": 22,
                    "sheetY": 9
                }
            },
            "tts": "old woman",
            "keywords": [
                "old",
                "woman"
            ]
        },
        {
            "unified": "1F468-200D-2695-FE0F",
            "name": null,
            "native": "👨‍⚕️",
            "shortName": "male-doctor",
            "shortNames": [
                "male-doctor"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 17,
            "sheetY": 43,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F468-1F3FB-200D-2695-FE0F",
                    "native": "👨🏻‍⚕️",
                    "sheetX": 17,
                    "sheetY": 44
                },
                "1F3FC": {
                    "unified": "1F468-1F3FC-200D-2695-FE0F",
                    "native": "👨🏼‍⚕️",
                    "sheetX": 17,
                    "sheetY": 45
                },
                "1F3FD": {
                    "unified": "1F468-1F3FD-200D-2695-FE0F",
                    "native": "👨🏽‍⚕️",
                    "sheetX": 17,
                    "sheetY": 46
                },
                "1F3FE": {
                    "unified": "1F468-1F3FE-200D-2695-FE0F",
                    "native": "👨🏾‍⚕️",
                    "sheetX": 17,
                    "sheetY": 47
                },
                "1F3FF": {
                    "unified": "1F468-1F3FF-200D-2695-FE0F",
                    "native": "👨🏿‍⚕️",
                    "sheetX": 17,
                    "sheetY": 48
                }
            },
            "tts": "man health worker",
            "keywords": [
                "doctor",
                "healthcare",
                "man",
                "man health worker",
                "nurse",
                "therapist"
            ]
        },
        {
            "unified": "1F469-200D-2695-FE0F",
            "name": null,
            "native": "👩‍⚕️",
            "shortName": "female-doctor",
            "shortNames": [
                "female-doctor"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 20,
            "sheetY": 1,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F469-1F3FB-200D-2695-FE0F",
                    "native": "👩🏻‍⚕️",
                    "sheetX": 20,
                    "sheetY": 2
                },
                "1F3FC": {
                    "unified": "1F469-1F3FC-200D-2695-FE0F",
                    "native": "👩🏼‍⚕️",
                    "sheetX": 20,
                    "sheetY": 3
                },
                "1F3FD": {
                    "unified": "1F469-1F3FD-200D-2695-FE0F",
                    "native": "👩🏽‍⚕️",
                    "sheetX": 20,
                    "sheetY": 4
                },
                "1F3FE": {
                    "unified": "1F469-1F3FE-200D-2695-FE0F",
                    "native": "👩🏾‍⚕️",
                    "sheetX": 20,
                    "sheetY": 5
                },
                "1F3FF": {
                    "unified": "1F469-1F3FF-200D-2695-FE0F",
                    "native": "👩🏿‍⚕️",
                    "sheetX": 20,
                    "sheetY": 6
                }
            },
            "tts": "woman health worker",
            "keywords": [
                "doctor",
                "healthcare",
                "nurse",
                "therapist",
                "woman",
                "woman health worker"
            ]
        },
        {
            "unified": "1F468-200D-1F393",
            "name": null,
            "native": "👨‍🎓",
            "shortName": "male-student",
            "shortNames": [
                "male-student"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 16,
            "sheetY": 14,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F468-1F3FB-200D-1F393",
                    "native": "👨🏻‍🎓",
                    "sheetX": 16,
                    "sheetY": 15
                },
                "1F3FC": {
                    "unified": "1F468-1F3FC-200D-1F393",
                    "native": "👨🏼‍🎓",
                    "sheetX": 16,
                    "sheetY": 16
                },
                "1F3FD": {
                    "unified": "1F468-1F3FD-200D-1F393",
                    "native": "👨🏽‍🎓",
                    "sheetX": 16,
                    "sheetY": 17
                },
                "1F3FE": {
                    "unified": "1F468-1F3FE-200D-1F393",
                    "native": "👨🏾‍🎓",
                    "sheetX": 16,
                    "sheetY": 18
                },
                "1F3FF": {
                    "unified": "1F468-1F3FF-200D-1F393",
                    "native": "👨🏿‍🎓",
                    "sheetX": 16,
                    "sheetY": 19
                }
            }
        },
        {
            "unified": "1F469-200D-1F393",
            "name": null,
            "native": "👩‍🎓",
            "shortName": "female-student",
            "shortNames": [
                "female-student"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 18,
            "sheetY": 29,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F469-1F3FB-200D-1F393",
                    "native": "👩🏻‍🎓",
                    "sheetX": 18,
                    "sheetY": 30
                },
                "1F3FC": {
                    "unified": "1F469-1F3FC-200D-1F393",
                    "native": "👩🏼‍🎓",
                    "sheetX": 18,
                    "sheetY": 31
                },
                "1F3FD": {
                    "unified": "1F469-1F3FD-200D-1F393",
                    "native": "👩🏽‍🎓",
                    "sheetX": 18,
                    "sheetY": 32
                },
                "1F3FE": {
                    "unified": "1F469-1F3FE-200D-1F393",
                    "native": "👩🏾‍🎓",
                    "sheetX": 18,
                    "sheetY": 33
                },
                "1F3FF": {
                    "unified": "1F469-1F3FF-200D-1F393",
                    "native": "👩🏿‍🎓",
                    "sheetX": 18,
                    "sheetY": 34
                }
            }
        },
        {
            "unified": "1F468-200D-1F3EB",
            "name": null,
            "native": "👨‍🏫",
            "shortName": "male-teacher",
            "shortNames": [
                "male-teacher"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 16,
            "sheetY": 32,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F468-1F3FB-200D-1F3EB",
                    "native": "👨🏻‍🏫",
                    "sheetX": 16,
                    "sheetY": 33
                },
                "1F3FC": {
                    "unified": "1F468-1F3FC-200D-1F3EB",
                    "native": "👨🏼‍🏫",
                    "sheetX": 16,
                    "sheetY": 34
                },
                "1F3FD": {
                    "unified": "1F468-1F3FD-200D-1F3EB",
                    "native": "👨🏽‍🏫",
                    "sheetX": 16,
                    "sheetY": 35
                },
                "1F3FE": {
                    "unified": "1F468-1F3FE-200D-1F3EB",
                    "native": "👨🏾‍🏫",
                    "sheetX": 16,
                    "sheetY": 36
                },
                "1F3FF": {
                    "unified": "1F468-1F3FF-200D-1F3EB",
                    "native": "👨🏿‍🏫",
                    "sheetX": 16,
                    "sheetY": 37
                }
            }
        },
        {
            "unified": "1F469-200D-1F3EB",
            "name": null,
            "native": "👩‍🏫",
            "shortName": "female-teacher",
            "shortNames": [
                "female-teacher"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 18,
            "sheetY": 47,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F469-1F3FB-200D-1F3EB",
                    "native": "👩🏻‍🏫",
                    "sheetX": 18,
                    "sheetY": 48
                },
                "1F3FC": {
                    "unified": "1F469-1F3FC-200D-1F3EB",
                    "native": "👩🏼‍🏫",
                    "sheetX": 18,
                    "sheetY": 49
                },
                "1F3FD": {
                    "unified": "1F469-1F3FD-200D-1F3EB",
                    "native": "👩🏽‍🏫",
                    "sheetX": 18,
                    "sheetY": 50
                },
                "1F3FE": {
                    "unified": "1F469-1F3FE-200D-1F3EB",
                    "native": "👩🏾‍🏫",
                    "sheetX": 18,
                    "sheetY": 51
                },
                "1F3FF": {
                    "unified": "1F469-1F3FF-200D-1F3EB",
                    "native": "👩🏿‍🏫",
                    "sheetX": 19,
                    "sheetY": 0
                }
            }
        },
        {
            "unified": "1F468-200D-2696-FE0F",
            "name": null,
            "native": "👨‍⚖️",
            "shortName": "male-judge",
            "shortNames": [
                "male-judge"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 17,
            "sheetY": 49,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F468-1F3FB-200D-2696-FE0F",
                    "native": "👨🏻‍⚖️",
                    "sheetX": 17,
                    "sheetY": 50
                },
                "1F3FC": {
                    "unified": "1F468-1F3FC-200D-2696-FE0F",
                    "native": "👨🏼‍⚖️",
                    "sheetX": 17,
                    "sheetY": 51
                },
                "1F3FD": {
                    "unified": "1F468-1F3FD-200D-2696-FE0F",
                    "native": "👨🏽‍⚖️",
                    "sheetX": 18,
                    "sheetY": 0
                },
                "1F3FE": {
                    "unified": "1F468-1F3FE-200D-2696-FE0F",
                    "native": "👨🏾‍⚖️",
                    "sheetX": 18,
                    "sheetY": 1
                },
                "1F3FF": {
                    "unified": "1F468-1F3FF-200D-2696-FE0F",
                    "native": "👨🏿‍⚖️",
                    "sheetX": 18,
                    "sheetY": 2
                }
            },
            "tts": "man judge",
            "keywords": [
                "justice",
                "man",
                "man judge",
                "scales"
            ]
        },
        {
            "unified": "1F469-200D-2696-FE0F",
            "name": null,
            "native": "👩‍⚖️",
            "shortName": "female-judge",
            "shortNames": [
                "female-judge"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 20,
            "sheetY": 7,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F469-1F3FB-200D-2696-FE0F",
                    "native": "👩🏻‍⚖️",
                    "sheetX": 20,
                    "sheetY": 8
                },
                "1F3FC": {
                    "unified": "1F469-1F3FC-200D-2696-FE0F",
                    "native": "👩🏼‍⚖️",
                    "sheetX": 20,
                    "sheetY": 9
                },
                "1F3FD": {
                    "unified": "1F469-1F3FD-200D-2696-FE0F",
                    "native": "👩🏽‍⚖️",
                    "sheetX": 20,
                    "sheetY": 10
                },
                "1F3FE": {
                    "unified": "1F469-1F3FE-200D-2696-FE0F",
                    "native": "👩🏾‍⚖️",
                    "sheetX": 20,
                    "sheetY": 11
                },
                "1F3FF": {
                    "unified": "1F469-1F3FF-200D-2696-FE0F",
                    "native": "👩🏿‍⚖️",
                    "sheetX": 20,
                    "sheetY": 12
                }
            },
            "tts": "woman judge",
            "keywords": [
                "judge",
                "scales",
                "woman"
            ]
        },
        {
            "unified": "1F468-200D-1F33E",
            "name": null,
            "native": "👨‍🌾",
            "shortName": "male-farmer",
            "shortNames": [
                "male-farmer"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 16,
            "sheetY": 2,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F468-1F3FB-200D-1F33E",
                    "native": "👨🏻‍🌾",
                    "sheetX": 16,
                    "sheetY": 3
                },
                "1F3FC": {
                    "unified": "1F468-1F3FC-200D-1F33E",
                    "native": "👨🏼‍🌾",
                    "sheetX": 16,
                    "sheetY": 4
                },
                "1F3FD": {
                    "unified": "1F468-1F3FD-200D-1F33E",
                    "native": "👨🏽‍🌾",
                    "sheetX": 16,
                    "sheetY": 5
                },
                "1F3FE": {
                    "unified": "1F468-1F3FE-200D-1F33E",
                    "native": "👨🏾‍🌾",
                    "sheetX": 16,
                    "sheetY": 6
                },
                "1F3FF": {
                    "unified": "1F468-1F3FF-200D-1F33E",
                    "native": "👨🏿‍🌾",
                    "sheetX": 16,
                    "sheetY": 7
                }
            }
        },
        {
            "unified": "1F469-200D-1F33E",
            "name": null,
            "native": "👩‍🌾",
            "shortName": "female-farmer",
            "shortNames": [
                "female-farmer"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 18,
            "sheetY": 17,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F469-1F3FB-200D-1F33E",
                    "native": "👩🏻‍🌾",
                    "sheetX": 18,
                    "sheetY": 18
                },
                "1F3FC": {
                    "unified": "1F469-1F3FC-200D-1F33E",
                    "native": "👩🏼‍🌾",
                    "sheetX": 18,
                    "sheetY": 19
                },
                "1F3FD": {
                    "unified": "1F469-1F3FD-200D-1F33E",
                    "native": "👩🏽‍🌾",
                    "sheetX": 18,
                    "sheetY": 20
                },
                "1F3FE": {
                    "unified": "1F469-1F3FE-200D-1F33E",
                    "native": "👩🏾‍🌾",
                    "sheetX": 18,
                    "sheetY": 21
                },
                "1F3FF": {
                    "unified": "1F469-1F3FF-200D-1F33E",
                    "native": "👩🏿‍🌾",
                    "sheetX": 18,
                    "sheetY": 22
                }
            }
        },
        {
            "unified": "1F468-200D-1F373",
            "name": null,
            "native": "👨‍🍳",
            "shortName": "male-cook",
            "shortNames": [
                "male-cook"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 16,
            "sheetY": 8,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F468-1F3FB-200D-1F373",
                    "native": "👨🏻‍🍳",
                    "sheetX": 16,
                    "sheetY": 9
                },
                "1F3FC": {
                    "unified": "1F468-1F3FC-200D-1F373",
                    "native": "👨🏼‍🍳",
                    "sheetX": 16,
                    "sheetY": 10
                },
                "1F3FD": {
                    "unified": "1F468-1F3FD-200D-1F373",
                    "native": "👨🏽‍🍳",
                    "sheetX": 16,
                    "sheetY": 11
                },
                "1F3FE": {
                    "unified": "1F468-1F3FE-200D-1F373",
                    "native": "👨🏾‍🍳",
                    "sheetX": 16,
                    "sheetY": 12
                },
                "1F3FF": {
                    "unified": "1F468-1F3FF-200D-1F373",
                    "native": "👨🏿‍🍳",
                    "sheetX": 16,
                    "sheetY": 13
                }
            }
        },
        {
            "unified": "1F469-200D-1F373",
            "name": null,
            "native": "👩‍🍳",
            "shortName": "female-cook",
            "shortNames": [
                "female-cook"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 18,
            "sheetY": 23,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F469-1F3FB-200D-1F373",
                    "native": "👩🏻‍🍳",
                    "sheetX": 18,
                    "sheetY": 24
                },
                "1F3FC": {
                    "unified": "1F469-1F3FC-200D-1F373",
                    "native": "👩🏼‍🍳",
                    "sheetX": 18,
                    "sheetY": 25
                },
                "1F3FD": {
                    "unified": "1F469-1F3FD-200D-1F373",
                    "native": "👩🏽‍🍳",
                    "sheetX": 18,
                    "sheetY": 26
                },
                "1F3FE": {
                    "unified": "1F469-1F3FE-200D-1F373",
                    "native": "👩🏾‍🍳",
                    "sheetX": 18,
                    "sheetY": 27
                },
                "1F3FF": {
                    "unified": "1F469-1F3FF-200D-1F373",
                    "native": "👩🏿‍🍳",
                    "sheetX": 18,
                    "sheetY": 28
                }
            }
        },
        {
            "unified": "1F468-200D-1F527",
            "name": null,
            "native": "👨‍🔧",
            "shortName": "male-mechanic",
            "shortNames": [
                "male-mechanic"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 17,
            "sheetY": 19,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F468-1F3FB-200D-1F527",
                    "native": "👨🏻‍🔧",
                    "sheetX": 17,
                    "sheetY": 20
                },
                "1F3FC": {
                    "unified": "1F468-1F3FC-200D-1F527",
                    "native": "👨🏼‍🔧",
                    "sheetX": 17,
                    "sheetY": 21
                },
                "1F3FD": {
                    "unified": "1F468-1F3FD-200D-1F527",
                    "native": "👨🏽‍🔧",
                    "sheetX": 17,
                    "sheetY": 22
                },
                "1F3FE": {
                    "unified": "1F468-1F3FE-200D-1F527",
                    "native": "👨🏾‍🔧",
                    "sheetX": 17,
                    "sheetY": 23
                },
                "1F3FF": {
                    "unified": "1F468-1F3FF-200D-1F527",
                    "native": "👨🏿‍🔧",
                    "sheetX": 17,
                    "sheetY": 24
                }
            }
        },
        {
            "unified": "1F469-200D-1F527",
            "name": null,
            "native": "👩‍🔧",
            "shortName": "female-mechanic",
            "shortNames": [
                "female-mechanic"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 19,
            "sheetY": 29,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F469-1F3FB-200D-1F527",
                    "native": "👩🏻‍🔧",
                    "sheetX": 19,
                    "sheetY": 30
                },
                "1F3FC": {
                    "unified": "1F469-1F3FC-200D-1F527",
                    "native": "👩🏼‍🔧",
                    "sheetX": 19,
                    "sheetY": 31
                },
                "1F3FD": {
                    "unified": "1F469-1F3FD-200D-1F527",
                    "native": "👩🏽‍🔧",
                    "sheetX": 19,
                    "sheetY": 32
                },
                "1F3FE": {
                    "unified": "1F469-1F3FE-200D-1F527",
                    "native": "👩🏾‍🔧",
                    "sheetX": 19,
                    "sheetY": 33
                },
                "1F3FF": {
                    "unified": "1F469-1F3FF-200D-1F527",
                    "native": "👩🏿‍🔧",
                    "sheetX": 19,
                    "sheetY": 34
                }
            }
        },
        {
            "unified": "1F468-200D-1F3ED",
            "name": null,
            "native": "👨‍🏭",
            "shortName": "male-factory-worker",
            "shortNames": [
                "male-factory-worker"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 16,
            "sheetY": 38,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F468-1F3FB-200D-1F3ED",
                    "native": "👨🏻‍🏭",
                    "sheetX": 16,
                    "sheetY": 39
                },
                "1F3FC": {
                    "unified": "1F468-1F3FC-200D-1F3ED",
                    "native": "👨🏼‍🏭",
                    "sheetX": 16,
                    "sheetY": 40
                },
                "1F3FD": {
                    "unified": "1F468-1F3FD-200D-1F3ED",
                    "native": "👨🏽‍🏭",
                    "sheetX": 16,
                    "sheetY": 41
                },
                "1F3FE": {
                    "unified": "1F468-1F3FE-200D-1F3ED",
                    "native": "👨🏾‍🏭",
                    "sheetX": 16,
                    "sheetY": 42
                },
                "1F3FF": {
                    "unified": "1F468-1F3FF-200D-1F3ED",
                    "native": "👨🏿‍🏭",
                    "sheetX": 16,
                    "sheetY": 43
                }
            }
        },
        {
            "unified": "1F469-200D-1F3ED",
            "name": null,
            "native": "👩‍🏭",
            "shortName": "female-factory-worker",
            "shortNames": [
                "female-factory-worker"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 19,
            "sheetY": 1,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F469-1F3FB-200D-1F3ED",
                    "native": "👩🏻‍🏭",
                    "sheetX": 19,
                    "sheetY": 2
                },
                "1F3FC": {
                    "unified": "1F469-1F3FC-200D-1F3ED",
                    "native": "👩🏼‍🏭",
                    "sheetX": 19,
                    "sheetY": 3
                },
                "1F3FD": {
                    "unified": "1F469-1F3FD-200D-1F3ED",
                    "native": "👩🏽‍🏭",
                    "sheetX": 19,
                    "sheetY": 4
                },
                "1F3FE": {
                    "unified": "1F469-1F3FE-200D-1F3ED",
                    "native": "👩🏾‍🏭",
                    "sheetX": 19,
                    "sheetY": 5
                },
                "1F3FF": {
                    "unified": "1F469-1F3FF-200D-1F3ED",
                    "native": "👩🏿‍🏭",
                    "sheetX": 19,
                    "sheetY": 6
                }
            }
        },
        {
            "unified": "1F468-200D-1F4BC",
            "name": null,
            "native": "👨‍💼",
            "shortName": "male-office-worker",
            "shortNames": [
                "male-office-worker"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 17,
            "sheetY": 13,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F468-1F3FB-200D-1F4BC",
                    "native": "👨🏻‍💼",
                    "sheetX": 17,
                    "sheetY": 14
                },
                "1F3FC": {
                    "unified": "1F468-1F3FC-200D-1F4BC",
                    "native": "👨🏼‍💼",
                    "sheetX": 17,
                    "sheetY": 15
                },
                "1F3FD": {
                    "unified": "1F468-1F3FD-200D-1F4BC",
                    "native": "👨🏽‍💼",
                    "sheetX": 17,
                    "sheetY": 16
                },
                "1F3FE": {
                    "unified": "1F468-1F3FE-200D-1F4BC",
                    "native": "👨🏾‍💼",
                    "sheetX": 17,
                    "sheetY": 17
                },
                "1F3FF": {
                    "unified": "1F468-1F3FF-200D-1F4BC",
                    "native": "👨🏿‍💼",
                    "sheetX": 17,
                    "sheetY": 18
                }
            }
        },
        {
            "unified": "1F469-200D-1F4BC",
            "name": null,
            "native": "👩‍💼",
            "shortName": "female-office-worker",
            "shortNames": [
                "female-office-worker"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 19,
            "sheetY": 23,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F469-1F3FB-200D-1F4BC",
                    "native": "👩🏻‍💼",
                    "sheetX": 19,
                    "sheetY": 24
                },
                "1F3FC": {
                    "unified": "1F469-1F3FC-200D-1F4BC",
                    "native": "👩🏼‍💼",
                    "sheetX": 19,
                    "sheetY": 25
                },
                "1F3FD": {
                    "unified": "1F469-1F3FD-200D-1F4BC",
                    "native": "👩🏽‍💼",
                    "sheetX": 19,
                    "sheetY": 26
                },
                "1F3FE": {
                    "unified": "1F469-1F3FE-200D-1F4BC",
                    "native": "👩🏾‍💼",
                    "sheetX": 19,
                    "sheetY": 27
                },
                "1F3FF": {
                    "unified": "1F469-1F3FF-200D-1F4BC",
                    "native": "👩🏿‍💼",
                    "sheetX": 19,
                    "sheetY": 28
                }
            }
        },
        {
            "unified": "1F468-200D-1F52C",
            "name": null,
            "native": "👨‍🔬",
            "shortName": "male-scientist",
            "shortNames": [
                "male-scientist"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 17,
            "sheetY": 25,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F468-1F3FB-200D-1F52C",
                    "native": "👨🏻‍🔬",
                    "sheetX": 17,
                    "sheetY": 26
                },
                "1F3FC": {
                    "unified": "1F468-1F3FC-200D-1F52C",
                    "native": "👨🏼‍🔬",
                    "sheetX": 17,
                    "sheetY": 27
                },
                "1F3FD": {
                    "unified": "1F468-1F3FD-200D-1F52C",
                    "native": "👨🏽‍🔬",
                    "sheetX": 17,
                    "sheetY": 28
                },
                "1F3FE": {
                    "unified": "1F468-1F3FE-200D-1F52C",
                    "native": "👨🏾‍🔬",
                    "sheetX": 17,
                    "sheetY": 29
                },
                "1F3FF": {
                    "unified": "1F468-1F3FF-200D-1F52C",
                    "native": "👨🏿‍🔬",
                    "sheetX": 17,
                    "sheetY": 30
                }
            }
        },
        {
            "unified": "1F469-200D-1F52C",
            "name": null,
            "native": "👩‍🔬",
            "shortName": "female-scientist",
            "shortNames": [
                "female-scientist"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 19,
            "sheetY": 35,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F469-1F3FB-200D-1F52C",
                    "native": "👩🏻‍🔬",
                    "sheetX": 19,
                    "sheetY": 36
                },
                "1F3FC": {
                    "unified": "1F469-1F3FC-200D-1F52C",
                    "native": "👩🏼‍🔬",
                    "sheetX": 19,
                    "sheetY": 37
                },
                "1F3FD": {
                    "unified": "1F469-1F3FD-200D-1F52C",
                    "native": "👩🏽‍🔬",
                    "sheetX": 19,
                    "sheetY": 38
                },
                "1F3FE": {
                    "unified": "1F469-1F3FE-200D-1F52C",
                    "native": "👩🏾‍🔬",
                    "sheetX": 19,
                    "sheetY": 39
                },
                "1F3FF": {
                    "unified": "1F469-1F3FF-200D-1F52C",
                    "native": "👩🏿‍🔬",
                    "sheetX": 19,
                    "sheetY": 40
                }
            }
        },
        {
            "unified": "1F468-200D-1F4BB",
            "name": null,
            "native": "👨‍💻",
            "shortName": "male-technologist",
            "shortNames": [
                "male-technologist"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 17,
            "sheetY": 7,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F468-1F3FB-200D-1F4BB",
                    "native": "👨🏻‍💻",
                    "sheetX": 17,
                    "sheetY": 8
                },
                "1F3FC": {
                    "unified": "1F468-1F3FC-200D-1F4BB",
                    "native": "👨🏼‍💻",
                    "sheetX": 17,
                    "sheetY": 9
                },
                "1F3FD": {
                    "unified": "1F468-1F3FD-200D-1F4BB",
                    "native": "👨🏽‍💻",
                    "sheetX": 17,
                    "sheetY": 10
                },
                "1F3FE": {
                    "unified": "1F468-1F3FE-200D-1F4BB",
                    "native": "👨🏾‍💻",
                    "sheetX": 17,
                    "sheetY": 11
                },
                "1F3FF": {
                    "unified": "1F468-1F3FF-200D-1F4BB",
                    "native": "👨🏿‍💻",
                    "sheetX": 17,
                    "sheetY": 12
                }
            }
        },
        {
            "unified": "1F469-200D-1F4BB",
            "name": null,
            "native": "👩‍💻",
            "shortName": "female-technologist",
            "shortNames": [
                "female-technologist"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 19,
            "sheetY": 17,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F469-1F3FB-200D-1F4BB",
                    "native": "👩🏻‍💻",
                    "sheetX": 19,
                    "sheetY": 18
                },
                "1F3FC": {
                    "unified": "1F469-1F3FC-200D-1F4BB",
                    "native": "👩🏼‍💻",
                    "sheetX": 19,
                    "sheetY": 19
                },
                "1F3FD": {
                    "unified": "1F469-1F3FD-200D-1F4BB",
                    "native": "👩🏽‍💻",
                    "sheetX": 19,
                    "sheetY": 20
                },
                "1F3FE": {
                    "unified": "1F469-1F3FE-200D-1F4BB",
                    "native": "👩🏾‍💻",
                    "sheetX": 19,
                    "sheetY": 21
                },
                "1F3FF": {
                    "unified": "1F469-1F3FF-200D-1F4BB",
                    "native": "👩🏿‍💻",
                    "sheetX": 19,
                    "sheetY": 22
                }
            }
        },
        {
            "unified": "1F468-200D-1F3A4",
            "name": null,
            "native": "👨‍🎤",
            "shortName": "male-singer",
            "shortNames": [
                "male-singer"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 16,
            "sheetY": 20,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F468-1F3FB-200D-1F3A4",
                    "native": "👨🏻‍🎤",
                    "sheetX": 16,
                    "sheetY": 21
                },
                "1F3FC": {
                    "unified": "1F468-1F3FC-200D-1F3A4",
                    "native": "👨🏼‍🎤",
                    "sheetX": 16,
                    "sheetY": 22
                },
                "1F3FD": {
                    "unified": "1F468-1F3FD-200D-1F3A4",
                    "native": "👨🏽‍🎤",
                    "sheetX": 16,
                    "sheetY": 23
                },
                "1F3FE": {
                    "unified": "1F468-1F3FE-200D-1F3A4",
                    "native": "👨🏾‍🎤",
                    "sheetX": 16,
                    "sheetY": 24
                },
                "1F3FF": {
                    "unified": "1F468-1F3FF-200D-1F3A4",
                    "native": "👨🏿‍🎤",
                    "sheetX": 16,
                    "sheetY": 25
                }
            }
        },
        {
            "unified": "1F469-200D-1F3A4",
            "name": null,
            "native": "👩‍🎤",
            "shortName": "female-singer",
            "shortNames": [
                "female-singer"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 18,
            "sheetY": 35,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F469-1F3FB-200D-1F3A4",
                    "native": "👩🏻‍🎤",
                    "sheetX": 18,
                    "sheetY": 36
                },
                "1F3FC": {
                    "unified": "1F469-1F3FC-200D-1F3A4",
                    "native": "👩🏼‍🎤",
                    "sheetX": 18,
                    "sheetY": 37
                },
                "1F3FD": {
                    "unified": "1F469-1F3FD-200D-1F3A4",
                    "native": "👩🏽‍🎤",
                    "sheetX": 18,
                    "sheetY": 38
                },
                "1F3FE": {
                    "unified": "1F469-1F3FE-200D-1F3A4",
                    "native": "👩🏾‍🎤",
                    "sheetX": 18,
                    "sheetY": 39
                },
                "1F3FF": {
                    "unified": "1F469-1F3FF-200D-1F3A4",
                    "native": "👩🏿‍🎤",
                    "sheetX": 18,
                    "sheetY": 40
                }
            }
        },
        {
            "unified": "1F468-200D-1F3A8",
            "name": null,
            "native": "👨‍🎨",
            "shortName": "male-artist",
            "shortNames": [
                "male-artist"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 16,
            "sheetY": 26,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F468-1F3FB-200D-1F3A8",
                    "native": "👨🏻‍🎨",
                    "sheetX": 16,
                    "sheetY": 27
                },
                "1F3FC": {
                    "unified": "1F468-1F3FC-200D-1F3A8",
                    "native": "👨🏼‍🎨",
                    "sheetX": 16,
                    "sheetY": 28
                },
                "1F3FD": {
                    "unified": "1F468-1F3FD-200D-1F3A8",
                    "native": "👨🏽‍🎨",
                    "sheetX": 16,
                    "sheetY": 29
                },
                "1F3FE": {
                    "unified": "1F468-1F3FE-200D-1F3A8",
                    "native": "👨🏾‍🎨",
                    "sheetX": 16,
                    "sheetY": 30
                },
                "1F3FF": {
                    "unified": "1F468-1F3FF-200D-1F3A8",
                    "native": "👨🏿‍🎨",
                    "sheetX": 16,
                    "sheetY": 31
                }
            }
        },
        {
            "unified": "1F469-200D-1F3A8",
            "name": null,
            "native": "👩‍🎨",
            "shortName": "female-artist",
            "shortNames": [
                "female-artist"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 18,
            "sheetY": 41,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F469-1F3FB-200D-1F3A8",
                    "native": "👩🏻‍🎨",
                    "sheetX": 18,
                    "sheetY": 42
                },
                "1F3FC": {
                    "unified": "1F469-1F3FC-200D-1F3A8",
                    "native": "👩🏼‍🎨",
                    "sheetX": 18,
                    "sheetY": 43
                },
                "1F3FD": {
                    "unified": "1F469-1F3FD-200D-1F3A8",
                    "native": "👩🏽‍🎨",
                    "sheetX": 18,
                    "sheetY": 44
                },
                "1F3FE": {
                    "unified": "1F469-1F3FE-200D-1F3A8",
                    "native": "👩🏾‍🎨",
                    "sheetX": 18,
                    "sheetY": 45
                },
                "1F3FF": {
                    "unified": "1F469-1F3FF-200D-1F3A8",
                    "native": "👩🏿‍🎨",
                    "sheetX": 18,
                    "sheetY": 46
                }
            }
        },
        {
            "unified": "1F468-200D-2708-FE0F",
            "name": null,
            "native": "👨‍✈️",
            "shortName": "male-pilot",
            "shortNames": [
                "male-pilot"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 18,
            "sheetY": 3,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F468-1F3FB-200D-2708-FE0F",
                    "native": "👨🏻‍✈️",
                    "sheetX": 18,
                    "sheetY": 4
                },
                "1F3FC": {
                    "unified": "1F468-1F3FC-200D-2708-FE0F",
                    "native": "👨🏼‍✈️",
                    "sheetX": 18,
                    "sheetY": 5
                },
                "1F3FD": {
                    "unified": "1F468-1F3FD-200D-2708-FE0F",
                    "native": "👨🏽‍✈️",
                    "sheetX": 18,
                    "sheetY": 6
                },
                "1F3FE": {
                    "unified": "1F468-1F3FE-200D-2708-FE0F",
                    "native": "👨🏾‍✈️",
                    "sheetX": 18,
                    "sheetY": 7
                },
                "1F3FF": {
                    "unified": "1F468-1F3FF-200D-2708-FE0F",
                    "native": "👨🏿‍✈️",
                    "sheetX": 18,
                    "sheetY": 8
                }
            },
            "tts": "man pilot",
            "keywords": [
                "man",
                "pilot",
                "plane"
            ]
        },
        {
            "unified": "1F469-200D-2708-FE0F",
            "name": null,
            "native": "👩‍✈️",
            "shortName": "female-pilot",
            "shortNames": [
                "female-pilot"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 20,
            "sheetY": 13,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F469-1F3FB-200D-2708-FE0F",
                    "native": "👩🏻‍✈️",
                    "sheetX": 20,
                    "sheetY": 14
                },
                "1F3FC": {
                    "unified": "1F469-1F3FC-200D-2708-FE0F",
                    "native": "👩🏼‍✈️",
                    "sheetX": 20,
                    "sheetY": 15
                },
                "1F3FD": {
                    "unified": "1F469-1F3FD-200D-2708-FE0F",
                    "native": "👩🏽‍✈️",
                    "sheetX": 20,
                    "sheetY": 16
                },
                "1F3FE": {
                    "unified": "1F469-1F3FE-200D-2708-FE0F",
                    "native": "👩🏾‍✈️",
                    "sheetX": 20,
                    "sheetY": 17
                },
                "1F3FF": {
                    "unified": "1F469-1F3FF-200D-2708-FE0F",
                    "native": "👩🏿‍✈️",
                    "sheetX": 20,
                    "sheetY": 18
                }
            },
            "tts": "woman pilot",
            "keywords": [
                "pilot",
                "plane",
                "woman"
            ]
        },
        {
            "unified": "1F468-200D-1F680",
            "name": null,
            "native": "👨‍🚀",
            "shortName": "male-astronaut",
            "shortNames": [
                "male-astronaut"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 17,
            "sheetY": 31,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F468-1F3FB-200D-1F680",
                    "native": "👨🏻‍🚀",
                    "sheetX": 17,
                    "sheetY": 32
                },
                "1F3FC": {
                    "unified": "1F468-1F3FC-200D-1F680",
                    "native": "👨🏼‍🚀",
                    "sheetX": 17,
                    "sheetY": 33
                },
                "1F3FD": {
                    "unified": "1F468-1F3FD-200D-1F680",
                    "native": "👨🏽‍🚀",
                    "sheetX": 17,
                    "sheetY": 34
                },
                "1F3FE": {
                    "unified": "1F468-1F3FE-200D-1F680",
                    "native": "👨🏾‍🚀",
                    "sheetX": 17,
                    "sheetY": 35
                },
                "1F3FF": {
                    "unified": "1F468-1F3FF-200D-1F680",
                    "native": "👨🏿‍🚀",
                    "sheetX": 17,
                    "sheetY": 36
                }
            }
        },
        {
            "unified": "1F469-200D-1F680",
            "name": null,
            "native": "👩‍🚀",
            "shortName": "female-astronaut",
            "shortNames": [
                "female-astronaut"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 19,
            "sheetY": 41,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F469-1F3FB-200D-1F680",
                    "native": "👩🏻‍🚀",
                    "sheetX": 19,
                    "sheetY": 42
                },
                "1F3FC": {
                    "unified": "1F469-1F3FC-200D-1F680",
                    "native": "👩🏼‍🚀",
                    "sheetX": 19,
                    "sheetY": 43
                },
                "1F3FD": {
                    "unified": "1F469-1F3FD-200D-1F680",
                    "native": "👩🏽‍🚀",
                    "sheetX": 19,
                    "sheetY": 44
                },
                "1F3FE": {
                    "unified": "1F469-1F3FE-200D-1F680",
                    "native": "👩🏾‍🚀",
                    "sheetX": 19,
                    "sheetY": 45
                },
                "1F3FF": {
                    "unified": "1F469-1F3FF-200D-1F680",
                    "native": "👩🏿‍🚀",
                    "sheetX": 19,
                    "sheetY": 46
                }
            }
        },
        {
            "unified": "1F468-200D-1F692",
            "name": null,
            "native": "👨‍🚒",
            "shortName": "male-firefighter",
            "shortNames": [
                "male-firefighter"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 17,
            "sheetY": 37,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F468-1F3FB-200D-1F692",
                    "native": "👨🏻‍🚒",
                    "sheetX": 17,
                    "sheetY": 38
                },
                "1F3FC": {
                    "unified": "1F468-1F3FC-200D-1F692",
                    "native": "👨🏼‍🚒",
                    "sheetX": 17,
                    "sheetY": 39
                },
                "1F3FD": {
                    "unified": "1F468-1F3FD-200D-1F692",
                    "native": "👨🏽‍🚒",
                    "sheetX": 17,
                    "sheetY": 40
                },
                "1F3FE": {
                    "unified": "1F468-1F3FE-200D-1F692",
                    "native": "👨🏾‍🚒",
                    "sheetX": 17,
                    "sheetY": 41
                },
                "1F3FF": {
                    "unified": "1F468-1F3FF-200D-1F692",
                    "native": "👨🏿‍🚒",
                    "sheetX": 17,
                    "sheetY": 42
                }
            }
        },
        {
            "unified": "1F469-200D-1F692",
            "name": null,
            "native": "👩‍🚒",
            "shortName": "female-firefighter",
            "shortNames": [
                "female-firefighter"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 19,
            "sheetY": 47,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F469-1F3FB-200D-1F692",
                    "native": "👩🏻‍🚒",
                    "sheetX": 19,
                    "sheetY": 48
                },
                "1F3FC": {
                    "unified": "1F469-1F3FC-200D-1F692",
                    "native": "👩🏼‍🚒",
                    "sheetX": 19,
                    "sheetY": 49
                },
                "1F3FD": {
                    "unified": "1F469-1F3FD-200D-1F692",
                    "native": "👩🏽‍🚒",
                    "sheetX": 19,
                    "sheetY": 50
                },
                "1F3FE": {
                    "unified": "1F469-1F3FE-200D-1F692",
                    "native": "👩🏾‍🚒",
                    "sheetX": 19,
                    "sheetY": 51
                },
                "1F3FF": {
                    "unified": "1F469-1F3FF-200D-1F692",
                    "native": "👩🏿‍🚒",
                    "sheetX": 20,
                    "sheetY": 0
                }
            }
        },
        {
            "unified": "1F46E-200D-2642-FE0F",
            "name": null,
            "native": "👮‍♂️",
            "shortName": "male-police-officer",
            "shortNames": [
                "male-police-officer"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 20,
            "sheetY": 39,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F46E-1F3FB-200D-2642-FE0F",
                    "native": "👮🏻‍♂️",
                    "sheetX": 20,
                    "sheetY": 40
                },
                "1F3FC": {
                    "unified": "1F46E-1F3FC-200D-2642-FE0F",
                    "native": "👮🏼‍♂️",
                    "sheetX": 20,
                    "sheetY": 41
                },
                "1F3FD": {
                    "unified": "1F46E-1F3FD-200D-2642-FE0F",
                    "native": "👮🏽‍♂️",
                    "sheetX": 20,
                    "sheetY": 42
                },
                "1F3FE": {
                    "unified": "1F46E-1F3FE-200D-2642-FE0F",
                    "native": "👮🏾‍♂️",
                    "sheetX": 20,
                    "sheetY": 43
                },
                "1F3FF": {
                    "unified": "1F46E-1F3FF-200D-2642-FE0F",
                    "native": "👮🏿‍♂️",
                    "sheetX": 20,
                    "sheetY": 44
                }
            },
            "tts": "man police officer",
            "keywords": [
                "cop",
                "officer",
                "police",
                "man"
            ]
        },
        {
            "unified": "1F46E-200D-2640-FE0F",
            "name": null,
            "native": "👮‍♀️",
            "shortName": "female-police-officer",
            "shortNames": [
                "female-police-officer"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 20,
            "sheetY": 33,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F46E-1F3FB-200D-2640-FE0F",
                    "native": "👮🏻‍♀️",
                    "sheetX": 20,
                    "sheetY": 34
                },
                "1F3FC": {
                    "unified": "1F46E-1F3FC-200D-2640-FE0F",
                    "native": "👮🏼‍♀️",
                    "sheetX": 20,
                    "sheetY": 35
                },
                "1F3FD": {
                    "unified": "1F46E-1F3FD-200D-2640-FE0F",
                    "native": "👮🏽‍♀️",
                    "sheetX": 20,
                    "sheetY": 36
                },
                "1F3FE": {
                    "unified": "1F46E-1F3FE-200D-2640-FE0F",
                    "native": "👮🏾‍♀️",
                    "sheetX": 20,
                    "sheetY": 37
                },
                "1F3FF": {
                    "unified": "1F46E-1F3FF-200D-2640-FE0F",
                    "native": "👮🏿‍♀️",
                    "sheetX": 20,
                    "sheetY": 38
                }
            },
            "tts": "woman police officer",
            "keywords": [
                "cop",
                "officer",
                "police",
                "woman"
            ]
        },
        {
            "unified": "1F575-FE0F",
            "name": null,
            "native": "🕵️",
            "shortName": "sleuth_or_spy",
            "shortNames": [
                "sleuth_or_spy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 29,
            "sheetY": 11,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F575-1F3FB",
                    "native": "🕵🏻",
                    "sheetX": 29,
                    "sheetY": 12
                },
                "1F3FC": {
                    "unified": "1F575-1F3FC",
                    "native": "🕵🏼",
                    "sheetX": 29,
                    "sheetY": 13
                },
                "1F3FD": {
                    "unified": "1F575-1F3FD",
                    "native": "🕵🏽",
                    "sheetX": 29,
                    "sheetY": 14
                },
                "1F3FE": {
                    "unified": "1F575-1F3FE",
                    "native": "🕵🏾",
                    "sheetX": 29,
                    "sheetY": 15
                },
                "1F3FF": {
                    "unified": "1F575-1F3FF",
                    "native": "🕵🏿",
                    "sheetX": 29,
                    "sheetY": 16
                }
            }
        },
        {
            "unified": "1F575-FE0F-200D-2642-FE0F",
            "name": null,
            "native": "🕵️‍♂️",
            "shortName": "male-detective",
            "shortNames": [
                "male-detective"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 29,
            "sheetY": 5,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F575-1F3FB-200D-2642-FE0F",
                    "native": "🕵🏻‍♂️",
                    "sheetX": 29,
                    "sheetY": 6
                },
                "1F3FC": {
                    "unified": "1F575-1F3FC-200D-2642-FE0F",
                    "native": "🕵🏼‍♂️",
                    "sheetX": 29,
                    "sheetY": 7
                },
                "1F3FD": {
                    "unified": "1F575-1F3FD-200D-2642-FE0F",
                    "native": "🕵🏽‍♂️",
                    "sheetX": 29,
                    "sheetY": 8
                },
                "1F3FE": {
                    "unified": "1F575-1F3FE-200D-2642-FE0F",
                    "native": "🕵🏾‍♂️",
                    "sheetX": 29,
                    "sheetY": 9
                },
                "1F3FF": {
                    "unified": "1F575-1F3FF-200D-2642-FE0F",
                    "native": "🕵🏿‍♂️",
                    "sheetX": 29,
                    "sheetY": 10
                }
            }
        },
        {
            "unified": "1F575-FE0F-200D-2640-FE0F",
            "name": null,
            "native": "🕵️‍♀️",
            "shortName": "female-detective",
            "shortNames": [
                "female-detective"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 28,
            "sheetY": 51,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F575-1F3FB-200D-2640-FE0F",
                    "native": "🕵🏻‍♀️",
                    "sheetX": 29,
                    "sheetY": 0
                },
                "1F3FC": {
                    "unified": "1F575-1F3FC-200D-2640-FE0F",
                    "native": "🕵🏼‍♀️",
                    "sheetX": 29,
                    "sheetY": 1
                },
                "1F3FD": {
                    "unified": "1F575-1F3FD-200D-2640-FE0F",
                    "native": "🕵🏽‍♀️",
                    "sheetX": 29,
                    "sheetY": 2
                },
                "1F3FE": {
                    "unified": "1F575-1F3FE-200D-2640-FE0F",
                    "native": "🕵🏾‍♀️",
                    "sheetX": 29,
                    "sheetY": 3
                },
                "1F3FF": {
                    "unified": "1F575-1F3FF-200D-2640-FE0F",
                    "native": "🕵🏿‍♀️",
                    "sheetX": 29,
                    "sheetY": 4
                }
            }
        },
        {
            "unified": "1F482-200D-2642-FE0F",
            "name": null,
            "native": "💂‍♂️",
            "shortName": "male-guard",
            "shortNames": [
                "male-guard"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 23,
            "sheetY": 25,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F482-1F3FB-200D-2642-FE0F",
                    "native": "💂🏻‍♂️",
                    "sheetX": 23,
                    "sheetY": 26
                },
                "1F3FC": {
                    "unified": "1F482-1F3FC-200D-2642-FE0F",
                    "native": "💂🏼‍♂️",
                    "sheetX": 23,
                    "sheetY": 27
                },
                "1F3FD": {
                    "unified": "1F482-1F3FD-200D-2642-FE0F",
                    "native": "💂🏽‍♂️",
                    "sheetX": 23,
                    "sheetY": 28
                },
                "1F3FE": {
                    "unified": "1F482-1F3FE-200D-2642-FE0F",
                    "native": "💂🏾‍♂️",
                    "sheetX": 23,
                    "sheetY": 29
                },
                "1F3FF": {
                    "unified": "1F482-1F3FF-200D-2642-FE0F",
                    "native": "💂🏿‍♂️",
                    "sheetX": 23,
                    "sheetY": 30
                }
            },
            "tts": "man guard",
            "keywords": [
                "guard",
                "man"
            ]
        },
        {
            "unified": "1F482-200D-2640-FE0F",
            "name": null,
            "native": "💂‍♀️",
            "shortName": "female-guard",
            "shortNames": [
                "female-guard"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 23,
            "sheetY": 19,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F482-1F3FB-200D-2640-FE0F",
                    "native": "💂🏻‍♀️",
                    "sheetX": 23,
                    "sheetY": 20
                },
                "1F3FC": {
                    "unified": "1F482-1F3FC-200D-2640-FE0F",
                    "native": "💂🏼‍♀️",
                    "sheetX": 23,
                    "sheetY": 21
                },
                "1F3FD": {
                    "unified": "1F482-1F3FD-200D-2640-FE0F",
                    "native": "💂🏽‍♀️",
                    "sheetX": 23,
                    "sheetY": 22
                },
                "1F3FE": {
                    "unified": "1F482-1F3FE-200D-2640-FE0F",
                    "native": "💂🏾‍♀️",
                    "sheetX": 23,
                    "sheetY": 23
                },
                "1F3FF": {
                    "unified": "1F482-1F3FF-200D-2640-FE0F",
                    "native": "💂🏿‍♀️",
                    "sheetX": 23,
                    "sheetY": 24
                }
            },
            "tts": "woman guard",
            "keywords": [
                "guard",
                "woman"
            ]
        },
        {
            "unified": "1F477-200D-2642-FE0F",
            "name": null,
            "native": "👷‍♂️",
            "shortName": "male-construction-worker",
            "shortNames": [
                "male-construction-worker"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 22,
            "sheetY": 22,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F477-1F3FB-200D-2642-FE0F",
                    "native": "👷🏻‍♂️",
                    "sheetX": 22,
                    "sheetY": 23
                },
                "1F3FC": {
                    "unified": "1F477-1F3FC-200D-2642-FE0F",
                    "native": "👷🏼‍♂️",
                    "sheetX": 22,
                    "sheetY": 24
                },
                "1F3FD": {
                    "unified": "1F477-1F3FD-200D-2642-FE0F",
                    "native": "👷🏽‍♂️",
                    "sheetX": 22,
                    "sheetY": 25
                },
                "1F3FE": {
                    "unified": "1F477-1F3FE-200D-2642-FE0F",
                    "native": "👷🏾‍♂️",
                    "sheetX": 22,
                    "sheetY": 26
                },
                "1F3FF": {
                    "unified": "1F477-1F3FF-200D-2642-FE0F",
                    "native": "👷🏿‍♂️",
                    "sheetX": 22,
                    "sheetY": 27
                }
            },
            "tts": "man construction worker",
            "keywords": [
                "construction",
                "hat",
                "worker",
                "man"
            ]
        },
        {
            "unified": "1F477-200D-2640-FE0F",
            "name": null,
            "native": "👷‍♀️",
            "shortName": "female-construction-worker",
            "shortNames": [
                "female-construction-worker"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 22,
            "sheetY": 16,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F477-1F3FB-200D-2640-FE0F",
                    "native": "👷🏻‍♀️",
                    "sheetX": 22,
                    "sheetY": 17
                },
                "1F3FC": {
                    "unified": "1F477-1F3FC-200D-2640-FE0F",
                    "native": "👷🏼‍♀️",
                    "sheetX": 22,
                    "sheetY": 18
                },
                "1F3FD": {
                    "unified": "1F477-1F3FD-200D-2640-FE0F",
                    "native": "👷🏽‍♀️",
                    "sheetX": 22,
                    "sheetY": 19
                },
                "1F3FE": {
                    "unified": "1F477-1F3FE-200D-2640-FE0F",
                    "native": "👷🏾‍♀️",
                    "sheetX": 22,
                    "sheetY": 20
                },
                "1F3FF": {
                    "unified": "1F477-1F3FF-200D-2640-FE0F",
                    "native": "👷🏿‍♀️",
                    "sheetX": 22,
                    "sheetY": 21
                }
            },
            "tts": "woman construction worker",
            "keywords": [
                "construction",
                "hat",
                "worker",
                "woman"
            ]
        },
        {
            "unified": "1F934",
            "name": "PRINCE",
            "native": "🤴",
            "shortName": "prince",
            "shortNames": [
                "prince"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 39,
            "sheetY": 28,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F934-1F3FB",
                    "native": "🤴🏻",
                    "sheetX": 39,
                    "sheetY": 29
                },
                "1F3FC": {
                    "unified": "1F934-1F3FC",
                    "native": "🤴🏼",
                    "sheetX": 39,
                    "sheetY": 30
                },
                "1F3FD": {
                    "unified": "1F934-1F3FD",
                    "native": "🤴🏽",
                    "sheetX": 39,
                    "sheetY": 31
                },
                "1F3FE": {
                    "unified": "1F934-1F3FE",
                    "native": "🤴🏾",
                    "sheetX": 39,
                    "sheetY": 32
                },
                "1F3FF": {
                    "unified": "1F934-1F3FF",
                    "native": "🤴🏿",
                    "sheetX": 39,
                    "sheetY": 33
                }
            },
            "tts": "prince",
            "keywords": [
                "prince"
            ]
        },
        {
            "unified": "1F478",
            "name": "PRINCESS",
            "native": "👸",
            "shortName": "princess",
            "shortNames": [
                "princess"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 22,
            "sheetY": 34,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F478-1F3FB",
                    "native": "👸🏻",
                    "sheetX": 22,
                    "sheetY": 35
                },
                "1F3FC": {
                    "unified": "1F478-1F3FC",
                    "native": "👸🏼",
                    "sheetX": 22,
                    "sheetY": 36
                },
                "1F3FD": {
                    "unified": "1F478-1F3FD",
                    "native": "👸🏽",
                    "sheetX": 22,
                    "sheetY": 37
                },
                "1F3FE": {
                    "unified": "1F478-1F3FE",
                    "native": "👸🏾",
                    "sheetX": 22,
                    "sheetY": 38
                },
                "1F3FF": {
                    "unified": "1F478-1F3FF",
                    "native": "👸🏿",
                    "sheetX": 22,
                    "sheetY": 39
                }
            },
            "tts": "princess",
            "keywords": [
                "fairy tale",
                "fantasy",
                "princess"
            ]
        },
        {
            "unified": "1F473-200D-2642-FE0F",
            "name": null,
            "native": "👳‍♂️",
            "shortName": "man-wearing-turban",
            "shortNames": [
                "man-wearing-turban"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 21,
            "sheetY": 38,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F473-1F3FB-200D-2642-FE0F",
                    "native": "👳🏻‍♂️",
                    "sheetX": 21,
                    "sheetY": 39
                },
                "1F3FC": {
                    "unified": "1F473-1F3FC-200D-2642-FE0F",
                    "native": "👳🏼‍♂️",
                    "sheetX": 21,
                    "sheetY": 40
                },
                "1F3FD": {
                    "unified": "1F473-1F3FD-200D-2642-FE0F",
                    "native": "👳🏽‍♂️",
                    "sheetX": 21,
                    "sheetY": 41
                },
                "1F3FE": {
                    "unified": "1F473-1F3FE-200D-2642-FE0F",
                    "native": "👳🏾‍♂️",
                    "sheetX": 21,
                    "sheetY": 42
                },
                "1F3FF": {
                    "unified": "1F473-1F3FF-200D-2642-FE0F",
                    "native": "👳🏿‍♂️",
                    "sheetX": 21,
                    "sheetY": 43
                }
            },
            "tts": "man wearing turban",
            "keywords": [
                "person wearing turban",
                "turban",
                "man",
                "man wearing turban"
            ]
        },
        {
            "unified": "1F473-200D-2640-FE0F",
            "name": null,
            "native": "👳‍♀️",
            "shortName": "woman-wearing-turban",
            "shortNames": [
                "woman-wearing-turban"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 21,
            "sheetY": 32,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F473-1F3FB-200D-2640-FE0F",
                    "native": "👳🏻‍♀️",
                    "sheetX": 21,
                    "sheetY": 33
                },
                "1F3FC": {
                    "unified": "1F473-1F3FC-200D-2640-FE0F",
                    "native": "👳🏼‍♀️",
                    "sheetX": 21,
                    "sheetY": 34
                },
                "1F3FD": {
                    "unified": "1F473-1F3FD-200D-2640-FE0F",
                    "native": "👳🏽‍♀️",
                    "sheetX": 21,
                    "sheetY": 35
                },
                "1F3FE": {
                    "unified": "1F473-1F3FE-200D-2640-FE0F",
                    "native": "👳🏾‍♀️",
                    "sheetX": 21,
                    "sheetY": 36
                },
                "1F3FF": {
                    "unified": "1F473-1F3FF-200D-2640-FE0F",
                    "native": "👳🏿‍♀️",
                    "sheetX": 21,
                    "sheetY": 37
                }
            },
            "tts": "woman wearing turban",
            "keywords": [
                "person wearing turban",
                "turban",
                "woman",
                "woman wearing turban"
            ]
        },
        {
            "unified": "1F472",
            "name": "MAN WITH GUA PI MAO",
            "native": "👲",
            "shortName": "man_with_gua_pi_mao",
            "shortNames": [
                "man_with_gua_pi_mao"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 21,
            "sheetY": 26,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F472-1F3FB",
                    "native": "👲🏻",
                    "sheetX": 21,
                    "sheetY": 27
                },
                "1F3FC": {
                    "unified": "1F472-1F3FC",
                    "native": "👲🏼",
                    "sheetX": 21,
                    "sheetY": 28
                },
                "1F3FD": {
                    "unified": "1F472-1F3FD",
                    "native": "👲🏽",
                    "sheetX": 21,
                    "sheetY": 29
                },
                "1F3FE": {
                    "unified": "1F472-1F3FE",
                    "native": "👲🏾",
                    "sheetX": 21,
                    "sheetY": 30
                },
                "1F3FF": {
                    "unified": "1F472-1F3FF",
                    "native": "👲🏿",
                    "sheetX": 21,
                    "sheetY": 31
                }
            },
            "tts": "man with Chinese cap",
            "keywords": [
                "gua pi mao",
                "hat",
                "man",
                "man with Chinese cap"
            ]
        },
        {
            "unified": "1F9D5",
            "name": "PERSON WITH HEADSCARF",
            "native": "🧕",
            "shortName": "person_with_headscarf",
            "shortNames": [
                "person_with_headscarf"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 43,
            "sheetY": 22,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F9D5-1F3FB",
                    "native": "🧕🏻",
                    "sheetX": 43,
                    "sheetY": 23
                },
                "1F3FC": {
                    "unified": "1F9D5-1F3FC",
                    "native": "🧕🏼",
                    "sheetX": 43,
                    "sheetY": 24
                },
                "1F3FD": {
                    "unified": "1F9D5-1F3FD",
                    "native": "🧕🏽",
                    "sheetX": 43,
                    "sheetY": 25
                },
                "1F3FE": {
                    "unified": "1F9D5-1F3FE",
                    "native": "🧕🏾",
                    "sheetX": 43,
                    "sheetY": 26
                },
                "1F3FF": {
                    "unified": "1F9D5-1F3FF",
                    "native": "🧕🏿",
                    "sheetX": 43,
                    "sheetY": 27
                }
            },
            "tts": "woman with headscarf",
            "keywords": [
                "headscarf",
                "hijab",
                "mantilla",
                "tichel",
                "woman with headscarf"
            ]
        },
        {
            "unified": "1F935",
            "name": "MAN IN TUXEDO",
            "native": "🤵",
            "shortName": "man_in_tuxedo",
            "shortNames": [
                "man_in_tuxedo"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 39,
            "sheetY": 34,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F935-1F3FB",
                    "native": "🤵🏻",
                    "sheetX": 39,
                    "sheetY": 35
                },
                "1F3FC": {
                    "unified": "1F935-1F3FC",
                    "native": "🤵🏼",
                    "sheetX": 39,
                    "sheetY": 36
                },
                "1F3FD": {
                    "unified": "1F935-1F3FD",
                    "native": "🤵🏽",
                    "sheetX": 39,
                    "sheetY": 37
                },
                "1F3FE": {
                    "unified": "1F935-1F3FE",
                    "native": "🤵🏾",
                    "sheetX": 39,
                    "sheetY": 38
                },
                "1F3FF": {
                    "unified": "1F935-1F3FF",
                    "native": "🤵🏿",
                    "sheetX": 39,
                    "sheetY": 39
                }
            },
            "tts": "man in tuxedo",
            "keywords": [
                "groom",
                "man",
                "man in tuxedo",
                "tuxedo"
            ]
        },
        {
            "unified": "1F470",
            "name": "BRIDE WITH VEIL",
            "native": "👰",
            "shortName": "bride_with_veil",
            "shortNames": [
                "bride_with_veil"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 21,
            "sheetY": 2,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F470-1F3FB",
                    "native": "👰🏻",
                    "sheetX": 21,
                    "sheetY": 3
                },
                "1F3FC": {
                    "unified": "1F470-1F3FC",
                    "native": "👰🏼",
                    "sheetX": 21,
                    "sheetY": 4
                },
                "1F3FD": {
                    "unified": "1F470-1F3FD",
                    "native": "👰🏽",
                    "sheetX": 21,
                    "sheetY": 5
                },
                "1F3FE": {
                    "unified": "1F470-1F3FE",
                    "native": "👰🏾",
                    "sheetX": 21,
                    "sheetY": 6
                },
                "1F3FF": {
                    "unified": "1F470-1F3FF",
                    "native": "👰🏿",
                    "sheetX": 21,
                    "sheetY": 7
                }
            },
            "tts": "bride with veil",
            "keywords": [
                "bride",
                "bride with veil",
                "veil",
                "wedding"
            ]
        },
        {
            "unified": "1F930",
            "name": "PREGNANT WOMAN",
            "native": "🤰",
            "shortName": "pregnant_woman",
            "shortNames": [
                "pregnant_woman"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 39,
            "sheetY": 4,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F930-1F3FB",
                    "native": "🤰🏻",
                    "sheetX": 39,
                    "sheetY": 5
                },
                "1F3FC": {
                    "unified": "1F930-1F3FC",
                    "native": "🤰🏼",
                    "sheetX": 39,
                    "sheetY": 6
                },
                "1F3FD": {
                    "unified": "1F930-1F3FD",
                    "native": "🤰🏽",
                    "sheetX": 39,
                    "sheetY": 7
                },
                "1F3FE": {
                    "unified": "1F930-1F3FE",
                    "native": "🤰🏾",
                    "sheetX": 39,
                    "sheetY": 8
                },
                "1F3FF": {
                    "unified": "1F930-1F3FF",
                    "native": "🤰🏿",
                    "sheetX": 39,
                    "sheetY": 9
                }
            },
            "tts": "pregnant woman",
            "keywords": [
                "pregnant",
                "woman"
            ]
        },
        {
            "unified": "1F931",
            "name": "BREAST-FEEDING",
            "native": "🤱",
            "shortName": "breast-feeding",
            "shortNames": [
                "breast-feeding"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 39,
            "sheetY": 10,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F931-1F3FB",
                    "native": "🤱🏻",
                    "sheetX": 39,
                    "sheetY": 11
                },
                "1F3FC": {
                    "unified": "1F931-1F3FC",
                    "native": "🤱🏼",
                    "sheetX": 39,
                    "sheetY": 12
                },
                "1F3FD": {
                    "unified": "1F931-1F3FD",
                    "native": "🤱🏽",
                    "sheetX": 39,
                    "sheetY": 13
                },
                "1F3FE": {
                    "unified": "1F931-1F3FE",
                    "native": "🤱🏾",
                    "sheetX": 39,
                    "sheetY": 14
                },
                "1F3FF": {
                    "unified": "1F931-1F3FF",
                    "native": "🤱🏿",
                    "sheetX": 39,
                    "sheetY": 15
                }
            },
            "tts": "breast-feeding",
            "keywords": [
                "baby",
                "breast",
                "breast-feeding",
                "nursing"
            ]
        },
        {
            "unified": "1F47C",
            "name": "BABY ANGEL",
            "native": "👼",
            "shortName": "angel",
            "shortNames": [
                "angel"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 22,
            "sheetY": 43,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F47C-1F3FB",
                    "native": "👼🏻",
                    "sheetX": 22,
                    "sheetY": 44
                },
                "1F3FC": {
                    "unified": "1F47C-1F3FC",
                    "native": "👼🏼",
                    "sheetX": 22,
                    "sheetY": 45
                },
                "1F3FD": {
                    "unified": "1F47C-1F3FD",
                    "native": "👼🏽",
                    "sheetX": 22,
                    "sheetY": 46
                },
                "1F3FE": {
                    "unified": "1F47C-1F3FE",
                    "native": "👼🏾",
                    "sheetX": 22,
                    "sheetY": 47
                },
                "1F3FF": {
                    "unified": "1F47C-1F3FF",
                    "native": "👼🏿",
                    "sheetX": 22,
                    "sheetY": 48
                }
            },
            "tts": "baby angel",
            "keywords": [
                "angel",
                "baby",
                "face",
                "fairy tale",
                "fantasy"
            ]
        },
        {
            "unified": "1F385",
            "name": "FATHER CHRISTMAS",
            "native": "🎅",
            "shortName": "santa",
            "shortNames": [
                "santa"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 8,
            "sheetY": 19,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F385-1F3FB",
                    "native": "🎅🏻",
                    "sheetX": 8,
                    "sheetY": 20
                },
                "1F3FC": {
                    "unified": "1F385-1F3FC",
                    "native": "🎅🏼",
                    "sheetX": 8,
                    "sheetY": 21
                },
                "1F3FD": {
                    "unified": "1F385-1F3FD",
                    "native": "🎅🏽",
                    "sheetX": 8,
                    "sheetY": 22
                },
                "1F3FE": {
                    "unified": "1F385-1F3FE",
                    "native": "🎅🏾",
                    "sheetX": 8,
                    "sheetY": 23
                },
                "1F3FF": {
                    "unified": "1F385-1F3FF",
                    "native": "🎅🏿",
                    "sheetX": 8,
                    "sheetY": 24
                }
            },
            "tts": "Santa Claus",
            "keywords": [
                "celebration",
                "Christmas",
                "claus",
                "father",
                "santa",
                "Santa Claus"
            ]
        },
        {
            "unified": "1F936",
            "name": "MOTHER CHRISTMAS",
            "native": "🤶",
            "shortName": "mrs_claus",
            "shortNames": [
                "mrs_claus",
                "mother_christmas"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 39,
            "sheetY": 40,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F936-1F3FB",
                    "native": "🤶🏻",
                    "sheetX": 39,
                    "sheetY": 41
                },
                "1F3FC": {
                    "unified": "1F936-1F3FC",
                    "native": "🤶🏼",
                    "sheetX": 39,
                    "sheetY": 42
                },
                "1F3FD": {
                    "unified": "1F936-1F3FD",
                    "native": "🤶🏽",
                    "sheetX": 39,
                    "sheetY": 43
                },
                "1F3FE": {
                    "unified": "1F936-1F3FE",
                    "native": "🤶🏾",
                    "sheetX": 39,
                    "sheetY": 44
                },
                "1F3FF": {
                    "unified": "1F936-1F3FF",
                    "native": "🤶🏿",
                    "sheetX": 39,
                    "sheetY": 45
                }
            },
            "tts": "Mrs. Claus",
            "keywords": [
                "celebration",
                "Christmas",
                "claus",
                "mother",
                "Mrs.",
                "Mrs. Claus"
            ]
        },
        {
            "unified": "1F9D9-200D-2640-FE0F",
            "name": null,
            "native": "🧙‍♀️",
            "shortName": "female_mage",
            "shortNames": [
                "female_mage"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 44,
            "sheetY": 30,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F9D9-1F3FB-200D-2640-FE0F",
                    "native": "🧙🏻‍♀️",
                    "sheetX": 44,
                    "sheetY": 31
                },
                "1F3FC": {
                    "unified": "1F9D9-1F3FC-200D-2640-FE0F",
                    "native": "🧙🏼‍♀️",
                    "sheetX": 44,
                    "sheetY": 32
                },
                "1F3FD": {
                    "unified": "1F9D9-1F3FD-200D-2640-FE0F",
                    "native": "🧙🏽‍♀️",
                    "sheetX": 44,
                    "sheetY": 33
                },
                "1F3FE": {
                    "unified": "1F9D9-1F3FE-200D-2640-FE0F",
                    "native": "🧙🏾‍♀️",
                    "sheetX": 44,
                    "sheetY": 34
                },
                "1F3FF": {
                    "unified": "1F9D9-1F3FF-200D-2640-FE0F",
                    "native": "🧙🏿‍♀️",
                    "sheetX": 44,
                    "sheetY": 35
                }
            },
            "tts": "woman mage",
            "keywords": [
                "mage",
                "sorcerer",
                "sorceress",
                "witch",
                "wizard",
                "woman mage"
            ]
        },
        {
            "unified": "1F9D9-200D-2642-FE0F",
            "name": null,
            "native": "🧙‍♂️",
            "shortName": "male_mage",
            "shortNames": [
                "male_mage"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 44,
            "sheetY": 36,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F9D9-1F3FB-200D-2642-FE0F",
                    "native": "🧙🏻‍♂️",
                    "sheetX": 44,
                    "sheetY": 37
                },
                "1F3FC": {
                    "unified": "1F9D9-1F3FC-200D-2642-FE0F",
                    "native": "🧙🏼‍♂️",
                    "sheetX": 44,
                    "sheetY": 38
                },
                "1F3FD": {
                    "unified": "1F9D9-1F3FD-200D-2642-FE0F",
                    "native": "🧙🏽‍♂️",
                    "sheetX": 44,
                    "sheetY": 39
                },
                "1F3FE": {
                    "unified": "1F9D9-1F3FE-200D-2642-FE0F",
                    "native": "🧙🏾‍♂️",
                    "sheetX": 44,
                    "sheetY": 40
                },
                "1F3FF": {
                    "unified": "1F9D9-1F3FF-200D-2642-FE0F",
                    "native": "🧙🏿‍♂️",
                    "sheetX": 44,
                    "sheetY": 41
                }
            },
            "tts": "man mage",
            "keywords": [
                "mage",
                "sorcerer",
                "sorceress",
                "witch",
                "wizard",
                "man mage"
            ]
        },
        {
            "unified": "1F9DA-200D-2640-FE0F",
            "name": null,
            "native": "🧚‍♀️",
            "shortName": "female_fairy",
            "shortNames": [
                "female_fairy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 44,
            "sheetY": 48,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F9DA-1F3FB-200D-2640-FE0F",
                    "native": "🧚🏻‍♀️",
                    "sheetX": 44,
                    "sheetY": 49
                },
                "1F3FC": {
                    "unified": "1F9DA-1F3FC-200D-2640-FE0F",
                    "native": "🧚🏼‍♀️",
                    "sheetX": 44,
                    "sheetY": 50
                },
                "1F3FD": {
                    "unified": "1F9DA-1F3FD-200D-2640-FE0F",
                    "native": "🧚🏽‍♀️",
                    "sheetX": 44,
                    "sheetY": 51
                },
                "1F3FE": {
                    "unified": "1F9DA-1F3FE-200D-2640-FE0F",
                    "native": "🧚🏾‍♀️",
                    "sheetX": 45,
                    "sheetY": 0
                },
                "1F3FF": {
                    "unified": "1F9DA-1F3FF-200D-2640-FE0F",
                    "native": "🧚🏿‍♀️",
                    "sheetX": 45,
                    "sheetY": 1
                }
            },
            "tts": "woman fairy",
            "keywords": [
                "fairy",
                "Oberon",
                "Puck",
                "Titania",
                "woman fairy"
            ]
        },
        {
            "unified": "1F9DA-200D-2642-FE0F",
            "name": null,
            "native": "🧚‍♂️",
            "shortName": "male_fairy",
            "shortNames": [
                "male_fairy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 45,
            "sheetY": 2,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F9DA-1F3FB-200D-2642-FE0F",
                    "native": "🧚🏻‍♂️",
                    "sheetX": 45,
                    "sheetY": 3
                },
                "1F3FC": {
                    "unified": "1F9DA-1F3FC-200D-2642-FE0F",
                    "native": "🧚🏼‍♂️",
                    "sheetX": 45,
                    "sheetY": 4
                },
                "1F3FD": {
                    "unified": "1F9DA-1F3FD-200D-2642-FE0F",
                    "native": "🧚🏽‍♂️",
                    "sheetX": 45,
                    "sheetY": 5
                },
                "1F3FE": {
                    "unified": "1F9DA-1F3FE-200D-2642-FE0F",
                    "native": "🧚🏾‍♂️",
                    "sheetX": 45,
                    "sheetY": 6
                },
                "1F3FF": {
                    "unified": "1F9DA-1F3FF-200D-2642-FE0F",
                    "native": "🧚🏿‍♂️",
                    "sheetX": 45,
                    "sheetY": 7
                }
            },
            "tts": "man fairy",
            "keywords": [
                "fairy",
                "Oberon",
                "Puck",
                "Titania",
                "man fairy"
            ]
        },
        {
            "unified": "1F9DB-200D-2640-FE0F",
            "name": null,
            "native": "🧛‍♀️",
            "shortName": "female_vampire",
            "shortNames": [
                "female_vampire"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 45,
            "sheetY": 14,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F9DB-1F3FB-200D-2640-FE0F",
                    "native": "🧛🏻‍♀️",
                    "sheetX": 45,
                    "sheetY": 15
                },
                "1F3FC": {
                    "unified": "1F9DB-1F3FC-200D-2640-FE0F",
                    "native": "🧛🏼‍♀️",
                    "sheetX": 45,
                    "sheetY": 16
                },
                "1F3FD": {
                    "unified": "1F9DB-1F3FD-200D-2640-FE0F",
                    "native": "🧛🏽‍♀️",
                    "sheetX": 45,
                    "sheetY": 17
                },
                "1F3FE": {
                    "unified": "1F9DB-1F3FE-200D-2640-FE0F",
                    "native": "🧛🏾‍♀️",
                    "sheetX": 45,
                    "sheetY": 18
                },
                "1F3FF": {
                    "unified": "1F9DB-1F3FF-200D-2640-FE0F",
                    "native": "🧛🏿‍♀️",
                    "sheetX": 45,
                    "sheetY": 19
                }
            },
            "tts": "woman vampire",
            "keywords": [
                "Dracula",
                "undead",
                "vampire",
                "woman vampire"
            ]
        },
        {
            "unified": "1F9DB-200D-2642-FE0F",
            "name": null,
            "native": "🧛‍♂️",
            "shortName": "male_vampire",
            "shortNames": [
                "male_vampire"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 45,
            "sheetY": 20,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F9DB-1F3FB-200D-2642-FE0F",
                    "native": "🧛🏻‍♂️",
                    "sheetX": 45,
                    "sheetY": 21
                },
                "1F3FC": {
                    "unified": "1F9DB-1F3FC-200D-2642-FE0F",
                    "native": "🧛🏼‍♂️",
                    "sheetX": 45,
                    "sheetY": 22
                },
                "1F3FD": {
                    "unified": "1F9DB-1F3FD-200D-2642-FE0F",
                    "native": "🧛🏽‍♂️",
                    "sheetX": 45,
                    "sheetY": 23
                },
                "1F3FE": {
                    "unified": "1F9DB-1F3FE-200D-2642-FE0F",
                    "native": "🧛🏾‍♂️",
                    "sheetX": 45,
                    "sheetY": 24
                },
                "1F3FF": {
                    "unified": "1F9DB-1F3FF-200D-2642-FE0F",
                    "native": "🧛🏿‍♂️",
                    "sheetX": 45,
                    "sheetY": 25
                }
            },
            "tts": "man vampire",
            "keywords": [
                "Dracula",
                "undead",
                "vampire",
                "man vampire"
            ]
        },
        {
            "unified": "1F9DC-200D-2640-FE0F",
            "name": null,
            "native": "🧜‍♀️",
            "shortName": "mermaid",
            "shortNames": [
                "mermaid"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 45,
            "sheetY": 32,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F9DC-1F3FB-200D-2640-FE0F",
                    "native": "🧜🏻‍♀️",
                    "sheetX": 45,
                    "sheetY": 33
                },
                "1F3FC": {
                    "unified": "1F9DC-1F3FC-200D-2640-FE0F",
                    "native": "🧜🏼‍♀️",
                    "sheetX": 45,
                    "sheetY": 34
                },
                "1F3FD": {
                    "unified": "1F9DC-1F3FD-200D-2640-FE0F",
                    "native": "🧜🏽‍♀️",
                    "sheetX": 45,
                    "sheetY": 35
                },
                "1F3FE": {
                    "unified": "1F9DC-1F3FE-200D-2640-FE0F",
                    "native": "🧜🏾‍♀️",
                    "sheetX": 45,
                    "sheetY": 36
                },
                "1F3FF": {
                    "unified": "1F9DC-1F3FF-200D-2640-FE0F",
                    "native": "🧜🏿‍♀️",
                    "sheetX": 45,
                    "sheetY": 37
                }
            },
            "tts": "mermaid",
            "keywords": [
                "mermaid",
                "merman",
                "merperson",
                "merwoman"
            ]
        },
        {
            "unified": "1F9DC-200D-2642-FE0F",
            "name": null,
            "native": "🧜‍♂️",
            "shortName": "merman",
            "shortNames": [
                "merman"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 45,
            "sheetY": 38,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F9DC-1F3FB-200D-2642-FE0F",
                    "native": "🧜🏻‍♂️",
                    "sheetX": 45,
                    "sheetY": 39
                },
                "1F3FC": {
                    "unified": "1F9DC-1F3FC-200D-2642-FE0F",
                    "native": "🧜🏼‍♂️",
                    "sheetX": 45,
                    "sheetY": 40
                },
                "1F3FD": {
                    "unified": "1F9DC-1F3FD-200D-2642-FE0F",
                    "native": "🧜🏽‍♂️",
                    "sheetX": 45,
                    "sheetY": 41
                },
                "1F3FE": {
                    "unified": "1F9DC-1F3FE-200D-2642-FE0F",
                    "native": "🧜🏾‍♂️",
                    "sheetX": 45,
                    "sheetY": 42
                },
                "1F3FF": {
                    "unified": "1F9DC-1F3FF-200D-2642-FE0F",
                    "native": "🧜🏿‍♂️",
                    "sheetX": 45,
                    "sheetY": 43
                }
            },
            "tts": "merman",
            "keywords": [
                "mermaid",
                "merman",
                "merperson",
                "merwoman",
                "Triton"
            ]
        },
        {
            "unified": "1F9DD-200D-2640-FE0F",
            "name": null,
            "native": "🧝‍♀️",
            "shortName": "female_elf",
            "shortNames": [
                "female_elf"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 45,
            "sheetY": 50,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F9DD-1F3FB-200D-2640-FE0F",
                    "native": "🧝🏻‍♀️",
                    "sheetX": 45,
                    "sheetY": 51
                },
                "1F3FC": {
                    "unified": "1F9DD-1F3FC-200D-2640-FE0F",
                    "native": "🧝🏼‍♀️",
                    "sheetX": 46,
                    "sheetY": 0
                },
                "1F3FD": {
                    "unified": "1F9DD-1F3FD-200D-2640-FE0F",
                    "native": "🧝🏽‍♀️",
                    "sheetX": 46,
                    "sheetY": 1
                },
                "1F3FE": {
                    "unified": "1F9DD-1F3FE-200D-2640-FE0F",
                    "native": "🧝🏾‍♀️",
                    "sheetX": 46,
                    "sheetY": 2
                },
                "1F3FF": {
                    "unified": "1F9DD-1F3FF-200D-2640-FE0F",
                    "native": "🧝🏿‍♀️",
                    "sheetX": 46,
                    "sheetY": 3
                }
            },
            "tts": "woman elf",
            "keywords": [
                "elf",
                "magical",
                "woman elf"
            ]
        },
        {
            "unified": "1F9DD-200D-2642-FE0F",
            "name": null,
            "native": "🧝‍♂️",
            "shortName": "male_elf",
            "shortNames": [
                "male_elf"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 46,
            "sheetY": 4,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F9DD-1F3FB-200D-2642-FE0F",
                    "native": "🧝🏻‍♂️",
                    "sheetX": 46,
                    "sheetY": 5
                },
                "1F3FC": {
                    "unified": "1F9DD-1F3FC-200D-2642-FE0F",
                    "native": "🧝🏼‍♂️",
                    "sheetX": 46,
                    "sheetY": 6
                },
                "1F3FD": {
                    "unified": "1F9DD-1F3FD-200D-2642-FE0F",
                    "native": "🧝🏽‍♂️",
                    "sheetX": 46,
                    "sheetY": 7
                },
                "1F3FE": {
                    "unified": "1F9DD-1F3FE-200D-2642-FE0F",
                    "native": "🧝🏾‍♂️",
                    "sheetX": 46,
                    "sheetY": 8
                },
                "1F3FF": {
                    "unified": "1F9DD-1F3FF-200D-2642-FE0F",
                    "native": "🧝🏿‍♂️",
                    "sheetX": 46,
                    "sheetY": 9
                }
            },
            "tts": "man elf",
            "keywords": [
                "elf",
                "magical",
                "man elf"
            ]
        },
        {
            "unified": "1F9DE-200D-2640-FE0F",
            "name": null,
            "native": "🧞‍♀️",
            "shortName": "female_genie",
            "shortNames": [
                "female_genie"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 46,
            "sheetY": 16,
            "tts": "woman genie",
            "keywords": [
                "djinn",
                "genie",
                "woman genie"
            ]
        },
        {
            "unified": "1F9DE-200D-2642-FE0F",
            "name": null,
            "native": "🧞‍♂️",
            "shortName": "male_genie",
            "shortNames": [
                "male_genie"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 46,
            "sheetY": 17,
            "tts": "man genie",
            "keywords": [
                "djinn",
                "genie",
                "man genie"
            ]
        },
        {
            "unified": "1F9DF-200D-2640-FE0F",
            "name": null,
            "native": "🧟‍♀️",
            "shortName": "female_zombie",
            "shortNames": [
                "female_zombie"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 46,
            "sheetY": 19,
            "tts": "woman zombie",
            "keywords": [
                "undead",
                "walking dead",
                "zombie",
                "woman zombie"
            ]
        },
        {
            "unified": "1F9DF-200D-2642-FE0F",
            "name": null,
            "native": "🧟‍♂️",
            "shortName": "male_zombie",
            "shortNames": [
                "male_zombie"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 46,
            "sheetY": 20,
            "tts": "man zombie",
            "keywords": [
                "undead",
                "walking dead",
                "zombie",
                "man zombie"
            ]
        },
        {
            "unified": "1F64D-200D-2642-FE0F",
            "name": null,
            "native": "🙍‍♂️",
            "shortName": "man-frowning",
            "shortNames": [
                "man-frowning"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 33,
            "sheetY": 24,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F64D-1F3FB-200D-2642-FE0F",
                    "native": "🙍🏻‍♂️",
                    "sheetX": 33,
                    "sheetY": 25
                },
                "1F3FC": {
                    "unified": "1F64D-1F3FC-200D-2642-FE0F",
                    "native": "🙍🏼‍♂️",
                    "sheetX": 33,
                    "sheetY": 26
                },
                "1F3FD": {
                    "unified": "1F64D-1F3FD-200D-2642-FE0F",
                    "native": "🙍🏽‍♂️",
                    "sheetX": 33,
                    "sheetY": 27
                },
                "1F3FE": {
                    "unified": "1F64D-1F3FE-200D-2642-FE0F",
                    "native": "🙍🏾‍♂️",
                    "sheetX": 33,
                    "sheetY": 28
                },
                "1F3FF": {
                    "unified": "1F64D-1F3FF-200D-2642-FE0F",
                    "native": "🙍🏿‍♂️",
                    "sheetX": 33,
                    "sheetY": 29
                }
            },
            "tts": "man frowning",
            "keywords": [
                "frown",
                "gesture",
                "person frowning",
                "frowning",
                "man"
            ]
        },
        {
            "unified": "1F64D-200D-2640-FE0F",
            "name": null,
            "native": "🙍‍♀️",
            "shortName": "woman-frowning",
            "shortNames": [
                "woman-frowning"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 33,
            "sheetY": 18,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F64D-1F3FB-200D-2640-FE0F",
                    "native": "🙍🏻‍♀️",
                    "sheetX": 33,
                    "sheetY": 19
                },
                "1F3FC": {
                    "unified": "1F64D-1F3FC-200D-2640-FE0F",
                    "native": "🙍🏼‍♀️",
                    "sheetX": 33,
                    "sheetY": 20
                },
                "1F3FD": {
                    "unified": "1F64D-1F3FD-200D-2640-FE0F",
                    "native": "🙍🏽‍♀️",
                    "sheetX": 33,
                    "sheetY": 21
                },
                "1F3FE": {
                    "unified": "1F64D-1F3FE-200D-2640-FE0F",
                    "native": "🙍🏾‍♀️",
                    "sheetX": 33,
                    "sheetY": 22
                },
                "1F3FF": {
                    "unified": "1F64D-1F3FF-200D-2640-FE0F",
                    "native": "🙍🏿‍♀️",
                    "sheetX": 33,
                    "sheetY": 23
                }
            },
            "tts": "woman frowning",
            "keywords": [
                "frown",
                "gesture",
                "person frowning",
                "frowning",
                "woman"
            ]
        },
        {
            "unified": "1F64E-200D-2642-FE0F",
            "name": null,
            "native": "🙎‍♂️",
            "shortName": "man-pouting",
            "shortNames": [
                "man-pouting"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 33,
            "sheetY": 42,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F64E-1F3FB-200D-2642-FE0F",
                    "native": "🙎🏻‍♂️",
                    "sheetX": 33,
                    "sheetY": 43
                },
                "1F3FC": {
                    "unified": "1F64E-1F3FC-200D-2642-FE0F",
                    "native": "🙎🏼‍♂️",
                    "sheetX": 33,
                    "sheetY": 44
                },
                "1F3FD": {
                    "unified": "1F64E-1F3FD-200D-2642-FE0F",
                    "native": "🙎🏽‍♂️",
                    "sheetX": 33,
                    "sheetY": 45
                },
                "1F3FE": {
                    "unified": "1F64E-1F3FE-200D-2642-FE0F",
                    "native": "🙎🏾‍♂️",
                    "sheetX": 33,
                    "sheetY": 46
                },
                "1F3FF": {
                    "unified": "1F64E-1F3FF-200D-2642-FE0F",
                    "native": "🙎🏿‍♂️",
                    "sheetX": 33,
                    "sheetY": 47
                }
            },
            "tts": "man pouting",
            "keywords": [
                "gesture",
                "person pouting",
                "pouting",
                "man"
            ]
        },
        {
            "unified": "1F64E-200D-2640-FE0F",
            "name": null,
            "native": "🙎‍♀️",
            "shortName": "woman-pouting",
            "shortNames": [
                "woman-pouting"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 33,
            "sheetY": 36,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F64E-1F3FB-200D-2640-FE0F",
                    "native": "🙎🏻‍♀️",
                    "sheetX": 33,
                    "sheetY": 37
                },
                "1F3FC": {
                    "unified": "1F64E-1F3FC-200D-2640-FE0F",
                    "native": "🙎🏼‍♀️",
                    "sheetX": 33,
                    "sheetY": 38
                },
                "1F3FD": {
                    "unified": "1F64E-1F3FD-200D-2640-FE0F",
                    "native": "🙎🏽‍♀️",
                    "sheetX": 33,
                    "sheetY": 39
                },
                "1F3FE": {
                    "unified": "1F64E-1F3FE-200D-2640-FE0F",
                    "native": "🙎🏾‍♀️",
                    "sheetX": 33,
                    "sheetY": 40
                },
                "1F3FF": {
                    "unified": "1F64E-1F3FF-200D-2640-FE0F",
                    "native": "🙎🏿‍♀️",
                    "sheetX": 33,
                    "sheetY": 41
                }
            },
            "tts": "woman pouting",
            "keywords": [
                "gesture",
                "person pouting",
                "pouting",
                "woman"
            ]
        },
        {
            "unified": "1F645-200D-2642-FE0F",
            "name": null,
            "native": "🙅‍♂️",
            "shortName": "man-gesturing-no",
            "shortNames": [
                "man-gesturing-no"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 47,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F645-1F3FB-200D-2642-FE0F",
                    "native": "🙅🏻‍♂️",
                    "sheetX": 31,
                    "sheetY": 48
                },
                "1F3FC": {
                    "unified": "1F645-1F3FC-200D-2642-FE0F",
                    "native": "🙅🏼‍♂️",
                    "sheetX": 31,
                    "sheetY": 49
                },
                "1F3FD": {
                    "unified": "1F645-1F3FD-200D-2642-FE0F",
                    "native": "🙅🏽‍♂️",
                    "sheetX": 31,
                    "sheetY": 50
                },
                "1F3FE": {
                    "unified": "1F645-1F3FE-200D-2642-FE0F",
                    "native": "🙅🏾‍♂️",
                    "sheetX": 31,
                    "sheetY": 51
                },
                "1F3FF": {
                    "unified": "1F645-1F3FF-200D-2642-FE0F",
                    "native": "🙅🏿‍♂️",
                    "sheetX": 32,
                    "sheetY": 0
                }
            },
            "tts": "man gesturing NO",
            "keywords": [
                "forbidden",
                "gesture",
                "hand",
                "no",
                "not",
                "person gesturing NO",
                "prohibited",
                "man",
                "man gesturing NO"
            ]
        },
        {
            "unified": "1F645-200D-2640-FE0F",
            "name": null,
            "native": "🙅‍♀️",
            "shortName": "woman-gesturing-no",
            "shortNames": [
                "woman-gesturing-no"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 31,
            "sheetY": 41,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F645-1F3FB-200D-2640-FE0F",
                    "native": "🙅🏻‍♀️",
                    "sheetX": 31,
                    "sheetY": 42
                },
                "1F3FC": {
                    "unified": "1F645-1F3FC-200D-2640-FE0F",
                    "native": "🙅🏼‍♀️",
                    "sheetX": 31,
                    "sheetY": 43
                },
                "1F3FD": {
                    "unified": "1F645-1F3FD-200D-2640-FE0F",
                    "native": "🙅🏽‍♀️",
                    "sheetX": 31,
                    "sheetY": 44
                },
                "1F3FE": {
                    "unified": "1F645-1F3FE-200D-2640-FE0F",
                    "native": "🙅🏾‍♀️",
                    "sheetX": 31,
                    "sheetY": 45
                },
                "1F3FF": {
                    "unified": "1F645-1F3FF-200D-2640-FE0F",
                    "native": "🙅🏿‍♀️",
                    "sheetX": 31,
                    "sheetY": 46
                }
            },
            "tts": "woman gesturing NO",
            "keywords": [
                "forbidden",
                "gesture",
                "hand",
                "no",
                "not",
                "person gesturing NO",
                "prohibited",
                "woman",
                "woman gesturing NO"
            ]
        },
        {
            "unified": "1F646-200D-2642-FE0F",
            "name": null,
            "native": "🙆‍♂️",
            "shortName": "man-gesturing-ok",
            "shortNames": [
                "man-gesturing-ok"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 32,
            "sheetY": 13,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F646-1F3FB-200D-2642-FE0F",
                    "native": "🙆🏻‍♂️",
                    "sheetX": 32,
                    "sheetY": 14
                },
                "1F3FC": {
                    "unified": "1F646-1F3FC-200D-2642-FE0F",
                    "native": "🙆🏼‍♂️",
                    "sheetX": 32,
                    "sheetY": 15
                },
                "1F3FD": {
                    "unified": "1F646-1F3FD-200D-2642-FE0F",
                    "native": "🙆🏽‍♂️",
                    "sheetX": 32,
                    "sheetY": 16
                },
                "1F3FE": {
                    "unified": "1F646-1F3FE-200D-2642-FE0F",
                    "native": "🙆🏾‍♂️",
                    "sheetX": 32,
                    "sheetY": 17
                },
                "1F3FF": {
                    "unified": "1F646-1F3FF-200D-2642-FE0F",
                    "native": "🙆🏿‍♂️",
                    "sheetX": 32,
                    "sheetY": 18
                }
            },
            "tts": "man gesturing OK",
            "keywords": [
                "gesture",
                "hand",
                "OK",
                "person gesturing OK",
                "man",
                "man gesturing OK"
            ]
        },
        {
            "unified": "1F646-200D-2640-FE0F",
            "name": null,
            "native": "🙆‍♀️",
            "shortName": "woman-gesturing-ok",
            "shortNames": [
                "woman-gesturing-ok"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 32,
            "sheetY": 7,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F646-1F3FB-200D-2640-FE0F",
                    "native": "🙆🏻‍♀️",
                    "sheetX": 32,
                    "sheetY": 8
                },
                "1F3FC": {
                    "unified": "1F646-1F3FC-200D-2640-FE0F",
                    "native": "🙆🏼‍♀️",
                    "sheetX": 32,
                    "sheetY": 9
                },
                "1F3FD": {
                    "unified": "1F646-1F3FD-200D-2640-FE0F",
                    "native": "🙆🏽‍♀️",
                    "sheetX": 32,
                    "sheetY": 10
                },
                "1F3FE": {
                    "unified": "1F646-1F3FE-200D-2640-FE0F",
                    "native": "🙆🏾‍♀️",
                    "sheetX": 32,
                    "sheetY": 11
                },
                "1F3FF": {
                    "unified": "1F646-1F3FF-200D-2640-FE0F",
                    "native": "🙆🏿‍♀️",
                    "sheetX": 32,
                    "sheetY": 12
                }
            },
            "tts": "woman gesturing OK",
            "keywords": [
                "gesture",
                "hand",
                "OK",
                "person gesturing OK",
                "woman",
                "woman gesturing OK"
            ]
        },
        {
            "unified": "1F481-200D-2642-FE0F",
            "name": null,
            "native": "💁‍♂️",
            "shortName": "man-tipping-hand",
            "shortNames": [
                "man-tipping-hand"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 23,
            "sheetY": 7,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F481-1F3FB-200D-2642-FE0F",
                    "native": "💁🏻‍♂️",
                    "sheetX": 23,
                    "sheetY": 8
                },
                "1F3FC": {
                    "unified": "1F481-1F3FC-200D-2642-FE0F",
                    "native": "💁🏼‍♂️",
                    "sheetX": 23,
                    "sheetY": 9
                },
                "1F3FD": {
                    "unified": "1F481-1F3FD-200D-2642-FE0F",
                    "native": "💁🏽‍♂️",
                    "sheetX": 23,
                    "sheetY": 10
                },
                "1F3FE": {
                    "unified": "1F481-1F3FE-200D-2642-FE0F",
                    "native": "💁🏾‍♂️",
                    "sheetX": 23,
                    "sheetY": 11
                },
                "1F3FF": {
                    "unified": "1F481-1F3FF-200D-2642-FE0F",
                    "native": "💁🏿‍♂️",
                    "sheetX": 23,
                    "sheetY": 12
                }
            },
            "tts": "man tipping hand",
            "keywords": [
                "hand",
                "help",
                "information",
                "person tipping hand",
                "sassy",
                "tipping",
                "man",
                "man tipping hand",
                "tipping hand"
            ]
        },
        {
            "unified": "1F481-200D-2640-FE0F",
            "name": null,
            "native": "💁‍♀️",
            "shortName": "woman-tipping-hand",
            "shortNames": [
                "woman-tipping-hand"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 23,
            "sheetY": 1,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F481-1F3FB-200D-2640-FE0F",
                    "native": "💁🏻‍♀️",
                    "sheetX": 23,
                    "sheetY": 2
                },
                "1F3FC": {
                    "unified": "1F481-1F3FC-200D-2640-FE0F",
                    "native": "💁🏼‍♀️",
                    "sheetX": 23,
                    "sheetY": 3
                },
                "1F3FD": {
                    "unified": "1F481-1F3FD-200D-2640-FE0F",
                    "native": "💁🏽‍♀️",
                    "sheetX": 23,
                    "sheetY": 4
                },
                "1F3FE": {
                    "unified": "1F481-1F3FE-200D-2640-FE0F",
                    "native": "💁🏾‍♀️",
                    "sheetX": 23,
                    "sheetY": 5
                },
                "1F3FF": {
                    "unified": "1F481-1F3FF-200D-2640-FE0F",
                    "native": "💁🏿‍♀️",
                    "sheetX": 23,
                    "sheetY": 6
                }
            },
            "tts": "woman tipping hand",
            "keywords": [
                "hand",
                "help",
                "information",
                "person tipping hand",
                "sassy",
                "tipping",
                "tipping hand",
                "woman",
                "woman tipping hand"
            ]
        },
        {
            "unified": "1F64B-200D-2642-FE0F",
            "name": null,
            "native": "🙋‍♂️",
            "shortName": "man-raising-hand",
            "shortNames": [
                "man-raising-hand"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 33,
            "sheetY": 0,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F64B-1F3FB-200D-2642-FE0F",
                    "native": "🙋🏻‍♂️",
                    "sheetX": 33,
                    "sheetY": 1
                },
                "1F3FC": {
                    "unified": "1F64B-1F3FC-200D-2642-FE0F",
                    "native": "🙋🏼‍♂️",
                    "sheetX": 33,
                    "sheetY": 2
                },
                "1F3FD": {
                    "unified": "1F64B-1F3FD-200D-2642-FE0F",
                    "native": "🙋🏽‍♂️",
                    "sheetX": 33,
                    "sheetY": 3
                },
                "1F3FE": {
                    "unified": "1F64B-1F3FE-200D-2642-FE0F",
                    "native": "🙋🏾‍♂️",
                    "sheetX": 33,
                    "sheetY": 4
                },
                "1F3FF": {
                    "unified": "1F64B-1F3FF-200D-2642-FE0F",
                    "native": "🙋🏿‍♂️",
                    "sheetX": 33,
                    "sheetY": 5
                }
            },
            "tts": "man raising hand",
            "keywords": [
                "gesture",
                "hand",
                "happy",
                "person raising hand",
                "raised",
                "man",
                "man raising hand",
                "raising hand"
            ]
        },
        {
            "unified": "1F64B-200D-2640-FE0F",
            "name": null,
            "native": "🙋‍♀️",
            "shortName": "woman-raising-hand",
            "shortNames": [
                "woman-raising-hand"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 32,
            "sheetY": 46,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F64B-1F3FB-200D-2640-FE0F",
                    "native": "🙋🏻‍♀️",
                    "sheetX": 32,
                    "sheetY": 47
                },
                "1F3FC": {
                    "unified": "1F64B-1F3FC-200D-2640-FE0F",
                    "native": "🙋🏼‍♀️",
                    "sheetX": 32,
                    "sheetY": 48
                },
                "1F3FD": {
                    "unified": "1F64B-1F3FD-200D-2640-FE0F",
                    "native": "🙋🏽‍♀️",
                    "sheetX": 32,
                    "sheetY": 49
                },
                "1F3FE": {
                    "unified": "1F64B-1F3FE-200D-2640-FE0F",
                    "native": "🙋🏾‍♀️",
                    "sheetX": 32,
                    "sheetY": 50
                },
                "1F3FF": {
                    "unified": "1F64B-1F3FF-200D-2640-FE0F",
                    "native": "🙋🏿‍♀️",
                    "sheetX": 32,
                    "sheetY": 51
                }
            },
            "tts": "woman raising hand",
            "keywords": [
                "gesture",
                "hand",
                "happy",
                "person raising hand",
                "raised",
                "raising hand",
                "woman",
                "woman raising hand"
            ]
        },
        {
            "unified": "1F647-200D-2642-FE0F",
            "name": null,
            "native": "🙇‍♂️",
            "shortName": "man-bowing",
            "shortNames": [
                "man-bowing"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 32,
            "sheetY": 31,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F647-1F3FB-200D-2642-FE0F",
                    "native": "🙇🏻‍♂️",
                    "sheetX": 32,
                    "sheetY": 32
                },
                "1F3FC": {
                    "unified": "1F647-1F3FC-200D-2642-FE0F",
                    "native": "🙇🏼‍♂️",
                    "sheetX": 32,
                    "sheetY": 33
                },
                "1F3FD": {
                    "unified": "1F647-1F3FD-200D-2642-FE0F",
                    "native": "🙇🏽‍♂️",
                    "sheetX": 32,
                    "sheetY": 34
                },
                "1F3FE": {
                    "unified": "1F647-1F3FE-200D-2642-FE0F",
                    "native": "🙇🏾‍♂️",
                    "sheetX": 32,
                    "sheetY": 35
                },
                "1F3FF": {
                    "unified": "1F647-1F3FF-200D-2642-FE0F",
                    "native": "🙇🏿‍♂️",
                    "sheetX": 32,
                    "sheetY": 36
                }
            },
            "tts": "man bowing",
            "keywords": [
                "apology",
                "bow",
                "gesture",
                "person bowing",
                "sorry",
                "bowing",
                "favor",
                "man"
            ]
        },
        {
            "unified": "1F647-200D-2640-FE0F",
            "name": null,
            "native": "🙇‍♀️",
            "shortName": "woman-bowing",
            "shortNames": [
                "woman-bowing"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 32,
            "sheetY": 25,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F647-1F3FB-200D-2640-FE0F",
                    "native": "🙇🏻‍♀️",
                    "sheetX": 32,
                    "sheetY": 26
                },
                "1F3FC": {
                    "unified": "1F647-1F3FC-200D-2640-FE0F",
                    "native": "🙇🏼‍♀️",
                    "sheetX": 32,
                    "sheetY": 27
                },
                "1F3FD": {
                    "unified": "1F647-1F3FD-200D-2640-FE0F",
                    "native": "🙇🏽‍♀️",
                    "sheetX": 32,
                    "sheetY": 28
                },
                "1F3FE": {
                    "unified": "1F647-1F3FE-200D-2640-FE0F",
                    "native": "🙇🏾‍♀️",
                    "sheetX": 32,
                    "sheetY": 29
                },
                "1F3FF": {
                    "unified": "1F647-1F3FF-200D-2640-FE0F",
                    "native": "🙇🏿‍♀️",
                    "sheetX": 32,
                    "sheetY": 30
                }
            },
            "tts": "woman bowing",
            "keywords": [
                "apology",
                "bow",
                "gesture",
                "person bowing",
                "sorry",
                "bowing",
                "favor",
                "woman"
            ]
        },
        {
            "unified": "1F926-200D-2642-FE0F",
            "name": null,
            "native": "🤦‍♂️",
            "shortName": "man-facepalming",
            "shortNames": [
                "man-facepalming"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 38,
            "sheetY": 35,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F926-1F3FB-200D-2642-FE0F",
                    "native": "🤦🏻‍♂️",
                    "sheetX": 38,
                    "sheetY": 36
                },
                "1F3FC": {
                    "unified": "1F926-1F3FC-200D-2642-FE0F",
                    "native": "🤦🏼‍♂️",
                    "sheetX": 38,
                    "sheetY": 37
                },
                "1F3FD": {
                    "unified": "1F926-1F3FD-200D-2642-FE0F",
                    "native": "🤦🏽‍♂️",
                    "sheetX": 38,
                    "sheetY": 38
                },
                "1F3FE": {
                    "unified": "1F926-1F3FE-200D-2642-FE0F",
                    "native": "🤦🏾‍♂️",
                    "sheetX": 38,
                    "sheetY": 39
                },
                "1F3FF": {
                    "unified": "1F926-1F3FF-200D-2642-FE0F",
                    "native": "🤦🏿‍♂️",
                    "sheetX": 38,
                    "sheetY": 40
                }
            },
            "tts": "man facepalming",
            "keywords": [
                "disbelief",
                "exasperation",
                "face",
                "palm",
                "person facepalming",
                "facepalm",
                "man",
                "man facepalming"
            ]
        },
        {
            "unified": "1F926-200D-2640-FE0F",
            "name": null,
            "native": "🤦‍♀️",
            "shortName": "woman-facepalming",
            "shortNames": [
                "woman-facepalming"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 38,
            "sheetY": 29,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F926-1F3FB-200D-2640-FE0F",
                    "native": "🤦🏻‍♀️",
                    "sheetX": 38,
                    "sheetY": 30
                },
                "1F3FC": {
                    "unified": "1F926-1F3FC-200D-2640-FE0F",
                    "native": "🤦🏼‍♀️",
                    "sheetX": 38,
                    "sheetY": 31
                },
                "1F3FD": {
                    "unified": "1F926-1F3FD-200D-2640-FE0F",
                    "native": "🤦🏽‍♀️",
                    "sheetX": 38,
                    "sheetY": 32
                },
                "1F3FE": {
                    "unified": "1F926-1F3FE-200D-2640-FE0F",
                    "native": "🤦🏾‍♀️",
                    "sheetX": 38,
                    "sheetY": 33
                },
                "1F3FF": {
                    "unified": "1F926-1F3FF-200D-2640-FE0F",
                    "native": "🤦🏿‍♀️",
                    "sheetX": 38,
                    "sheetY": 34
                }
            },
            "tts": "woman facepalming",
            "keywords": [
                "disbelief",
                "exasperation",
                "face",
                "palm",
                "person facepalming",
                "facepalm",
                "woman",
                "woman facepalming"
            ]
        },
        {
            "unified": "1F937-200D-2642-FE0F",
            "name": null,
            "native": "🤷‍♂️",
            "shortName": "man-shrugging",
            "shortNames": [
                "man-shrugging"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 40,
            "sheetY": 0,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F937-1F3FB-200D-2642-FE0F",
                    "native": "🤷🏻‍♂️",
                    "sheetX": 40,
                    "sheetY": 1
                },
                "1F3FC": {
                    "unified": "1F937-1F3FC-200D-2642-FE0F",
                    "native": "🤷🏼‍♂️",
                    "sheetX": 40,
                    "sheetY": 2
                },
                "1F3FD": {
                    "unified": "1F937-1F3FD-200D-2642-FE0F",
                    "native": "🤷🏽‍♂️",
                    "sheetX": 40,
                    "sheetY": 3
                },
                "1F3FE": {
                    "unified": "1F937-1F3FE-200D-2642-FE0F",
                    "native": "🤷🏾‍♂️",
                    "sheetX": 40,
                    "sheetY": 4
                },
                "1F3FF": {
                    "unified": "1F937-1F3FF-200D-2642-FE0F",
                    "native": "🤷🏿‍♂️",
                    "sheetX": 40,
                    "sheetY": 5
                }
            },
            "tts": "man shrugging",
            "keywords": [
                "doubt",
                "ignorance",
                "indifference",
                "person shrugging",
                "shrug",
                "man",
                "man shrugging"
            ]
        },
        {
            "unified": "1F937-200D-2640-FE0F",
            "name": null,
            "native": "🤷‍♀️",
            "shortName": "woman-shrugging",
            "shortNames": [
                "woman-shrugging"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 39,
            "sheetY": 46,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F937-1F3FB-200D-2640-FE0F",
                    "native": "🤷🏻‍♀️",
                    "sheetX": 39,
                    "sheetY": 47
                },
                "1F3FC": {
                    "unified": "1F937-1F3FC-200D-2640-FE0F",
                    "native": "🤷🏼‍♀️",
                    "sheetX": 39,
                    "sheetY": 48
                },
                "1F3FD": {
                    "unified": "1F937-1F3FD-200D-2640-FE0F",
                    "native": "🤷🏽‍♀️",
                    "sheetX": 39,
                    "sheetY": 49
                },
                "1F3FE": {
                    "unified": "1F937-1F3FE-200D-2640-FE0F",
                    "native": "🤷🏾‍♀️",
                    "sheetX": 39,
                    "sheetY": 50
                },
                "1F3FF": {
                    "unified": "1F937-1F3FF-200D-2640-FE0F",
                    "native": "🤷🏿‍♀️",
                    "sheetX": 39,
                    "sheetY": 51
                }
            },
            "tts": "woman shrugging",
            "keywords": [
                "doubt",
                "ignorance",
                "indifference",
                "person shrugging",
                "shrug",
                "woman",
                "woman shrugging"
            ]
        },
        {
            "unified": "1F486-200D-2642-FE0F",
            "name": null,
            "native": "💆‍♂️",
            "shortName": "man-getting-massage",
            "shortNames": [
                "man-getting-massage"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 24,
            "sheetY": 4,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F486-1F3FB-200D-2642-FE0F",
                    "native": "💆🏻‍♂️",
                    "sheetX": 24,
                    "sheetY": 5
                },
                "1F3FC": {
                    "unified": "1F486-1F3FC-200D-2642-FE0F",
                    "native": "💆🏼‍♂️",
                    "sheetX": 24,
                    "sheetY": 6
                },
                "1F3FD": {
                    "unified": "1F486-1F3FD-200D-2642-FE0F",
                    "native": "💆🏽‍♂️",
                    "sheetX": 24,
                    "sheetY": 7
                },
                "1F3FE": {
                    "unified": "1F486-1F3FE-200D-2642-FE0F",
                    "native": "💆🏾‍♂️",
                    "sheetX": 24,
                    "sheetY": 8
                },
                "1F3FF": {
                    "unified": "1F486-1F3FF-200D-2642-FE0F",
                    "native": "💆🏿‍♂️",
                    "sheetX": 24,
                    "sheetY": 9
                }
            },
            "tts": "man getting massage",
            "keywords": [
                "face",
                "massage",
                "person getting massage",
                "salon",
                "man",
                "man getting massage"
            ]
        },
        {
            "unified": "1F486-200D-2640-FE0F",
            "name": null,
            "native": "💆‍♀️",
            "shortName": "woman-getting-massage",
            "shortNames": [
                "woman-getting-massage"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 23,
            "sheetY": 50,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F486-1F3FB-200D-2640-FE0F",
                    "native": "💆🏻‍♀️",
                    "sheetX": 23,
                    "sheetY": 51
                },
                "1F3FC": {
                    "unified": "1F486-1F3FC-200D-2640-FE0F",
                    "native": "💆🏼‍♀️",
                    "sheetX": 24,
                    "sheetY": 0
                },
                "1F3FD": {
                    "unified": "1F486-1F3FD-200D-2640-FE0F",
                    "native": "💆🏽‍♀️",
                    "sheetX": 24,
                    "sheetY": 1
                },
                "1F3FE": {
                    "unified": "1F486-1F3FE-200D-2640-FE0F",
                    "native": "💆🏾‍♀️",
                    "sheetX": 24,
                    "sheetY": 2
                },
                "1F3FF": {
                    "unified": "1F486-1F3FF-200D-2640-FE0F",
                    "native": "💆🏿‍♀️",
                    "sheetX": 24,
                    "sheetY": 3
                }
            },
            "tts": "woman getting massage",
            "keywords": [
                "face",
                "massage",
                "person getting massage",
                "salon",
                "woman",
                "woman getting massage"
            ]
        },
        {
            "unified": "1F487-200D-2642-FE0F",
            "name": null,
            "native": "💇‍♂️",
            "shortName": "man-getting-haircut",
            "shortNames": [
                "man-getting-haircut"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 24,
            "sheetY": 22,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F487-1F3FB-200D-2642-FE0F",
                    "native": "💇🏻‍♂️",
                    "sheetX": 24,
                    "sheetY": 23
                },
                "1F3FC": {
                    "unified": "1F487-1F3FC-200D-2642-FE0F",
                    "native": "💇🏼‍♂️",
                    "sheetX": 24,
                    "sheetY": 24
                },
                "1F3FD": {
                    "unified": "1F487-1F3FD-200D-2642-FE0F",
                    "native": "💇🏽‍♂️",
                    "sheetX": 24,
                    "sheetY": 25
                },
                "1F3FE": {
                    "unified": "1F487-1F3FE-200D-2642-FE0F",
                    "native": "💇🏾‍♂️",
                    "sheetX": 24,
                    "sheetY": 26
                },
                "1F3FF": {
                    "unified": "1F487-1F3FF-200D-2642-FE0F",
                    "native": "💇🏿‍♂️",
                    "sheetX": 24,
                    "sheetY": 27
                }
            },
            "tts": "man getting haircut",
            "keywords": [
                "barber",
                "beauty",
                "haircut",
                "parlor",
                "person getting haircut",
                "man",
                "man getting haircut"
            ]
        },
        {
            "unified": "1F487-200D-2640-FE0F",
            "name": null,
            "native": "💇‍♀️",
            "shortName": "woman-getting-haircut",
            "shortNames": [
                "woman-getting-haircut"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 24,
            "sheetY": 16,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F487-1F3FB-200D-2640-FE0F",
                    "native": "💇🏻‍♀️",
                    "sheetX": 24,
                    "sheetY": 17
                },
                "1F3FC": {
                    "unified": "1F487-1F3FC-200D-2640-FE0F",
                    "native": "💇🏼‍♀️",
                    "sheetX": 24,
                    "sheetY": 18
                },
                "1F3FD": {
                    "unified": "1F487-1F3FD-200D-2640-FE0F",
                    "native": "💇🏽‍♀️",
                    "sheetX": 24,
                    "sheetY": 19
                },
                "1F3FE": {
                    "unified": "1F487-1F3FE-200D-2640-FE0F",
                    "native": "💇🏾‍♀️",
                    "sheetX": 24,
                    "sheetY": 20
                },
                "1F3FF": {
                    "unified": "1F487-1F3FF-200D-2640-FE0F",
                    "native": "💇🏿‍♀️",
                    "sheetX": 24,
                    "sheetY": 21
                }
            },
            "tts": "woman getting haircut",
            "keywords": [
                "barber",
                "beauty",
                "haircut",
                "parlor",
                "person getting haircut",
                "woman",
                "woman getting haircut"
            ]
        },
        {
            "unified": "1F6B6-200D-2642-FE0F",
            "name": null,
            "native": "🚶‍♂️",
            "shortName": "man-walking",
            "shortNames": [
                "man-walking"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 36,
            "sheetY": 15,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F6B6-1F3FB-200D-2642-FE0F",
                    "native": "🚶🏻‍♂️",
                    "sheetX": 36,
                    "sheetY": 16
                },
                "1F3FC": {
                    "unified": "1F6B6-1F3FC-200D-2642-FE0F",
                    "native": "🚶🏼‍♂️",
                    "sheetX": 36,
                    "sheetY": 17
                },
                "1F3FD": {
                    "unified": "1F6B6-1F3FD-200D-2642-FE0F",
                    "native": "🚶🏽‍♂️",
                    "sheetX": 36,
                    "sheetY": 18
                },
                "1F3FE": {
                    "unified": "1F6B6-1F3FE-200D-2642-FE0F",
                    "native": "🚶🏾‍♂️",
                    "sheetX": 36,
                    "sheetY": 19
                },
                "1F3FF": {
                    "unified": "1F6B6-1F3FF-200D-2642-FE0F",
                    "native": "🚶🏿‍♂️",
                    "sheetX": 36,
                    "sheetY": 20
                }
            },
            "tts": "man walking",
            "keywords": [
                "hike",
                "person walking",
                "walk",
                "walking",
                "man",
                "man walking"
            ]
        },
        {
            "unified": "1F6B6-200D-2640-FE0F",
            "name": null,
            "native": "🚶‍♀️",
            "shortName": "woman-walking",
            "shortNames": [
                "woman-walking"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 36,
            "sheetY": 9,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F6B6-1F3FB-200D-2640-FE0F",
                    "native": "🚶🏻‍♀️",
                    "sheetX": 36,
                    "sheetY": 10
                },
                "1F3FC": {
                    "unified": "1F6B6-1F3FC-200D-2640-FE0F",
                    "native": "🚶🏼‍♀️",
                    "sheetX": 36,
                    "sheetY": 11
                },
                "1F3FD": {
                    "unified": "1F6B6-1F3FD-200D-2640-FE0F",
                    "native": "🚶🏽‍♀️",
                    "sheetX": 36,
                    "sheetY": 12
                },
                "1F3FE": {
                    "unified": "1F6B6-1F3FE-200D-2640-FE0F",
                    "native": "🚶🏾‍♀️",
                    "sheetX": 36,
                    "sheetY": 13
                },
                "1F3FF": {
                    "unified": "1F6B6-1F3FF-200D-2640-FE0F",
                    "native": "🚶🏿‍♀️",
                    "sheetX": 36,
                    "sheetY": 14
                }
            },
            "tts": "woman walking",
            "keywords": [
                "hike",
                "person walking",
                "walk",
                "walking",
                "woman",
                "woman walking"
            ]
        },
        {
            "unified": "1F3C3-200D-2642-FE0F",
            "name": null,
            "native": "🏃‍♂️",
            "shortName": "man-running",
            "shortNames": [
                "man-running"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 9,
            "sheetY": 40,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F3C3-1F3FB-200D-2642-FE0F",
                    "native": "🏃🏻‍♂️",
                    "sheetX": 9,
                    "sheetY": 41
                },
                "1F3FC": {
                    "unified": "1F3C3-1F3FC-200D-2642-FE0F",
                    "native": "🏃🏼‍♂️",
                    "sheetX": 9,
                    "sheetY": 42
                },
                "1F3FD": {
                    "unified": "1F3C3-1F3FD-200D-2642-FE0F",
                    "native": "🏃🏽‍♂️",
                    "sheetX": 9,
                    "sheetY": 43
                },
                "1F3FE": {
                    "unified": "1F3C3-1F3FE-200D-2642-FE0F",
                    "native": "🏃🏾‍♂️",
                    "sheetX": 9,
                    "sheetY": 44
                },
                "1F3FF": {
                    "unified": "1F3C3-1F3FF-200D-2642-FE0F",
                    "native": "🏃🏿‍♂️",
                    "sheetX": 9,
                    "sheetY": 45
                }
            },
            "tts": "man running",
            "keywords": [
                "marathon",
                "person running",
                "running",
                "man",
                "racing"
            ]
        },
        {
            "unified": "1F3C3-200D-2640-FE0F",
            "name": null,
            "native": "🏃‍♀️",
            "shortName": "woman-running",
            "shortNames": [
                "woman-running"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 9,
            "sheetY": 34,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F3C3-1F3FB-200D-2640-FE0F",
                    "native": "🏃🏻‍♀️",
                    "sheetX": 9,
                    "sheetY": 35
                },
                "1F3FC": {
                    "unified": "1F3C3-1F3FC-200D-2640-FE0F",
                    "native": "🏃🏼‍♀️",
                    "sheetX": 9,
                    "sheetY": 36
                },
                "1F3FD": {
                    "unified": "1F3C3-1F3FD-200D-2640-FE0F",
                    "native": "🏃🏽‍♀️",
                    "sheetX": 9,
                    "sheetY": 37
                },
                "1F3FE": {
                    "unified": "1F3C3-1F3FE-200D-2640-FE0F",
                    "native": "🏃🏾‍♀️",
                    "sheetX": 9,
                    "sheetY": 38
                },
                "1F3FF": {
                    "unified": "1F3C3-1F3FF-200D-2640-FE0F",
                    "native": "🏃🏿‍♀️",
                    "sheetX": 9,
                    "sheetY": 39
                }
            },
            "tts": "woman running",
            "keywords": [
                "marathon",
                "person running",
                "running",
                "racing",
                "woman"
            ]
        },
        {
            "unified": "1F483",
            "name": "DANCER",
            "native": "💃",
            "shortName": "dancer",
            "shortNames": [
                "dancer"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 23,
            "sheetY": 37,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F483-1F3FB",
                    "native": "💃🏻",
                    "sheetX": 23,
                    "sheetY": 38
                },
                "1F3FC": {
                    "unified": "1F483-1F3FC",
                    "native": "💃🏼",
                    "sheetX": 23,
                    "sheetY": 39
                },
                "1F3FD": {
                    "unified": "1F483-1F3FD",
                    "native": "💃🏽",
                    "sheetX": 23,
                    "sheetY": 40
                },
                "1F3FE": {
                    "unified": "1F483-1F3FE",
                    "native": "💃🏾",
                    "sheetX": 23,
                    "sheetY": 41
                },
                "1F3FF": {
                    "unified": "1F483-1F3FF",
                    "native": "💃🏿",
                    "sheetX": 23,
                    "sheetY": 42
                }
            },
            "tts": "woman dancing",
            "keywords": [
                "dancing",
                "woman"
            ]
        },
        {
            "unified": "1F57A",
            "name": "MAN DANCING",
            "native": "🕺",
            "shortName": "man_dancing",
            "shortNames": [
                "man_dancing"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 29,
            "sheetY": 21,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F57A-1F3FB",
                    "native": "🕺🏻",
                    "sheetX": 29,
                    "sheetY": 22
                },
                "1F3FC": {
                    "unified": "1F57A-1F3FC",
                    "native": "🕺🏼",
                    "sheetX": 29,
                    "sheetY": 23
                },
                "1F3FD": {
                    "unified": "1F57A-1F3FD",
                    "native": "🕺🏽",
                    "sheetX": 29,
                    "sheetY": 24
                },
                "1F3FE": {
                    "unified": "1F57A-1F3FE",
                    "native": "🕺🏾",
                    "sheetX": 29,
                    "sheetY": 25
                },
                "1F3FF": {
                    "unified": "1F57A-1F3FF",
                    "native": "🕺🏿",
                    "sheetX": 29,
                    "sheetY": 26
                }
            },
            "tts": "man dancing",
            "keywords": [
                "dance",
                "man",
                "man dancing"
            ]
        },
        {
            "unified": "1F46F-200D-2642-FE0F",
            "name": null,
            "native": "👯‍♂️",
            "shortName": "man-with-bunny-ears-partying",
            "shortNames": [
                "man-with-bunny-ears-partying"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 21,
            "sheetY": 0,
            "tts": "men with bunny ears",
            "keywords": [
                "bunny ear",
                "dancer",
                "partying",
                "people with bunny ears",
                "men",
                "men with bunny ears"
            ]
        },
        {
            "unified": "1F46F-200D-2640-FE0F",
            "name": null,
            "native": "👯‍♀️",
            "shortName": "woman-with-bunny-ears-partying",
            "shortNames": [
                "woman-with-bunny-ears-partying"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 20,
            "sheetY": 51,
            "tts": "women with bunny ears",
            "keywords": [
                "bunny ear",
                "dancer",
                "partying",
                "people with bunny ears",
                "women",
                "women with bunny ears"
            ]
        },
        {
            "unified": "1F9D6-200D-2640-FE0F",
            "name": null,
            "native": "🧖‍♀️",
            "shortName": "woman_in_steamy_room",
            "shortNames": [
                "woman_in_steamy_room"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 43,
            "sheetY": 28,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F9D6-1F3FB-200D-2640-FE0F",
                    "native": "🧖🏻‍♀️",
                    "sheetX": 43,
                    "sheetY": 29
                },
                "1F3FC": {
                    "unified": "1F9D6-1F3FC-200D-2640-FE0F",
                    "native": "🧖🏼‍♀️",
                    "sheetX": 43,
                    "sheetY": 30
                },
                "1F3FD": {
                    "unified": "1F9D6-1F3FD-200D-2640-FE0F",
                    "native": "🧖🏽‍♀️",
                    "sheetX": 43,
                    "sheetY": 31
                },
                "1F3FE": {
                    "unified": "1F9D6-1F3FE-200D-2640-FE0F",
                    "native": "🧖🏾‍♀️",
                    "sheetX": 43,
                    "sheetY": 32
                },
                "1F3FF": {
                    "unified": "1F9D6-1F3FF-200D-2640-FE0F",
                    "native": "🧖🏿‍♀️",
                    "sheetX": 43,
                    "sheetY": 33
                }
            },
            "tts": "woman in steamy room",
            "keywords": [
                "person in steamy room",
                "sauna",
                "steam room",
                "woman in steamy room"
            ]
        },
        {
            "unified": "1F9D6-200D-2642-FE0F",
            "name": null,
            "native": "🧖‍♂️",
            "shortName": "man_in_steamy_room",
            "shortNames": [
                "man_in_steamy_room"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 43,
            "sheetY": 34,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F9D6-1F3FB-200D-2642-FE0F",
                    "native": "🧖🏻‍♂️",
                    "sheetX": 43,
                    "sheetY": 35
                },
                "1F3FC": {
                    "unified": "1F9D6-1F3FC-200D-2642-FE0F",
                    "native": "🧖🏼‍♂️",
                    "sheetX": 43,
                    "sheetY": 36
                },
                "1F3FD": {
                    "unified": "1F9D6-1F3FD-200D-2642-FE0F",
                    "native": "🧖🏽‍♂️",
                    "sheetX": 43,
                    "sheetY": 37
                },
                "1F3FE": {
                    "unified": "1F9D6-1F3FE-200D-2642-FE0F",
                    "native": "🧖🏾‍♂️",
                    "sheetX": 43,
                    "sheetY": 38
                },
                "1F3FF": {
                    "unified": "1F9D6-1F3FF-200D-2642-FE0F",
                    "native": "🧖🏿‍♂️",
                    "sheetX": 43,
                    "sheetY": 39
                }
            },
            "tts": "man in steamy room",
            "keywords": [
                "person in steamy room",
                "sauna",
                "steam room",
                "man in steamy room"
            ]
        },
        {
            "unified": "1F9D7-200D-2640-FE0F",
            "name": null,
            "native": "🧗‍♀️",
            "shortName": "woman_climbing",
            "shortNames": [
                "woman_climbing"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 43,
            "sheetY": 46,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F9D7-1F3FB-200D-2640-FE0F",
                    "native": "🧗🏻‍♀️",
                    "sheetX": 43,
                    "sheetY": 47
                },
                "1F3FC": {
                    "unified": "1F9D7-1F3FC-200D-2640-FE0F",
                    "native": "🧗🏼‍♀️",
                    "sheetX": 43,
                    "sheetY": 48
                },
                "1F3FD": {
                    "unified": "1F9D7-1F3FD-200D-2640-FE0F",
                    "native": "🧗🏽‍♀️",
                    "sheetX": 43,
                    "sheetY": 49
                },
                "1F3FE": {
                    "unified": "1F9D7-1F3FE-200D-2640-FE0F",
                    "native": "🧗🏾‍♀️",
                    "sheetX": 43,
                    "sheetY": 50
                },
                "1F3FF": {
                    "unified": "1F9D7-1F3FF-200D-2640-FE0F",
                    "native": "🧗🏿‍♀️",
                    "sheetX": 43,
                    "sheetY": 51
                }
            },
            "tts": "woman climbing",
            "keywords": [
                "climber",
                "person climbing",
                "woman climbing"
            ]
        },
        {
            "unified": "1F9D7-200D-2642-FE0F",
            "name": null,
            "native": "🧗‍♂️",
            "shortName": "man_climbing",
            "shortNames": [
                "man_climbing"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 44,
            "sheetY": 0,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F9D7-1F3FB-200D-2642-FE0F",
                    "native": "🧗🏻‍♂️",
                    "sheetX": 44,
                    "sheetY": 1
                },
                "1F3FC": {
                    "unified": "1F9D7-1F3FC-200D-2642-FE0F",
                    "native": "🧗🏼‍♂️",
                    "sheetX": 44,
                    "sheetY": 2
                },
                "1F3FD": {
                    "unified": "1F9D7-1F3FD-200D-2642-FE0F",
                    "native": "🧗🏽‍♂️",
                    "sheetX": 44,
                    "sheetY": 3
                },
                "1F3FE": {
                    "unified": "1F9D7-1F3FE-200D-2642-FE0F",
                    "native": "🧗🏾‍♂️",
                    "sheetX": 44,
                    "sheetY": 4
                },
                "1F3FF": {
                    "unified": "1F9D7-1F3FF-200D-2642-FE0F",
                    "native": "🧗🏿‍♂️",
                    "sheetX": 44,
                    "sheetY": 5
                }
            },
            "tts": "man climbing",
            "keywords": [
                "climber",
                "person climbing",
                "man climbing"
            ]
        },
        {
            "unified": "1F9D8-200D-2640-FE0F",
            "name": null,
            "native": "🧘‍♀️",
            "shortName": "woman_in_lotus_position",
            "shortNames": [
                "woman_in_lotus_position"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 44,
            "sheetY": 12,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F9D8-1F3FB-200D-2640-FE0F",
                    "native": "🧘🏻‍♀️",
                    "sheetX": 44,
                    "sheetY": 13
                },
                "1F3FC": {
                    "unified": "1F9D8-1F3FC-200D-2640-FE0F",
                    "native": "🧘🏼‍♀️",
                    "sheetX": 44,
                    "sheetY": 14
                },
                "1F3FD": {
                    "unified": "1F9D8-1F3FD-200D-2640-FE0F",
                    "native": "🧘🏽‍♀️",
                    "sheetX": 44,
                    "sheetY": 15
                },
                "1F3FE": {
                    "unified": "1F9D8-1F3FE-200D-2640-FE0F",
                    "native": "🧘🏾‍♀️",
                    "sheetX": 44,
                    "sheetY": 16
                },
                "1F3FF": {
                    "unified": "1F9D8-1F3FF-200D-2640-FE0F",
                    "native": "🧘🏿‍♀️",
                    "sheetX": 44,
                    "sheetY": 17
                }
            },
            "tts": "woman in lotus position",
            "keywords": [
                "meditation",
                "person in lotus position",
                "yoga",
                "woman in lotus position"
            ]
        },
        {
            "unified": "1F9D8-200D-2642-FE0F",
            "name": null,
            "native": "🧘‍♂️",
            "shortName": "man_in_lotus_position",
            "shortNames": [
                "man_in_lotus_position"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 44,
            "sheetY": 18,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F9D8-1F3FB-200D-2642-FE0F",
                    "native": "🧘🏻‍♂️",
                    "sheetX": 44,
                    "sheetY": 19
                },
                "1F3FC": {
                    "unified": "1F9D8-1F3FC-200D-2642-FE0F",
                    "native": "🧘🏼‍♂️",
                    "sheetX": 44,
                    "sheetY": 20
                },
                "1F3FD": {
                    "unified": "1F9D8-1F3FD-200D-2642-FE0F",
                    "native": "🧘🏽‍♂️",
                    "sheetX": 44,
                    "sheetY": 21
                },
                "1F3FE": {
                    "unified": "1F9D8-1F3FE-200D-2642-FE0F",
                    "native": "🧘🏾‍♂️",
                    "sheetX": 44,
                    "sheetY": 22
                },
                "1F3FF": {
                    "unified": "1F9D8-1F3FF-200D-2642-FE0F",
                    "native": "🧘🏿‍♂️",
                    "sheetX": 44,
                    "sheetY": 23
                }
            },
            "tts": "man in lotus position",
            "keywords": [
                "meditation",
                "person in lotus position",
                "yoga",
                "man in lotus position"
            ]
        },
        {
            "unified": "1F6C0",
            "name": "BATH",
            "native": "🛀",
            "shortName": "bath",
            "shortNames": [
                "bath"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 36,
            "sheetY": 36,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F6C0-1F3FB",
                    "native": "🛀🏻",
                    "sheetX": 36,
                    "sheetY": 37
                },
                "1F3FC": {
                    "unified": "1F6C0-1F3FC",
                    "native": "🛀🏼",
                    "sheetX": 36,
                    "sheetY": 38
                },
                "1F3FD": {
                    "unified": "1F6C0-1F3FD",
                    "native": "🛀🏽",
                    "sheetX": 36,
                    "sheetY": 39
                },
                "1F3FE": {
                    "unified": "1F6C0-1F3FE",
                    "native": "🛀🏾",
                    "sheetX": 36,
                    "sheetY": 40
                },
                "1F3FF": {
                    "unified": "1F6C0-1F3FF",
                    "native": "🛀🏿",
                    "sheetX": 36,
                    "sheetY": 41
                }
            },
            "tts": "person taking bath",
            "keywords": [
                "bath",
                "bathtub",
                "person taking bath"
            ]
        },
        {
            "unified": "1F6CC",
            "name": "SLEEPING ACCOMMODATION",
            "native": "🛌",
            "shortName": "sleeping_accommodation",
            "shortNames": [
                "sleeping_accommodation"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 36,
            "sheetY": 48,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F6CC-1F3FB",
                    "native": "🛌🏻",
                    "sheetX": 36,
                    "sheetY": 49
                },
                "1F3FC": {
                    "unified": "1F6CC-1F3FC",
                    "native": "🛌🏼",
                    "sheetX": 36,
                    "sheetY": 50
                },
                "1F3FD": {
                    "unified": "1F6CC-1F3FD",
                    "native": "🛌🏽",
                    "sheetX": 36,
                    "sheetY": 51
                },
                "1F3FE": {
                    "unified": "1F6CC-1F3FE",
                    "native": "🛌🏾",
                    "sheetX": 37,
                    "sheetY": 0
                },
                "1F3FF": {
                    "unified": "1F6CC-1F3FF",
                    "native": "🛌🏿",
                    "sheetX": 37,
                    "sheetY": 1
                }
            },
            "tts": "person in bed",
            "keywords": [
                "hotel",
                "person in bed",
                "sleep"
            ]
        },
        {
            "unified": "1F574-FE0F",
            "name": null,
            "native": "🕴️",
            "shortName": "man_in_business_suit_levitating",
            "shortNames": [
                "man_in_business_suit_levitating"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 28,
            "sheetY": 45,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F574-1F3FB",
                    "native": "🕴🏻",
                    "sheetX": 28,
                    "sheetY": 46
                },
                "1F3FC": {
                    "unified": "1F574-1F3FC",
                    "native": "🕴🏼",
                    "sheetX": 28,
                    "sheetY": 47
                },
                "1F3FD": {
                    "unified": "1F574-1F3FD",
                    "native": "🕴🏽",
                    "sheetX": 28,
                    "sheetY": 48
                },
                "1F3FE": {
                    "unified": "1F574-1F3FE",
                    "native": "🕴🏾",
                    "sheetX": 28,
                    "sheetY": 49
                },
                "1F3FF": {
                    "unified": "1F574-1F3FF",
                    "native": "🕴🏿",
                    "sheetX": 28,
                    "sheetY": 50
                }
            }
        },
        {
            "unified": "1F5E3-FE0F",
            "name": null,
            "native": "🗣️",
            "shortName": "speaking_head_in_silhouette",
            "shortNames": [
                "speaking_head_in_silhouette"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 14
        },
        {
            "unified": "1F464",
            "name": "BUST IN SILHOUETTE",
            "native": "👤",
            "shortName": "bust_in_silhouette",
            "shortNames": [
                "bust_in_silhouette"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 40,
            "tts": "bust in silhouette",
            "keywords": [
                "bust",
                "bust in silhouette",
                "silhouette"
            ]
        },
        {
            "unified": "1F465",
            "name": "BUSTS IN SILHOUETTE",
            "native": "👥",
            "shortName": "busts_in_silhouette",
            "shortNames": [
                "busts_in_silhouette"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 41,
            "tts": "busts in silhouette",
            "keywords": [
                "bust",
                "busts in silhouette",
                "silhouette"
            ]
        },
        {
            "unified": "1F93A",
            "name": "FENCER",
            "native": "🤺",
            "shortName": "fencer",
            "shortNames": [
                "fencer"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 40,
            "sheetY": 48,
            "tts": "person fencing",
            "keywords": [
                "fencer",
                "fencing",
                "person fencing",
                "sword"
            ]
        },
        {
            "unified": "1F3C7",
            "name": "HORSE RACING",
            "native": "🏇",
            "shortName": "horse_racing",
            "shortNames": [
                "horse_racing"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 10,
            "sheetY": 20,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F3C7-1F3FB",
                    "native": "🏇🏻",
                    "sheetX": 10,
                    "sheetY": 21
                },
                "1F3FC": {
                    "unified": "1F3C7-1F3FC",
                    "native": "🏇🏼",
                    "sheetX": 10,
                    "sheetY": 22
                },
                "1F3FD": {
                    "unified": "1F3C7-1F3FD",
                    "native": "🏇🏽",
                    "sheetX": 10,
                    "sheetY": 23
                },
                "1F3FE": {
                    "unified": "1F3C7-1F3FE",
                    "native": "🏇🏾",
                    "sheetX": 10,
                    "sheetY": 24
                },
                "1F3FF": {
                    "unified": "1F3C7-1F3FF",
                    "native": "🏇🏿",
                    "sheetX": 10,
                    "sheetY": 25
                }
            },
            "tts": "horse racing",
            "keywords": [
                "horse",
                "jockey",
                "racehorse",
                "racing"
            ]
        },
        {
            "unified": "26F7-FE0F",
            "name": null,
            "native": "⛷️",
            "shortName": "skier",
            "shortNames": [
                "skier"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 48,
            "sheetY": 44
        },
        {
            "unified": "1F3C2",
            "name": "SNOWBOARDER",
            "native": "🏂",
            "shortName": "snowboarder",
            "shortNames": [
                "snowboarder"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 9,
            "sheetY": 28,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F3C2-1F3FB",
                    "native": "🏂🏻",
                    "sheetX": 9,
                    "sheetY": 29
                },
                "1F3FC": {
                    "unified": "1F3C2-1F3FC",
                    "native": "🏂🏼",
                    "sheetX": 9,
                    "sheetY": 30
                },
                "1F3FD": {
                    "unified": "1F3C2-1F3FD",
                    "native": "🏂🏽",
                    "sheetX": 9,
                    "sheetY": 31
                },
                "1F3FE": {
                    "unified": "1F3C2-1F3FE",
                    "native": "🏂🏾",
                    "sheetX": 9,
                    "sheetY": 32
                },
                "1F3FF": {
                    "unified": "1F3C2-1F3FF",
                    "native": "🏂🏿",
                    "sheetX": 9,
                    "sheetY": 33
                }
            },
            "tts": "snowboarder",
            "keywords": [
                "ski",
                "snow",
                "snowboard",
                "snowboarder"
            ]
        },
        {
            "unified": "1F3CC-FE0F",
            "name": null,
            "native": "🏌️",
            "shortName": "golfer",
            "shortNames": [
                "golfer"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 11,
            "sheetY": 24,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F3CC-1F3FB",
                    "native": "🏌🏻",
                    "sheetX": 11,
                    "sheetY": 25
                },
                "1F3FC": {
                    "unified": "1F3CC-1F3FC",
                    "native": "🏌🏼",
                    "sheetX": 11,
                    "sheetY": 26
                },
                "1F3FD": {
                    "unified": "1F3CC-1F3FD",
                    "native": "🏌🏽",
                    "sheetX": 11,
                    "sheetY": 27
                },
                "1F3FE": {
                    "unified": "1F3CC-1F3FE",
                    "native": "🏌🏾",
                    "sheetX": 11,
                    "sheetY": 28
                },
                "1F3FF": {
                    "unified": "1F3CC-1F3FF",
                    "native": "🏌🏿",
                    "sheetX": 11,
                    "sheetY": 29
                }
            }
        },
        {
            "unified": "1F3CC-FE0F-200D-2642-FE0F",
            "name": null,
            "native": "🏌️‍♂️",
            "shortName": "man-golfing",
            "shortNames": [
                "man-golfing"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 11,
            "sheetY": 18,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F3CC-1F3FB-200D-2642-FE0F",
                    "native": "🏌🏻‍♂️",
                    "sheetX": 11,
                    "sheetY": 19
                },
                "1F3FC": {
                    "unified": "1F3CC-1F3FC-200D-2642-FE0F",
                    "native": "🏌🏼‍♂️",
                    "sheetX": 11,
                    "sheetY": 20
                },
                "1F3FD": {
                    "unified": "1F3CC-1F3FD-200D-2642-FE0F",
                    "native": "🏌🏽‍♂️",
                    "sheetX": 11,
                    "sheetY": 21
                },
                "1F3FE": {
                    "unified": "1F3CC-1F3FE-200D-2642-FE0F",
                    "native": "🏌🏾‍♂️",
                    "sheetX": 11,
                    "sheetY": 22
                },
                "1F3FF": {
                    "unified": "1F3CC-1F3FF-200D-2642-FE0F",
                    "native": "🏌🏿‍♂️",
                    "sheetX": 11,
                    "sheetY": 23
                }
            }
        },
        {
            "unified": "1F3CC-FE0F-200D-2640-FE0F",
            "name": null,
            "native": "🏌️‍♀️",
            "shortName": "woman-golfing",
            "shortNames": [
                "woman-golfing"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 11,
            "sheetY": 12,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F3CC-1F3FB-200D-2640-FE0F",
                    "native": "🏌🏻‍♀️",
                    "sheetX": 11,
                    "sheetY": 13
                },
                "1F3FC": {
                    "unified": "1F3CC-1F3FC-200D-2640-FE0F",
                    "native": "🏌🏼‍♀️",
                    "sheetX": 11,
                    "sheetY": 14
                },
                "1F3FD": {
                    "unified": "1F3CC-1F3FD-200D-2640-FE0F",
                    "native": "🏌🏽‍♀️",
                    "sheetX": 11,
                    "sheetY": 15
                },
                "1F3FE": {
                    "unified": "1F3CC-1F3FE-200D-2640-FE0F",
                    "native": "🏌🏾‍♀️",
                    "sheetX": 11,
                    "sheetY": 16
                },
                "1F3FF": {
                    "unified": "1F3CC-1F3FF-200D-2640-FE0F",
                    "native": "🏌🏿‍♀️",
                    "sheetX": 11,
                    "sheetY": 17
                }
            }
        },
        {
            "unified": "1F3C4-200D-2642-FE0F",
            "name": null,
            "native": "🏄‍♂️",
            "shortName": "man-surfing",
            "shortNames": [
                "man-surfing"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 10,
            "sheetY": 6,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F3C4-1F3FB-200D-2642-FE0F",
                    "native": "🏄🏻‍♂️",
                    "sheetX": 10,
                    "sheetY": 7
                },
                "1F3FC": {
                    "unified": "1F3C4-1F3FC-200D-2642-FE0F",
                    "native": "🏄🏼‍♂️",
                    "sheetX": 10,
                    "sheetY": 8
                },
                "1F3FD": {
                    "unified": "1F3C4-1F3FD-200D-2642-FE0F",
                    "native": "🏄🏽‍♂️",
                    "sheetX": 10,
                    "sheetY": 9
                },
                "1F3FE": {
                    "unified": "1F3C4-1F3FE-200D-2642-FE0F",
                    "native": "🏄🏾‍♂️",
                    "sheetX": 10,
                    "sheetY": 10
                },
                "1F3FF": {
                    "unified": "1F3C4-1F3FF-200D-2642-FE0F",
                    "native": "🏄🏿‍♂️",
                    "sheetX": 10,
                    "sheetY": 11
                }
            },
            "tts": "man surfing",
            "keywords": [
                "person surfing",
                "surfing",
                "man"
            ]
        },
        {
            "unified": "1F3C4-200D-2640-FE0F",
            "name": null,
            "native": "🏄‍♀️",
            "shortName": "woman-surfing",
            "shortNames": [
                "woman-surfing"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 10,
            "sheetY": 0,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F3C4-1F3FB-200D-2640-FE0F",
                    "native": "🏄🏻‍♀️",
                    "sheetX": 10,
                    "sheetY": 1
                },
                "1F3FC": {
                    "unified": "1F3C4-1F3FC-200D-2640-FE0F",
                    "native": "🏄🏼‍♀️",
                    "sheetX": 10,
                    "sheetY": 2
                },
                "1F3FD": {
                    "unified": "1F3C4-1F3FD-200D-2640-FE0F",
                    "native": "🏄🏽‍♀️",
                    "sheetX": 10,
                    "sheetY": 3
                },
                "1F3FE": {
                    "unified": "1F3C4-1F3FE-200D-2640-FE0F",
                    "native": "🏄🏾‍♀️",
                    "sheetX": 10,
                    "sheetY": 4
                },
                "1F3FF": {
                    "unified": "1F3C4-1F3FF-200D-2640-FE0F",
                    "native": "🏄🏿‍♀️",
                    "sheetX": 10,
                    "sheetY": 5
                }
            },
            "tts": "woman surfing",
            "keywords": [
                "person surfing",
                "surfing",
                "woman"
            ]
        },
        {
            "unified": "1F6A3-200D-2642-FE0F",
            "name": null,
            "native": "🚣‍♂️",
            "shortName": "man-rowing-boat",
            "shortNames": [
                "man-rowing-boat"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 34,
            "sheetY": 49,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F6A3-1F3FB-200D-2642-FE0F",
                    "native": "🚣🏻‍♂️",
                    "sheetX": 34,
                    "sheetY": 50
                },
                "1F3FC": {
                    "unified": "1F6A3-1F3FC-200D-2642-FE0F",
                    "native": "🚣🏼‍♂️",
                    "sheetX": 34,
                    "sheetY": 51
                },
                "1F3FD": {
                    "unified": "1F6A3-1F3FD-200D-2642-FE0F",
                    "native": "🚣🏽‍♂️",
                    "sheetX": 35,
                    "sheetY": 0
                },
                "1F3FE": {
                    "unified": "1F6A3-1F3FE-200D-2642-FE0F",
                    "native": "🚣🏾‍♂️",
                    "sheetX": 35,
                    "sheetY": 1
                },
                "1F3FF": {
                    "unified": "1F6A3-1F3FF-200D-2642-FE0F",
                    "native": "🚣🏿‍♂️",
                    "sheetX": 35,
                    "sheetY": 2
                }
            },
            "tts": "man rowing boat",
            "keywords": [
                "boat",
                "person rowing boat",
                "rowboat",
                "man",
                "man rowing boat"
            ]
        },
        {
            "unified": "1F6A3-200D-2640-FE0F",
            "name": null,
            "native": "🚣‍♀️",
            "shortName": "woman-rowing-boat",
            "shortNames": [
                "woman-rowing-boat"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 34,
            "sheetY": 43,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F6A3-1F3FB-200D-2640-FE0F",
                    "native": "🚣🏻‍♀️",
                    "sheetX": 34,
                    "sheetY": 44
                },
                "1F3FC": {
                    "unified": "1F6A3-1F3FC-200D-2640-FE0F",
                    "native": "🚣🏼‍♀️",
                    "sheetX": 34,
                    "sheetY": 45
                },
                "1F3FD": {
                    "unified": "1F6A3-1F3FD-200D-2640-FE0F",
                    "native": "🚣🏽‍♀️",
                    "sheetX": 34,
                    "sheetY": 46
                },
                "1F3FE": {
                    "unified": "1F6A3-1F3FE-200D-2640-FE0F",
                    "native": "🚣🏾‍♀️",
                    "sheetX": 34,
                    "sheetY": 47
                },
                "1F3FF": {
                    "unified": "1F6A3-1F3FF-200D-2640-FE0F",
                    "native": "🚣🏿‍♀️",
                    "sheetX": 34,
                    "sheetY": 48
                }
            },
            "tts": "woman rowing boat",
            "keywords": [
                "boat",
                "person rowing boat",
                "rowboat",
                "woman",
                "woman rowing boat"
            ]
        },
        {
            "unified": "1F3CA-200D-2642-FE0F",
            "name": null,
            "native": "🏊‍♂️",
            "shortName": "man-swimming",
            "shortNames": [
                "man-swimming"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 10,
            "sheetY": 34,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F3CA-1F3FB-200D-2642-FE0F",
                    "native": "🏊🏻‍♂️",
                    "sheetX": 10,
                    "sheetY": 35
                },
                "1F3FC": {
                    "unified": "1F3CA-1F3FC-200D-2642-FE0F",
                    "native": "🏊🏼‍♂️",
                    "sheetX": 10,
                    "sheetY": 36
                },
                "1F3FD": {
                    "unified": "1F3CA-1F3FD-200D-2642-FE0F",
                    "native": "🏊🏽‍♂️",
                    "sheetX": 10,
                    "sheetY": 37
                },
                "1F3FE": {
                    "unified": "1F3CA-1F3FE-200D-2642-FE0F",
                    "native": "🏊🏾‍♂️",
                    "sheetX": 10,
                    "sheetY": 38
                },
                "1F3FF": {
                    "unified": "1F3CA-1F3FF-200D-2642-FE0F",
                    "native": "🏊🏿‍♂️",
                    "sheetX": 10,
                    "sheetY": 39
                }
            },
            "tts": "man swimming",
            "keywords": [
                "person swimming",
                "swim",
                "man",
                "man swimming"
            ]
        },
        {
            "unified": "1F3CA-200D-2640-FE0F",
            "name": null,
            "native": "🏊‍♀️",
            "shortName": "woman-swimming",
            "shortNames": [
                "woman-swimming"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 10,
            "sheetY": 28,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F3CA-1F3FB-200D-2640-FE0F",
                    "native": "🏊🏻‍♀️",
                    "sheetX": 10,
                    "sheetY": 29
                },
                "1F3FC": {
                    "unified": "1F3CA-1F3FC-200D-2640-FE0F",
                    "native": "🏊🏼‍♀️",
                    "sheetX": 10,
                    "sheetY": 30
                },
                "1F3FD": {
                    "unified": "1F3CA-1F3FD-200D-2640-FE0F",
                    "native": "🏊🏽‍♀️",
                    "sheetX": 10,
                    "sheetY": 31
                },
                "1F3FE": {
                    "unified": "1F3CA-1F3FE-200D-2640-FE0F",
                    "native": "🏊🏾‍♀️",
                    "sheetX": 10,
                    "sheetY": 32
                },
                "1F3FF": {
                    "unified": "1F3CA-1F3FF-200D-2640-FE0F",
                    "native": "🏊🏿‍♀️",
                    "sheetX": 10,
                    "sheetY": 33
                }
            },
            "tts": "woman swimming",
            "keywords": [
                "person swimming",
                "swim",
                "woman",
                "woman swimming"
            ]
        },
        {
            "unified": "26F9-FE0F",
            "name": null,
            "native": "⛹️",
            "shortName": "person_with_ball",
            "shortNames": [
                "person_with_ball"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 49,
            "sheetY": 6,
            "skinVariations": {
                "1F3FB": {
                    "unified": "26F9-1F3FB",
                    "native": "⛹🏻",
                    "sheetX": 49,
                    "sheetY": 7
                },
                "1F3FC": {
                    "unified": "26F9-1F3FC",
                    "native": "⛹🏼",
                    "sheetX": 49,
                    "sheetY": 8
                },
                "1F3FD": {
                    "unified": "26F9-1F3FD",
                    "native": "⛹🏽",
                    "sheetX": 49,
                    "sheetY": 9
                },
                "1F3FE": {
                    "unified": "26F9-1F3FE",
                    "native": "⛹🏾",
                    "sheetX": 49,
                    "sheetY": 10
                },
                "1F3FF": {
                    "unified": "26F9-1F3FF",
                    "native": "⛹🏿",
                    "sheetX": 49,
                    "sheetY": 11
                }
            }
        },
        {
            "unified": "26F9-FE0F-200D-2642-FE0F",
            "name": null,
            "native": "⛹️‍♂️",
            "shortName": "man-bouncing-ball",
            "shortNames": [
                "man-bouncing-ball"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 49,
            "sheetY": 0,
            "skinVariations": {
                "1F3FB": {
                    "unified": "26F9-1F3FB-200D-2642-FE0F",
                    "native": "⛹🏻‍♂️",
                    "sheetX": 49,
                    "sheetY": 1
                },
                "1F3FC": {
                    "unified": "26F9-1F3FC-200D-2642-FE0F",
                    "native": "⛹🏼‍♂️",
                    "sheetX": 49,
                    "sheetY": 2
                },
                "1F3FD": {
                    "unified": "26F9-1F3FD-200D-2642-FE0F",
                    "native": "⛹🏽‍♂️",
                    "sheetX": 49,
                    "sheetY": 3
                },
                "1F3FE": {
                    "unified": "26F9-1F3FE-200D-2642-FE0F",
                    "native": "⛹🏾‍♂️",
                    "sheetX": 49,
                    "sheetY": 4
                },
                "1F3FF": {
                    "unified": "26F9-1F3FF-200D-2642-FE0F",
                    "native": "⛹🏿‍♂️",
                    "sheetX": 49,
                    "sheetY": 5
                }
            }
        },
        {
            "unified": "26F9-FE0F-200D-2640-FE0F",
            "name": null,
            "native": "⛹️‍♀️",
            "shortName": "woman-bouncing-ball",
            "shortNames": [
                "woman-bouncing-ball"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 48,
            "sheetY": 46,
            "skinVariations": {
                "1F3FB": {
                    "unified": "26F9-1F3FB-200D-2640-FE0F",
                    "native": "⛹🏻‍♀️",
                    "sheetX": 48,
                    "sheetY": 47
                },
                "1F3FC": {
                    "unified": "26F9-1F3FC-200D-2640-FE0F",
                    "native": "⛹🏼‍♀️",
                    "sheetX": 48,
                    "sheetY": 48
                },
                "1F3FD": {
                    "unified": "26F9-1F3FD-200D-2640-FE0F",
                    "native": "⛹🏽‍♀️",
                    "sheetX": 48,
                    "sheetY": 49
                },
                "1F3FE": {
                    "unified": "26F9-1F3FE-200D-2640-FE0F",
                    "native": "⛹🏾‍♀️",
                    "sheetX": 48,
                    "sheetY": 50
                },
                "1F3FF": {
                    "unified": "26F9-1F3FF-200D-2640-FE0F",
                    "native": "⛹🏿‍♀️",
                    "sheetX": 48,
                    "sheetY": 51
                }
            }
        },
        {
            "unified": "1F3CB-FE0F",
            "name": null,
            "native": "🏋️",
            "shortName": "weight_lifter",
            "shortNames": [
                "weight_lifter"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 11,
            "sheetY": 6,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F3CB-1F3FB",
                    "native": "🏋🏻",
                    "sheetX": 11,
                    "sheetY": 7
                },
                "1F3FC": {
                    "unified": "1F3CB-1F3FC",
                    "native": "🏋🏼",
                    "sheetX": 11,
                    "sheetY": 8
                },
                "1F3FD": {
                    "unified": "1F3CB-1F3FD",
                    "native": "🏋🏽",
                    "sheetX": 11,
                    "sheetY": 9
                },
                "1F3FE": {
                    "unified": "1F3CB-1F3FE",
                    "native": "🏋🏾",
                    "sheetX": 11,
                    "sheetY": 10
                },
                "1F3FF": {
                    "unified": "1F3CB-1F3FF",
                    "native": "🏋🏿",
                    "sheetX": 11,
                    "sheetY": 11
                }
            }
        },
        {
            "unified": "1F3CB-FE0F-200D-2642-FE0F",
            "name": null,
            "native": "🏋️‍♂️",
            "shortName": "man-lifting-weights",
            "shortNames": [
                "man-lifting-weights"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 11,
            "sheetY": 0,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F3CB-1F3FB-200D-2642-FE0F",
                    "native": "🏋🏻‍♂️",
                    "sheetX": 11,
                    "sheetY": 1
                },
                "1F3FC": {
                    "unified": "1F3CB-1F3FC-200D-2642-FE0F",
                    "native": "🏋🏼‍♂️",
                    "sheetX": 11,
                    "sheetY": 2
                },
                "1F3FD": {
                    "unified": "1F3CB-1F3FD-200D-2642-FE0F",
                    "native": "🏋🏽‍♂️",
                    "sheetX": 11,
                    "sheetY": 3
                },
                "1F3FE": {
                    "unified": "1F3CB-1F3FE-200D-2642-FE0F",
                    "native": "🏋🏾‍♂️",
                    "sheetX": 11,
                    "sheetY": 4
                },
                "1F3FF": {
                    "unified": "1F3CB-1F3FF-200D-2642-FE0F",
                    "native": "🏋🏿‍♂️",
                    "sheetX": 11,
                    "sheetY": 5
                }
            }
        },
        {
            "unified": "1F3CB-FE0F-200D-2640-FE0F",
            "name": null,
            "native": "🏋️‍♀️",
            "shortName": "woman-lifting-weights",
            "shortNames": [
                "woman-lifting-weights"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 10,
            "sheetY": 46,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F3CB-1F3FB-200D-2640-FE0F",
                    "native": "🏋🏻‍♀️",
                    "sheetX": 10,
                    "sheetY": 47
                },
                "1F3FC": {
                    "unified": "1F3CB-1F3FC-200D-2640-FE0F",
                    "native": "🏋🏼‍♀️",
                    "sheetX": 10,
                    "sheetY": 48
                },
                "1F3FD": {
                    "unified": "1F3CB-1F3FD-200D-2640-FE0F",
                    "native": "🏋🏽‍♀️",
                    "sheetX": 10,
                    "sheetY": 49
                },
                "1F3FE": {
                    "unified": "1F3CB-1F3FE-200D-2640-FE0F",
                    "native": "🏋🏾‍♀️",
                    "sheetX": 10,
                    "sheetY": 50
                },
                "1F3FF": {
                    "unified": "1F3CB-1F3FF-200D-2640-FE0F",
                    "native": "🏋🏿‍♀️",
                    "sheetX": 10,
                    "sheetY": 51
                }
            }
        },
        {
            "unified": "1F6B4-200D-2642-FE0F",
            "name": null,
            "native": "🚴‍♂️",
            "shortName": "man-biking",
            "shortNames": [
                "man-biking"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 35,
            "sheetY": 31,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F6B4-1F3FB-200D-2642-FE0F",
                    "native": "🚴🏻‍♂️",
                    "sheetX": 35,
                    "sheetY": 32
                },
                "1F3FC": {
                    "unified": "1F6B4-1F3FC-200D-2642-FE0F",
                    "native": "🚴🏼‍♂️",
                    "sheetX": 35,
                    "sheetY": 33
                },
                "1F3FD": {
                    "unified": "1F6B4-1F3FD-200D-2642-FE0F",
                    "native": "🚴🏽‍♂️",
                    "sheetX": 35,
                    "sheetY": 34
                },
                "1F3FE": {
                    "unified": "1F6B4-1F3FE-200D-2642-FE0F",
                    "native": "🚴🏾‍♂️",
                    "sheetX": 35,
                    "sheetY": 35
                },
                "1F3FF": {
                    "unified": "1F6B4-1F3FF-200D-2642-FE0F",
                    "native": "🚴🏿‍♂️",
                    "sheetX": 35,
                    "sheetY": 36
                }
            },
            "tts": "man biking",
            "keywords": [
                "bicycle",
                "biking",
                "cyclist",
                "person biking",
                "man"
            ]
        },
        {
            "unified": "1F6B4-200D-2640-FE0F",
            "name": null,
            "native": "🚴‍♀️",
            "shortName": "woman-biking",
            "shortNames": [
                "woman-biking"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 35,
            "sheetY": 25,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F6B4-1F3FB-200D-2640-FE0F",
                    "native": "🚴🏻‍♀️",
                    "sheetX": 35,
                    "sheetY": 26
                },
                "1F3FC": {
                    "unified": "1F6B4-1F3FC-200D-2640-FE0F",
                    "native": "🚴🏼‍♀️",
                    "sheetX": 35,
                    "sheetY": 27
                },
                "1F3FD": {
                    "unified": "1F6B4-1F3FD-200D-2640-FE0F",
                    "native": "🚴🏽‍♀️",
                    "sheetX": 35,
                    "sheetY": 28
                },
                "1F3FE": {
                    "unified": "1F6B4-1F3FE-200D-2640-FE0F",
                    "native": "🚴🏾‍♀️",
                    "sheetX": 35,
                    "sheetY": 29
                },
                "1F3FF": {
                    "unified": "1F6B4-1F3FF-200D-2640-FE0F",
                    "native": "🚴🏿‍♀️",
                    "sheetX": 35,
                    "sheetY": 30
                }
            },
            "tts": "woman biking",
            "keywords": [
                "bicycle",
                "biking",
                "cyclist",
                "person biking",
                "woman"
            ]
        },
        {
            "unified": "1F6B5-200D-2642-FE0F",
            "name": null,
            "native": "🚵‍♂️",
            "shortName": "man-mountain-biking",
            "shortNames": [
                "man-mountain-biking"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 35,
            "sheetY": 49,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F6B5-1F3FB-200D-2642-FE0F",
                    "native": "🚵🏻‍♂️",
                    "sheetX": 35,
                    "sheetY": 50
                },
                "1F3FC": {
                    "unified": "1F6B5-1F3FC-200D-2642-FE0F",
                    "native": "🚵🏼‍♂️",
                    "sheetX": 35,
                    "sheetY": 51
                },
                "1F3FD": {
                    "unified": "1F6B5-1F3FD-200D-2642-FE0F",
                    "native": "🚵🏽‍♂️",
                    "sheetX": 36,
                    "sheetY": 0
                },
                "1F3FE": {
                    "unified": "1F6B5-1F3FE-200D-2642-FE0F",
                    "native": "🚵🏾‍♂️",
                    "sheetX": 36,
                    "sheetY": 1
                },
                "1F3FF": {
                    "unified": "1F6B5-1F3FF-200D-2642-FE0F",
                    "native": "🚵🏿‍♂️",
                    "sheetX": 36,
                    "sheetY": 2
                }
            },
            "tts": "man mountain biking",
            "keywords": [
                "bicycle",
                "bicyclist",
                "bike",
                "cyclist",
                "mountain",
                "person mountain biking",
                "man",
                "man mountain biking"
            ]
        },
        {
            "unified": "1F6B5-200D-2640-FE0F",
            "name": null,
            "native": "🚵‍♀️",
            "shortName": "woman-mountain-biking",
            "shortNames": [
                "woman-mountain-biking"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 35,
            "sheetY": 43,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F6B5-1F3FB-200D-2640-FE0F",
                    "native": "🚵🏻‍♀️",
                    "sheetX": 35,
                    "sheetY": 44
                },
                "1F3FC": {
                    "unified": "1F6B5-1F3FC-200D-2640-FE0F",
                    "native": "🚵🏼‍♀️",
                    "sheetX": 35,
                    "sheetY": 45
                },
                "1F3FD": {
                    "unified": "1F6B5-1F3FD-200D-2640-FE0F",
                    "native": "🚵🏽‍♀️",
                    "sheetX": 35,
                    "sheetY": 46
                },
                "1F3FE": {
                    "unified": "1F6B5-1F3FE-200D-2640-FE0F",
                    "native": "🚵🏾‍♀️",
                    "sheetX": 35,
                    "sheetY": 47
                },
                "1F3FF": {
                    "unified": "1F6B5-1F3FF-200D-2640-FE0F",
                    "native": "🚵🏿‍♀️",
                    "sheetX": 35,
                    "sheetY": 48
                }
            },
            "tts": "woman mountain biking",
            "keywords": [
                "bicycle",
                "bicyclist",
                "bike",
                "cyclist",
                "mountain",
                "person mountain biking",
                "biking",
                "woman"
            ]
        },
        {
            "unified": "1F3CE-FE0F",
            "name": null,
            "native": "🏎️",
            "shortName": "racing_car",
            "shortNames": [
                "racing_car"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 11,
            "sheetY": 31
        },
        {
            "unified": "1F3CD-FE0F",
            "name": null,
            "native": "🏍️",
            "shortName": "racing_motorcycle",
            "shortNames": [
                "racing_motorcycle"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 11,
            "sheetY": 30
        },
        {
            "unified": "1F938-200D-2642-FE0F",
            "name": null,
            "native": "🤸‍♂️",
            "shortName": "man-cartwheeling",
            "shortNames": [
                "man-cartwheeling"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 40,
            "sheetY": 18,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F938-1F3FB-200D-2642-FE0F",
                    "native": "🤸🏻‍♂️",
                    "sheetX": 40,
                    "sheetY": 19
                },
                "1F3FC": {
                    "unified": "1F938-1F3FC-200D-2642-FE0F",
                    "native": "🤸🏼‍♂️",
                    "sheetX": 40,
                    "sheetY": 20
                },
                "1F3FD": {
                    "unified": "1F938-1F3FD-200D-2642-FE0F",
                    "native": "🤸🏽‍♂️",
                    "sheetX": 40,
                    "sheetY": 21
                },
                "1F3FE": {
                    "unified": "1F938-1F3FE-200D-2642-FE0F",
                    "native": "🤸🏾‍♂️",
                    "sheetX": 40,
                    "sheetY": 22
                },
                "1F3FF": {
                    "unified": "1F938-1F3FF-200D-2642-FE0F",
                    "native": "🤸🏿‍♂️",
                    "sheetX": 40,
                    "sheetY": 23
                }
            },
            "tts": "man cartwheeling",
            "keywords": [
                "cartwheel",
                "gymnastics",
                "person cartwheeling",
                "man",
                "man cartwheeling"
            ]
        },
        {
            "unified": "1F938-200D-2640-FE0F",
            "name": null,
            "native": "🤸‍♀️",
            "shortName": "woman-cartwheeling",
            "shortNames": [
                "woman-cartwheeling"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 40,
            "sheetY": 12,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F938-1F3FB-200D-2640-FE0F",
                    "native": "🤸🏻‍♀️",
                    "sheetX": 40,
                    "sheetY": 13
                },
                "1F3FC": {
                    "unified": "1F938-1F3FC-200D-2640-FE0F",
                    "native": "🤸🏼‍♀️",
                    "sheetX": 40,
                    "sheetY": 14
                },
                "1F3FD": {
                    "unified": "1F938-1F3FD-200D-2640-FE0F",
                    "native": "🤸🏽‍♀️",
                    "sheetX": 40,
                    "sheetY": 15
                },
                "1F3FE": {
                    "unified": "1F938-1F3FE-200D-2640-FE0F",
                    "native": "🤸🏾‍♀️",
                    "sheetX": 40,
                    "sheetY": 16
                },
                "1F3FF": {
                    "unified": "1F938-1F3FF-200D-2640-FE0F",
                    "native": "🤸🏿‍♀️",
                    "sheetX": 40,
                    "sheetY": 17
                }
            },
            "tts": "woman cartwheeling",
            "keywords": [
                "cartwheel",
                "gymnastics",
                "person cartwheeling",
                "woman",
                "woman cartwheeling"
            ]
        },
        {
            "unified": "1F93C-200D-2642-FE0F",
            "name": null,
            "native": "🤼‍♂️",
            "shortName": "man-wrestling",
            "shortNames": [
                "man-wrestling"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 40,
            "sheetY": 50,
            "tts": "men wrestling",
            "keywords": [
                "people wrestling",
                "wrestle",
                "wrestler",
                "men",
                "men wrestling"
            ]
        },
        {
            "unified": "1F93C-200D-2640-FE0F",
            "name": null,
            "native": "🤼‍♀️",
            "shortName": "woman-wrestling",
            "shortNames": [
                "woman-wrestling"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 40,
            "sheetY": 49,
            "tts": "women wrestling",
            "keywords": [
                "people wrestling",
                "wrestle",
                "wrestler",
                "women",
                "women wrestling"
            ]
        },
        {
            "unified": "1F93D-200D-2642-FE0F",
            "name": null,
            "native": "🤽‍♂️",
            "shortName": "man-playing-water-polo",
            "shortNames": [
                "man-playing-water-polo"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 41,
            "sheetY": 6,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F93D-1F3FB-200D-2642-FE0F",
                    "native": "🤽🏻‍♂️",
                    "sheetX": 41,
                    "sheetY": 7
                },
                "1F3FC": {
                    "unified": "1F93D-1F3FC-200D-2642-FE0F",
                    "native": "🤽🏼‍♂️",
                    "sheetX": 41,
                    "sheetY": 8
                },
                "1F3FD": {
                    "unified": "1F93D-1F3FD-200D-2642-FE0F",
                    "native": "🤽🏽‍♂️",
                    "sheetX": 41,
                    "sheetY": 9
                },
                "1F3FE": {
                    "unified": "1F93D-1F3FE-200D-2642-FE0F",
                    "native": "🤽🏾‍♂️",
                    "sheetX": 41,
                    "sheetY": 10
                },
                "1F3FF": {
                    "unified": "1F93D-1F3FF-200D-2642-FE0F",
                    "native": "🤽🏿‍♂️",
                    "sheetX": 41,
                    "sheetY": 11
                }
            },
            "tts": "man playing water polo",
            "keywords": [
                "person playing water polo",
                "polo",
                "water",
                "man",
                "man playing water polo",
                "water polo"
            ]
        },
        {
            "unified": "1F93D-200D-2640-FE0F",
            "name": null,
            "native": "🤽‍♀️",
            "shortName": "woman-playing-water-polo",
            "shortNames": [
                "woman-playing-water-polo"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 41,
            "sheetY": 0,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F93D-1F3FB-200D-2640-FE0F",
                    "native": "🤽🏻‍♀️",
                    "sheetX": 41,
                    "sheetY": 1
                },
                "1F3FC": {
                    "unified": "1F93D-1F3FC-200D-2640-FE0F",
                    "native": "🤽🏼‍♀️",
                    "sheetX": 41,
                    "sheetY": 2
                },
                "1F3FD": {
                    "unified": "1F93D-1F3FD-200D-2640-FE0F",
                    "native": "🤽🏽‍♀️",
                    "sheetX": 41,
                    "sheetY": 3
                },
                "1F3FE": {
                    "unified": "1F93D-1F3FE-200D-2640-FE0F",
                    "native": "🤽🏾‍♀️",
                    "sheetX": 41,
                    "sheetY": 4
                },
                "1F3FF": {
                    "unified": "1F93D-1F3FF-200D-2640-FE0F",
                    "native": "🤽🏿‍♀️",
                    "sheetX": 41,
                    "sheetY": 5
                }
            },
            "tts": "woman playing water polo",
            "keywords": [
                "person playing water polo",
                "polo",
                "water",
                "water polo",
                "woman",
                "woman playing water polo"
            ]
        },
        {
            "unified": "1F93E-200D-2642-FE0F",
            "name": null,
            "native": "🤾‍♂️",
            "shortName": "man-playing-handball",
            "shortNames": [
                "man-playing-handball"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 41,
            "sheetY": 24,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F93E-1F3FB-200D-2642-FE0F",
                    "native": "🤾🏻‍♂️",
                    "sheetX": 41,
                    "sheetY": 25
                },
                "1F3FC": {
                    "unified": "1F93E-1F3FC-200D-2642-FE0F",
                    "native": "🤾🏼‍♂️",
                    "sheetX": 41,
                    "sheetY": 26
                },
                "1F3FD": {
                    "unified": "1F93E-1F3FD-200D-2642-FE0F",
                    "native": "🤾🏽‍♂️",
                    "sheetX": 41,
                    "sheetY": 27
                },
                "1F3FE": {
                    "unified": "1F93E-1F3FE-200D-2642-FE0F",
                    "native": "🤾🏾‍♂️",
                    "sheetX": 41,
                    "sheetY": 28
                },
                "1F3FF": {
                    "unified": "1F93E-1F3FF-200D-2642-FE0F",
                    "native": "🤾🏿‍♂️",
                    "sheetX": 41,
                    "sheetY": 29
                }
            },
            "tts": "man playing handball",
            "keywords": [
                "ball",
                "handball",
                "person playing handball",
                "man",
                "man playing handball"
            ]
        },
        {
            "unified": "1F93E-200D-2640-FE0F",
            "name": null,
            "native": "🤾‍♀️",
            "shortName": "woman-playing-handball",
            "shortNames": [
                "woman-playing-handball"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 41,
            "sheetY": 18,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F93E-1F3FB-200D-2640-FE0F",
                    "native": "🤾🏻‍♀️",
                    "sheetX": 41,
                    "sheetY": 19
                },
                "1F3FC": {
                    "unified": "1F93E-1F3FC-200D-2640-FE0F",
                    "native": "🤾🏼‍♀️",
                    "sheetX": 41,
                    "sheetY": 20
                },
                "1F3FD": {
                    "unified": "1F93E-1F3FD-200D-2640-FE0F",
                    "native": "🤾🏽‍♀️",
                    "sheetX": 41,
                    "sheetY": 21
                },
                "1F3FE": {
                    "unified": "1F93E-1F3FE-200D-2640-FE0F",
                    "native": "🤾🏾‍♀️",
                    "sheetX": 41,
                    "sheetY": 22
                },
                "1F3FF": {
                    "unified": "1F93E-1F3FF-200D-2640-FE0F",
                    "native": "🤾🏿‍♀️",
                    "sheetX": 41,
                    "sheetY": 23
                }
            },
            "tts": "woman playing handball",
            "keywords": [
                "ball",
                "handball",
                "person playing handball",
                "woman",
                "woman playing handball"
            ]
        },
        {
            "unified": "1F939-200D-2642-FE0F",
            "name": null,
            "native": "🤹‍♂️",
            "shortName": "man-juggling",
            "shortNames": [
                "man-juggling"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 40,
            "sheetY": 36,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F939-1F3FB-200D-2642-FE0F",
                    "native": "🤹🏻‍♂️",
                    "sheetX": 40,
                    "sheetY": 37
                },
                "1F3FC": {
                    "unified": "1F939-1F3FC-200D-2642-FE0F",
                    "native": "🤹🏼‍♂️",
                    "sheetX": 40,
                    "sheetY": 38
                },
                "1F3FD": {
                    "unified": "1F939-1F3FD-200D-2642-FE0F",
                    "native": "🤹🏽‍♂️",
                    "sheetX": 40,
                    "sheetY": 39
                },
                "1F3FE": {
                    "unified": "1F939-1F3FE-200D-2642-FE0F",
                    "native": "🤹🏾‍♂️",
                    "sheetX": 40,
                    "sheetY": 40
                },
                "1F3FF": {
                    "unified": "1F939-1F3FF-200D-2642-FE0F",
                    "native": "🤹🏿‍♂️",
                    "sheetX": 40,
                    "sheetY": 41
                }
            },
            "tts": "man juggling",
            "keywords": [
                "balance",
                "juggle",
                "multitask",
                "person juggling",
                "skill",
                "juggling",
                "man"
            ]
        },
        {
            "unified": "1F939-200D-2640-FE0F",
            "name": null,
            "native": "🤹‍♀️",
            "shortName": "woman-juggling",
            "shortNames": [
                "woman-juggling"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 40,
            "sheetY": 30,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F939-1F3FB-200D-2640-FE0F",
                    "native": "🤹🏻‍♀️",
                    "sheetX": 40,
                    "sheetY": 31
                },
                "1F3FC": {
                    "unified": "1F939-1F3FC-200D-2640-FE0F",
                    "native": "🤹🏼‍♀️",
                    "sheetX": 40,
                    "sheetY": 32
                },
                "1F3FD": {
                    "unified": "1F939-1F3FD-200D-2640-FE0F",
                    "native": "🤹🏽‍♀️",
                    "sheetX": 40,
                    "sheetY": 33
                },
                "1F3FE": {
                    "unified": "1F939-1F3FE-200D-2640-FE0F",
                    "native": "🤹🏾‍♀️",
                    "sheetX": 40,
                    "sheetY": 34
                },
                "1F3FF": {
                    "unified": "1F939-1F3FF-200D-2640-FE0F",
                    "native": "🤹🏿‍♀️",
                    "sheetX": 40,
                    "sheetY": 35
                }
            },
            "tts": "woman juggling",
            "keywords": [
                "balance",
                "juggle",
                "multitask",
                "person juggling",
                "skill",
                "juggling",
                "woman"
            ]
        },
        {
            "unified": "1F46B",
            "name": "MAN AND WOMAN HOLDING HANDS",
            "native": "👫",
            "shortName": "couple",
            "shortNames": [
                "couple",
                "man_and_woman_holding_hands"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 20,
            "sheetY": 30,
            "tts": "man and woman holding hands",
            "keywords": [
                "couple",
                "hand",
                "hold",
                "man",
                "man and woman holding hands",
                "woman"
            ]
        },
        {
            "unified": "1F46C",
            "name": "TWO MEN HOLDING HANDS",
            "native": "👬",
            "shortName": "two_men_holding_hands",
            "shortNames": [
                "two_men_holding_hands"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 20,
            "sheetY": 31,
            "tts": "two men holding hands",
            "keywords": [
                "couple",
                "Gemini",
                "hand",
                "hold",
                "man",
                "twins",
                "two men holding hands",
                "zodiac"
            ]
        },
        {
            "unified": "1F46D",
            "name": "TWO WOMEN HOLDING HANDS",
            "native": "👭",
            "shortName": "two_women_holding_hands",
            "shortNames": [
                "two_women_holding_hands"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 20,
            "sheetY": 32,
            "tts": "two women holding hands",
            "keywords": [
                "couple",
                "hand",
                "hold",
                "two women holding hands",
                "woman"
            ]
        },
        {
            "unified": "1F48F",
            "name": "KISS",
            "native": "💏",
            "shortName": "couplekiss",
            "shortNames": [
                "couplekiss"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 24,
            "sheetY": 41,
            "tts": "kiss",
            "keywords": [
                "couple",
                "kiss"
            ]
        },
        {
            "unified": "1F469-200D-2764-FE0F-200D-1F48B-200D-1F468",
            "name": null,
            "native": "👩‍❤️‍💋‍👨",
            "shortName": "woman-kiss-man",
            "shortNames": [
                "woman-kiss-man"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 20,
            "sheetY": 21
        },
        {
            "unified": "1F468-200D-2764-FE0F-200D-1F48B-200D-1F468",
            "name": null,
            "native": "👨‍❤️‍💋‍👨",
            "shortName": "man-kiss-man",
            "shortNames": [
                "man-kiss-man"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 18,
            "sheetY": 10
        },
        {
            "unified": "1F469-200D-2764-FE0F-200D-1F48B-200D-1F469",
            "name": null,
            "native": "👩‍❤️‍💋‍👩",
            "shortName": "woman-kiss-woman",
            "shortNames": [
                "woman-kiss-woman"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 20,
            "sheetY": 22
        },
        {
            "unified": "1F491",
            "name": "COUPLE WITH HEART",
            "native": "💑",
            "shortName": "couple_with_heart",
            "shortNames": [
                "couple_with_heart"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 24,
            "sheetY": 43,
            "tts": "couple with heart",
            "keywords": [
                "couple",
                "couple with heart",
                "love"
            ]
        },
        {
            "unified": "1F469-200D-2764-FE0F-200D-1F468",
            "name": null,
            "native": "👩‍❤️‍👨",
            "shortName": "woman-heart-man",
            "shortNames": [
                "woman-heart-man"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 20,
            "sheetY": 19
        },
        {
            "unified": "1F468-200D-2764-FE0F-200D-1F468",
            "name": null,
            "native": "👨‍❤️‍👨",
            "shortName": "man-heart-man",
            "shortNames": [
                "man-heart-man"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 18,
            "sheetY": 9
        },
        {
            "unified": "1F469-200D-2764-FE0F-200D-1F469",
            "name": null,
            "native": "👩‍❤️‍👩",
            "shortName": "woman-heart-woman",
            "shortNames": [
                "woman-heart-woman"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 20,
            "sheetY": 20
        },
        {
            "unified": "1F46A",
            "name": "FAMILY",
            "native": "👪",
            "shortName": "family",
            "shortNames": [
                "family",
                "man-woman-boy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 20,
            "sheetY": 29,
            "tts": "family",
            "keywords": [
                "family"
            ]
        },
        {
            "unified": "1F468-200D-1F469-200D-1F466",
            "name": null,
            "native": "👨‍👩‍👦",
            "shortName": "man-woman-boy",
            "shortNames": [
                "man-woman-boy",
                "family"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 17,
            "sheetY": 2
        },
        {
            "unified": "1F468-200D-1F469-200D-1F467",
            "name": null,
            "native": "👨‍👩‍👧",
            "shortName": "man-woman-girl",
            "shortNames": [
                "man-woman-girl"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 17,
            "sheetY": 4
        },
        {
            "unified": "1F468-200D-1F469-200D-1F467-200D-1F466",
            "name": null,
            "native": "👨‍👩‍👧‍👦",
            "shortName": "man-woman-girl-boy",
            "shortNames": [
                "man-woman-girl-boy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 17,
            "sheetY": 5
        },
        {
            "unified": "1F468-200D-1F469-200D-1F466-200D-1F466",
            "name": null,
            "native": "👨‍👩‍👦‍👦",
            "shortName": "man-woman-boy-boy",
            "shortNames": [
                "man-woman-boy-boy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 17,
            "sheetY": 3
        },
        {
            "unified": "1F468-200D-1F469-200D-1F467-200D-1F467",
            "name": null,
            "native": "👨‍👩‍👧‍👧",
            "shortName": "man-woman-girl-girl",
            "shortNames": [
                "man-woman-girl-girl"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 17,
            "sheetY": 6
        },
        {
            "unified": "1F468-200D-1F468-200D-1F466",
            "name": null,
            "native": "👨‍👨‍👦",
            "shortName": "man-man-boy",
            "shortNames": [
                "man-man-boy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 16,
            "sheetY": 49
        },
        {
            "unified": "1F468-200D-1F468-200D-1F467",
            "name": null,
            "native": "👨‍👨‍👧",
            "shortName": "man-man-girl",
            "shortNames": [
                "man-man-girl"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 16,
            "sheetY": 51
        },
        {
            "unified": "1F468-200D-1F468-200D-1F467-200D-1F466",
            "name": null,
            "native": "👨‍👨‍👧‍👦",
            "shortName": "man-man-girl-boy",
            "shortNames": [
                "man-man-girl-boy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 17,
            "sheetY": 0
        },
        {
            "unified": "1F468-200D-1F468-200D-1F466-200D-1F466",
            "name": null,
            "native": "👨‍👨‍👦‍👦",
            "shortName": "man-man-boy-boy",
            "shortNames": [
                "man-man-boy-boy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 16,
            "sheetY": 50
        },
        {
            "unified": "1F468-200D-1F468-200D-1F467-200D-1F467",
            "name": null,
            "native": "👨‍👨‍👧‍👧",
            "shortName": "man-man-girl-girl",
            "shortNames": [
                "man-man-girl-girl"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 17,
            "sheetY": 1
        },
        {
            "unified": "1F469-200D-1F469-200D-1F466",
            "name": null,
            "native": "👩‍👩‍👦",
            "shortName": "woman-woman-boy",
            "shortNames": [
                "woman-woman-boy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 19,
            "sheetY": 12
        },
        {
            "unified": "1F469-200D-1F469-200D-1F467",
            "name": null,
            "native": "👩‍👩‍👧",
            "shortName": "woman-woman-girl",
            "shortNames": [
                "woman-woman-girl"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 19,
            "sheetY": 14
        },
        {
            "unified": "1F469-200D-1F469-200D-1F467-200D-1F466",
            "name": null,
            "native": "👩‍👩‍👧‍👦",
            "shortName": "woman-woman-girl-boy",
            "shortNames": [
                "woman-woman-girl-boy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 19,
            "sheetY": 15
        },
        {
            "unified": "1F469-200D-1F469-200D-1F466-200D-1F466",
            "name": null,
            "native": "👩‍👩‍👦‍👦",
            "shortName": "woman-woman-boy-boy",
            "shortNames": [
                "woman-woman-boy-boy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 19,
            "sheetY": 13
        },
        {
            "unified": "1F469-200D-1F469-200D-1F467-200D-1F467",
            "name": null,
            "native": "👩‍👩‍👧‍👧",
            "shortName": "woman-woman-girl-girl",
            "shortNames": [
                "woman-woman-girl-girl"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 19,
            "sheetY": 16
        },
        {
            "unified": "1F468-200D-1F466",
            "name": null,
            "native": "👨‍👦",
            "shortName": "man-boy",
            "shortNames": [
                "man-boy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 16,
            "sheetY": 45
        },
        {
            "unified": "1F468-200D-1F466-200D-1F466",
            "name": null,
            "native": "👨‍👦‍👦",
            "shortName": "man-boy-boy",
            "shortNames": [
                "man-boy-boy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 16,
            "sheetY": 44
        },
        {
            "unified": "1F468-200D-1F467",
            "name": null,
            "native": "👨‍👧",
            "shortName": "man-girl",
            "shortNames": [
                "man-girl"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 16,
            "sheetY": 48
        },
        {
            "unified": "1F468-200D-1F467-200D-1F466",
            "name": null,
            "native": "👨‍👧‍👦",
            "shortName": "man-girl-boy",
            "shortNames": [
                "man-girl-boy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 16,
            "sheetY": 46
        },
        {
            "unified": "1F468-200D-1F467-200D-1F467",
            "name": null,
            "native": "👨‍👧‍👧",
            "shortName": "man-girl-girl",
            "shortNames": [
                "man-girl-girl"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 16,
            "sheetY": 47
        },
        {
            "unified": "1F469-200D-1F466",
            "name": null,
            "native": "👩‍👦",
            "shortName": "woman-boy",
            "shortNames": [
                "woman-boy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 19,
            "sheetY": 8
        },
        {
            "unified": "1F469-200D-1F466-200D-1F466",
            "name": null,
            "native": "👩‍👦‍👦",
            "shortName": "woman-boy-boy",
            "shortNames": [
                "woman-boy-boy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 19,
            "sheetY": 7
        },
        {
            "unified": "1F469-200D-1F467",
            "name": null,
            "native": "👩‍👧",
            "shortName": "woman-girl",
            "shortNames": [
                "woman-girl"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 19,
            "sheetY": 11
        },
        {
            "unified": "1F469-200D-1F467-200D-1F466",
            "name": null,
            "native": "👩‍👧‍👦",
            "shortName": "woman-girl-boy",
            "shortNames": [
                "woman-girl-boy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 19,
            "sheetY": 9
        },
        {
            "unified": "1F469-200D-1F467-200D-1F467",
            "name": null,
            "native": "👩‍👧‍👧",
            "shortName": "woman-girl-girl",
            "shortNames": [
                "woman-girl-girl"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 19,
            "sheetY": 10
        },
        {
            "unified": "1F933",
            "name": "SELFIE",
            "native": "🤳",
            "shortName": "selfie",
            "shortNames": [
                "selfie"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 39,
            "sheetY": 22,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F933-1F3FB",
                    "native": "🤳🏻",
                    "sheetX": 39,
                    "sheetY": 23
                },
                "1F3FC": {
                    "unified": "1F933-1F3FC",
                    "native": "🤳🏼",
                    "sheetX": 39,
                    "sheetY": 24
                },
                "1F3FD": {
                    "unified": "1F933-1F3FD",
                    "native": "🤳🏽",
                    "sheetX": 39,
                    "sheetY": 25
                },
                "1F3FE": {
                    "unified": "1F933-1F3FE",
                    "native": "🤳🏾",
                    "sheetX": 39,
                    "sheetY": 26
                },
                "1F3FF": {
                    "unified": "1F933-1F3FF",
                    "native": "🤳🏿",
                    "sheetX": 39,
                    "sheetY": 27
                }
            },
            "tts": "selfie",
            "keywords": [
                "camera",
                "phone",
                "selfie"
            ]
        },
        {
            "unified": "1F4AA",
            "name": "FLEXED BICEPS",
            "native": "💪",
            "shortName": "muscle",
            "shortNames": [
                "muscle"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 25,
            "sheetY": 16,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F4AA-1F3FB",
                    "native": "💪🏻",
                    "sheetX": 25,
                    "sheetY": 17
                },
                "1F3FC": {
                    "unified": "1F4AA-1F3FC",
                    "native": "💪🏼",
                    "sheetX": 25,
                    "sheetY": 18
                },
                "1F3FD": {
                    "unified": "1F4AA-1F3FD",
                    "native": "💪🏽",
                    "sheetX": 25,
                    "sheetY": 19
                },
                "1F3FE": {
                    "unified": "1F4AA-1F3FE",
                    "native": "💪🏾",
                    "sheetX": 25,
                    "sheetY": 20
                },
                "1F3FF": {
                    "unified": "1F4AA-1F3FF",
                    "native": "💪🏿",
                    "sheetX": 25,
                    "sheetY": 21
                }
            },
            "tts": "flexed biceps",
            "keywords": [
                "biceps",
                "comic",
                "flex",
                "flexed biceps",
                "muscle"
            ]
        },
        {
            "unified": "1F448",
            "name": "WHITE LEFT POINTING BACKHAND INDEX",
            "native": "👈",
            "shortName": "point_left",
            "shortNames": [
                "point_left"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 14,
            "sheetY": 19,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F448-1F3FB",
                    "native": "👈🏻",
                    "sheetX": 14,
                    "sheetY": 20
                },
                "1F3FC": {
                    "unified": "1F448-1F3FC",
                    "native": "👈🏼",
                    "sheetX": 14,
                    "sheetY": 21
                },
                "1F3FD": {
                    "unified": "1F448-1F3FD",
                    "native": "👈🏽",
                    "sheetX": 14,
                    "sheetY": 22
                },
                "1F3FE": {
                    "unified": "1F448-1F3FE",
                    "native": "👈🏾",
                    "sheetX": 14,
                    "sheetY": 23
                },
                "1F3FF": {
                    "unified": "1F448-1F3FF",
                    "native": "👈🏿",
                    "sheetX": 14,
                    "sheetY": 24
                }
            },
            "tts": "backhand index pointing left",
            "keywords": [
                "backhand",
                "backhand index pointing left",
                "finger",
                "hand",
                "index",
                "point"
            ]
        },
        {
            "unified": "1F449",
            "name": "WHITE RIGHT POINTING BACKHAND INDEX",
            "native": "👉",
            "shortName": "point_right",
            "shortNames": [
                "point_right"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 14,
            "sheetY": 25,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F449-1F3FB",
                    "native": "👉🏻",
                    "sheetX": 14,
                    "sheetY": 26
                },
                "1F3FC": {
                    "unified": "1F449-1F3FC",
                    "native": "👉🏼",
                    "sheetX": 14,
                    "sheetY": 27
                },
                "1F3FD": {
                    "unified": "1F449-1F3FD",
                    "native": "👉🏽",
                    "sheetX": 14,
                    "sheetY": 28
                },
                "1F3FE": {
                    "unified": "1F449-1F3FE",
                    "native": "👉🏾",
                    "sheetX": 14,
                    "sheetY": 29
                },
                "1F3FF": {
                    "unified": "1F449-1F3FF",
                    "native": "👉🏿",
                    "sheetX": 14,
                    "sheetY": 30
                }
            },
            "tts": "backhand index pointing right",
            "keywords": [
                "backhand",
                "backhand index pointing right",
                "finger",
                "hand",
                "index",
                "point"
            ]
        },
        {
            "unified": "261D-FE0F",
            "name": "WHITE UP POINTING INDEX",
            "native": "☝️",
            "shortName": "point_up",
            "shortNames": [
                "point_up"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 47,
            "sheetY": 26,
            "skinVariations": {
                "1F3FB": {
                    "unified": "261D-1F3FB",
                    "native": "☝🏻",
                    "sheetX": 47,
                    "sheetY": 27
                },
                "1F3FC": {
                    "unified": "261D-1F3FC",
                    "native": "☝🏼",
                    "sheetX": 47,
                    "sheetY": 28
                },
                "1F3FD": {
                    "unified": "261D-1F3FD",
                    "native": "☝🏽",
                    "sheetX": 47,
                    "sheetY": 29
                },
                "1F3FE": {
                    "unified": "261D-1F3FE",
                    "native": "☝🏾",
                    "sheetX": 47,
                    "sheetY": 30
                },
                "1F3FF": {
                    "unified": "261D-1F3FF",
                    "native": "☝🏿",
                    "sheetX": 47,
                    "sheetY": 31
                }
            }
        },
        {
            "unified": "1F446",
            "name": "WHITE UP POINTING BACKHAND INDEX",
            "native": "👆",
            "shortName": "point_up_2",
            "shortNames": [
                "point_up_2"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 14,
            "sheetY": 7,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F446-1F3FB",
                    "native": "👆🏻",
                    "sheetX": 14,
                    "sheetY": 8
                },
                "1F3FC": {
                    "unified": "1F446-1F3FC",
                    "native": "👆🏼",
                    "sheetX": 14,
                    "sheetY": 9
                },
                "1F3FD": {
                    "unified": "1F446-1F3FD",
                    "native": "👆🏽",
                    "sheetX": 14,
                    "sheetY": 10
                },
                "1F3FE": {
                    "unified": "1F446-1F3FE",
                    "native": "👆🏾",
                    "sheetX": 14,
                    "sheetY": 11
                },
                "1F3FF": {
                    "unified": "1F446-1F3FF",
                    "native": "👆🏿",
                    "sheetX": 14,
                    "sheetY": 12
                }
            },
            "tts": "backhand index pointing up",
            "keywords": [
                "backhand",
                "backhand index pointing up",
                "finger",
                "hand",
                "index",
                "point",
                "up"
            ]
        },
        {
            "unified": "1F595",
            "name": "REVERSED HAND WITH MIDDLE FINGER EXTENDED",
            "native": "🖕",
            "shortName": "middle_finger",
            "shortNames": [
                "middle_finger",
                "reversed_hand_with_middle_finger_extended"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 29,
            "sheetY": 38,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F595-1F3FB",
                    "native": "🖕🏻",
                    "sheetX": 29,
                    "sheetY": 39
                },
                "1F3FC": {
                    "unified": "1F595-1F3FC",
                    "native": "🖕🏼",
                    "sheetX": 29,
                    "sheetY": 40
                },
                "1F3FD": {
                    "unified": "1F595-1F3FD",
                    "native": "🖕🏽",
                    "sheetX": 29,
                    "sheetY": 41
                },
                "1F3FE": {
                    "unified": "1F595-1F3FE",
                    "native": "🖕🏾",
                    "sheetX": 29,
                    "sheetY": 42
                },
                "1F3FF": {
                    "unified": "1F595-1F3FF",
                    "native": "🖕🏿",
                    "sheetX": 29,
                    "sheetY": 43
                }
            },
            "tts": "middle finger",
            "keywords": [
                "finger",
                "hand",
                "middle finger"
            ]
        },
        {
            "unified": "1F447",
            "name": "WHITE DOWN POINTING BACKHAND INDEX",
            "native": "👇",
            "shortName": "point_down",
            "shortNames": [
                "point_down"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 14,
            "sheetY": 13,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F447-1F3FB",
                    "native": "👇🏻",
                    "sheetX": 14,
                    "sheetY": 14
                },
                "1F3FC": {
                    "unified": "1F447-1F3FC",
                    "native": "👇🏼",
                    "sheetX": 14,
                    "sheetY": 15
                },
                "1F3FD": {
                    "unified": "1F447-1F3FD",
                    "native": "👇🏽",
                    "sheetX": 14,
                    "sheetY": 16
                },
                "1F3FE": {
                    "unified": "1F447-1F3FE",
                    "native": "👇🏾",
                    "sheetX": 14,
                    "sheetY": 17
                },
                "1F3FF": {
                    "unified": "1F447-1F3FF",
                    "native": "👇🏿",
                    "sheetX": 14,
                    "sheetY": 18
                }
            },
            "tts": "backhand index pointing down",
            "keywords": [
                "backhand",
                "backhand index pointing down",
                "down",
                "finger",
                "hand",
                "index",
                "point"
            ]
        },
        {
            "unified": "270C-FE0F",
            "name": "VICTORY HAND",
            "native": "✌️",
            "shortName": "v",
            "shortNames": [
                "v"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 49,
            "sheetY": 30,
            "skinVariations": {
                "1F3FB": {
                    "unified": "270C-1F3FB",
                    "native": "✌🏻",
                    "sheetX": 49,
                    "sheetY": 31
                },
                "1F3FC": {
                    "unified": "270C-1F3FC",
                    "native": "✌🏼",
                    "sheetX": 49,
                    "sheetY": 32
                },
                "1F3FD": {
                    "unified": "270C-1F3FD",
                    "native": "✌🏽",
                    "sheetX": 49,
                    "sheetY": 33
                },
                "1F3FE": {
                    "unified": "270C-1F3FE",
                    "native": "✌🏾",
                    "sheetX": 49,
                    "sheetY": 34
                },
                "1F3FF": {
                    "unified": "270C-1F3FF",
                    "native": "✌🏿",
                    "sheetX": 49,
                    "sheetY": 35
                }
            }
        },
        {
            "unified": "1F91E",
            "name": "HAND WITH INDEX AND MIDDLE FINGERS CROSSED",
            "native": "🤞",
            "shortName": "crossed_fingers",
            "shortNames": [
                "crossed_fingers",
                "hand_with_index_and_middle_fingers_crossed"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 38,
            "sheetY": 11,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F91E-1F3FB",
                    "native": "🤞🏻",
                    "sheetX": 38,
                    "sheetY": 12
                },
                "1F3FC": {
                    "unified": "1F91E-1F3FC",
                    "native": "🤞🏼",
                    "sheetX": 38,
                    "sheetY": 13
                },
                "1F3FD": {
                    "unified": "1F91E-1F3FD",
                    "native": "🤞🏽",
                    "sheetX": 38,
                    "sheetY": 14
                },
                "1F3FE": {
                    "unified": "1F91E-1F3FE",
                    "native": "🤞🏾",
                    "sheetX": 38,
                    "sheetY": 15
                },
                "1F3FF": {
                    "unified": "1F91E-1F3FF",
                    "native": "🤞🏿",
                    "sheetX": 38,
                    "sheetY": 16
                }
            },
            "tts": "crossed fingers",
            "keywords": [
                "cross",
                "crossed fingers",
                "finger",
                "hand",
                "luck"
            ]
        },
        {
            "unified": "1F596",
            "name": "RAISED HAND WITH PART BETWEEN MIDDLE AND RING FINGERS",
            "native": "🖖",
            "shortName": "spock-hand",
            "shortNames": [
                "spock-hand"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 29,
            "sheetY": 44,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F596-1F3FB",
                    "native": "🖖🏻",
                    "sheetX": 29,
                    "sheetY": 45
                },
                "1F3FC": {
                    "unified": "1F596-1F3FC",
                    "native": "🖖🏼",
                    "sheetX": 29,
                    "sheetY": 46
                },
                "1F3FD": {
                    "unified": "1F596-1F3FD",
                    "native": "🖖🏽",
                    "sheetX": 29,
                    "sheetY": 47
                },
                "1F3FE": {
                    "unified": "1F596-1F3FE",
                    "native": "🖖🏾",
                    "sheetX": 29,
                    "sheetY": 48
                },
                "1F3FF": {
                    "unified": "1F596-1F3FF",
                    "native": "🖖🏿",
                    "sheetX": 29,
                    "sheetY": 49
                }
            },
            "tts": "vulcan salute",
            "keywords": [
                "finger",
                "hand",
                "spock",
                "vulcan",
                "vulcan salute"
            ]
        },
        {
            "unified": "1F918",
            "name": "SIGN OF THE HORNS",
            "native": "🤘",
            "shortName": "the_horns",
            "shortNames": [
                "the_horns",
                "sign_of_the_horns"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 37,
            "sheetY": 32,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F918-1F3FB",
                    "native": "🤘🏻",
                    "sheetX": 37,
                    "sheetY": 33
                },
                "1F3FC": {
                    "unified": "1F918-1F3FC",
                    "native": "🤘🏼",
                    "sheetX": 37,
                    "sheetY": 34
                },
                "1F3FD": {
                    "unified": "1F918-1F3FD",
                    "native": "🤘🏽",
                    "sheetX": 37,
                    "sheetY": 35
                },
                "1F3FE": {
                    "unified": "1F918-1F3FE",
                    "native": "🤘🏾",
                    "sheetX": 37,
                    "sheetY": 36
                },
                "1F3FF": {
                    "unified": "1F918-1F3FF",
                    "native": "🤘🏿",
                    "sheetX": 37,
                    "sheetY": 37
                }
            },
            "tts": "sign of the horns",
            "keywords": [
                "finger",
                "hand",
                "horns",
                "rock-on",
                "sign of the horns"
            ]
        },
        {
            "unified": "1F919",
            "name": "CALL ME HAND",
            "native": "🤙",
            "shortName": "call_me_hand",
            "shortNames": [
                "call_me_hand"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 37,
            "sheetY": 38,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F919-1F3FB",
                    "native": "🤙🏻",
                    "sheetX": 37,
                    "sheetY": 39
                },
                "1F3FC": {
                    "unified": "1F919-1F3FC",
                    "native": "🤙🏼",
                    "sheetX": 37,
                    "sheetY": 40
                },
                "1F3FD": {
                    "unified": "1F919-1F3FD",
                    "native": "🤙🏽",
                    "sheetX": 37,
                    "sheetY": 41
                },
                "1F3FE": {
                    "unified": "1F919-1F3FE",
                    "native": "🤙🏾",
                    "sheetX": 37,
                    "sheetY": 42
                },
                "1F3FF": {
                    "unified": "1F919-1F3FF",
                    "native": "🤙🏿",
                    "sheetX": 37,
                    "sheetY": 43
                }
            },
            "tts": "call me hand",
            "keywords": [
                "call",
                "call me hand",
                "hand"
            ]
        },
        {
            "unified": "1F590-FE0F",
            "name": null,
            "native": "🖐️",
            "shortName": "raised_hand_with_fingers_splayed",
            "shortNames": [
                "raised_hand_with_fingers_splayed"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 29,
            "sheetY": 32,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F590-1F3FB",
                    "native": "🖐🏻",
                    "sheetX": 29,
                    "sheetY": 33
                },
                "1F3FC": {
                    "unified": "1F590-1F3FC",
                    "native": "🖐🏼",
                    "sheetX": 29,
                    "sheetY": 34
                },
                "1F3FD": {
                    "unified": "1F590-1F3FD",
                    "native": "🖐🏽",
                    "sheetX": 29,
                    "sheetY": 35
                },
                "1F3FE": {
                    "unified": "1F590-1F3FE",
                    "native": "🖐🏾",
                    "sheetX": 29,
                    "sheetY": 36
                },
                "1F3FF": {
                    "unified": "1F590-1F3FF",
                    "native": "🖐🏿",
                    "sheetX": 29,
                    "sheetY": 37
                }
            }
        },
        {
            "unified": "270B",
            "name": "RAISED HAND",
            "native": "✋",
            "shortName": "hand",
            "shortNames": [
                "hand",
                "raised_hand"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 49,
            "sheetY": 24,
            "skinVariations": {
                "1F3FB": {
                    "unified": "270B-1F3FB",
                    "native": "✋🏻",
                    "sheetX": 49,
                    "sheetY": 25
                },
                "1F3FC": {
                    "unified": "270B-1F3FC",
                    "native": "✋🏼",
                    "sheetX": 49,
                    "sheetY": 26
                },
                "1F3FD": {
                    "unified": "270B-1F3FD",
                    "native": "✋🏽",
                    "sheetX": 49,
                    "sheetY": 27
                },
                "1F3FE": {
                    "unified": "270B-1F3FE",
                    "native": "✋🏾",
                    "sheetX": 49,
                    "sheetY": 28
                },
                "1F3FF": {
                    "unified": "270B-1F3FF",
                    "native": "✋🏿",
                    "sheetX": 49,
                    "sheetY": 29
                }
            },
            "tts": "raised hand",
            "keywords": [
                "hand",
                "raised hand"
            ]
        },
        {
            "unified": "1F44C",
            "name": "OK HAND SIGN",
            "native": "👌",
            "shortName": "ok_hand",
            "shortNames": [
                "ok_hand"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 14,
            "sheetY": 43,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F44C-1F3FB",
                    "native": "👌🏻",
                    "sheetX": 14,
                    "sheetY": 44
                },
                "1F3FC": {
                    "unified": "1F44C-1F3FC",
                    "native": "👌🏼",
                    "sheetX": 14,
                    "sheetY": 45
                },
                "1F3FD": {
                    "unified": "1F44C-1F3FD",
                    "native": "👌🏽",
                    "sheetX": 14,
                    "sheetY": 46
                },
                "1F3FE": {
                    "unified": "1F44C-1F3FE",
                    "native": "👌🏾",
                    "sheetX": 14,
                    "sheetY": 47
                },
                "1F3FF": {
                    "unified": "1F44C-1F3FF",
                    "native": "👌🏿",
                    "sheetX": 14,
                    "sheetY": 48
                }
            },
            "tts": "OK hand",
            "keywords": [
                "hand",
                "OK"
            ]
        },
        {
            "unified": "1F44D",
            "name": "THUMBS UP SIGN",
            "native": "👍",
            "shortName": "+1",
            "shortNames": [
                "+1",
                "thumbsup"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 14,
            "sheetY": 49,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F44D-1F3FB",
                    "native": "👍🏻",
                    "sheetX": 14,
                    "sheetY": 50
                },
                "1F3FC": {
                    "unified": "1F44D-1F3FC",
                    "native": "👍🏼",
                    "sheetX": 14,
                    "sheetY": 51
                },
                "1F3FD": {
                    "unified": "1F44D-1F3FD",
                    "native": "👍🏽",
                    "sheetX": 15,
                    "sheetY": 0
                },
                "1F3FE": {
                    "unified": "1F44D-1F3FE",
                    "native": "👍🏾",
                    "sheetX": 15,
                    "sheetY": 1
                },
                "1F3FF": {
                    "unified": "1F44D-1F3FF",
                    "native": "👍🏿",
                    "sheetX": 15,
                    "sheetY": 2
                }
            },
            "tts": "thumbs up",
            "keywords": [
                "+1",
                "hand",
                "thumb",
                "thumbs up",
                "up"
            ]
        },
        {
            "unified": "1F44E",
            "name": "THUMBS DOWN SIGN",
            "native": "👎",
            "shortName": "-1",
            "shortNames": [
                "-1",
                "thumbsdown"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 3,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F44E-1F3FB",
                    "native": "👎🏻",
                    "sheetX": 15,
                    "sheetY": 4
                },
                "1F3FC": {
                    "unified": "1F44E-1F3FC",
                    "native": "👎🏼",
                    "sheetX": 15,
                    "sheetY": 5
                },
                "1F3FD": {
                    "unified": "1F44E-1F3FD",
                    "native": "👎🏽",
                    "sheetX": 15,
                    "sheetY": 6
                },
                "1F3FE": {
                    "unified": "1F44E-1F3FE",
                    "native": "👎🏾",
                    "sheetX": 15,
                    "sheetY": 7
                },
                "1F3FF": {
                    "unified": "1F44E-1F3FF",
                    "native": "👎🏿",
                    "sheetX": 15,
                    "sheetY": 8
                }
            },
            "tts": "thumbs down",
            "keywords": [
                "-1",
                "down",
                "hand",
                "thumb",
                "thumbs down"
            ]
        },
        {
            "unified": "270A",
            "name": "RAISED FIST",
            "native": "✊",
            "shortName": "fist",
            "shortNames": [
                "fist"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 49,
            "sheetY": 18,
            "skinVariations": {
                "1F3FB": {
                    "unified": "270A-1F3FB",
                    "native": "✊🏻",
                    "sheetX": 49,
                    "sheetY": 19
                },
                "1F3FC": {
                    "unified": "270A-1F3FC",
                    "native": "✊🏼",
                    "sheetX": 49,
                    "sheetY": 20
                },
                "1F3FD": {
                    "unified": "270A-1F3FD",
                    "native": "✊🏽",
                    "sheetX": 49,
                    "sheetY": 21
                },
                "1F3FE": {
                    "unified": "270A-1F3FE",
                    "native": "✊🏾",
                    "sheetX": 49,
                    "sheetY": 22
                },
                "1F3FF": {
                    "unified": "270A-1F3FF",
                    "native": "✊🏿",
                    "sheetX": 49,
                    "sheetY": 23
                }
            },
            "tts": "raised fist",
            "keywords": [
                "clenched",
                "fist",
                "hand",
                "punch",
                "raised fist"
            ]
        },
        {
            "unified": "1F44A",
            "name": "FISTED HAND SIGN",
            "native": "👊",
            "shortName": "facepunch",
            "shortNames": [
                "facepunch",
                "punch"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 14,
            "sheetY": 31,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F44A-1F3FB",
                    "native": "👊🏻",
                    "sheetX": 14,
                    "sheetY": 32
                },
                "1F3FC": {
                    "unified": "1F44A-1F3FC",
                    "native": "👊🏼",
                    "sheetX": 14,
                    "sheetY": 33
                },
                "1F3FD": {
                    "unified": "1F44A-1F3FD",
                    "native": "👊🏽",
                    "sheetX": 14,
                    "sheetY": 34
                },
                "1F3FE": {
                    "unified": "1F44A-1F3FE",
                    "native": "👊🏾",
                    "sheetX": 14,
                    "sheetY": 35
                },
                "1F3FF": {
                    "unified": "1F44A-1F3FF",
                    "native": "👊🏿",
                    "sheetX": 14,
                    "sheetY": 36
                }
            },
            "tts": "oncoming fist",
            "keywords": [
                "clenched",
                "fist",
                "hand",
                "oncoming fist",
                "punch"
            ]
        },
        {
            "unified": "1F91B",
            "name": "LEFT-FACING FIST",
            "native": "🤛",
            "shortName": "left-facing_fist",
            "shortNames": [
                "left-facing_fist"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 37,
            "sheetY": 50,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F91B-1F3FB",
                    "native": "🤛🏻",
                    "sheetX": 37,
                    "sheetY": 51
                },
                "1F3FC": {
                    "unified": "1F91B-1F3FC",
                    "native": "🤛🏼",
                    "sheetX": 38,
                    "sheetY": 0
                },
                "1F3FD": {
                    "unified": "1F91B-1F3FD",
                    "native": "🤛🏽",
                    "sheetX": 38,
                    "sheetY": 1
                },
                "1F3FE": {
                    "unified": "1F91B-1F3FE",
                    "native": "🤛🏾",
                    "sheetX": 38,
                    "sheetY": 2
                },
                "1F3FF": {
                    "unified": "1F91B-1F3FF",
                    "native": "🤛🏿",
                    "sheetX": 38,
                    "sheetY": 3
                }
            },
            "tts": "left-facing fist",
            "keywords": [
                "fist",
                "left-facing fist",
                "leftwards"
            ]
        },
        {
            "unified": "1F91C",
            "name": "RIGHT-FACING FIST",
            "native": "🤜",
            "shortName": "right-facing_fist",
            "shortNames": [
                "right-facing_fist"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 38,
            "sheetY": 4,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F91C-1F3FB",
                    "native": "🤜🏻",
                    "sheetX": 38,
                    "sheetY": 5
                },
                "1F3FC": {
                    "unified": "1F91C-1F3FC",
                    "native": "🤜🏼",
                    "sheetX": 38,
                    "sheetY": 6
                },
                "1F3FD": {
                    "unified": "1F91C-1F3FD",
                    "native": "🤜🏽",
                    "sheetX": 38,
                    "sheetY": 7
                },
                "1F3FE": {
                    "unified": "1F91C-1F3FE",
                    "native": "🤜🏾",
                    "sheetX": 38,
                    "sheetY": 8
                },
                "1F3FF": {
                    "unified": "1F91C-1F3FF",
                    "native": "🤜🏿",
                    "sheetX": 38,
                    "sheetY": 9
                }
            },
            "tts": "right-facing fist",
            "keywords": [
                "fist",
                "right-facing fist",
                "rightwards"
            ]
        },
        {
            "unified": "1F91A",
            "name": "RAISED BACK OF HAND",
            "native": "🤚",
            "shortName": "raised_back_of_hand",
            "shortNames": [
                "raised_back_of_hand"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 37,
            "sheetY": 44,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F91A-1F3FB",
                    "native": "🤚🏻",
                    "sheetX": 37,
                    "sheetY": 45
                },
                "1F3FC": {
                    "unified": "1F91A-1F3FC",
                    "native": "🤚🏼",
                    "sheetX": 37,
                    "sheetY": 46
                },
                "1F3FD": {
                    "unified": "1F91A-1F3FD",
                    "native": "🤚🏽",
                    "sheetX": 37,
                    "sheetY": 47
                },
                "1F3FE": {
                    "unified": "1F91A-1F3FE",
                    "native": "🤚🏾",
                    "sheetX": 37,
                    "sheetY": 48
                },
                "1F3FF": {
                    "unified": "1F91A-1F3FF",
                    "native": "🤚🏿",
                    "sheetX": 37,
                    "sheetY": 49
                }
            },
            "tts": "raised back of hand",
            "keywords": [
                "backhand",
                "raised",
                "raised back of hand"
            ]
        },
        {
            "unified": "1F44B",
            "name": "WAVING HAND SIGN",
            "native": "👋",
            "shortName": "wave",
            "shortNames": [
                "wave"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 14,
            "sheetY": 37,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F44B-1F3FB",
                    "native": "👋🏻",
                    "sheetX": 14,
                    "sheetY": 38
                },
                "1F3FC": {
                    "unified": "1F44B-1F3FC",
                    "native": "👋🏼",
                    "sheetX": 14,
                    "sheetY": 39
                },
                "1F3FD": {
                    "unified": "1F44B-1F3FD",
                    "native": "👋🏽",
                    "sheetX": 14,
                    "sheetY": 40
                },
                "1F3FE": {
                    "unified": "1F44B-1F3FE",
                    "native": "👋🏾",
                    "sheetX": 14,
                    "sheetY": 41
                },
                "1F3FF": {
                    "unified": "1F44B-1F3FF",
                    "native": "👋🏿",
                    "sheetX": 14,
                    "sheetY": 42
                }
            },
            "tts": "waving hand",
            "keywords": [
                "hand",
                "wave",
                "waving"
            ]
        },
        {
            "unified": "1F91F",
            "name": "I LOVE YOU HAND SIGN",
            "native": "🤟",
            "shortName": "i_love_you_hand_sign",
            "shortNames": [
                "i_love_you_hand_sign"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 38,
            "sheetY": 17,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F91F-1F3FB",
                    "native": "🤟🏻",
                    "sheetX": 38,
                    "sheetY": 18
                },
                "1F3FC": {
                    "unified": "1F91F-1F3FC",
                    "native": "🤟🏼",
                    "sheetX": 38,
                    "sheetY": 19
                },
                "1F3FD": {
                    "unified": "1F91F-1F3FD",
                    "native": "🤟🏽",
                    "sheetX": 38,
                    "sheetY": 20
                },
                "1F3FE": {
                    "unified": "1F91F-1F3FE",
                    "native": "🤟🏾",
                    "sheetX": 38,
                    "sheetY": 21
                },
                "1F3FF": {
                    "unified": "1F91F-1F3FF",
                    "native": "🤟🏿",
                    "sheetX": 38,
                    "sheetY": 22
                }
            },
            "tts": "love-you gesture",
            "keywords": [
                "hand",
                "ILY",
                "love-you gesture"
            ]
        },
        {
            "unified": "270D-FE0F",
            "name": null,
            "native": "✍️",
            "shortName": "writing_hand",
            "shortNames": [
                "writing_hand"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 49,
            "sheetY": 36,
            "skinVariations": {
                "1F3FB": {
                    "unified": "270D-1F3FB",
                    "native": "✍🏻",
                    "sheetX": 49,
                    "sheetY": 37
                },
                "1F3FC": {
                    "unified": "270D-1F3FC",
                    "native": "✍🏼",
                    "sheetX": 49,
                    "sheetY": 38
                },
                "1F3FD": {
                    "unified": "270D-1F3FD",
                    "native": "✍🏽",
                    "sheetX": 49,
                    "sheetY": 39
                },
                "1F3FE": {
                    "unified": "270D-1F3FE",
                    "native": "✍🏾",
                    "sheetX": 49,
                    "sheetY": 40
                },
                "1F3FF": {
                    "unified": "270D-1F3FF",
                    "native": "✍🏿",
                    "sheetX": 49,
                    "sheetY": 41
                }
            }
        },
        {
            "unified": "1F44F",
            "name": "CLAPPING HANDS SIGN",
            "native": "👏",
            "shortName": "clap",
            "shortNames": [
                "clap"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 9,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F44F-1F3FB",
                    "native": "👏🏻",
                    "sheetX": 15,
                    "sheetY": 10
                },
                "1F3FC": {
                    "unified": "1F44F-1F3FC",
                    "native": "👏🏼",
                    "sheetX": 15,
                    "sheetY": 11
                },
                "1F3FD": {
                    "unified": "1F44F-1F3FD",
                    "native": "👏🏽",
                    "sheetX": 15,
                    "sheetY": 12
                },
                "1F3FE": {
                    "unified": "1F44F-1F3FE",
                    "native": "👏🏾",
                    "sheetX": 15,
                    "sheetY": 13
                },
                "1F3FF": {
                    "unified": "1F44F-1F3FF",
                    "native": "👏🏿",
                    "sheetX": 15,
                    "sheetY": 14
                }
            },
            "tts": "clapping hands",
            "keywords": [
                "clap",
                "clapping hands",
                "hand"
            ]
        },
        {
            "unified": "1F450",
            "name": "OPEN HANDS SIGN",
            "native": "👐",
            "shortName": "open_hands",
            "shortNames": [
                "open_hands"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 15,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F450-1F3FB",
                    "native": "👐🏻",
                    "sheetX": 15,
                    "sheetY": 16
                },
                "1F3FC": {
                    "unified": "1F450-1F3FC",
                    "native": "👐🏼",
                    "sheetX": 15,
                    "sheetY": 17
                },
                "1F3FD": {
                    "unified": "1F450-1F3FD",
                    "native": "👐🏽",
                    "sheetX": 15,
                    "sheetY": 18
                },
                "1F3FE": {
                    "unified": "1F450-1F3FE",
                    "native": "👐🏾",
                    "sheetX": 15,
                    "sheetY": 19
                },
                "1F3FF": {
                    "unified": "1F450-1F3FF",
                    "native": "👐🏿",
                    "sheetX": 15,
                    "sheetY": 20
                }
            },
            "tts": "open hands",
            "keywords": [
                "hand",
                "open",
                "open hands"
            ]
        },
        {
            "unified": "1F64C",
            "name": "PERSON RAISING BOTH HANDS IN CELEBRATION",
            "native": "🙌",
            "shortName": "raised_hands",
            "shortNames": [
                "raised_hands"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 33,
            "sheetY": 12,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F64C-1F3FB",
                    "native": "🙌🏻",
                    "sheetX": 33,
                    "sheetY": 13
                },
                "1F3FC": {
                    "unified": "1F64C-1F3FC",
                    "native": "🙌🏼",
                    "sheetX": 33,
                    "sheetY": 14
                },
                "1F3FD": {
                    "unified": "1F64C-1F3FD",
                    "native": "🙌🏽",
                    "sheetX": 33,
                    "sheetY": 15
                },
                "1F3FE": {
                    "unified": "1F64C-1F3FE",
                    "native": "🙌🏾",
                    "sheetX": 33,
                    "sheetY": 16
                },
                "1F3FF": {
                    "unified": "1F64C-1F3FF",
                    "native": "🙌🏿",
                    "sheetX": 33,
                    "sheetY": 17
                }
            },
            "tts": "raising hands",
            "keywords": [
                "celebration",
                "gesture",
                "hand",
                "hooray",
                "raised",
                "raising hands"
            ]
        },
        {
            "unified": "1F932",
            "name": "PALMS UP TOGETHER",
            "native": "🤲",
            "shortName": "palms_up_together",
            "shortNames": [
                "palms_up_together"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 39,
            "sheetY": 16,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F932-1F3FB",
                    "native": "🤲🏻",
                    "sheetX": 39,
                    "sheetY": 17
                },
                "1F3FC": {
                    "unified": "1F932-1F3FC",
                    "native": "🤲🏼",
                    "sheetX": 39,
                    "sheetY": 18
                },
                "1F3FD": {
                    "unified": "1F932-1F3FD",
                    "native": "🤲🏽",
                    "sheetX": 39,
                    "sheetY": 19
                },
                "1F3FE": {
                    "unified": "1F932-1F3FE",
                    "native": "🤲🏾",
                    "sheetX": 39,
                    "sheetY": 20
                },
                "1F3FF": {
                    "unified": "1F932-1F3FF",
                    "native": "🤲🏿",
                    "sheetX": 39,
                    "sheetY": 21
                }
            },
            "tts": "palms up together",
            "keywords": [
                "palms up together",
                "prayer"
            ]
        },
        {
            "unified": "1F64F",
            "name": "PERSON WITH FOLDED HANDS",
            "native": "🙏",
            "shortName": "pray",
            "shortNames": [
                "pray"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 34,
            "sheetY": 2,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F64F-1F3FB",
                    "native": "🙏🏻",
                    "sheetX": 34,
                    "sheetY": 3
                },
                "1F3FC": {
                    "unified": "1F64F-1F3FC",
                    "native": "🙏🏼",
                    "sheetX": 34,
                    "sheetY": 4
                },
                "1F3FD": {
                    "unified": "1F64F-1F3FD",
                    "native": "🙏🏽",
                    "sheetX": 34,
                    "sheetY": 5
                },
                "1F3FE": {
                    "unified": "1F64F-1F3FE",
                    "native": "🙏🏾",
                    "sheetX": 34,
                    "sheetY": 6
                },
                "1F3FF": {
                    "unified": "1F64F-1F3FF",
                    "native": "🙏🏿",
                    "sheetX": 34,
                    "sheetY": 7
                }
            },
            "tts": "folded hands",
            "keywords": [
                "ask",
                "bow",
                "folded",
                "folded hands",
                "gesture",
                "hand",
                "please",
                "pray",
                "thanks"
            ]
        },
        {
            "unified": "1F91D",
            "name": "HANDSHAKE",
            "native": "🤝",
            "shortName": "handshake",
            "shortNames": [
                "handshake"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 38,
            "sheetY": 10,
            "tts": "handshake",
            "keywords": [
                "agreement",
                "hand",
                "handshake",
                "meeting",
                "shake"
            ]
        },
        {
            "unified": "1F485",
            "name": "NAIL POLISH",
            "native": "💅",
            "shortName": "nail_care",
            "shortNames": [
                "nail_care"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 23,
            "sheetY": 44,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F485-1F3FB",
                    "native": "💅🏻",
                    "sheetX": 23,
                    "sheetY": 45
                },
                "1F3FC": {
                    "unified": "1F485-1F3FC",
                    "native": "💅🏼",
                    "sheetX": 23,
                    "sheetY": 46
                },
                "1F3FD": {
                    "unified": "1F485-1F3FD",
                    "native": "💅🏽",
                    "sheetX": 23,
                    "sheetY": 47
                },
                "1F3FE": {
                    "unified": "1F485-1F3FE",
                    "native": "💅🏾",
                    "sheetX": 23,
                    "sheetY": 48
                },
                "1F3FF": {
                    "unified": "1F485-1F3FF",
                    "native": "💅🏿",
                    "sheetX": 23,
                    "sheetY": 49
                }
            },
            "tts": "nail polish",
            "keywords": [
                "care",
                "cosmetics",
                "manicure",
                "nail",
                "polish"
            ]
        },
        {
            "unified": "1F442",
            "name": "EAR",
            "native": "👂",
            "shortName": "ear",
            "shortNames": [
                "ear"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 13,
            "sheetY": 45,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F442-1F3FB",
                    "native": "👂🏻",
                    "sheetX": 13,
                    "sheetY": 46
                },
                "1F3FC": {
                    "unified": "1F442-1F3FC",
                    "native": "👂🏼",
                    "sheetX": 13,
                    "sheetY": 47
                },
                "1F3FD": {
                    "unified": "1F442-1F3FD",
                    "native": "👂🏽",
                    "sheetX": 13,
                    "sheetY": 48
                },
                "1F3FE": {
                    "unified": "1F442-1F3FE",
                    "native": "👂🏾",
                    "sheetX": 13,
                    "sheetY": 49
                },
                "1F3FF": {
                    "unified": "1F442-1F3FF",
                    "native": "👂🏿",
                    "sheetX": 13,
                    "sheetY": 50
                }
            },
            "tts": "ear",
            "keywords": [
                "body",
                "ear"
            ]
        },
        {
            "unified": "1F443",
            "name": "NOSE",
            "native": "👃",
            "shortName": "nose",
            "shortNames": [
                "nose"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 13,
            "sheetY": 51,
            "skinVariations": {
                "1F3FB": {
                    "unified": "1F443-1F3FB",
                    "native": "👃🏻",
                    "sheetX": 14,
                    "sheetY": 0
                },
                "1F3FC": {
                    "unified": "1F443-1F3FC",
                    "native": "👃🏼",
                    "sheetX": 14,
                    "sheetY": 1
                },
                "1F3FD": {
                    "unified": "1F443-1F3FD",
                    "native": "👃🏽",
                    "sheetX": 14,
                    "sheetY": 2
                },
                "1F3FE": {
                    "unified": "1F443-1F3FE",
                    "native": "👃🏾",
                    "sheetX": 14,
                    "sheetY": 3
                },
                "1F3FF": {
                    "unified": "1F443-1F3FF",
                    "native": "👃🏿",
                    "sheetX": 14,
                    "sheetY": 4
                }
            },
            "tts": "nose",
            "keywords": [
                "body",
                "nose"
            ]
        },
        {
            "unified": "1F463",
            "name": "FOOTPRINTS",
            "native": "👣",
            "shortName": "footprints",
            "shortNames": [
                "footprints"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 39,
            "tts": "footprints",
            "keywords": [
                "clothing",
                "footprint",
                "footprints",
                "print"
            ]
        },
        {
            "unified": "1F440",
            "name": "EYES",
            "native": "👀",
            "shortName": "eyes",
            "shortNames": [
                "eyes"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 13,
            "sheetY": 42,
            "tts": "eyes",
            "keywords": [
                "eye",
                "eyes",
                "face"
            ]
        },
        {
            "unified": "1F441-FE0F",
            "name": null,
            "native": "👁️",
            "shortName": "eye",
            "shortNames": [
                "eye"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 13,
            "sheetY": 44
        },
        {
            "unified": "1F441-FE0F-200D-1F5E8-FE0F",
            "name": null,
            "native": "👁️‍🗨️",
            "shortName": "eye-in-speech-bubble",
            "shortNames": [
                "eye-in-speech-bubble"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 13,
            "sheetY": 43
        },
        {
            "unified": "1F9E0",
            "name": "BRAIN",
            "native": "🧠",
            "shortName": "brain",
            "shortNames": [
                "brain"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 46,
            "sheetY": 22,
            "tts": "brain",
            "keywords": [
                "brain",
                "intelligent"
            ]
        },
        {
            "unified": "1F445",
            "name": "TONGUE",
            "native": "👅",
            "shortName": "tongue",
            "shortNames": [
                "tongue"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 14,
            "sheetY": 6,
            "tts": "tongue",
            "keywords": [
                "body",
                "tongue"
            ]
        },
        {
            "unified": "1F444",
            "name": "MOUTH",
            "native": "👄",
            "shortName": "lips",
            "shortNames": [
                "lips"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 14,
            "sheetY": 5,
            "tts": "mouth",
            "keywords": [
                "lips",
                "mouth"
            ]
        },
        {
            "unified": "1F3FB",
            "name": "EMOJI MODIFIER FITZPATRICK TYPE-1-2",
            "native": "🏻",
            "shortName": "skin-tone-2",
            "shortNames": [
                "skin-tone-2"
            ],
            "text": null,
            "texts": null,
            "category": "Skin Tones",
            "sheetX": 12,
            "sheetY": 25,
            "tts": "light skin tone",
            "keywords": [
                "light skin tone",
                "skin tone",
                "type 1–2"
            ]
        },
        {
            "unified": "1F3FC",
            "name": "EMOJI MODIFIER FITZPATRICK TYPE-3",
            "native": "🏼",
            "shortName": "skin-tone-3",
            "shortNames": [
                "skin-tone-3"
            ],
            "text": null,
            "texts": null,
            "category": "Skin Tones",
            "sheetX": 12,
            "sheetY": 26,
            "tts": "medium-light skin tone",
            "keywords": [
                "medium-light skin tone",
                "skin tone",
                "type 3"
            ]
        },
        {
            "unified": "1F3FD",
            "name": "EMOJI MODIFIER FITZPATRICK TYPE-4",
            "native": "🏽",
            "shortName": "skin-tone-4",
            "shortNames": [
                "skin-tone-4"
            ],
            "text": null,
            "texts": null,
            "category": "Skin Tones",
            "sheetX": 12,
            "sheetY": 27,
            "tts": "medium skin tone",
            "keywords": [
                "medium skin tone",
                "skin tone",
                "type 4"
            ]
        },
        {
            "unified": "1F3FE",
            "name": "EMOJI MODIFIER FITZPATRICK TYPE-5",
            "native": "🏾",
            "shortName": "skin-tone-5",
            "shortNames": [
                "skin-tone-5"
            ],
            "text": null,
            "texts": null,
            "category": "Skin Tones",
            "sheetX": 12,
            "sheetY": 28,
            "tts": "medium-dark skin tone",
            "keywords": [
                "medium-dark skin tone",
                "skin tone",
                "type 5"
            ]
        },
        {
            "unified": "1F3FF",
            "name": "EMOJI MODIFIER FITZPATRICK TYPE-6",
            "native": "🏿",
            "shortName": "skin-tone-6",
            "shortNames": [
                "skin-tone-6"
            ],
            "text": null,
            "texts": null,
            "category": "Skin Tones",
            "sheetX": 12,
            "sheetY": 29,
            "tts": "dark skin tone",
            "keywords": [
                "dark skin tone",
                "skin tone",
                "type 6"
            ]
        },
        {
            "unified": "1F48B",
            "name": "KISS MARK",
            "native": "💋",
            "shortName": "kiss",
            "shortNames": [
                "kiss"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 24,
            "sheetY": 37,
            "tts": "kiss mark",
            "keywords": [
                "kiss",
                "kiss mark",
                "lips"
            ]
        },
        {
            "unified": "1F498",
            "name": "HEART WITH ARROW",
            "native": "💘",
            "shortName": "cupid",
            "shortNames": [
                "cupid"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 24,
            "sheetY": 50,
            "tts": "heart with arrow",
            "keywords": [
                "arrow",
                "cupid",
                "heart with arrow"
            ]
        },
        {
            "unified": "1F49D",
            "name": "HEART WITH RIBBON",
            "native": "💝",
            "shortName": "gift_heart",
            "shortNames": [
                "gift_heart"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 25,
            "sheetY": 3,
            "tts": "heart with ribbon",
            "keywords": [
                "heart with ribbon",
                "ribbon",
                "valentine"
            ]
        },
        {
            "unified": "1F496",
            "name": "SPARKLING HEART",
            "native": "💖",
            "shortName": "sparkling_heart",
            "shortNames": [
                "sparkling_heart"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 24,
            "sheetY": 48,
            "tts": "sparkling heart",
            "keywords": [
                "excited",
                "sparkle",
                "sparkling heart"
            ]
        },
        {
            "unified": "1F497",
            "name": "GROWING HEART",
            "native": "💗",
            "shortName": "heartpulse",
            "shortNames": [
                "heartpulse"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 24,
            "sheetY": 49,
            "tts": "growing heart",
            "keywords": [
                "excited",
                "growing",
                "growing heart",
                "nervous",
                "pulse"
            ]
        },
        {
            "unified": "1F493",
            "name": "BEATING HEART",
            "native": "💓",
            "shortName": "heartbeat",
            "shortNames": [
                "heartbeat"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 24,
            "sheetY": 45,
            "tts": "beating heart",
            "keywords": [
                "beating",
                "beating heart",
                "heartbeat",
                "pulsating"
            ]
        },
        {
            "unified": "1F49E",
            "name": "REVOLVING HEARTS",
            "native": "💞",
            "shortName": "revolving_hearts",
            "shortNames": [
                "revolving_hearts"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 25,
            "sheetY": 4,
            "tts": "revolving hearts",
            "keywords": [
                "revolving",
                "revolving hearts"
            ]
        },
        {
            "unified": "1F495",
            "name": "TWO HEARTS",
            "native": "💕",
            "shortName": "two_hearts",
            "shortNames": [
                "two_hearts"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 24,
            "sheetY": 47,
            "tts": "two hearts",
            "keywords": [
                "love",
                "two hearts"
            ]
        },
        {
            "unified": "1F48C",
            "name": "LOVE LETTER",
            "native": "💌",
            "shortName": "love_letter",
            "shortNames": [
                "love_letter"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 24,
            "sheetY": 38,
            "tts": "love letter",
            "keywords": [
                "heart",
                "letter",
                "love",
                "mail"
            ]
        },
        {
            "unified": "2763-FE0F",
            "name": null,
            "native": "❣️",
            "shortName": "heavy_heart_exclamation_mark_ornament",
            "shortNames": [
                "heavy_heart_exclamation_mark_ornament"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 50,
            "sheetY": 7
        },
        {
            "unified": "1F494",
            "name": "BROKEN HEART",
            "native": "💔",
            "shortName": "broken_heart",
            "shortNames": [
                "broken_heart"
            ],
            "text": "</3",
            "texts": [
                "</3"
            ],
            "category": "Smileys & People",
            "sheetX": 24,
            "sheetY": 46,
            "tts": "broken heart",
            "keywords": [
                "break",
                "broken",
                "broken heart"
            ]
        },
        {
            "unified": "2764-FE0F",
            "name": "HEAVY BLACK HEART",
            "native": "❤️",
            "shortName": "heart",
            "shortNames": [
                "heart"
            ],
            "text": "<3",
            "texts": [
                "<3"
            ],
            "category": "Smileys & People",
            "sheetX": 50,
            "sheetY": 8
        },
        {
            "unified": "1F9E1",
            "name": "ORANGE HEART",
            "native": "🧡",
            "shortName": "orange_heart",
            "shortNames": [
                "orange_heart"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 46,
            "sheetY": 23,
            "tts": "orange heart",
            "keywords": [
                "orange",
                "orange heart"
            ]
        },
        {
            "unified": "1F49B",
            "name": "YELLOW HEART",
            "native": "💛",
            "shortName": "yellow_heart",
            "shortNames": [
                "yellow_heart"
            ],
            "text": "<3",
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 25,
            "sheetY": 1,
            "tts": "yellow heart",
            "keywords": [
                "yellow",
                "yellow heart"
            ]
        },
        {
            "unified": "1F49A",
            "name": "GREEN HEART",
            "native": "💚",
            "shortName": "green_heart",
            "shortNames": [
                "green_heart"
            ],
            "text": "<3",
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 25,
            "sheetY": 0,
            "tts": "green heart",
            "keywords": [
                "green",
                "green heart"
            ]
        },
        {
            "unified": "1F499",
            "name": "BLUE HEART",
            "native": "💙",
            "shortName": "blue_heart",
            "shortNames": [
                "blue_heart"
            ],
            "text": "<3",
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 24,
            "sheetY": 51,
            "tts": "blue heart",
            "keywords": [
                "blue",
                "blue heart"
            ]
        },
        {
            "unified": "1F49C",
            "name": "PURPLE HEART",
            "native": "💜",
            "shortName": "purple_heart",
            "shortNames": [
                "purple_heart"
            ],
            "text": "<3",
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 25,
            "sheetY": 2,
            "tts": "purple heart",
            "keywords": [
                "purple",
                "purple heart"
            ]
        },
        {
            "unified": "1F5A4",
            "name": "BLACK HEART",
            "native": "🖤",
            "shortName": "black_heart",
            "shortNames": [
                "black_heart"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 29,
            "sheetY": 50,
            "tts": "black heart",
            "keywords": [
                "black",
                "black heart",
                "evil",
                "wicked"
            ]
        },
        {
            "unified": "1F49F",
            "name": "HEART DECORATION",
            "native": "💟",
            "shortName": "heart_decoration",
            "shortNames": [
                "heart_decoration"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 25,
            "sheetY": 5,
            "tts": "heart decoration",
            "keywords": [
                "heart",
                "heart decoration"
            ]
        },
        {
            "unified": "1F4A4",
            "name": "SLEEPING SYMBOL",
            "native": "💤",
            "shortName": "zzz",
            "shortNames": [
                "zzz"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 25,
            "sheetY": 10,
            "tts": "zzz",
            "keywords": [
                "comic",
                "sleep",
                "zzz"
            ]
        },
        {
            "unified": "1F4A2",
            "name": "ANGER SYMBOL",
            "native": "💢",
            "shortName": "anger",
            "shortNames": [
                "anger"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 25,
            "sheetY": 8,
            "tts": "anger symbol",
            "keywords": [
                "anger symbol",
                "angry",
                "comic",
                "mad"
            ]
        },
        {
            "unified": "1F4A3",
            "name": "BOMB",
            "native": "💣",
            "shortName": "bomb",
            "shortNames": [
                "bomb"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 25,
            "sheetY": 9,
            "tts": "bomb",
            "keywords": [
                "bomb",
                "comic"
            ]
        },
        {
            "unified": "1F4A5",
            "name": "COLLISION SYMBOL",
            "native": "💥",
            "shortName": "boom",
            "shortNames": [
                "boom",
                "collision"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 25,
            "sheetY": 11,
            "tts": "collision",
            "keywords": [
                "boom",
                "collision",
                "comic"
            ]
        },
        {
            "unified": "1F4A6",
            "name": "SPLASHING SWEAT SYMBOL",
            "native": "💦",
            "shortName": "sweat_drops",
            "shortNames": [
                "sweat_drops"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 25,
            "sheetY": 12,
            "tts": "sweat droplets",
            "keywords": [
                "comic",
                "splashing",
                "sweat",
                "sweat droplets"
            ]
        },
        {
            "unified": "1F4A8",
            "name": "DASH SYMBOL",
            "native": "💨",
            "shortName": "dash",
            "shortNames": [
                "dash"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 25,
            "sheetY": 14,
            "tts": "dashing away",
            "keywords": [
                "comic",
                "dash",
                "dashing away",
                "running"
            ]
        },
        {
            "unified": "1F4AB",
            "name": "DIZZY SYMBOL",
            "native": "💫",
            "shortName": "dizzy",
            "shortNames": [
                "dizzy"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 25,
            "sheetY": 22,
            "tts": "dizzy",
            "keywords": [
                "comic",
                "dizzy",
                "star"
            ]
        },
        {
            "unified": "1F4AC",
            "name": "SPEECH BALLOON",
            "native": "💬",
            "shortName": "speech_balloon",
            "shortNames": [
                "speech_balloon"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 25,
            "sheetY": 23,
            "tts": "speech balloon",
            "keywords": [
                "balloon",
                "bubble",
                "comic",
                "dialog",
                "speech"
            ]
        },
        {
            "unified": "1F5E8-FE0F",
            "name": null,
            "native": "🗨️",
            "shortName": "left_speech_bubble",
            "shortNames": [
                "left_speech_bubble"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 15
        },
        {
            "unified": "1F5EF-FE0F",
            "name": null,
            "native": "🗯️",
            "shortName": "right_anger_bubble",
            "shortNames": [
                "right_anger_bubble"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 30,
            "sheetY": 16
        },
        {
            "unified": "1F4AD",
            "name": "THOUGHT BALLOON",
            "native": "💭",
            "shortName": "thought_balloon",
            "shortNames": [
                "thought_balloon"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 25,
            "sheetY": 24,
            "tts": "thought balloon",
            "keywords": [
                "balloon",
                "bubble",
                "comic",
                "thought"
            ]
        },
        {
            "unified": "1F573-FE0F",
            "name": null,
            "native": "🕳️",
            "shortName": "hole",
            "shortNames": [
                "hole"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 28,
            "sheetY": 44
        },
        {
            "unified": "1F453",
            "name": "EYEGLASSES",
            "native": "👓",
            "shortName": "eyeglasses",
            "shortNames": [
                "eyeglasses"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 23,
            "tts": "glasses",
            "keywords": [
                "clothing",
                "eye",
                "eyeglasses",
                "eyewear",
                "glasses"
            ]
        },
        {
            "unified": "1F576-FE0F",
            "name": null,
            "native": "🕶️",
            "shortName": "dark_sunglasses",
            "shortNames": [
                "dark_sunglasses"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 29,
            "sheetY": 17
        },
        {
            "unified": "1F454",
            "name": "NECKTIE",
            "native": "👔",
            "shortName": "necktie",
            "shortNames": [
                "necktie"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 24,
            "tts": "necktie",
            "keywords": [
                "clothing",
                "necktie",
                "tie"
            ]
        },
        {
            "unified": "1F455",
            "name": "T-SHIRT",
            "native": "👕",
            "shortName": "shirt",
            "shortNames": [
                "shirt",
                "tshirt"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 25,
            "tts": "t-shirt",
            "keywords": [
                "clothing",
                "shirt",
                "t-shirt",
                "tshirt"
            ]
        },
        {
            "unified": "1F456",
            "name": "JEANS",
            "native": "👖",
            "shortName": "jeans",
            "shortNames": [
                "jeans"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 26,
            "tts": "jeans",
            "keywords": [
                "clothing",
                "jeans",
                "pants",
                "trousers"
            ]
        },
        {
            "unified": "1F9E3",
            "name": "SCARF",
            "native": "🧣",
            "shortName": "scarf",
            "shortNames": [
                "scarf"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 46,
            "sheetY": 25,
            "tts": "scarf",
            "keywords": [
                "neck",
                "scarf"
            ]
        },
        {
            "unified": "1F9E4",
            "name": "GLOVES",
            "native": "🧤",
            "shortName": "gloves",
            "shortNames": [
                "gloves"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 46,
            "sheetY": 26,
            "tts": "gloves",
            "keywords": [
                "gloves",
                "hand"
            ]
        },
        {
            "unified": "1F9E5",
            "name": "COAT",
            "native": "🧥",
            "shortName": "coat",
            "shortNames": [
                "coat"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 46,
            "sheetY": 27,
            "tts": "coat",
            "keywords": [
                "coat",
                "jacket"
            ]
        },
        {
            "unified": "1F9E6",
            "name": "SOCKS",
            "native": "🧦",
            "shortName": "socks",
            "shortNames": [
                "socks"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 46,
            "sheetY": 28,
            "tts": "socks",
            "keywords": [
                "socks",
                "stocking"
            ]
        },
        {
            "unified": "1F457",
            "name": "DRESS",
            "native": "👗",
            "shortName": "dress",
            "shortNames": [
                "dress"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 27,
            "tts": "dress",
            "keywords": [
                "clothing",
                "dress"
            ]
        },
        {
            "unified": "1F458",
            "name": "KIMONO",
            "native": "👘",
            "shortName": "kimono",
            "shortNames": [
                "kimono"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 28,
            "tts": "kimono",
            "keywords": [
                "clothing",
                "kimono"
            ]
        },
        {
            "unified": "1F459",
            "name": "BIKINI",
            "native": "👙",
            "shortName": "bikini",
            "shortNames": [
                "bikini"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 29,
            "tts": "bikini",
            "keywords": [
                "bikini",
                "clothing",
                "swim"
            ]
        },
        {
            "unified": "1F45A",
            "name": "WOMANS CLOTHES",
            "native": "👚",
            "shortName": "womans_clothes",
            "shortNames": [
                "womans_clothes"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 30,
            "tts": "woman’s clothes",
            "keywords": [
                "clothing",
                "woman",
                "woman’s clothes"
            ]
        },
        {
            "unified": "1F45B",
            "name": "PURSE",
            "native": "👛",
            "shortName": "purse",
            "shortNames": [
                "purse"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 31,
            "tts": "purse",
            "keywords": [
                "clothing",
                "coin",
                "purse"
            ]
        },
        {
            "unified": "1F45C",
            "name": "HANDBAG",
            "native": "👜",
            "shortName": "handbag",
            "shortNames": [
                "handbag"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 32,
            "tts": "handbag",
            "keywords": [
                "bag",
                "clothing",
                "handbag",
                "purse"
            ]
        },
        {
            "unified": "1F45D",
            "name": "POUCH",
            "native": "👝",
            "shortName": "pouch",
            "shortNames": [
                "pouch"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 33,
            "tts": "clutch bag",
            "keywords": [
                "bag",
                "clothing",
                "clutch bag",
                "pouch"
            ]
        },
        {
            "unified": "1F6CD-FE0F",
            "name": null,
            "native": "🛍️",
            "shortName": "shopping_bags",
            "shortNames": [
                "shopping_bags"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 37,
            "sheetY": 2
        },
        {
            "unified": "1F392",
            "name": "SCHOOL SATCHEL",
            "native": "🎒",
            "shortName": "school_satchel",
            "shortNames": [
                "school_satchel"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 8,
            "sheetY": 37,
            "tts": "backpack",
            "keywords": [
                "backpack",
                "bag",
                "rucksack",
                "satchel",
                "school"
            ]
        },
        {
            "unified": "1F45E",
            "name": "MANS SHOE",
            "native": "👞",
            "shortName": "mans_shoe",
            "shortNames": [
                "mans_shoe",
                "shoe"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 34,
            "tts": "man’s shoe",
            "keywords": [
                "clothing",
                "man",
                "man’s shoe",
                "shoe"
            ]
        },
        {
            "unified": "1F45F",
            "name": "ATHLETIC SHOE",
            "native": "👟",
            "shortName": "athletic_shoe",
            "shortNames": [
                "athletic_shoe"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 35,
            "tts": "running shoe",
            "keywords": [
                "athletic",
                "clothing",
                "running shoe",
                "shoe",
                "sneaker"
            ]
        },
        {
            "unified": "1F460",
            "name": "HIGH-HEELED SHOE",
            "native": "👠",
            "shortName": "high_heel",
            "shortNames": [
                "high_heel"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 36,
            "tts": "high-heeled shoe",
            "keywords": [
                "clothing",
                "heel",
                "high-heeled shoe",
                "shoe",
                "woman"
            ]
        },
        {
            "unified": "1F461",
            "name": "WOMANS SANDAL",
            "native": "👡",
            "shortName": "sandal",
            "shortNames": [
                "sandal"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 37,
            "tts": "woman’s sandal",
            "keywords": [
                "clothing",
                "sandal",
                "shoe",
                "woman",
                "woman’s sandal"
            ]
        },
        {
            "unified": "1F462",
            "name": "WOMANS BOOTS",
            "native": "👢",
            "shortName": "boot",
            "shortNames": [
                "boot"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 38,
            "tts": "woman’s boot",
            "keywords": [
                "boot",
                "clothing",
                "shoe",
                "woman",
                "woman’s boot"
            ]
        },
        {
            "unified": "1F451",
            "name": "CROWN",
            "native": "👑",
            "shortName": "crown",
            "shortNames": [
                "crown"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 21,
            "tts": "crown",
            "keywords": [
                "clothing",
                "crown",
                "king",
                "queen"
            ]
        },
        {
            "unified": "1F452",
            "name": "WOMANS HAT",
            "native": "👒",
            "shortName": "womans_hat",
            "shortNames": [
                "womans_hat"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 15,
            "sheetY": 22,
            "tts": "woman’s hat",
            "keywords": [
                "clothing",
                "hat",
                "woman",
                "woman’s hat"
            ]
        },
        {
            "unified": "1F3A9",
            "name": "TOP HAT",
            "native": "🎩",
            "shortName": "tophat",
            "shortNames": [
                "tophat"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 9,
            "sheetY": 3,
            "tts": "top hat",
            "keywords": [
                "clothing",
                "hat",
                "top",
                "tophat"
            ]
        },
        {
            "unified": "1F393",
            "name": "GRADUATION CAP",
            "native": "🎓",
            "shortName": "mortar_board",
            "shortNames": [
                "mortar_board"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 8,
            "sheetY": 38,
            "tts": "graduation cap",
            "keywords": [
                "cap",
                "celebration",
                "clothing",
                "graduation",
                "hat"
            ]
        },
        {
            "unified": "1F9E2",
            "name": "BILLED CAP",
            "native": "🧢",
            "shortName": "billed_cap",
            "shortNames": [
                "billed_cap"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 46,
            "sheetY": 24,
            "tts": "billed cap",
            "keywords": [
                "baseball cap",
                "billed cap"
            ]
        },
        {
            "unified": "26D1-FE0F",
            "name": null,
            "native": "⛑️",
            "shortName": "helmet_with_white_cross",
            "shortNames": [
                "helmet_with_white_cross"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 48,
            "sheetY": 33
        },
        {
            "unified": "1F4FF",
            "name": "PRAYER BEADS",
            "native": "📿",
            "shortName": "prayer_beads",
            "shortNames": [
                "prayer_beads"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 27,
            "sheetY": 1,
            "tts": "prayer beads",
            "keywords": [
                "beads",
                "clothing",
                "necklace",
                "prayer",
                "religion"
            ]
        },
        {
            "unified": "1F484",
            "name": "LIPSTICK",
            "native": "💄",
            "shortName": "lipstick",
            "shortNames": [
                "lipstick"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 23,
            "sheetY": 43,
            "tts": "lipstick",
            "keywords": [
                "cosmetics",
                "lipstick",
                "makeup"
            ]
        },
        {
            "unified": "1F48D",
            "name": "RING",
            "native": "💍",
            "shortName": "ring",
            "shortNames": [
                "ring"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 24,
            "sheetY": 39,
            "tts": "ring",
            "keywords": [
                "diamond",
                "ring"
            ]
        },
        {
            "unified": "1F48E",
            "name": "GEM STONE",
            "native": "💎",
            "shortName": "gem",
            "shortNames": [
                "gem"
            ],
            "text": null,
            "texts": null,
            "category": "Smileys & People",
            "sheetX": 24,
            "sheetY": 40,
            "tts": "gem stone",
            "keywords": [
                "diamond",
                "gem",
                "gem stone",
                "jewel"
            ]
        },
        {
            "unified": "1F435",
            "name": "MONKEY FACE",
            "native": "🐵",
            "shortName": "monkey_face",
            "shortNames": [
                "monkey_face"
            ],
            "text": null,
            "texts": [
                ":o)"
            ],
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 31,
            "tts": "monkey face",
            "keywords": [
                "face",
                "monkey"
            ]
        },
        {
            "unified": "1F412",
            "name": "MONKEY",
            "native": "🐒",
            "shortName": "monkey",
            "shortNames": [
                "monkey"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 48,
            "tts": "monkey",
            "keywords": [
                "monkey"
            ]
        },
        {
            "unified": "1F98D",
            "name": "GORILLA",
            "native": "🦍",
            "shortName": "gorilla",
            "shortNames": [
                "gorilla"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 37,
            "tts": "gorilla",
            "keywords": [
                "gorilla"
            ]
        },
        {
            "unified": "1F436",
            "name": "DOG FACE",
            "native": "🐶",
            "shortName": "dog",
            "shortNames": [
                "dog"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 32,
            "tts": "dog face",
            "keywords": [
                "dog",
                "face",
                "pet"
            ]
        },
        {
            "unified": "1F415",
            "name": "DOG",
            "native": "🐕",
            "shortName": "dog2",
            "shortNames": [
                "dog2"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 51,
            "tts": "dog",
            "keywords": [
                "dog",
                "pet"
            ]
        },
        {
            "unified": "1F429",
            "name": "POODLE",
            "native": "🐩",
            "shortName": "poodle",
            "shortNames": [
                "poodle"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 19,
            "tts": "poodle",
            "keywords": [
                "dog",
                "poodle"
            ]
        },
        {
            "unified": "1F43A",
            "name": "WOLF FACE",
            "native": "🐺",
            "shortName": "wolf",
            "shortNames": [
                "wolf"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 36,
            "tts": "wolf face",
            "keywords": [
                "face",
                "wolf"
            ]
        },
        {
            "unified": "1F98A",
            "name": "FOX FACE",
            "native": "🦊",
            "shortName": "fox_face",
            "shortNames": [
                "fox_face"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 34,
            "tts": "fox face",
            "keywords": [
                "face",
                "fox"
            ]
        },
        {
            "unified": "1F431",
            "name": "CAT FACE",
            "native": "🐱",
            "shortName": "cat",
            "shortNames": [
                "cat"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 27,
            "tts": "cat face",
            "keywords": [
                "cat",
                "face",
                "pet"
            ]
        },
        {
            "unified": "1F408",
            "name": "CAT",
            "native": "🐈",
            "shortName": "cat2",
            "shortNames": [
                "cat2"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 38,
            "tts": "cat",
            "keywords": [
                "cat",
                "pet"
            ]
        },
        {
            "unified": "1F981",
            "name": "LION FACE",
            "native": "🦁",
            "shortName": "lion_face",
            "shortNames": [
                "lion_face"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 25,
            "tts": "lion face",
            "keywords": [
                "face",
                "Leo",
                "lion",
                "zodiac"
            ]
        },
        {
            "unified": "1F42F",
            "name": "TIGER FACE",
            "native": "🐯",
            "shortName": "tiger",
            "shortNames": [
                "tiger"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 25,
            "tts": "tiger face",
            "keywords": [
                "face",
                "tiger"
            ]
        },
        {
            "unified": "1F405",
            "name": "TIGER",
            "native": "🐅",
            "shortName": "tiger2",
            "shortNames": [
                "tiger2"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 35,
            "tts": "tiger",
            "keywords": [
                "tiger"
            ]
        },
        {
            "unified": "1F406",
            "name": "LEOPARD",
            "native": "🐆",
            "shortName": "leopard",
            "shortNames": [
                "leopard"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 36,
            "tts": "leopard",
            "keywords": [
                "leopard"
            ]
        },
        {
            "unified": "1F434",
            "name": "HORSE FACE",
            "native": "🐴",
            "shortName": "horse",
            "shortNames": [
                "horse"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 30,
            "tts": "horse face",
            "keywords": [
                "face",
                "horse"
            ]
        },
        {
            "unified": "1F40E",
            "name": "HORSE",
            "native": "🐎",
            "shortName": "racehorse",
            "shortNames": [
                "racehorse"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 44,
            "tts": "horse",
            "keywords": [
                "equestrian",
                "horse",
                "racehorse",
                "racing"
            ]
        },
        {
            "unified": "1F984",
            "name": "UNICORN FACE",
            "native": "🦄",
            "shortName": "unicorn_face",
            "shortNames": [
                "unicorn_face"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 28,
            "tts": "unicorn face",
            "keywords": [
                "face",
                "unicorn"
            ]
        },
        {
            "unified": "1F993",
            "name": "ZEBRA FACE",
            "native": "🦓",
            "shortName": "zebra_face",
            "shortNames": [
                "zebra_face"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 43,
            "tts": "zebra",
            "keywords": [
                "stripe",
                "zebra"
            ]
        },
        {
            "unified": "1F98C",
            "name": "DEER",
            "native": "🦌",
            "shortName": "deer",
            "shortNames": [
                "deer"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 36,
            "tts": "deer",
            "keywords": [
                "deer"
            ]
        },
        {
            "unified": "1F42E",
            "name": "COW FACE",
            "native": "🐮",
            "shortName": "cow",
            "shortNames": [
                "cow"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 24,
            "tts": "cow face",
            "keywords": [
                "cow",
                "face"
            ]
        },
        {
            "unified": "1F402",
            "name": "OX",
            "native": "🐂",
            "shortName": "ox",
            "shortNames": [
                "ox"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 32,
            "tts": "ox",
            "keywords": [
                "bull",
                "ox",
                "Taurus",
                "zodiac"
            ]
        },
        {
            "unified": "1F403",
            "name": "WATER BUFFALO",
            "native": "🐃",
            "shortName": "water_buffalo",
            "shortNames": [
                "water_buffalo"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 33,
            "tts": "water buffalo",
            "keywords": [
                "buffalo",
                "water"
            ]
        },
        {
            "unified": "1F404",
            "name": "COW",
            "native": "🐄",
            "shortName": "cow2",
            "shortNames": [
                "cow2"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 34,
            "tts": "cow",
            "keywords": [
                "cow"
            ]
        },
        {
            "unified": "1F437",
            "name": "PIG FACE",
            "native": "🐷",
            "shortName": "pig",
            "shortNames": [
                "pig"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 33,
            "tts": "pig face",
            "keywords": [
                "face",
                "pig"
            ]
        },
        {
            "unified": "1F416",
            "name": "PIG",
            "native": "🐖",
            "shortName": "pig2",
            "shortNames": [
                "pig2"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 0,
            "tts": "pig",
            "keywords": [
                "pig",
                "sow"
            ]
        },
        {
            "unified": "1F417",
            "name": "BOAR",
            "native": "🐗",
            "shortName": "boar",
            "shortNames": [
                "boar"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 1,
            "tts": "boar",
            "keywords": [
                "boar",
                "pig"
            ]
        },
        {
            "unified": "1F43D",
            "name": "PIG NOSE",
            "native": "🐽",
            "shortName": "pig_nose",
            "shortNames": [
                "pig_nose"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 39,
            "tts": "pig nose",
            "keywords": [
                "face",
                "nose",
                "pig"
            ]
        },
        {
            "unified": "1F40F",
            "name": "RAM",
            "native": "🐏",
            "shortName": "ram",
            "shortNames": [
                "ram"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 45,
            "tts": "ram",
            "keywords": [
                "Aries",
                "male",
                "ram",
                "sheep",
                "zodiac"
            ]
        },
        {
            "unified": "1F411",
            "name": "SHEEP",
            "native": "🐑",
            "shortName": "sheep",
            "shortNames": [
                "sheep"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 47,
            "tts": "ewe",
            "keywords": [
                "ewe",
                "female",
                "sheep"
            ]
        },
        {
            "unified": "1F410",
            "name": "GOAT",
            "native": "🐐",
            "shortName": "goat",
            "shortNames": [
                "goat"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 46,
            "tts": "goat",
            "keywords": [
                "Capricorn",
                "goat",
                "zodiac"
            ]
        },
        {
            "unified": "1F42A",
            "name": "DROMEDARY CAMEL",
            "native": "🐪",
            "shortName": "dromedary_camel",
            "shortNames": [
                "dromedary_camel"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 20,
            "tts": "camel",
            "keywords": [
                "camel",
                "dromedary",
                "hump"
            ]
        },
        {
            "unified": "1F42B",
            "name": "BACTRIAN CAMEL",
            "native": "🐫",
            "shortName": "camel",
            "shortNames": [
                "camel"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 21,
            "tts": "two-hump camel",
            "keywords": [
                "bactrian",
                "camel",
                "hump",
                "two-hump camel"
            ]
        },
        {
            "unified": "1F992",
            "name": "GIRAFFE FACE",
            "native": "🦒",
            "shortName": "giraffe_face",
            "shortNames": [
                "giraffe_face"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 42,
            "tts": "giraffe",
            "keywords": [
                "giraffe",
                "spots"
            ]
        },
        {
            "unified": "1F418",
            "name": "ELEPHANT",
            "native": "🐘",
            "shortName": "elephant",
            "shortNames": [
                "elephant"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 2,
            "tts": "elephant",
            "keywords": [
                "elephant"
            ]
        },
        {
            "unified": "1F98F",
            "name": "RHINOCEROS",
            "native": "🦏",
            "shortName": "rhinoceros",
            "shortNames": [
                "rhinoceros"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 39,
            "tts": "rhinoceros",
            "keywords": [
                "rhinoceros"
            ]
        },
        {
            "unified": "1F42D",
            "name": "MOUSE FACE",
            "native": "🐭",
            "shortName": "mouse",
            "shortNames": [
                "mouse"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 23,
            "tts": "mouse face",
            "keywords": [
                "face",
                "mouse"
            ]
        },
        {
            "unified": "1F401",
            "name": "MOUSE",
            "native": "🐁",
            "shortName": "mouse2",
            "shortNames": [
                "mouse2"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 31,
            "tts": "mouse",
            "keywords": [
                "mouse"
            ]
        },
        {
            "unified": "1F400",
            "name": "RAT",
            "native": "🐀",
            "shortName": "rat",
            "shortNames": [
                "rat"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 30,
            "tts": "rat",
            "keywords": [
                "rat"
            ]
        },
        {
            "unified": "1F439",
            "name": "HAMSTER FACE",
            "native": "🐹",
            "shortName": "hamster",
            "shortNames": [
                "hamster"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 35,
            "tts": "hamster face",
            "keywords": [
                "face",
                "hamster",
                "pet"
            ]
        },
        {
            "unified": "1F430",
            "name": "RABBIT FACE",
            "native": "🐰",
            "shortName": "rabbit",
            "shortNames": [
                "rabbit"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 26,
            "tts": "rabbit face",
            "keywords": [
                "bunny",
                "face",
                "pet",
                "rabbit"
            ]
        },
        {
            "unified": "1F407",
            "name": "RABBIT",
            "native": "🐇",
            "shortName": "rabbit2",
            "shortNames": [
                "rabbit2"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 37,
            "tts": "rabbit",
            "keywords": [
                "bunny",
                "pet",
                "rabbit"
            ]
        },
        {
            "unified": "1F43F-FE0F",
            "name": null,
            "native": "🐿️",
            "shortName": "chipmunk",
            "shortNames": [
                "chipmunk"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 41
        },
        {
            "unified": "1F994",
            "name": "HEDGEHOG",
            "native": "🦔",
            "shortName": "hedgehog",
            "shortNames": [
                "hedgehog"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 44,
            "tts": "hedgehog",
            "keywords": [
                "hedgehog",
                "spiny"
            ]
        },
        {
            "unified": "1F987",
            "name": "BAT",
            "native": "🦇",
            "shortName": "bat",
            "shortNames": [
                "bat"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 31,
            "tts": "bat",
            "keywords": [
                "bat",
                "vampire"
            ]
        },
        {
            "unified": "1F43B",
            "name": "BEAR FACE",
            "native": "🐻",
            "shortName": "bear",
            "shortNames": [
                "bear"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 37,
            "tts": "bear face",
            "keywords": [
                "bear",
                "face"
            ]
        },
        {
            "unified": "1F428",
            "name": "KOALA",
            "native": "🐨",
            "shortName": "koala",
            "shortNames": [
                "koala"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 18,
            "tts": "koala",
            "keywords": [
                "bear",
                "koala"
            ]
        },
        {
            "unified": "1F43C",
            "name": "PANDA FACE",
            "native": "🐼",
            "shortName": "panda_face",
            "shortNames": [
                "panda_face"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 38,
            "tts": "panda face",
            "keywords": [
                "face",
                "panda"
            ]
        },
        {
            "unified": "1F43E",
            "name": "PAW PRINTS",
            "native": "🐾",
            "shortName": "feet",
            "shortNames": [
                "feet",
                "paw_prints"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 40,
            "tts": "paw prints",
            "keywords": [
                "feet",
                "paw",
                "paw prints",
                "print"
            ]
        },
        {
            "unified": "1F983",
            "name": "TURKEY",
            "native": "🦃",
            "shortName": "turkey",
            "shortNames": [
                "turkey"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 27,
            "tts": "turkey",
            "keywords": [
                "bird",
                "turkey"
            ]
        },
        {
            "unified": "1F414",
            "name": "CHICKEN",
            "native": "🐔",
            "shortName": "chicken",
            "shortNames": [
                "chicken"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 50,
            "tts": "chicken",
            "keywords": [
                "bird",
                "chicken"
            ]
        },
        {
            "unified": "1F413",
            "name": "ROOSTER",
            "native": "🐓",
            "shortName": "rooster",
            "shortNames": [
                "rooster"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 49,
            "tts": "rooster",
            "keywords": [
                "bird",
                "rooster"
            ]
        },
        {
            "unified": "1F423",
            "name": "HATCHING CHICK",
            "native": "🐣",
            "shortName": "hatching_chick",
            "shortNames": [
                "hatching_chick"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 13,
            "tts": "hatching chick",
            "keywords": [
                "baby",
                "bird",
                "chick",
                "hatching"
            ]
        },
        {
            "unified": "1F424",
            "name": "BABY CHICK",
            "native": "🐤",
            "shortName": "baby_chick",
            "shortNames": [
                "baby_chick"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 14,
            "tts": "baby chick",
            "keywords": [
                "baby",
                "bird",
                "chick"
            ]
        },
        {
            "unified": "1F425",
            "name": "FRONT-FACING BABY CHICK",
            "native": "🐥",
            "shortName": "hatched_chick",
            "shortNames": [
                "hatched_chick"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 15,
            "tts": "front-facing baby chick",
            "keywords": [
                "baby",
                "bird",
                "chick",
                "front-facing baby chick"
            ]
        },
        {
            "unified": "1F426",
            "name": "BIRD",
            "native": "🐦",
            "shortName": "bird",
            "shortNames": [
                "bird"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 16,
            "tts": "bird",
            "keywords": [
                "bird"
            ]
        },
        {
            "unified": "1F427",
            "name": "PENGUIN",
            "native": "🐧",
            "shortName": "penguin",
            "shortNames": [
                "penguin"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 17,
            "tts": "penguin",
            "keywords": [
                "bird",
                "penguin"
            ]
        },
        {
            "unified": "1F54A-FE0F",
            "name": null,
            "native": "🕊️",
            "shortName": "dove_of_peace",
            "shortNames": [
                "dove_of_peace"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 28,
            "sheetY": 13
        },
        {
            "unified": "1F985",
            "name": "EAGLE",
            "native": "🦅",
            "shortName": "eagle",
            "shortNames": [
                "eagle"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 29,
            "tts": "eagle",
            "keywords": [
                "bird",
                "eagle"
            ]
        },
        {
            "unified": "1F986",
            "name": "DUCK",
            "native": "🦆",
            "shortName": "duck",
            "shortNames": [
                "duck"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 30,
            "tts": "duck",
            "keywords": [
                "bird",
                "duck"
            ]
        },
        {
            "unified": "1F989",
            "name": "OWL",
            "native": "🦉",
            "shortName": "owl",
            "shortNames": [
                "owl"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 33,
            "tts": "owl",
            "keywords": [
                "bird",
                "owl",
                "wise"
            ]
        },
        {
            "unified": "1F438",
            "name": "FROG FACE",
            "native": "🐸",
            "shortName": "frog",
            "shortNames": [
                "frog"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 34,
            "tts": "frog face",
            "keywords": [
                "face",
                "frog"
            ]
        },
        {
            "unified": "1F40A",
            "name": "CROCODILE",
            "native": "🐊",
            "shortName": "crocodile",
            "shortNames": [
                "crocodile"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 40,
            "tts": "crocodile",
            "keywords": [
                "crocodile"
            ]
        },
        {
            "unified": "1F422",
            "name": "TURTLE",
            "native": "🐢",
            "shortName": "turtle",
            "shortNames": [
                "turtle"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 12,
            "tts": "turtle",
            "keywords": [
                "terrapin",
                "tortoise",
                "turtle"
            ]
        },
        {
            "unified": "1F98E",
            "name": "LIZARD",
            "native": "🦎",
            "shortName": "lizard",
            "shortNames": [
                "lizard"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 38,
            "tts": "lizard",
            "keywords": [
                "lizard",
                "reptile"
            ]
        },
        {
            "unified": "1F40D",
            "name": "SNAKE",
            "native": "🐍",
            "shortName": "snake",
            "shortNames": [
                "snake"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 43,
            "tts": "snake",
            "keywords": [
                "bearer",
                "Ophiuchus",
                "serpent",
                "snake",
                "zodiac"
            ]
        },
        {
            "unified": "1F432",
            "name": "DRAGON FACE",
            "native": "🐲",
            "shortName": "dragon_face",
            "shortNames": [
                "dragon_face"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 28,
            "tts": "dragon face",
            "keywords": [
                "dragon",
                "face",
                "fairy tale"
            ]
        },
        {
            "unified": "1F409",
            "name": "DRAGON",
            "native": "🐉",
            "shortName": "dragon",
            "shortNames": [
                "dragon"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 39,
            "tts": "dragon",
            "keywords": [
                "dragon",
                "fairy tale"
            ]
        },
        {
            "unified": "1F995",
            "name": "SAUROPOD",
            "native": "🦕",
            "shortName": "sauropod",
            "shortNames": [
                "sauropod"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 45,
            "tts": "sauropod",
            "keywords": [
                "brachiosaurus",
                "brontosaurus",
                "diplodocus",
                "sauropod"
            ]
        },
        {
            "unified": "1F996",
            "name": "T-REX",
            "native": "🦖",
            "shortName": "t-rex",
            "shortNames": [
                "t-rex"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 46,
            "tts": "T-Rex",
            "keywords": [
                "T-Rex",
                "Tyrannosaurus Rex"
            ]
        },
        {
            "unified": "1F433",
            "name": "SPOUTING WHALE",
            "native": "🐳",
            "shortName": "whale",
            "shortNames": [
                "whale"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 29,
            "tts": "spouting whale",
            "keywords": [
                "face",
                "spouting",
                "whale"
            ]
        },
        {
            "unified": "1F40B",
            "name": "WHALE",
            "native": "🐋",
            "shortName": "whale2",
            "shortNames": [
                "whale2"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 41,
            "tts": "whale",
            "keywords": [
                "whale"
            ]
        },
        {
            "unified": "1F42C",
            "name": "DOLPHIN",
            "native": "🐬",
            "shortName": "dolphin",
            "shortNames": [
                "dolphin",
                "flipper"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 22,
            "tts": "dolphin",
            "keywords": [
                "dolphin",
                "flipper"
            ]
        },
        {
            "unified": "1F41F",
            "name": "FISH",
            "native": "🐟",
            "shortName": "fish",
            "shortNames": [
                "fish"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 9,
            "tts": "fish",
            "keywords": [
                "fish",
                "Pisces",
                "zodiac"
            ]
        },
        {
            "unified": "1F420",
            "name": "TROPICAL FISH",
            "native": "🐠",
            "shortName": "tropical_fish",
            "shortNames": [
                "tropical_fish"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 10,
            "tts": "tropical fish",
            "keywords": [
                "fish",
                "tropical"
            ]
        },
        {
            "unified": "1F421",
            "name": "BLOWFISH",
            "native": "🐡",
            "shortName": "blowfish",
            "shortNames": [
                "blowfish"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 11,
            "tts": "blowfish",
            "keywords": [
                "blowfish",
                "fish"
            ]
        },
        {
            "unified": "1F988",
            "name": "SHARK",
            "native": "🦈",
            "shortName": "shark",
            "shortNames": [
                "shark"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 32,
            "tts": "shark",
            "keywords": [
                "fish",
                "shark"
            ]
        },
        {
            "unified": "1F419",
            "name": "OCTOPUS",
            "native": "🐙",
            "shortName": "octopus",
            "shortNames": [
                "octopus"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 3,
            "tts": "octopus",
            "keywords": [
                "octopus"
            ]
        },
        {
            "unified": "1F41A",
            "name": "SPIRAL SHELL",
            "native": "🐚",
            "shortName": "shell",
            "shortNames": [
                "shell"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 4,
            "tts": "spiral shell",
            "keywords": [
                "shell",
                "spiral"
            ]
        },
        {
            "unified": "1F980",
            "name": "CRAB",
            "native": "🦀",
            "shortName": "crab",
            "shortNames": [
                "crab"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 24,
            "tts": "crab",
            "keywords": [
                "Cancer",
                "crab",
                "zodiac"
            ]
        },
        {
            "unified": "1F990",
            "name": "SHRIMP",
            "native": "🦐",
            "shortName": "shrimp",
            "shortNames": [
                "shrimp"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 40,
            "tts": "shrimp",
            "keywords": [
                "food",
                "shellfish",
                "shrimp",
                "small"
            ]
        },
        {
            "unified": "1F991",
            "name": "SQUID",
            "native": "🦑",
            "shortName": "squid",
            "shortNames": [
                "squid"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 41,
            "tts": "squid",
            "keywords": [
                "food",
                "molusc",
                "squid"
            ]
        },
        {
            "unified": "1F40C",
            "name": "SNAIL",
            "native": "🐌",
            "shortName": "snail",
            "shortNames": [
                "snail"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 42,
            "tts": "snail",
            "keywords": [
                "snail"
            ]
        },
        {
            "unified": "1F98B",
            "name": "BUTTERFLY",
            "native": "🦋",
            "shortName": "butterfly",
            "shortNames": [
                "butterfly"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 35,
            "tts": "butterfly",
            "keywords": [
                "butterfly",
                "insect",
                "pretty"
            ]
        },
        {
            "unified": "1F41B",
            "name": "BUG",
            "native": "🐛",
            "shortName": "bug",
            "shortNames": [
                "bug"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 5,
            "tts": "bug",
            "keywords": [
                "bug",
                "insect"
            ]
        },
        {
            "unified": "1F41C",
            "name": "ANT",
            "native": "🐜",
            "shortName": "ant",
            "shortNames": [
                "ant"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 6,
            "tts": "ant",
            "keywords": [
                "ant",
                "insect"
            ]
        },
        {
            "unified": "1F41D",
            "name": "HONEYBEE",
            "native": "🐝",
            "shortName": "bee",
            "shortNames": [
                "bee",
                "honeybee"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 7,
            "tts": "honeybee",
            "keywords": [
                "bee",
                "honeybee",
                "insect"
            ]
        },
        {
            "unified": "1F41E",
            "name": "LADY BEETLE",
            "native": "🐞",
            "shortName": "beetle",
            "shortNames": [
                "beetle"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 13,
            "sheetY": 8,
            "tts": "lady beetle",
            "keywords": [
                "beetle",
                "insect",
                "lady beetle",
                "ladybird",
                "ladybug"
            ]
        },
        {
            "unified": "1F997",
            "name": "CRICKET",
            "native": "🦗",
            "shortName": "cricket",
            "shortNames": [
                "cricket"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 47,
            "tts": "cricket",
            "keywords": [
                "cricket",
                "grasshopper"
            ]
        },
        {
            "unified": "1F577-FE0F",
            "name": null,
            "native": "🕷️",
            "shortName": "spider",
            "shortNames": [
                "spider"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 29,
            "sheetY": 18
        },
        {
            "unified": "1F578-FE0F",
            "name": null,
            "native": "🕸️",
            "shortName": "spider_web",
            "shortNames": [
                "spider_web"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 29,
            "sheetY": 19
        },
        {
            "unified": "1F982",
            "name": "SCORPION",
            "native": "🦂",
            "shortName": "scorpion",
            "shortNames": [
                "scorpion"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 42,
            "sheetY": 26,
            "tts": "scorpion",
            "keywords": [
                "scorpio",
                "Scorpio",
                "scorpion",
                "zodiac"
            ]
        },
        {
            "unified": "1F490",
            "name": "BOUQUET",
            "native": "💐",
            "shortName": "bouquet",
            "shortNames": [
                "bouquet"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 24,
            "sheetY": 42,
            "tts": "bouquet",
            "keywords": [
                "bouquet",
                "flower"
            ]
        },
        {
            "unified": "1F338",
            "name": "CHERRY BLOSSOM",
            "native": "🌸",
            "shortName": "cherry_blossom",
            "shortNames": [
                "cherry_blossom"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 6,
            "sheetY": 46,
            "tts": "cherry blossom",
            "keywords": [
                "blossom",
                "cherry",
                "flower"
            ]
        },
        {
            "unified": "1F4AE",
            "name": "WHITE FLOWER",
            "native": "💮",
            "shortName": "white_flower",
            "shortNames": [
                "white_flower"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 25,
            "sheetY": 25,
            "tts": "white flower",
            "keywords": [
                "flower",
                "white flower"
            ]
        },
        {
            "unified": "1F3F5-FE0F",
            "name": null,
            "native": "🏵️",
            "shortName": "rosette",
            "shortNames": [
                "rosette"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 12,
            "sheetY": 20
        },
        {
            "unified": "1F339",
            "name": "ROSE",
            "native": "🌹",
            "shortName": "rose",
            "shortNames": [
                "rose"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 6,
            "sheetY": 47,
            "tts": "rose",
            "keywords": [
                "flower",
                "rose"
            ]
        },
        {
            "unified": "1F940",
            "name": "WILTED FLOWER",
            "native": "🥀",
            "shortName": "wilted_flower",
            "shortNames": [
                "wilted_flower"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 41,
            "sheetY": 36,
            "tts": "wilted flower",
            "keywords": [
                "flower",
                "wilted"
            ]
        },
        {
            "unified": "1F33A",
            "name": "HIBISCUS",
            "native": "🌺",
            "shortName": "hibiscus",
            "shortNames": [
                "hibiscus"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 6,
            "sheetY": 48,
            "tts": "hibiscus",
            "keywords": [
                "flower",
                "hibiscus"
            ]
        },
        {
            "unified": "1F33B",
            "name": "SUNFLOWER",
            "native": "🌻",
            "shortName": "sunflower",
            "shortNames": [
                "sunflower"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 6,
            "sheetY": 49,
            "tts": "sunflower",
            "keywords": [
                "flower",
                "sun",
                "sunflower"
            ]
        },
        {
            "unified": "1F33C",
            "name": "BLOSSOM",
            "native": "🌼",
            "shortName": "blossom",
            "shortNames": [
                "blossom"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 6,
            "sheetY": 50,
            "tts": "blossom",
            "keywords": [
                "blossom",
                "flower"
            ]
        },
        {
            "unified": "1F337",
            "name": "TULIP",
            "native": "🌷",
            "shortName": "tulip",
            "shortNames": [
                "tulip"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 6,
            "sheetY": 45,
            "tts": "tulip",
            "keywords": [
                "flower",
                "tulip"
            ]
        },
        {
            "unified": "1F331",
            "name": "SEEDLING",
            "native": "🌱",
            "shortName": "seedling",
            "shortNames": [
                "seedling"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 6,
            "sheetY": 39,
            "tts": "seedling",
            "keywords": [
                "seedling",
                "young"
            ]
        },
        {
            "unified": "1F332",
            "name": "EVERGREEN TREE",
            "native": "🌲",
            "shortName": "evergreen_tree",
            "shortNames": [
                "evergreen_tree"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 6,
            "sheetY": 40,
            "tts": "evergreen tree",
            "keywords": [
                "evergreen tree",
                "tree"
            ]
        },
        {
            "unified": "1F333",
            "name": "DECIDUOUS TREE",
            "native": "🌳",
            "shortName": "deciduous_tree",
            "shortNames": [
                "deciduous_tree"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 6,
            "sheetY": 41,
            "tts": "deciduous tree",
            "keywords": [
                "deciduous",
                "shedding",
                "tree"
            ]
        },
        {
            "unified": "1F334",
            "name": "PALM TREE",
            "native": "🌴",
            "shortName": "palm_tree",
            "shortNames": [
                "palm_tree"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 6,
            "sheetY": 42,
            "tts": "palm tree",
            "keywords": [
                "palm",
                "tree"
            ]
        },
        {
            "unified": "1F335",
            "name": "CACTUS",
            "native": "🌵",
            "shortName": "cactus",
            "shortNames": [
                "cactus"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 6,
            "sheetY": 43,
            "tts": "cactus",
            "keywords": [
                "cactus",
                "plant"
            ]
        },
        {
            "unified": "1F33E",
            "name": "EAR OF RICE",
            "native": "🌾",
            "shortName": "ear_of_rice",
            "shortNames": [
                "ear_of_rice"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 7,
            "sheetY": 0,
            "tts": "sheaf of rice",
            "keywords": [
                "ear",
                "grain",
                "rice",
                "sheaf of rice"
            ]
        },
        {
            "unified": "1F33F",
            "name": "HERB",
            "native": "🌿",
            "shortName": "herb",
            "shortNames": [
                "herb"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 7,
            "sheetY": 1,
            "tts": "herb",
            "keywords": [
                "herb",
                "leaf"
            ]
        },
        {
            "unified": "2618-FE0F",
            "name": null,
            "native": "☘️",
            "shortName": "shamrock",
            "shortNames": [
                "shamrock"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 47,
            "sheetY": 25
        },
        {
            "unified": "1F340",
            "name": "FOUR LEAF CLOVER",
            "native": "🍀",
            "shortName": "four_leaf_clover",
            "shortNames": [
                "four_leaf_clover"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 7,
            "sheetY": 2,
            "tts": "four leaf clover",
            "keywords": [
                "4",
                "clover",
                "four",
                "four-leaf clover",
                "leaf"
            ]
        },
        {
            "unified": "1F341",
            "name": "MAPLE LEAF",
            "native": "🍁",
            "shortName": "maple_leaf",
            "shortNames": [
                "maple_leaf"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 7,
            "sheetY": 3,
            "tts": "maple leaf",
            "keywords": [
                "falling",
                "leaf",
                "maple"
            ]
        },
        {
            "unified": "1F342",
            "name": "FALLEN LEAF",
            "native": "🍂",
            "shortName": "fallen_leaf",
            "shortNames": [
                "fallen_leaf"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 7,
            "sheetY": 4,
            "tts": "fallen leaf",
            "keywords": [
                "fallen leaf",
                "falling",
                "leaf"
            ]
        },
        {
            "unified": "1F343",
            "name": "LEAF FLUTTERING IN WIND",
            "native": "🍃",
            "shortName": "leaves",
            "shortNames": [
                "leaves"
            ],
            "text": null,
            "texts": null,
            "category": "Animals & Nature",
            "sheetX": 7,
            "sheetY": 5,
            "tts": "leaf fluttering in wind",
            "keywords": [
                "blow",
                "flutter",
                "leaf",
                "leaf fluttering in wind",
                "wind"
            ]
        },
        {
            "unified": "1F347",
            "name": "GRAPES",
            "native": "🍇",
            "shortName": "grapes",
            "shortNames": [
                "grapes"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 9,
            "tts": "grapes",
            "keywords": [
                "fruit",
                "grape",
                "grapes"
            ]
        },
        {
            "unified": "1F348",
            "name": "MELON",
            "native": "🍈",
            "shortName": "melon",
            "shortNames": [
                "melon"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 10,
            "tts": "melon",
            "keywords": [
                "fruit",
                "melon"
            ]
        },
        {
            "unified": "1F349",
            "name": "WATERMELON",
            "native": "🍉",
            "shortName": "watermelon",
            "shortNames": [
                "watermelon"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 11,
            "tts": "watermelon",
            "keywords": [
                "fruit",
                "watermelon"
            ]
        },
        {
            "unified": "1F34A",
            "name": "TANGERINE",
            "native": "🍊",
            "shortName": "tangerine",
            "shortNames": [
                "tangerine"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 12,
            "tts": "tangerine",
            "keywords": [
                "fruit",
                "orange",
                "tangerine"
            ]
        },
        {
            "unified": "1F34B",
            "name": "LEMON",
            "native": "🍋",
            "shortName": "lemon",
            "shortNames": [
                "lemon"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 13,
            "tts": "lemon",
            "keywords": [
                "citrus",
                "fruit",
                "lemon"
            ]
        },
        {
            "unified": "1F34C",
            "name": "BANANA",
            "native": "🍌",
            "shortName": "banana",
            "shortNames": [
                "banana"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 14,
            "tts": "banana",
            "keywords": [
                "banana",
                "fruit"
            ]
        },
        {
            "unified": "1F34D",
            "name": "PINEAPPLE",
            "native": "🍍",
            "shortName": "pineapple",
            "shortNames": [
                "pineapple"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 15,
            "tts": "pineapple",
            "keywords": [
                "fruit",
                "pineapple"
            ]
        },
        {
            "unified": "1F34E",
            "name": "RED APPLE",
            "native": "🍎",
            "shortName": "apple",
            "shortNames": [
                "apple"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 16,
            "tts": "red apple",
            "keywords": [
                "apple",
                "fruit",
                "red"
            ]
        },
        {
            "unified": "1F34F",
            "name": "GREEN APPLE",
            "native": "🍏",
            "shortName": "green_apple",
            "shortNames": [
                "green_apple"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 17,
            "tts": "green apple",
            "keywords": [
                "apple",
                "fruit",
                "green"
            ]
        },
        {
            "unified": "1F350",
            "name": "PEAR",
            "native": "🍐",
            "shortName": "pear",
            "shortNames": [
                "pear"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 18,
            "tts": "pear",
            "keywords": [
                "fruit",
                "pear"
            ]
        },
        {
            "unified": "1F351",
            "name": "PEACH",
            "native": "🍑",
            "shortName": "peach",
            "shortNames": [
                "peach"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 19,
            "tts": "peach",
            "keywords": [
                "fruit",
                "peach"
            ]
        },
        {
            "unified": "1F352",
            "name": "CHERRIES",
            "native": "🍒",
            "shortName": "cherries",
            "shortNames": [
                "cherries"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 20,
            "tts": "cherries",
            "keywords": [
                "berries",
                "cherries",
                "cherry",
                "fruit",
                "red"
            ]
        },
        {
            "unified": "1F353",
            "name": "STRAWBERRY",
            "native": "🍓",
            "shortName": "strawberry",
            "shortNames": [
                "strawberry"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 21,
            "tts": "strawberry",
            "keywords": [
                "berry",
                "fruit",
                "strawberry"
            ]
        },
        {
            "unified": "1F95D",
            "name": "KIWIFRUIT",
            "native": "🥝",
            "shortName": "kiwifruit",
            "shortNames": [
                "kiwifruit"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 9,
            "tts": "kiwi fruit",
            "keywords": [
                "food",
                "fruit",
                "kiwi"
            ]
        },
        {
            "unified": "1F345",
            "name": "TOMATO",
            "native": "🍅",
            "shortName": "tomato",
            "shortNames": [
                "tomato"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 7,
            "tts": "tomato",
            "keywords": [
                "fruit",
                "tomato",
                "vegetable"
            ]
        },
        {
            "unified": "1F965",
            "name": "COCONUT",
            "native": "🥥",
            "shortName": "coconut",
            "shortNames": [
                "coconut"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 17,
            "tts": "coconut",
            "keywords": [
                "coconut",
                "palm",
                "piña colada"
            ]
        },
        {
            "unified": "1F951",
            "name": "AVOCADO",
            "native": "🥑",
            "shortName": "avocado",
            "shortNames": [
                "avocado"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 41,
            "sheetY": 49,
            "tts": "avocado",
            "keywords": [
                "avocado",
                "food",
                "fruit"
            ]
        },
        {
            "unified": "1F346",
            "name": "AUBERGINE",
            "native": "🍆",
            "shortName": "eggplant",
            "shortNames": [
                "eggplant"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 8,
            "tts": "eggplant",
            "keywords": [
                "aubergine",
                "eggplant",
                "vegetable"
            ]
        },
        {
            "unified": "1F954",
            "name": "POTATO",
            "native": "🥔",
            "shortName": "potato",
            "shortNames": [
                "potato"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 0,
            "tts": "potato",
            "keywords": [
                "food",
                "potato",
                "vegetable"
            ]
        },
        {
            "unified": "1F955",
            "name": "CARROT",
            "native": "🥕",
            "shortName": "carrot",
            "shortNames": [
                "carrot"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 1,
            "tts": "carrot",
            "keywords": [
                "carrot",
                "food",
                "vegetable"
            ]
        },
        {
            "unified": "1F33D",
            "name": "EAR OF MAIZE",
            "native": "🌽",
            "shortName": "corn",
            "shortNames": [
                "corn"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 6,
            "sheetY": 51,
            "tts": "ear of corn",
            "keywords": [
                "corn",
                "ear",
                "ear of corn",
                "maize",
                "maze"
            ]
        },
        {
            "unified": "1F336-FE0F",
            "name": null,
            "native": "🌶️",
            "shortName": "hot_pepper",
            "shortNames": [
                "hot_pepper"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 6,
            "sheetY": 44
        },
        {
            "unified": "1F952",
            "name": "CUCUMBER",
            "native": "🥒",
            "shortName": "cucumber",
            "shortNames": [
                "cucumber"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 41,
            "sheetY": 50,
            "tts": "cucumber",
            "keywords": [
                "cucumber",
                "food",
                "pickle",
                "vegetable"
            ]
        },
        {
            "unified": "1F966",
            "name": "BROCCOLI",
            "native": "🥦",
            "shortName": "broccoli",
            "shortNames": [
                "broccoli"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 18,
            "tts": "broccoli",
            "keywords": [
                "broccoli",
                "wild cabbage"
            ]
        },
        {
            "unified": "1F344",
            "name": "MUSHROOM",
            "native": "🍄",
            "shortName": "mushroom",
            "shortNames": [
                "mushroom"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 6,
            "tts": "mushroom",
            "keywords": [
                "mushroom",
                "toadstool"
            ]
        },
        {
            "unified": "1F95C",
            "name": "PEANUTS",
            "native": "🥜",
            "shortName": "peanuts",
            "shortNames": [
                "peanuts"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 8,
            "tts": "peanuts",
            "keywords": [
                "food",
                "nut",
                "peanut",
                "peanuts",
                "vegetable"
            ]
        },
        {
            "unified": "1F330",
            "name": "CHESTNUT",
            "native": "🌰",
            "shortName": "chestnut",
            "shortNames": [
                "chestnut"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 6,
            "sheetY": 38,
            "tts": "chestnut",
            "keywords": [
                "chestnut",
                "plant"
            ]
        },
        {
            "unified": "1F35E",
            "name": "BREAD",
            "native": "🍞",
            "shortName": "bread",
            "shortNames": [
                "bread"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 32,
            "tts": "bread",
            "keywords": [
                "bread",
                "loaf"
            ]
        },
        {
            "unified": "1F950",
            "name": "CROISSANT",
            "native": "🥐",
            "shortName": "croissant",
            "shortNames": [
                "croissant"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 41,
            "sheetY": 48,
            "tts": "croissant",
            "keywords": [
                "bread",
                "crescent roll",
                "croissant",
                "food",
                "french"
            ]
        },
        {
            "unified": "1F956",
            "name": "BAGUETTE BREAD",
            "native": "🥖",
            "shortName": "baguette_bread",
            "shortNames": [
                "baguette_bread"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 2,
            "tts": "baguette bread",
            "keywords": [
                "baguette",
                "bread",
                "food",
                "french"
            ]
        },
        {
            "unified": "1F968",
            "name": "PRETZEL",
            "native": "🥨",
            "shortName": "pretzel",
            "shortNames": [
                "pretzel"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 20,
            "tts": "pretzel",
            "keywords": [
                "pretzel",
                "twisted"
            ]
        },
        {
            "unified": "1F95E",
            "name": "PANCAKES",
            "native": "🥞",
            "shortName": "pancakes",
            "shortNames": [
                "pancakes"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 10,
            "tts": "pancakes",
            "keywords": [
                "crêpe",
                "food",
                "hotcake",
                "pancake",
                "pancakes"
            ]
        },
        {
            "unified": "1F9C0",
            "name": "CHEESE WEDGE",
            "native": "🧀",
            "shortName": "cheese_wedge",
            "shortNames": [
                "cheese_wedge"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 48,
            "tts": "cheese wedge",
            "keywords": [
                "cheese",
                "cheese wedge"
            ]
        },
        {
            "unified": "1F356",
            "name": "MEAT ON BONE",
            "native": "🍖",
            "shortName": "meat_on_bone",
            "shortNames": [
                "meat_on_bone"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 24,
            "tts": "meat on bone",
            "keywords": [
                "bone",
                "meat",
                "meat on bone"
            ]
        },
        {
            "unified": "1F357",
            "name": "POULTRY LEG",
            "native": "🍗",
            "shortName": "poultry_leg",
            "shortNames": [
                "poultry_leg"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 25,
            "tts": "poultry leg",
            "keywords": [
                "bone",
                "chicken",
                "drumstick",
                "leg",
                "poultry"
            ]
        },
        {
            "unified": "1F969",
            "name": "CUT OF MEAT",
            "native": "🥩",
            "shortName": "cut_of_meat",
            "shortNames": [
                "cut_of_meat"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 21,
            "tts": "cut of meat",
            "keywords": [
                "chop",
                "cut of meat",
                "lambchop",
                "porkchop",
                "steak"
            ]
        },
        {
            "unified": "1F953",
            "name": "BACON",
            "native": "🥓",
            "shortName": "bacon",
            "shortNames": [
                "bacon"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 41,
            "sheetY": 51,
            "tts": "bacon",
            "keywords": [
                "bacon",
                "food",
                "meat"
            ]
        },
        {
            "unified": "1F354",
            "name": "HAMBURGER",
            "native": "🍔",
            "shortName": "hamburger",
            "shortNames": [
                "hamburger"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 22,
            "tts": "hamburger",
            "keywords": [
                "burger",
                "hamburger"
            ]
        },
        {
            "unified": "1F35F",
            "name": "FRENCH FRIES",
            "native": "🍟",
            "shortName": "fries",
            "shortNames": [
                "fries"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 33,
            "tts": "french fries",
            "keywords": [
                "french",
                "fries"
            ]
        },
        {
            "unified": "1F355",
            "name": "SLICE OF PIZZA",
            "native": "🍕",
            "shortName": "pizza",
            "shortNames": [
                "pizza"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 23,
            "tts": "pizza",
            "keywords": [
                "cheese",
                "pizza",
                "slice"
            ]
        },
        {
            "unified": "1F32D",
            "name": "HOT DOG",
            "native": "🌭",
            "shortName": "hotdog",
            "shortNames": [
                "hotdog"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 6,
            "sheetY": 35,
            "tts": "hot dog",
            "keywords": [
                "frankfurter",
                "hot dog",
                "hotdog",
                "sausage"
            ]
        },
        {
            "unified": "1F96A",
            "name": "SANDWICH",
            "native": "🥪",
            "shortName": "sandwich",
            "shortNames": [
                "sandwich"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 22,
            "tts": "sandwich",
            "keywords": [
                "bread",
                "sandwich"
            ]
        },
        {
            "unified": "1F32E",
            "name": "TACO",
            "native": "🌮",
            "shortName": "taco",
            "shortNames": [
                "taco"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 6,
            "sheetY": 36,
            "tts": "taco",
            "keywords": [
                "mexican",
                "taco"
            ]
        },
        {
            "unified": "1F32F",
            "name": "BURRITO",
            "native": "🌯",
            "shortName": "burrito",
            "shortNames": [
                "burrito"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 6,
            "sheetY": 37,
            "tts": "burrito",
            "keywords": [
                "burrito",
                "mexican",
                "wrap"
            ]
        },
        {
            "unified": "1F959",
            "name": "STUFFED FLATBREAD",
            "native": "🥙",
            "shortName": "stuffed_flatbread",
            "shortNames": [
                "stuffed_flatbread"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 5,
            "tts": "stuffed flatbread",
            "keywords": [
                "falafel",
                "flatbread",
                "food",
                "gyro",
                "kebab",
                "stuffed"
            ]
        },
        {
            "unified": "1F95A",
            "name": "EGG",
            "native": "🥚",
            "shortName": "egg",
            "shortNames": [
                "egg"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 6,
            "tts": "egg",
            "keywords": [
                "egg",
                "food"
            ]
        },
        {
            "unified": "1F373",
            "name": "COOKING",
            "native": "🍳",
            "shortName": "fried_egg",
            "shortNames": [
                "fried_egg",
                "cooking"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 8,
            "sheetY": 1,
            "tts": "cooking",
            "keywords": [
                "cooking",
                "egg",
                "frying",
                "pan"
            ]
        },
        {
            "unified": "1F958",
            "name": "SHALLOW PAN OF FOOD",
            "native": "🥘",
            "shortName": "shallow_pan_of_food",
            "shortNames": [
                "shallow_pan_of_food"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 4,
            "tts": "shallow pan of food",
            "keywords": [
                "casserole",
                "food",
                "paella",
                "pan",
                "shallow",
                "shallow pan of food"
            ]
        },
        {
            "unified": "1F372",
            "name": "POT OF FOOD",
            "native": "🍲",
            "shortName": "stew",
            "shortNames": [
                "stew"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 8,
            "sheetY": 0,
            "tts": "pot of food",
            "keywords": [
                "pot",
                "pot of food",
                "stew"
            ]
        },
        {
            "unified": "1F963",
            "name": "BOWL WITH SPOON",
            "native": "🥣",
            "shortName": "bowl_with_spoon",
            "shortNames": [
                "bowl_with_spoon"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 15,
            "tts": "bowl with spoon",
            "keywords": [
                "bowl with spoon",
                "breakfast",
                "cereal",
                "congee"
            ]
        },
        {
            "unified": "1F957",
            "name": "GREEN SALAD",
            "native": "🥗",
            "shortName": "green_salad",
            "shortNames": [
                "green_salad"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 3,
            "tts": "green salad",
            "keywords": [
                "food",
                "green",
                "salad"
            ]
        },
        {
            "unified": "1F37F",
            "name": "POPCORN",
            "native": "🍿",
            "shortName": "popcorn",
            "shortNames": [
                "popcorn"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 8,
            "sheetY": 13,
            "tts": "popcorn",
            "keywords": [
                "popcorn"
            ]
        },
        {
            "unified": "1F96B",
            "name": "CANNED FOOD",
            "native": "🥫",
            "shortName": "canned_food",
            "shortNames": [
                "canned_food"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 23,
            "tts": "canned food",
            "keywords": [
                "can",
                "canned food"
            ]
        },
        {
            "unified": "1F371",
            "name": "BENTO BOX",
            "native": "🍱",
            "shortName": "bento",
            "shortNames": [
                "bento"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 51,
            "tts": "bento box",
            "keywords": [
                "bento",
                "box"
            ]
        },
        {
            "unified": "1F358",
            "name": "RICE CRACKER",
            "native": "🍘",
            "shortName": "rice_cracker",
            "shortNames": [
                "rice_cracker"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 26,
            "tts": "rice cracker",
            "keywords": [
                "cracker",
                "rice"
            ]
        },
        {
            "unified": "1F359",
            "name": "RICE BALL",
            "native": "🍙",
            "shortName": "rice_ball",
            "shortNames": [
                "rice_ball"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 27,
            "tts": "rice ball",
            "keywords": [
                "ball",
                "Japanese",
                "rice"
            ]
        },
        {
            "unified": "1F35A",
            "name": "COOKED RICE",
            "native": "🍚",
            "shortName": "rice",
            "shortNames": [
                "rice"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 28,
            "tts": "cooked rice",
            "keywords": [
                "cooked",
                "rice"
            ]
        },
        {
            "unified": "1F35B",
            "name": "CURRY AND RICE",
            "native": "🍛",
            "shortName": "curry",
            "shortNames": [
                "curry"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 29,
            "tts": "curry rice",
            "keywords": [
                "curry",
                "rice"
            ]
        },
        {
            "unified": "1F35C",
            "name": "STEAMING BOWL",
            "native": "🍜",
            "shortName": "ramen",
            "shortNames": [
                "ramen"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 30,
            "tts": "steaming bowl",
            "keywords": [
                "bowl",
                "noodle",
                "ramen",
                "steaming"
            ]
        },
        {
            "unified": "1F35D",
            "name": "SPAGHETTI",
            "native": "🍝",
            "shortName": "spaghetti",
            "shortNames": [
                "spaghetti"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 31,
            "tts": "spaghetti",
            "keywords": [
                "pasta",
                "spaghetti"
            ]
        },
        {
            "unified": "1F360",
            "name": "ROASTED SWEET POTATO",
            "native": "🍠",
            "shortName": "sweet_potato",
            "shortNames": [
                "sweet_potato"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 34,
            "tts": "roasted sweet potato",
            "keywords": [
                "potato",
                "roasted",
                "sweet"
            ]
        },
        {
            "unified": "1F362",
            "name": "ODEN",
            "native": "🍢",
            "shortName": "oden",
            "shortNames": [
                "oden"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 36,
            "tts": "oden",
            "keywords": [
                "kebab",
                "oden",
                "seafood",
                "skewer",
                "stick"
            ]
        },
        {
            "unified": "1F363",
            "name": "SUSHI",
            "native": "🍣",
            "shortName": "sushi",
            "shortNames": [
                "sushi"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 37,
            "tts": "sushi",
            "keywords": [
                "sushi"
            ]
        },
        {
            "unified": "1F364",
            "name": "FRIED SHRIMP",
            "native": "🍤",
            "shortName": "fried_shrimp",
            "shortNames": [
                "fried_shrimp"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 38,
            "tts": "fried shrimp",
            "keywords": [
                "fried",
                "prawn",
                "shrimp",
                "tempura"
            ]
        },
        {
            "unified": "1F365",
            "name": "FISH CAKE WITH SWIRL DESIGN",
            "native": "🍥",
            "shortName": "fish_cake",
            "shortNames": [
                "fish_cake"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 39,
            "tts": "fish cake with swirl",
            "keywords": [
                "cake",
                "fish",
                "fish cake with swirl",
                "pastry",
                "swirl"
            ]
        },
        {
            "unified": "1F361",
            "name": "DANGO",
            "native": "🍡",
            "shortName": "dango",
            "shortNames": [
                "dango"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 35,
            "tts": "dango",
            "keywords": [
                "dango",
                "dessert",
                "Japanese",
                "skewer",
                "stick",
                "sweet"
            ]
        },
        {
            "unified": "1F95F",
            "name": "DUMPLING",
            "native": "🥟",
            "shortName": "dumpling",
            "shortNames": [
                "dumpling"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 11,
            "tts": "dumpling",
            "keywords": [
                "dumpling",
                "empanada",
                "gyōza",
                "jiaozi",
                "pierogi",
                "potsticker"
            ]
        },
        {
            "unified": "1F960",
            "name": "FORTUNE COOKIE",
            "native": "🥠",
            "shortName": "fortune_cookie",
            "shortNames": [
                "fortune_cookie"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 12,
            "tts": "fortune cookie",
            "keywords": [
                "fortune cookie",
                "prophecy"
            ]
        },
        {
            "unified": "1F961",
            "name": "TAKEOUT BOX",
            "native": "🥡",
            "shortName": "takeout_box",
            "shortNames": [
                "takeout_box"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 13,
            "tts": "takeout box",
            "keywords": [
                "oyster pail",
                "takeout box"
            ]
        },
        {
            "unified": "1F366",
            "name": "SOFT ICE CREAM",
            "native": "🍦",
            "shortName": "icecream",
            "shortNames": [
                "icecream"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 40,
            "tts": "soft ice cream",
            "keywords": [
                "cream",
                "dessert",
                "ice",
                "icecream",
                "soft",
                "sweet"
            ]
        },
        {
            "unified": "1F367",
            "name": "SHAVED ICE",
            "native": "🍧",
            "shortName": "shaved_ice",
            "shortNames": [
                "shaved_ice"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 41,
            "tts": "shaved ice",
            "keywords": [
                "dessert",
                "ice",
                "shaved",
                "sweet"
            ]
        },
        {
            "unified": "1F368",
            "name": "ICE CREAM",
            "native": "🍨",
            "shortName": "ice_cream",
            "shortNames": [
                "ice_cream"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 42,
            "tts": "ice cream",
            "keywords": [
                "cream",
                "dessert",
                "ice",
                "sweet"
            ]
        },
        {
            "unified": "1F369",
            "name": "DOUGHNUT",
            "native": "🍩",
            "shortName": "doughnut",
            "shortNames": [
                "doughnut"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 43,
            "tts": "doughnut",
            "keywords": [
                "dessert",
                "donut",
                "doughnut",
                "sweet"
            ]
        },
        {
            "unified": "1F36A",
            "name": "COOKIE",
            "native": "🍪",
            "shortName": "cookie",
            "shortNames": [
                "cookie"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 44,
            "tts": "cookie",
            "keywords": [
                "cookie",
                "dessert",
                "sweet"
            ]
        },
        {
            "unified": "1F382",
            "name": "BIRTHDAY CAKE",
            "native": "🎂",
            "shortName": "birthday",
            "shortNames": [
                "birthday"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 8,
            "sheetY": 16,
            "tts": "birthday cake",
            "keywords": [
                "birthday",
                "cake",
                "celebration",
                "dessert",
                "pastry",
                "sweet"
            ]
        },
        {
            "unified": "1F370",
            "name": "SHORTCAKE",
            "native": "🍰",
            "shortName": "cake",
            "shortNames": [
                "cake"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 50,
            "tts": "shortcake",
            "keywords": [
                "cake",
                "dessert",
                "pastry",
                "shortcake",
                "slice",
                "sweet"
            ]
        },
        {
            "unified": "1F967",
            "name": "PIE",
            "native": "🥧",
            "shortName": "pie",
            "shortNames": [
                "pie"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 19,
            "tts": "pie",
            "keywords": [
                "filling",
                "pastry",
                "pie"
            ]
        },
        {
            "unified": "1F36B",
            "name": "CHOCOLATE BAR",
            "native": "🍫",
            "shortName": "chocolate_bar",
            "shortNames": [
                "chocolate_bar"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 45,
            "tts": "chocolate bar",
            "keywords": [
                "bar",
                "chocolate",
                "dessert",
                "sweet"
            ]
        },
        {
            "unified": "1F36C",
            "name": "CANDY",
            "native": "🍬",
            "shortName": "candy",
            "shortNames": [
                "candy"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 46,
            "tts": "candy",
            "keywords": [
                "candy",
                "dessert",
                "sweet"
            ]
        },
        {
            "unified": "1F36D",
            "name": "LOLLIPOP",
            "native": "🍭",
            "shortName": "lollipop",
            "shortNames": [
                "lollipop"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 47,
            "tts": "lollipop",
            "keywords": [
                "candy",
                "dessert",
                "lollipop",
                "sweet"
            ]
        },
        {
            "unified": "1F36E",
            "name": "CUSTARD",
            "native": "🍮",
            "shortName": "custard",
            "shortNames": [
                "custard"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 48,
            "tts": "custard",
            "keywords": [
                "custard",
                "dessert",
                "pudding",
                "sweet"
            ]
        },
        {
            "unified": "1F36F",
            "name": "HONEY POT",
            "native": "🍯",
            "shortName": "honey_pot",
            "shortNames": [
                "honey_pot"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 7,
            "sheetY": 49,
            "tts": "honey pot",
            "keywords": [
                "honey",
                "honeypot",
                "pot",
                "sweet"
            ]
        },
        {
            "unified": "1F37C",
            "name": "BABY BOTTLE",
            "native": "🍼",
            "shortName": "baby_bottle",
            "shortNames": [
                "baby_bottle"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 8,
            "sheetY": 10,
            "tts": "baby bottle",
            "keywords": [
                "baby",
                "bottle",
                "drink",
                "milk"
            ]
        },
        {
            "unified": "1F95B",
            "name": "GLASS OF MILK",
            "native": "🥛",
            "shortName": "glass_of_milk",
            "shortNames": [
                "glass_of_milk"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 7,
            "tts": "glass of milk",
            "keywords": [
                "drink",
                "glass",
                "glass of milk",
                "milk"
            ]
        },
        {
            "unified": "2615",
            "name": "HOT BEVERAGE",
            "native": "☕",
            "shortName": "coffee",
            "shortNames": [
                "coffee"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 47,
            "sheetY": 24,
            "tts": "hot beverage",
            "keywords": [
                "beverage",
                "coffee",
                "drink",
                "hot",
                "steaming",
                "tea"
            ]
        },
        {
            "unified": "1F375",
            "name": "TEACUP WITHOUT HANDLE",
            "native": "🍵",
            "shortName": "tea",
            "shortNames": [
                "tea"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 8,
            "sheetY": 3,
            "tts": "teacup without handle",
            "keywords": [
                "beverage",
                "cup",
                "drink",
                "tea",
                "teacup",
                "teacup without handle"
            ]
        },
        {
            "unified": "1F376",
            "name": "SAKE BOTTLE AND CUP",
            "native": "🍶",
            "shortName": "sake",
            "shortNames": [
                "sake"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 8,
            "sheetY": 4,
            "tts": "sake",
            "keywords": [
                "bar",
                "beverage",
                "bottle",
                "cup",
                "drink",
                "sake"
            ]
        },
        {
            "unified": "1F37E",
            "name": "BOTTLE WITH POPPING CORK",
            "native": "🍾",
            "shortName": "champagne",
            "shortNames": [
                "champagne"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 8,
            "sheetY": 12,
            "tts": "bottle with popping cork",
            "keywords": [
                "bar",
                "bottle",
                "bottle with popping cork",
                "cork",
                "drink",
                "popping"
            ]
        },
        {
            "unified": "1F377",
            "name": "WINE GLASS",
            "native": "🍷",
            "shortName": "wine_glass",
            "shortNames": [
                "wine_glass"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 8,
            "sheetY": 5,
            "tts": "wine glass",
            "keywords": [
                "bar",
                "beverage",
                "drink",
                "glass",
                "wine"
            ]
        },
        {
            "unified": "1F378",
            "name": "COCKTAIL GLASS",
            "native": "🍸",
            "shortName": "cocktail",
            "shortNames": [
                "cocktail"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 8,
            "sheetY": 6,
            "tts": "cocktail glass",
            "keywords": [
                "bar",
                "cocktail",
                "drink",
                "glass"
            ]
        },
        {
            "unified": "1F379",
            "name": "TROPICAL DRINK",
            "native": "🍹",
            "shortName": "tropical_drink",
            "shortNames": [
                "tropical_drink"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 8,
            "sheetY": 7,
            "tts": "tropical drink",
            "keywords": [
                "bar",
                "drink",
                "tropical"
            ]
        },
        {
            "unified": "1F37A",
            "name": "BEER MUG",
            "native": "🍺",
            "shortName": "beer",
            "shortNames": [
                "beer"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 8,
            "sheetY": 8,
            "tts": "beer mug",
            "keywords": [
                "bar",
                "beer",
                "drink",
                "mug"
            ]
        },
        {
            "unified": "1F37B",
            "name": "CLINKING BEER MUGS",
            "native": "🍻",
            "shortName": "beers",
            "shortNames": [
                "beers"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 8,
            "sheetY": 9,
            "tts": "clinking beer mugs",
            "keywords": [
                "bar",
                "beer",
                "clink",
                "clinking beer mugs",
                "drink",
                "mug"
            ]
        },
        {
            "unified": "1F942",
            "name": "CLINKING GLASSES",
            "native": "🥂",
            "shortName": "clinking_glasses",
            "shortNames": [
                "clinking_glasses"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 41,
            "sheetY": 38,
            "tts": "clinking glasses",
            "keywords": [
                "celebrate",
                "clink",
                "clinking glasses",
                "drink",
                "glass"
            ]
        },
        {
            "unified": "1F943",
            "name": "TUMBLER GLASS",
            "native": "🥃",
            "shortName": "tumbler_glass",
            "shortNames": [
                "tumbler_glass"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 41,
            "sheetY": 39,
            "tts": "tumbler glass",
            "keywords": [
                "glass",
                "liquor",
                "shot",
                "tumbler",
                "whisky"
            ]
        },
        {
            "unified": "1F964",
            "name": "CUP WITH STRAW",
            "native": "🥤",
            "shortName": "cup_with_straw",
            "shortNames": [
                "cup_with_straw"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 16,
            "tts": "cup with straw",
            "keywords": [
                "cup with straw",
                "juice",
                "soda"
            ]
        },
        {
            "unified": "1F962",
            "name": "CHOPSTICKS",
            "native": "🥢",
            "shortName": "chopsticks",
            "shortNames": [
                "chopsticks"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 42,
            "sheetY": 14,
            "tts": "chopsticks",
            "keywords": [
                "chopsticks",
                "hashi"
            ]
        },
        {
            "unified": "1F37D-FE0F",
            "name": null,
            "native": "🍽️",
            "shortName": "knife_fork_plate",
            "shortNames": [
                "knife_fork_plate"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 8,
            "sheetY": 11
        },
        {
            "unified": "1F374",
            "name": "FORK AND KNIFE",
            "native": "🍴",
            "shortName": "fork_and_knife",
            "shortNames": [
                "fork_and_knife"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 8,
            "sheetY": 2,
            "tts": "fork and knife",
            "keywords": [
                "cooking",
                "cutlery",
                "fork",
                "fork and knife",
                "knife"
            ]
        },
        {
            "unified": "1F944",
            "name": "SPOON",
            "native": "🥄",
            "shortName": "spoon",
            "shortNames": [
                "spoon"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 41,
            "sheetY": 40,
            "tts": "spoon",
            "keywords": [
                "spoon",
                "tableware"
            ]
        },
        {
            "unified": "1F52A",
            "name": "HOCHO",
            "native": "🔪",
            "shortName": "hocho",
            "shortNames": [
                "hocho",
                "knife"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 27,
            "sheetY": 44,
            "tts": "kitchen knife",
            "keywords": [
                "cooking",
                "hocho",
                "kitchen knife",
                "knife",
                "tool",
                "weapon"
            ]
        },
        {
            "unified": "1F3FA",
            "name": "AMPHORA",
            "native": "🏺",
            "shortName": "amphora",
            "shortNames": [
                "amphora"
            ],
            "text": null,
            "texts": null,
            "category": "Food & Drink",
            "sheetX": 12,
            "sheetY": 24,
            "tts": "amphora",
            "keywords": [
                "amphora",
                "Aquarius",
                "cooking",
                "drink",
                "jug",
                "tool",
                "weapon",
                "zodiac"
            ]
        },
        {
            "unified": "1F30D",
            "name": "EARTH GLOBE EUROPE-AFRICA",
            "native": "🌍",
            "shortName": "earth_africa",
            "shortNames": [
                "earth_africa"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 5,
            "tts": "globe showing Europe-Africa",
            "keywords": [
                "Africa",
                "earth",
                "Europe",
                "globe",
                "globe showing Europe-Africa",
                "world"
            ]
        },
        {
            "unified": "1F30E",
            "name": "EARTH GLOBE AMERICAS",
            "native": "🌎",
            "shortName": "earth_americas",
            "shortNames": [
                "earth_americas"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 6,
            "tts": "globe showing Americas",
            "keywords": [
                "Americas",
                "earth",
                "globe",
                "globe showing Americas",
                "world"
            ]
        },
        {
            "unified": "1F30F",
            "name": "EARTH GLOBE ASIA-AUSTRALIA",
            "native": "🌏",
            "shortName": "earth_asia",
            "shortNames": [
                "earth_asia"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 7,
            "tts": "globe showing Asia-Australia",
            "keywords": [
                "Asia",
                "Australia",
                "earth",
                "globe",
                "globe showing Asia-Australia",
                "world"
            ]
        },
        {
            "unified": "1F310",
            "name": "GLOBE WITH MERIDIANS",
            "native": "🌐",
            "shortName": "globe_with_meridians",
            "shortNames": [
                "globe_with_meridians"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 8,
            "tts": "globe with meridians",
            "keywords": [
                "earth",
                "globe",
                "globe with meridians",
                "meridians",
                "world"
            ]
        },
        {
            "unified": "1F5FA-FE0F",
            "name": null,
            "native": "🗺️",
            "shortName": "world_map",
            "shortNames": [
                "world_map"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 30,
            "sheetY": 18
        },
        {
            "unified": "1F5FE",
            "name": "SILHOUETTE OF JAPAN",
            "native": "🗾",
            "shortName": "japan",
            "shortNames": [
                "japan"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 30,
            "sheetY": 22,
            "tts": "map of Japan",
            "keywords": [
                "Japan",
                "map",
                "map of Japan"
            ]
        },
        {
            "unified": "1F3D4-FE0F",
            "name": null,
            "native": "🏔️",
            "shortName": "snow_capped_mountain",
            "shortNames": [
                "snow_capped_mountain"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 11,
            "sheetY": 37
        },
        {
            "unified": "26F0-FE0F",
            "name": null,
            "native": "⛰️",
            "shortName": "mountain",
            "shortNames": [
                "mountain"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 48,
            "sheetY": 38
        },
        {
            "unified": "1F30B",
            "name": "VOLCANO",
            "native": "🌋",
            "shortName": "volcano",
            "shortNames": [
                "volcano"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 3,
            "tts": "volcano",
            "keywords": [
                "eruption",
                "mountain",
                "volcano"
            ]
        },
        {
            "unified": "1F5FB",
            "name": "MOUNT FUJI",
            "native": "🗻",
            "shortName": "mount_fuji",
            "shortNames": [
                "mount_fuji"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 30,
            "sheetY": 19,
            "tts": "mount fuji",
            "keywords": [
                "fuji",
                "mount fuji",
                "mountain"
            ]
        },
        {
            "unified": "1F3D5-FE0F",
            "name": null,
            "native": "🏕️",
            "shortName": "camping",
            "shortNames": [
                "camping"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 11,
            "sheetY": 38
        },
        {
            "unified": "1F3D6-FE0F",
            "name": null,
            "native": "🏖️",
            "shortName": "beach_with_umbrella",
            "shortNames": [
                "beach_with_umbrella"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 11,
            "sheetY": 39
        },
        {
            "unified": "1F3DC-FE0F",
            "name": null,
            "native": "🏜️",
            "shortName": "desert",
            "shortNames": [
                "desert"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 11,
            "sheetY": 45
        },
        {
            "unified": "1F3DD-FE0F",
            "name": null,
            "native": "🏝️",
            "shortName": "desert_island",
            "shortNames": [
                "desert_island"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 11,
            "sheetY": 46
        },
        {
            "unified": "1F3DE-FE0F",
            "name": null,
            "native": "🏞️",
            "shortName": "national_park",
            "shortNames": [
                "national_park"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 11,
            "sheetY": 47
        },
        {
            "unified": "1F3DF-FE0F",
            "name": null,
            "native": "🏟️",
            "shortName": "stadium",
            "shortNames": [
                "stadium"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 11,
            "sheetY": 48
        },
        {
            "unified": "1F3DB-FE0F",
            "name": null,
            "native": "🏛️",
            "shortName": "classical_building",
            "shortNames": [
                "classical_building"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 11,
            "sheetY": 44
        },
        {
            "unified": "1F3D7-FE0F",
            "name": null,
            "native": "🏗️",
            "shortName": "building_construction",
            "shortNames": [
                "building_construction"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 11,
            "sheetY": 40
        },
        {
            "unified": "1F3D8-FE0F",
            "name": null,
            "native": "🏘️",
            "shortName": "house_buildings",
            "shortNames": [
                "house_buildings"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 11,
            "sheetY": 41
        },
        {
            "unified": "1F3DA-FE0F",
            "name": null,
            "native": "🏚️",
            "shortName": "derelict_house_building",
            "shortNames": [
                "derelict_house_building"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 11,
            "sheetY": 43
        },
        {
            "unified": "1F3E0",
            "name": "HOUSE BUILDING",
            "native": "🏠",
            "shortName": "house",
            "shortNames": [
                "house"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 11,
            "sheetY": 49,
            "tts": "house",
            "keywords": [
                "home",
                "house"
            ]
        },
        {
            "unified": "1F3E1",
            "name": "HOUSE WITH GARDEN",
            "native": "🏡",
            "shortName": "house_with_garden",
            "shortNames": [
                "house_with_garden"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 11,
            "sheetY": 50,
            "tts": "house with garden",
            "keywords": [
                "garden",
                "home",
                "house",
                "house with garden"
            ]
        },
        {
            "unified": "1F3E2",
            "name": "OFFICE BUILDING",
            "native": "🏢",
            "shortName": "office",
            "shortNames": [
                "office"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 11,
            "sheetY": 51,
            "tts": "office building",
            "keywords": [
                "building",
                "office building"
            ]
        },
        {
            "unified": "1F3E3",
            "name": "JAPANESE POST OFFICE",
            "native": "🏣",
            "shortName": "post_office",
            "shortNames": [
                "post_office"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 12,
            "sheetY": 0,
            "tts": "Japanese post office",
            "keywords": [
                "Japanese",
                "Japanese post office",
                "post"
            ]
        },
        {
            "unified": "1F3E4",
            "name": "EUROPEAN POST OFFICE",
            "native": "🏤",
            "shortName": "european_post_office",
            "shortNames": [
                "european_post_office"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 12,
            "sheetY": 1,
            "tts": "post office",
            "keywords": [
                "European",
                "post",
                "post office"
            ]
        },
        {
            "unified": "1F3E5",
            "name": "HOSPITAL",
            "native": "🏥",
            "shortName": "hospital",
            "shortNames": [
                "hospital"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 12,
            "sheetY": 2,
            "tts": "hospital",
            "keywords": [
                "doctor",
                "hospital",
                "medicine"
            ]
        },
        {
            "unified": "1F3E6",
            "name": "BANK",
            "native": "🏦",
            "shortName": "bank",
            "shortNames": [
                "bank"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 12,
            "sheetY": 3,
            "tts": "bank",
            "keywords": [
                "bank",
                "building"
            ]
        },
        {
            "unified": "1F3E8",
            "name": "HOTEL",
            "native": "🏨",
            "shortName": "hotel",
            "shortNames": [
                "hotel"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 12,
            "sheetY": 5,
            "tts": "hotel",
            "keywords": [
                "building",
                "hotel"
            ]
        },
        {
            "unified": "1F3E9",
            "name": "LOVE HOTEL",
            "native": "🏩",
            "shortName": "love_hotel",
            "shortNames": [
                "love_hotel"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 12,
            "sheetY": 6,
            "tts": "love hotel",
            "keywords": [
                "hotel",
                "love"
            ]
        },
        {
            "unified": "1F3EA",
            "name": "CONVENIENCE STORE",
            "native": "🏪",
            "shortName": "convenience_store",
            "shortNames": [
                "convenience_store"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 12,
            "sheetY": 7,
            "tts": "convenience store",
            "keywords": [
                "convenience",
                "store"
            ]
        },
        {
            "unified": "1F3EB",
            "name": "SCHOOL",
            "native": "🏫",
            "shortName": "school",
            "shortNames": [
                "school"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 12,
            "sheetY": 8,
            "tts": "school",
            "keywords": [
                "building",
                "school"
            ]
        },
        {
            "unified": "1F3EC",
            "name": "DEPARTMENT STORE",
            "native": "🏬",
            "shortName": "department_store",
            "shortNames": [
                "department_store"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 12,
            "sheetY": 9,
            "tts": "department store",
            "keywords": [
                "department",
                "store"
            ]
        },
        {
            "unified": "1F3ED",
            "name": "FACTORY",
            "native": "🏭",
            "shortName": "factory",
            "shortNames": [
                "factory"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 12,
            "sheetY": 10,
            "tts": "factory",
            "keywords": [
                "building",
                "factory"
            ]
        },
        {
            "unified": "1F3EF",
            "name": "JAPANESE CASTLE",
            "native": "🏯",
            "shortName": "japanese_castle",
            "shortNames": [
                "japanese_castle"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 12,
            "sheetY": 12,
            "tts": "Japanese castle",
            "keywords": [
                "castle",
                "Japanese"
            ]
        },
        {
            "unified": "1F3F0",
            "name": "EUROPEAN CASTLE",
            "native": "🏰",
            "shortName": "european_castle",
            "shortNames": [
                "european_castle"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 12,
            "sheetY": 13,
            "tts": "castle",
            "keywords": [
                "castle",
                "European"
            ]
        },
        {
            "unified": "1F492",
            "name": "WEDDING",
            "native": "💒",
            "shortName": "wedding",
            "shortNames": [
                "wedding"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 24,
            "sheetY": 44,
            "tts": "wedding",
            "keywords": [
                "chapel",
                "romance",
                "wedding"
            ]
        },
        {
            "unified": "1F5FC",
            "name": "TOKYO TOWER",
            "native": "🗼",
            "shortName": "tokyo_tower",
            "shortNames": [
                "tokyo_tower"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 30,
            "sheetY": 20,
            "tts": "Tokyo tower",
            "keywords": [
                "Tokyo",
                "tower"
            ]
        },
        {
            "unified": "1F5FD",
            "name": "STATUE OF LIBERTY",
            "native": "🗽",
            "shortName": "statue_of_liberty",
            "shortNames": [
                "statue_of_liberty"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 30,
            "sheetY": 21,
            "tts": "Statue of Liberty",
            "keywords": [
                "liberty",
                "statue",
                "Statue of Liberty"
            ]
        },
        {
            "unified": "26EA",
            "name": "CHURCH",
            "native": "⛪",
            "shortName": "church",
            "shortNames": [
                "church"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 48,
            "sheetY": 37,
            "tts": "church",
            "keywords": [
                "Christian",
                "church",
                "cross",
                "religion"
            ]
        },
        {
            "unified": "1F54C",
            "name": "MOSQUE",
            "native": "🕌",
            "shortName": "mosque",
            "shortNames": [
                "mosque"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 15,
            "tts": "mosque",
            "keywords": [
                "islam",
                "mosque",
                "Muslim",
                "religion"
            ]
        },
        {
            "unified": "1F54D",
            "name": "SYNAGOGUE",
            "native": "🕍",
            "shortName": "synagogue",
            "shortNames": [
                "synagogue"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 16,
            "tts": "synagogue",
            "keywords": [
                "Jew",
                "Jewish",
                "religion",
                "synagogue",
                "temple"
            ]
        },
        {
            "unified": "26E9-FE0F",
            "name": null,
            "native": "⛩️",
            "shortName": "shinto_shrine",
            "shortNames": [
                "shinto_shrine"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 48,
            "sheetY": 36
        },
        {
            "unified": "1F54B",
            "name": "KAABA",
            "native": "🕋",
            "shortName": "kaaba",
            "shortNames": [
                "kaaba"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 14,
            "tts": "kaaba",
            "keywords": [
                "islam",
                "kaaba",
                "Muslim",
                "religion"
            ]
        },
        {
            "unified": "26F2",
            "name": "FOUNTAIN",
            "native": "⛲",
            "shortName": "fountain",
            "shortNames": [
                "fountain"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 48,
            "sheetY": 40,
            "tts": "fountain",
            "keywords": [
                "fountain"
            ]
        },
        {
            "unified": "26FA",
            "name": "TENT",
            "native": "⛺",
            "shortName": "tent",
            "shortNames": [
                "tent"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 49,
            "sheetY": 12,
            "tts": "tent",
            "keywords": [
                "camping",
                "tent"
            ]
        },
        {
            "unified": "1F301",
            "name": "FOGGY",
            "native": "🌁",
            "shortName": "foggy",
            "shortNames": [
                "foggy"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 5,
            "sheetY": 45,
            "tts": "foggy",
            "keywords": [
                "fog",
                "foggy"
            ]
        },
        {
            "unified": "1F303",
            "name": "NIGHT WITH STARS",
            "native": "🌃",
            "shortName": "night_with_stars",
            "shortNames": [
                "night_with_stars"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 5,
            "sheetY": 47,
            "tts": "night with stars",
            "keywords": [
                "night",
                "night with stars",
                "star"
            ]
        },
        {
            "unified": "1F3D9-FE0F",
            "name": null,
            "native": "🏙️",
            "shortName": "cityscape",
            "shortNames": [
                "cityscape"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 11,
            "sheetY": 42
        },
        {
            "unified": "1F304",
            "name": "SUNRISE OVER MOUNTAINS",
            "native": "🌄",
            "shortName": "sunrise_over_mountains",
            "shortNames": [
                "sunrise_over_mountains"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 5,
            "sheetY": 48,
            "tts": "sunrise over mountains",
            "keywords": [
                "morning",
                "mountain",
                "sun",
                "sunrise",
                "sunrise over mountains"
            ]
        },
        {
            "unified": "1F305",
            "name": "SUNRISE",
            "native": "🌅",
            "shortName": "sunrise",
            "shortNames": [
                "sunrise"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 5,
            "sheetY": 49,
            "tts": "sunrise",
            "keywords": [
                "morning",
                "sun",
                "sunrise"
            ]
        },
        {
            "unified": "1F306",
            "name": "CITYSCAPE AT DUSK",
            "native": "🌆",
            "shortName": "city_sunset",
            "shortNames": [
                "city_sunset"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 5,
            "sheetY": 50,
            "tts": "cityscape at dusk",
            "keywords": [
                "city",
                "cityscape at dusk",
                "dusk",
                "evening",
                "landscape",
                "sun",
                "sunset"
            ]
        },
        {
            "unified": "1F307",
            "name": "SUNSET OVER BUILDINGS",
            "native": "🌇",
            "shortName": "city_sunrise",
            "shortNames": [
                "city_sunrise"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 5,
            "sheetY": 51,
            "tts": "sunset",
            "keywords": [
                "dusk",
                "sun",
                "sunset"
            ]
        },
        {
            "unified": "1F309",
            "name": "BRIDGE AT NIGHT",
            "native": "🌉",
            "shortName": "bridge_at_night",
            "shortNames": [
                "bridge_at_night"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 1,
            "tts": "bridge at night",
            "keywords": [
                "bridge",
                "bridge at night",
                "night"
            ]
        },
        {
            "unified": "2668-FE0F",
            "name": "HOT SPRINGS",
            "native": "♨️",
            "shortName": "hotsprings",
            "shortNames": [
                "hotsprings"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 48,
            "sheetY": 8
        },
        {
            "unified": "1F30C",
            "name": "MILKY WAY",
            "native": "🌌",
            "shortName": "milky_way",
            "shortNames": [
                "milky_way"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 4,
            "tts": "milky way",
            "keywords": [
                "milky way",
                "space"
            ]
        },
        {
            "unified": "1F3A0",
            "name": "CAROUSEL HORSE",
            "native": "🎠",
            "shortName": "carousel_horse",
            "shortNames": [
                "carousel_horse"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 8,
            "sheetY": 46,
            "tts": "carousel horse",
            "keywords": [
                "carousel",
                "horse"
            ]
        },
        {
            "unified": "1F3A1",
            "name": "FERRIS WHEEL",
            "native": "🎡",
            "shortName": "ferris_wheel",
            "shortNames": [
                "ferris_wheel"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 8,
            "sheetY": 47,
            "tts": "ferris wheel",
            "keywords": [
                "amusement park",
                "ferris",
                "wheel"
            ]
        },
        {
            "unified": "1F3A2",
            "name": "ROLLER COASTER",
            "native": "🎢",
            "shortName": "roller_coaster",
            "shortNames": [
                "roller_coaster"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 8,
            "sheetY": 48,
            "tts": "roller coaster",
            "keywords": [
                "amusement park",
                "coaster",
                "roller"
            ]
        },
        {
            "unified": "1F488",
            "name": "BARBER POLE",
            "native": "💈",
            "shortName": "barber",
            "shortNames": [
                "barber"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 24,
            "sheetY": 34,
            "tts": "barber pole",
            "keywords": [
                "barber",
                "haircut",
                "pole"
            ]
        },
        {
            "unified": "1F3AA",
            "name": "CIRCUS TENT",
            "native": "🎪",
            "shortName": "circus_tent",
            "shortNames": [
                "circus_tent"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 9,
            "sheetY": 4,
            "tts": "circus tent",
            "keywords": [
                "circus",
                "tent"
            ]
        },
        {
            "unified": "1F682",
            "name": "STEAM LOCOMOTIVE",
            "native": "🚂",
            "shortName": "steam_locomotive",
            "shortNames": [
                "steam_locomotive"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 10,
            "tts": "locomotive",
            "keywords": [
                "engine",
                "locomotive",
                "railway",
                "steam",
                "train"
            ]
        },
        {
            "unified": "1F683",
            "name": "RAILWAY CAR",
            "native": "🚃",
            "shortName": "railway_car",
            "shortNames": [
                "railway_car"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 11,
            "tts": "railway car",
            "keywords": [
                "car",
                "electric",
                "railway",
                "train",
                "tram",
                "trolleybus"
            ]
        },
        {
            "unified": "1F684",
            "name": "HIGH-SPEED TRAIN",
            "native": "🚄",
            "shortName": "bullettrain_side",
            "shortNames": [
                "bullettrain_side"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 12,
            "tts": "high-speed train",
            "keywords": [
                "high-speed train",
                "railway",
                "shinkansen",
                "speed",
                "train"
            ]
        },
        {
            "unified": "1F685",
            "name": "HIGH-SPEED TRAIN WITH BULLET NOSE",
            "native": "🚅",
            "shortName": "bullettrain_front",
            "shortNames": [
                "bullettrain_front"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 13,
            "tts": "bullet train",
            "keywords": [
                "bullet",
                "railway",
                "shinkansen",
                "speed",
                "train"
            ]
        },
        {
            "unified": "1F686",
            "name": "TRAIN",
            "native": "🚆",
            "shortName": "train2",
            "shortNames": [
                "train2"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 14,
            "tts": "train",
            "keywords": [
                "railway",
                "train"
            ]
        },
        {
            "unified": "1F687",
            "name": "METRO",
            "native": "🚇",
            "shortName": "metro",
            "shortNames": [
                "metro"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 15,
            "tts": "metro",
            "keywords": [
                "metro",
                "subway"
            ]
        },
        {
            "unified": "1F688",
            "name": "LIGHT RAIL",
            "native": "🚈",
            "shortName": "light_rail",
            "shortNames": [
                "light_rail"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 16,
            "tts": "light rail",
            "keywords": [
                "light rail",
                "railway"
            ]
        },
        {
            "unified": "1F689",
            "name": "STATION",
            "native": "🚉",
            "shortName": "station",
            "shortNames": [
                "station"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 17,
            "tts": "station",
            "keywords": [
                "railway",
                "station",
                "train"
            ]
        },
        {
            "unified": "1F68A",
            "name": "TRAM",
            "native": "🚊",
            "shortName": "tram",
            "shortNames": [
                "tram"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 18,
            "tts": "tram",
            "keywords": [
                "tram",
                "trolleybus"
            ]
        },
        {
            "unified": "1F69D",
            "name": "MONORAIL",
            "native": "🚝",
            "shortName": "monorail",
            "shortNames": [
                "monorail"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 37,
            "tts": "monorail",
            "keywords": [
                "monorail",
                "vehicle"
            ]
        },
        {
            "unified": "1F69E",
            "name": "MOUNTAIN RAILWAY",
            "native": "🚞",
            "shortName": "mountain_railway",
            "shortNames": [
                "mountain_railway"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 38,
            "tts": "mountain railway",
            "keywords": [
                "car",
                "mountain",
                "railway"
            ]
        },
        {
            "unified": "1F68B",
            "name": "TRAM CAR",
            "native": "🚋",
            "shortName": "train",
            "shortNames": [
                "train"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 19,
            "tts": "tram car",
            "keywords": [
                "car",
                "tram",
                "trolleybus"
            ]
        },
        {
            "unified": "1F68C",
            "name": "BUS",
            "native": "🚌",
            "shortName": "bus",
            "shortNames": [
                "bus"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 20,
            "tts": "bus",
            "keywords": [
                "bus",
                "vehicle"
            ]
        },
        {
            "unified": "1F68D",
            "name": "ONCOMING BUS",
            "native": "🚍",
            "shortName": "oncoming_bus",
            "shortNames": [
                "oncoming_bus"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 21,
            "tts": "oncoming bus",
            "keywords": [
                "bus",
                "oncoming"
            ]
        },
        {
            "unified": "1F68E",
            "name": "TROLLEYBUS",
            "native": "🚎",
            "shortName": "trolleybus",
            "shortNames": [
                "trolleybus"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 22,
            "tts": "trolleybus",
            "keywords": [
                "bus",
                "tram",
                "trolley",
                "trolleybus"
            ]
        },
        {
            "unified": "1F690",
            "name": "MINIBUS",
            "native": "🚐",
            "shortName": "minibus",
            "shortNames": [
                "minibus"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 24,
            "tts": "minibus",
            "keywords": [
                "bus",
                "minibus"
            ]
        },
        {
            "unified": "1F691",
            "name": "AMBULANCE",
            "native": "🚑",
            "shortName": "ambulance",
            "shortNames": [
                "ambulance"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 25,
            "tts": "ambulance",
            "keywords": [
                "ambulance",
                "vehicle"
            ]
        },
        {
            "unified": "1F692",
            "name": "FIRE ENGINE",
            "native": "🚒",
            "shortName": "fire_engine",
            "shortNames": [
                "fire_engine"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 26,
            "tts": "fire engine",
            "keywords": [
                "engine",
                "fire",
                "truck"
            ]
        },
        {
            "unified": "1F693",
            "name": "POLICE CAR",
            "native": "🚓",
            "shortName": "police_car",
            "shortNames": [
                "police_car"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 27,
            "tts": "police car",
            "keywords": [
                "car",
                "patrol",
                "police"
            ]
        },
        {
            "unified": "1F694",
            "name": "ONCOMING POLICE CAR",
            "native": "🚔",
            "shortName": "oncoming_police_car",
            "shortNames": [
                "oncoming_police_car"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 28,
            "tts": "oncoming police car",
            "keywords": [
                "car",
                "oncoming",
                "police"
            ]
        },
        {
            "unified": "1F695",
            "name": "TAXI",
            "native": "🚕",
            "shortName": "taxi",
            "shortNames": [
                "taxi"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 29,
            "tts": "taxi",
            "keywords": [
                "taxi",
                "vehicle"
            ]
        },
        {
            "unified": "1F696",
            "name": "ONCOMING TAXI",
            "native": "🚖",
            "shortName": "oncoming_taxi",
            "shortNames": [
                "oncoming_taxi"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 30,
            "tts": "oncoming taxi",
            "keywords": [
                "oncoming",
                "taxi"
            ]
        },
        {
            "unified": "1F697",
            "name": "AUTOMOBILE",
            "native": "🚗",
            "shortName": "car",
            "shortNames": [
                "car",
                "red_car"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 31,
            "tts": "automobile",
            "keywords": [
                "automobile",
                "car"
            ]
        },
        {
            "unified": "1F698",
            "name": "ONCOMING AUTOMOBILE",
            "native": "🚘",
            "shortName": "oncoming_automobile",
            "shortNames": [
                "oncoming_automobile"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 32,
            "tts": "oncoming automobile",
            "keywords": [
                "automobile",
                "car",
                "oncoming"
            ]
        },
        {
            "unified": "1F699",
            "name": "RECREATIONAL VEHICLE",
            "native": "🚙",
            "shortName": "blue_car",
            "shortNames": [
                "blue_car"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 33,
            "tts": "sport utility vehicle",
            "keywords": [
                "recreational",
                "sport utility",
                "sport utility vehicle"
            ]
        },
        {
            "unified": "1F69A",
            "name": "DELIVERY TRUCK",
            "native": "🚚",
            "shortName": "truck",
            "shortNames": [
                "truck"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 34,
            "tts": "delivery truck",
            "keywords": [
                "delivery",
                "truck"
            ]
        },
        {
            "unified": "1F69B",
            "name": "ARTICULATED LORRY",
            "native": "🚛",
            "shortName": "articulated_lorry",
            "shortNames": [
                "articulated_lorry"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 35,
            "tts": "articulated lorry",
            "keywords": [
                "articulated lorry",
                "lorry",
                "semi",
                "truck"
            ]
        },
        {
            "unified": "1F69C",
            "name": "TRACTOR",
            "native": "🚜",
            "shortName": "tractor",
            "shortNames": [
                "tractor"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 36,
            "tts": "tractor",
            "keywords": [
                "tractor",
                "vehicle"
            ]
        },
        {
            "unified": "1F6B2",
            "name": "BICYCLE",
            "native": "🚲",
            "shortName": "bike",
            "shortNames": [
                "bike"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 35,
            "sheetY": 23,
            "tts": "bicycle",
            "keywords": [
                "bicycle",
                "bike"
            ]
        },
        {
            "unified": "1F6F4",
            "name": "SCOOTER",
            "native": "🛴",
            "shortName": "scooter",
            "shortNames": [
                "scooter"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 37,
            "sheetY": 19,
            "tts": "kick scooter",
            "keywords": [
                "kick",
                "scooter"
            ]
        },
        {
            "unified": "1F6F5",
            "name": "MOTOR SCOOTER",
            "native": "🛵",
            "shortName": "motor_scooter",
            "shortNames": [
                "motor_scooter"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 37,
            "sheetY": 20,
            "tts": "motor scooter",
            "keywords": [
                "motor",
                "scooter"
            ]
        },
        {
            "unified": "1F68F",
            "name": "BUS STOP",
            "native": "🚏",
            "shortName": "busstop",
            "shortNames": [
                "busstop"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 23,
            "tts": "bus stop",
            "keywords": [
                "bus",
                "busstop",
                "stop"
            ]
        },
        {
            "unified": "1F6E3-FE0F",
            "name": null,
            "native": "🛣️",
            "shortName": "motorway",
            "shortNames": [
                "motorway"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 37,
            "sheetY": 11
        },
        {
            "unified": "1F6E4-FE0F",
            "name": null,
            "native": "🛤️",
            "shortName": "railway_track",
            "shortNames": [
                "railway_track"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 37,
            "sheetY": 12
        },
        {
            "unified": "1F6E2-FE0F",
            "name": null,
            "native": "🛢️",
            "shortName": "oil_drum",
            "shortNames": [
                "oil_drum"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 37,
            "sheetY": 10
        },
        {
            "unified": "26FD",
            "name": "FUEL PUMP",
            "native": "⛽",
            "shortName": "fuelpump",
            "shortNames": [
                "fuelpump"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 49,
            "sheetY": 13,
            "tts": "fuel pump",
            "keywords": [
                "diesel",
                "fuel",
                "fuelpump",
                "gas",
                "pump",
                "station"
            ]
        },
        {
            "unified": "1F6A8",
            "name": "POLICE CARS REVOLVING LIGHT",
            "native": "🚨",
            "shortName": "rotating_light",
            "shortNames": [
                "rotating_light"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 35,
            "sheetY": 13,
            "tts": "police car light",
            "keywords": [
                "beacon",
                "car",
                "light",
                "police",
                "revolving"
            ]
        },
        {
            "unified": "1F6A5",
            "name": "HORIZONTAL TRAFFIC LIGHT",
            "native": "🚥",
            "shortName": "traffic_light",
            "shortNames": [
                "traffic_light"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 35,
            "sheetY": 10,
            "tts": "horizontal traffic light",
            "keywords": [
                "horizontal traffic light",
                "light",
                "signal",
                "traffic"
            ]
        },
        {
            "unified": "1F6A6",
            "name": "VERTICAL TRAFFIC LIGHT",
            "native": "🚦",
            "shortName": "vertical_traffic_light",
            "shortNames": [
                "vertical_traffic_light"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 35,
            "sheetY": 11,
            "tts": "vertical traffic light",
            "keywords": [
                "light",
                "signal",
                "traffic",
                "vertical traffic light"
            ]
        },
        {
            "unified": "1F6D1",
            "name": "OCTAGONAL SIGN",
            "native": "🛑",
            "shortName": "octagonal_sign",
            "shortNames": [
                "octagonal_sign"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 37,
            "sheetY": 6,
            "tts": "stop sign",
            "keywords": [
                "octagonal",
                "sign",
                "stop"
            ]
        },
        {
            "unified": "1F6A7",
            "name": "CONSTRUCTION SIGN",
            "native": "🚧",
            "shortName": "construction",
            "shortNames": [
                "construction"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 35,
            "sheetY": 12,
            "tts": "construction",
            "keywords": [
                "barrier",
                "construction"
            ]
        },
        {
            "unified": "2693",
            "name": "ANCHOR",
            "native": "⚓",
            "shortName": "anchor",
            "shortNames": [
                "anchor"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 48,
            "sheetY": 12,
            "tts": "anchor",
            "keywords": [
                "anchor",
                "ship",
                "tool"
            ]
        },
        {
            "unified": "26F5",
            "name": "SAILBOAT",
            "native": "⛵",
            "shortName": "boat",
            "shortNames": [
                "boat",
                "sailboat"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 48,
            "sheetY": 43,
            "tts": "sailboat",
            "keywords": [
                "boat",
                "resort",
                "sailboat",
                "sea",
                "yacht"
            ]
        },
        {
            "unified": "1F6F6",
            "name": "CANOE",
            "native": "🛶",
            "shortName": "canoe",
            "shortNames": [
                "canoe"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 37,
            "sheetY": 21,
            "tts": "canoe",
            "keywords": [
                "boat",
                "canoe"
            ]
        },
        {
            "unified": "1F6A4",
            "name": "SPEEDBOAT",
            "native": "🚤",
            "shortName": "speedboat",
            "shortNames": [
                "speedboat"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 35,
            "sheetY": 9,
            "tts": "speedboat",
            "keywords": [
                "boat",
                "speedboat"
            ]
        },
        {
            "unified": "1F6F3-FE0F",
            "name": null,
            "native": "🛳️",
            "shortName": "passenger_ship",
            "shortNames": [
                "passenger_ship"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 37,
            "sheetY": 18
        },
        {
            "unified": "26F4-FE0F",
            "name": null,
            "native": "⛴️",
            "shortName": "ferry",
            "shortNames": [
                "ferry"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 48,
            "sheetY": 42
        },
        {
            "unified": "1F6E5-FE0F",
            "name": null,
            "native": "🛥️",
            "shortName": "motor_boat",
            "shortNames": [
                "motor_boat"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 37,
            "sheetY": 13
        },
        {
            "unified": "1F6A2",
            "name": "SHIP",
            "native": "🚢",
            "shortName": "ship",
            "shortNames": [
                "ship"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 42,
            "tts": "ship",
            "keywords": [
                "boat",
                "passenger",
                "ship"
            ]
        },
        {
            "unified": "2708-FE0F",
            "name": "AIRPLANE",
            "native": "✈️",
            "shortName": "airplane",
            "shortNames": [
                "airplane"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 49,
            "sheetY": 16
        },
        {
            "unified": "1F6E9-FE0F",
            "name": null,
            "native": "🛩️",
            "shortName": "small_airplane",
            "shortNames": [
                "small_airplane"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 37,
            "sheetY": 14
        },
        {
            "unified": "1F6EB",
            "name": "AIRPLANE DEPARTURE",
            "native": "🛫",
            "shortName": "airplane_departure",
            "shortNames": [
                "airplane_departure"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 37,
            "sheetY": 15,
            "tts": "airplane departure",
            "keywords": [
                "aeroplane",
                "airplane",
                "check-in",
                "departure",
                "departures"
            ]
        },
        {
            "unified": "1F6EC",
            "name": "AIRPLANE ARRIVING",
            "native": "🛬",
            "shortName": "airplane_arriving",
            "shortNames": [
                "airplane_arriving"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 37,
            "sheetY": 16,
            "tts": "airplane arrival",
            "keywords": [
                "aeroplane",
                "airplane",
                "airplane arrival",
                "arrivals",
                "arriving",
                "landing"
            ]
        },
        {
            "unified": "1F4BA",
            "name": "SEAT",
            "native": "💺",
            "shortName": "seat",
            "shortNames": [
                "seat"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 25,
            "sheetY": 37,
            "tts": "seat",
            "keywords": [
                "chair",
                "seat"
            ]
        },
        {
            "unified": "1F681",
            "name": "HELICOPTER",
            "native": "🚁",
            "shortName": "helicopter",
            "shortNames": [
                "helicopter"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 9,
            "tts": "helicopter",
            "keywords": [
                "helicopter",
                "vehicle"
            ]
        },
        {
            "unified": "1F69F",
            "name": "SUSPENSION RAILWAY",
            "native": "🚟",
            "shortName": "suspension_railway",
            "shortNames": [
                "suspension_railway"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 39,
            "tts": "suspension railway",
            "keywords": [
                "railway",
                "suspension"
            ]
        },
        {
            "unified": "1F6A0",
            "name": "MOUNTAIN CABLEWAY",
            "native": "🚠",
            "shortName": "mountain_cableway",
            "shortNames": [
                "mountain_cableway"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 40,
            "tts": "mountain cableway",
            "keywords": [
                "cable",
                "gondola",
                "mountain",
                "mountain cableway"
            ]
        },
        {
            "unified": "1F6A1",
            "name": "AERIAL TRAMWAY",
            "native": "🚡",
            "shortName": "aerial_tramway",
            "shortNames": [
                "aerial_tramway"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 41,
            "tts": "aerial tramway",
            "keywords": [
                "aerial",
                "cable",
                "car",
                "gondola",
                "tramway"
            ]
        },
        {
            "unified": "1F6F0-FE0F",
            "name": null,
            "native": "🛰️",
            "shortName": "satellite",
            "shortNames": [
                "satellite"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 37,
            "sheetY": 17
        },
        {
            "unified": "1F680",
            "name": "ROCKET",
            "native": "🚀",
            "shortName": "rocket",
            "shortNames": [
                "rocket"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 34,
            "sheetY": 8,
            "tts": "rocket",
            "keywords": [
                "rocket",
                "space"
            ]
        },
        {
            "unified": "1F6F8",
            "name": "FLYING SAUCER",
            "native": "🛸",
            "shortName": "flying_saucer",
            "shortNames": [
                "flying_saucer"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 37,
            "sheetY": 23,
            "tts": "flying saucer",
            "keywords": [
                "flying saucer",
                "UFO"
            ]
        },
        {
            "unified": "1F6CE-FE0F",
            "name": null,
            "native": "🛎️",
            "shortName": "bellhop_bell",
            "shortNames": [
                "bellhop_bell"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 37,
            "sheetY": 3
        },
        {
            "unified": "231B",
            "name": "HOURGLASS",
            "native": "⌛",
            "shortName": "hourglass",
            "shortNames": [
                "hourglass"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 46,
            "sheetY": 42,
            "tts": "hourglass done",
            "keywords": [
                "hourglass done",
                "sand",
                "timer"
            ]
        },
        {
            "unified": "23F3",
            "name": "HOURGLASS WITH FLOWING SAND",
            "native": "⏳",
            "shortName": "hourglass_flowing_sand",
            "shortNames": [
                "hourglass_flowing_sand"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 47,
            "sheetY": 3,
            "tts": "hourglass not done",
            "keywords": [
                "hourglass",
                "hourglass not done",
                "sand",
                "timer"
            ]
        },
        {
            "unified": "231A",
            "name": "WATCH",
            "native": "⌚",
            "shortName": "watch",
            "shortNames": [
                "watch"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 46,
            "sheetY": 41,
            "tts": "watch",
            "keywords": [
                "clock",
                "watch"
            ]
        },
        {
            "unified": "23F0",
            "name": "ALARM CLOCK",
            "native": "⏰",
            "shortName": "alarm_clock",
            "shortNames": [
                "alarm_clock"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 47,
            "sheetY": 0,
            "tts": "alarm clock",
            "keywords": [
                "alarm",
                "clock"
            ]
        },
        {
            "unified": "23F1-FE0F",
            "name": null,
            "native": "⏱️",
            "shortName": "stopwatch",
            "shortNames": [
                "stopwatch"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 47,
            "sheetY": 1
        },
        {
            "unified": "23F2-FE0F",
            "name": null,
            "native": "⏲️",
            "shortName": "timer_clock",
            "shortNames": [
                "timer_clock"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 47,
            "sheetY": 2
        },
        {
            "unified": "1F570-FE0F",
            "name": null,
            "native": "🕰️",
            "shortName": "mantelpiece_clock",
            "shortNames": [
                "mantelpiece_clock"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 43
        },
        {
            "unified": "1F55B",
            "name": "CLOCK FACE TWELVE OCLOCK",
            "native": "🕛",
            "shortName": "clock12",
            "shortNames": [
                "clock12"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 29,
            "tts": "twelve o’clock",
            "keywords": [
                "00",
                "12",
                "12:00",
                "clock",
                "o’clock",
                "twelve"
            ]
        },
        {
            "unified": "1F567",
            "name": "CLOCK FACE TWELVE-THIRTY",
            "native": "🕧",
            "shortName": "clock1230",
            "shortNames": [
                "clock1230"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 41,
            "tts": "twelve-thirty",
            "keywords": [
                "12",
                "12:30",
                "30",
                "clock",
                "thirty",
                "twelve",
                "twelve-thirty"
            ]
        },
        {
            "unified": "1F550",
            "name": "CLOCK FACE ONE OCLOCK",
            "native": "🕐",
            "shortName": "clock1",
            "shortNames": [
                "clock1"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 18,
            "tts": "one o’clock",
            "keywords": [
                "00",
                "1",
                "1:00",
                "clock",
                "o’clock",
                "one"
            ]
        },
        {
            "unified": "1F55C",
            "name": "CLOCK FACE ONE-THIRTY",
            "native": "🕜",
            "shortName": "clock130",
            "shortNames": [
                "clock130"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 30,
            "tts": "one-thirty",
            "keywords": [
                "1",
                "1:30",
                "30",
                "clock",
                "one",
                "one-thirty",
                "thirty"
            ]
        },
        {
            "unified": "1F551",
            "name": "CLOCK FACE TWO OCLOCK",
            "native": "🕑",
            "shortName": "clock2",
            "shortNames": [
                "clock2"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 19,
            "tts": "two o’clock",
            "keywords": [
                "00",
                "2",
                "2:00",
                "clock",
                "o’clock",
                "two"
            ]
        },
        {
            "unified": "1F55D",
            "name": "CLOCK FACE TWO-THIRTY",
            "native": "🕝",
            "shortName": "clock230",
            "shortNames": [
                "clock230"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 31,
            "tts": "two-thirty",
            "keywords": [
                "2",
                "2:30",
                "30",
                "clock",
                "thirty",
                "two",
                "two-thirty"
            ]
        },
        {
            "unified": "1F552",
            "name": "CLOCK FACE THREE OCLOCK",
            "native": "🕒",
            "shortName": "clock3",
            "shortNames": [
                "clock3"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 20,
            "tts": "three o’clock",
            "keywords": [
                "00",
                "3",
                "3:00",
                "clock",
                "o’clock",
                "three"
            ]
        },
        {
            "unified": "1F55E",
            "name": "CLOCK FACE THREE-THIRTY",
            "native": "🕞",
            "shortName": "clock330",
            "shortNames": [
                "clock330"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 32,
            "tts": "three-thirty",
            "keywords": [
                "3",
                "3:30",
                "30",
                "clock",
                "thirty",
                "three",
                "three-thirty"
            ]
        },
        {
            "unified": "1F553",
            "name": "CLOCK FACE FOUR OCLOCK",
            "native": "🕓",
            "shortName": "clock4",
            "shortNames": [
                "clock4"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 21,
            "tts": "four o’clock",
            "keywords": [
                "00",
                "4",
                "4:00",
                "clock",
                "four",
                "o’clock"
            ]
        },
        {
            "unified": "1F55F",
            "name": "CLOCK FACE FOUR-THIRTY",
            "native": "🕟",
            "shortName": "clock430",
            "shortNames": [
                "clock430"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 33,
            "tts": "four-thirty",
            "keywords": [
                "30",
                "4",
                "4:30",
                "clock",
                "four",
                "four-thirty",
                "thirty"
            ]
        },
        {
            "unified": "1F554",
            "name": "CLOCK FACE FIVE OCLOCK",
            "native": "🕔",
            "shortName": "clock5",
            "shortNames": [
                "clock5"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 22,
            "tts": "five o’clock",
            "keywords": [
                "00",
                "5",
                "5:00",
                "clock",
                "five",
                "o’clock"
            ]
        },
        {
            "unified": "1F560",
            "name": "CLOCK FACE FIVE-THIRTY",
            "native": "🕠",
            "shortName": "clock530",
            "shortNames": [
                "clock530"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 34,
            "tts": "five-thirty",
            "keywords": [
                "30",
                "5",
                "5:30",
                "clock",
                "five",
                "five-thirty",
                "thirty"
            ]
        },
        {
            "unified": "1F555",
            "name": "CLOCK FACE SIX OCLOCK",
            "native": "🕕",
            "shortName": "clock6",
            "shortNames": [
                "clock6"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 23,
            "tts": "six o’clock",
            "keywords": [
                "00",
                "6",
                "6:00",
                "clock",
                "o’clock",
                "six"
            ]
        },
        {
            "unified": "1F561",
            "name": "CLOCK FACE SIX-THIRTY",
            "native": "🕡",
            "shortName": "clock630",
            "shortNames": [
                "clock630"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 35,
            "tts": "six-thirty",
            "keywords": [
                "30",
                "6",
                "6:30",
                "clock",
                "six",
                "six-thirty",
                "thirty"
            ]
        },
        {
            "unified": "1F556",
            "name": "CLOCK FACE SEVEN OCLOCK",
            "native": "🕖",
            "shortName": "clock7",
            "shortNames": [
                "clock7"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 24,
            "tts": "seven o’clock",
            "keywords": [
                "00",
                "7",
                "7:00",
                "clock",
                "o’clock",
                "seven"
            ]
        },
        {
            "unified": "1F562",
            "name": "CLOCK FACE SEVEN-THIRTY",
            "native": "🕢",
            "shortName": "clock730",
            "shortNames": [
                "clock730"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 36,
            "tts": "seven-thirty",
            "keywords": [
                "30",
                "7",
                "7:30",
                "clock",
                "seven",
                "seven-thirty",
                "thirty"
            ]
        },
        {
            "unified": "1F557",
            "name": "CLOCK FACE EIGHT OCLOCK",
            "native": "🕗",
            "shortName": "clock8",
            "shortNames": [
                "clock8"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 25,
            "tts": "eight o’clock",
            "keywords": [
                "00",
                "8",
                "8:00",
                "clock",
                "eight",
                "o’clock"
            ]
        },
        {
            "unified": "1F563",
            "name": "CLOCK FACE EIGHT-THIRTY",
            "native": "🕣",
            "shortName": "clock830",
            "shortNames": [
                "clock830"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 37,
            "tts": "eight-thirty",
            "keywords": [
                "30",
                "8",
                "8:30",
                "clock",
                "eight",
                "eight-thirty",
                "thirty"
            ]
        },
        {
            "unified": "1F558",
            "name": "CLOCK FACE NINE OCLOCK",
            "native": "🕘",
            "shortName": "clock9",
            "shortNames": [
                "clock9"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 26,
            "tts": "nine o’clock",
            "keywords": [
                "00",
                "9",
                "9:00",
                "clock",
                "nine",
                "o’clock"
            ]
        },
        {
            "unified": "1F564",
            "name": "CLOCK FACE NINE-THIRTY",
            "native": "🕤",
            "shortName": "clock930",
            "shortNames": [
                "clock930"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 38,
            "tts": "nine-thirty",
            "keywords": [
                "30",
                "9",
                "9:30",
                "clock",
                "nine",
                "nine-thirty",
                "thirty"
            ]
        },
        {
            "unified": "1F559",
            "name": "CLOCK FACE TEN OCLOCK",
            "native": "🕙",
            "shortName": "clock10",
            "shortNames": [
                "clock10"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 27,
            "tts": "ten o’clock",
            "keywords": [
                "00",
                "10",
                "10:00",
                "clock",
                "o’clock",
                "ten"
            ]
        },
        {
            "unified": "1F565",
            "name": "CLOCK FACE TEN-THIRTY",
            "native": "🕥",
            "shortName": "clock1030",
            "shortNames": [
                "clock1030"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 39,
            "tts": "ten-thirty",
            "keywords": [
                "10",
                "10:30",
                "30",
                "clock",
                "ten",
                "ten-thirty",
                "thirty"
            ]
        },
        {
            "unified": "1F55A",
            "name": "CLOCK FACE ELEVEN OCLOCK",
            "native": "🕚",
            "shortName": "clock11",
            "shortNames": [
                "clock11"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 28,
            "tts": "eleven o’clock",
            "keywords": [
                "00",
                "11",
                "11:00",
                "clock",
                "eleven",
                "o’clock"
            ]
        },
        {
            "unified": "1F566",
            "name": "CLOCK FACE ELEVEN-THIRTY",
            "native": "🕦",
            "shortName": "clock1130",
            "shortNames": [
                "clock1130"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 28,
            "sheetY": 40,
            "tts": "eleven-thirty",
            "keywords": [
                "11",
                "11:30",
                "30",
                "clock",
                "eleven",
                "eleven-thirty",
                "thirty"
            ]
        },
        {
            "unified": "1F311",
            "name": "NEW MOON SYMBOL",
            "native": "🌑",
            "shortName": "new_moon",
            "shortNames": [
                "new_moon"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 9,
            "tts": "new moon",
            "keywords": [
                "dark",
                "moon",
                "new moon"
            ]
        },
        {
            "unified": "1F312",
            "name": "WAXING CRESCENT MOON SYMBOL",
            "native": "🌒",
            "shortName": "waxing_crescent_moon",
            "shortNames": [
                "waxing_crescent_moon"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 10,
            "tts": "waxing crescent moon",
            "keywords": [
                "crescent",
                "moon",
                "waxing"
            ]
        },
        {
            "unified": "1F313",
            "name": "FIRST QUARTER MOON SYMBOL",
            "native": "🌓",
            "shortName": "first_quarter_moon",
            "shortNames": [
                "first_quarter_moon"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 11,
            "tts": "first quarter moon",
            "keywords": [
                "first quarter moon",
                "moon",
                "quarter"
            ]
        },
        {
            "unified": "1F314",
            "name": "WAXING GIBBOUS MOON SYMBOL",
            "native": "🌔",
            "shortName": "moon",
            "shortNames": [
                "moon",
                "waxing_gibbous_moon"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 12,
            "tts": "waxing gibbous moon",
            "keywords": [
                "gibbous",
                "moon",
                "waxing"
            ]
        },
        {
            "unified": "1F315",
            "name": "FULL MOON SYMBOL",
            "native": "🌕",
            "shortName": "full_moon",
            "shortNames": [
                "full_moon"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 13,
            "tts": "full moon",
            "keywords": [
                "full",
                "moon"
            ]
        },
        {
            "unified": "1F316",
            "name": "WANING GIBBOUS MOON SYMBOL",
            "native": "🌖",
            "shortName": "waning_gibbous_moon",
            "shortNames": [
                "waning_gibbous_moon"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 14,
            "tts": "waning gibbous moon",
            "keywords": [
                "gibbous",
                "moon",
                "waning"
            ]
        },
        {
            "unified": "1F317",
            "name": "LAST QUARTER MOON SYMBOL",
            "native": "🌗",
            "shortName": "last_quarter_moon",
            "shortNames": [
                "last_quarter_moon"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 15,
            "tts": "last quarter moon",
            "keywords": [
                "last quarter moon",
                "moon",
                "quarter"
            ]
        },
        {
            "unified": "1F318",
            "name": "WANING CRESCENT MOON SYMBOL",
            "native": "🌘",
            "shortName": "waning_crescent_moon",
            "shortNames": [
                "waning_crescent_moon"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 16,
            "tts": "waning crescent moon",
            "keywords": [
                "crescent",
                "moon",
                "waning"
            ]
        },
        {
            "unified": "1F319",
            "name": "CRESCENT MOON",
            "native": "🌙",
            "shortName": "crescent_moon",
            "shortNames": [
                "crescent_moon"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 17,
            "tts": "crescent moon",
            "keywords": [
                "crescent",
                "moon"
            ]
        },
        {
            "unified": "1F31A",
            "name": "NEW MOON WITH FACE",
            "native": "🌚",
            "shortName": "new_moon_with_face",
            "shortNames": [
                "new_moon_with_face"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 18,
            "tts": "new moon face",
            "keywords": [
                "face",
                "moon",
                "new moon face"
            ]
        },
        {
            "unified": "1F31B",
            "name": "FIRST QUARTER MOON WITH FACE",
            "native": "🌛",
            "shortName": "first_quarter_moon_with_face",
            "shortNames": [
                "first_quarter_moon_with_face"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 19,
            "tts": "first quarter moon face",
            "keywords": [
                "face",
                "first quarter moon face",
                "moon",
                "quarter"
            ]
        },
        {
            "unified": "1F31C",
            "name": "LAST QUARTER MOON WITH FACE",
            "native": "🌜",
            "shortName": "last_quarter_moon_with_face",
            "shortNames": [
                "last_quarter_moon_with_face"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 20,
            "tts": "last quarter moon face",
            "keywords": [
                "face",
                "last quarter moon face",
                "moon",
                "quarter"
            ]
        },
        {
            "unified": "1F321-FE0F",
            "name": null,
            "native": "🌡️",
            "shortName": "thermometer",
            "shortNames": [
                "thermometer"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 25
        },
        {
            "unified": "2600-FE0F",
            "name": "BLACK SUN WITH RAYS",
            "native": "☀️",
            "shortName": "sunny",
            "shortNames": [
                "sunny"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 47,
            "sheetY": 16
        },
        {
            "unified": "1F31D",
            "name": "FULL MOON WITH FACE",
            "native": "🌝",
            "shortName": "full_moon_with_face",
            "shortNames": [
                "full_moon_with_face"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 21,
            "tts": "full moon face",
            "keywords": [
                "bright",
                "face",
                "full",
                "moon"
            ]
        },
        {
            "unified": "1F31E",
            "name": "SUN WITH FACE",
            "native": "🌞",
            "shortName": "sun_with_face",
            "shortNames": [
                "sun_with_face"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 22,
            "tts": "sun with face",
            "keywords": [
                "bright",
                "face",
                "sun",
                "sun with face"
            ]
        },
        {
            "unified": "2B50",
            "name": "WHITE MEDIUM STAR",
            "native": "⭐",
            "shortName": "star",
            "shortNames": [
                "star"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 50,
            "sheetY": 22,
            "tts": "star",
            "keywords": [
                "star"
            ]
        },
        {
            "unified": "1F31F",
            "name": "GLOWING STAR",
            "native": "🌟",
            "shortName": "star2",
            "shortNames": [
                "star2"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 23,
            "tts": "glowing star",
            "keywords": [
                "glittery",
                "glow",
                "glowing star",
                "shining",
                "sparkle",
                "star"
            ]
        },
        {
            "unified": "1F320",
            "name": "SHOOTING STAR",
            "native": "🌠",
            "shortName": "stars",
            "shortNames": [
                "stars"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 24,
            "tts": "shooting star",
            "keywords": [
                "falling",
                "shooting",
                "star"
            ]
        },
        {
            "unified": "2601-FE0F",
            "name": "CLOUD",
            "native": "☁️",
            "shortName": "cloud",
            "shortNames": [
                "cloud"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 47,
            "sheetY": 17
        },
        {
            "unified": "26C5",
            "name": "SUN BEHIND CLOUD",
            "native": "⛅",
            "shortName": "partly_sunny",
            "shortNames": [
                "partly_sunny"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 48,
            "sheetY": 29,
            "tts": "sun behind cloud",
            "keywords": [
                "cloud",
                "sun",
                "sun behind cloud"
            ]
        },
        {
            "unified": "26C8-FE0F",
            "name": null,
            "native": "⛈️",
            "shortName": "thunder_cloud_and_rain",
            "shortNames": [
                "thunder_cloud_and_rain"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 48,
            "sheetY": 30
        },
        {
            "unified": "1F324-FE0F",
            "name": null,
            "native": "🌤️",
            "shortName": "mostly_sunny",
            "shortNames": [
                "mostly_sunny",
                "sun_small_cloud"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 26
        },
        {
            "unified": "1F325-FE0F",
            "name": null,
            "native": "🌥️",
            "shortName": "barely_sunny",
            "shortNames": [
                "barely_sunny",
                "sun_behind_cloud"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 27
        },
        {
            "unified": "1F326-FE0F",
            "name": null,
            "native": "🌦️",
            "shortName": "partly_sunny_rain",
            "shortNames": [
                "partly_sunny_rain",
                "sun_behind_rain_cloud"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 28
        },
        {
            "unified": "1F327-FE0F",
            "name": null,
            "native": "🌧️",
            "shortName": "rain_cloud",
            "shortNames": [
                "rain_cloud"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 29
        },
        {
            "unified": "1F328-FE0F",
            "name": null,
            "native": "🌨️",
            "shortName": "snow_cloud",
            "shortNames": [
                "snow_cloud"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 30
        },
        {
            "unified": "1F329-FE0F",
            "name": null,
            "native": "🌩️",
            "shortName": "lightning",
            "shortNames": [
                "lightning",
                "lightning_cloud"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 31
        },
        {
            "unified": "1F32A-FE0F",
            "name": null,
            "native": "🌪️",
            "shortName": "tornado",
            "shortNames": [
                "tornado",
                "tornado_cloud"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 32
        },
        {
            "unified": "1F32B-FE0F",
            "name": null,
            "native": "🌫️",
            "shortName": "fog",
            "shortNames": [
                "fog"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 33
        },
        {
            "unified": "1F32C-FE0F",
            "name": null,
            "native": "🌬️",
            "shortName": "wind_blowing_face",
            "shortNames": [
                "wind_blowing_face"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 34
        },
        {
            "unified": "1F300",
            "name": "CYCLONE",
            "native": "🌀",
            "shortName": "cyclone",
            "shortNames": [
                "cyclone"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 5,
            "sheetY": 44,
            "tts": "cyclone",
            "keywords": [
                "cyclone",
                "dizzy",
                "hurricane",
                "twister",
                "typhoon"
            ]
        },
        {
            "unified": "1F308",
            "name": "RAINBOW",
            "native": "🌈",
            "shortName": "rainbow",
            "shortNames": [
                "rainbow"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 0,
            "tts": "rainbow",
            "keywords": [
                "rain",
                "rainbow"
            ]
        },
        {
            "unified": "1F302",
            "name": "CLOSED UMBRELLA",
            "native": "🌂",
            "shortName": "closed_umbrella",
            "shortNames": [
                "closed_umbrella"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 5,
            "sheetY": 46,
            "tts": "closed umbrella",
            "keywords": [
                "closed umbrella",
                "clothing",
                "rain",
                "umbrella"
            ]
        },
        {
            "unified": "2602-FE0F",
            "name": null,
            "native": "☂️",
            "shortName": "umbrella",
            "shortNames": [
                "umbrella"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 47,
            "sheetY": 18
        },
        {
            "unified": "2614",
            "name": "UMBRELLA WITH RAIN DROPS",
            "native": "☔",
            "shortName": "umbrella_with_rain_drops",
            "shortNames": [
                "umbrella_with_rain_drops"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 47,
            "sheetY": 23,
            "tts": "umbrella with rain drops",
            "keywords": [
                "clothing",
                "drop",
                "rain",
                "umbrella",
                "umbrella with rain drops"
            ]
        },
        {
            "unified": "26F1-FE0F",
            "name": null,
            "native": "⛱️",
            "shortName": "umbrella_on_ground",
            "shortNames": [
                "umbrella_on_ground"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 48,
            "sheetY": 39
        },
        {
            "unified": "26A1",
            "name": "HIGH VOLTAGE SIGN",
            "native": "⚡",
            "shortName": "zap",
            "shortNames": [
                "zap"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 48,
            "sheetY": 21,
            "tts": "high voltage",
            "keywords": [
                "danger",
                "electric",
                "electricity",
                "high voltage",
                "lightning",
                "voltage",
                "zap"
            ]
        },
        {
            "unified": "2744-FE0F",
            "name": "SNOWFLAKE",
            "native": "❄️",
            "shortName": "snowflake",
            "shortNames": [
                "snowflake"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 49,
            "sheetY": 51
        },
        {
            "unified": "2603-FE0F",
            "name": null,
            "native": "☃️",
            "shortName": "snowman",
            "shortNames": [
                "snowman"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 47,
            "sheetY": 19
        },
        {
            "unified": "26C4",
            "name": "SNOWMAN WITHOUT SNOW",
            "native": "⛄",
            "shortName": "snowman_without_snow",
            "shortNames": [
                "snowman_without_snow"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 48,
            "sheetY": 28,
            "tts": "snowman without snow",
            "keywords": [
                "cold",
                "snow",
                "snowman",
                "snowman without snow"
            ]
        },
        {
            "unified": "2604-FE0F",
            "name": null,
            "native": "☄️",
            "shortName": "comet",
            "shortNames": [
                "comet"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 47,
            "sheetY": 20
        },
        {
            "unified": "1F525",
            "name": "FIRE",
            "native": "🔥",
            "shortName": "fire",
            "shortNames": [
                "fire"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 27,
            "sheetY": 39,
            "tts": "fire",
            "keywords": [
                "fire",
                "flame",
                "tool"
            ]
        },
        {
            "unified": "1F4A7",
            "name": "DROPLET",
            "native": "💧",
            "shortName": "droplet",
            "shortNames": [
                "droplet"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 25,
            "sheetY": 13,
            "tts": "droplet",
            "keywords": [
                "cold",
                "comic",
                "drop",
                "droplet",
                "sweat"
            ]
        },
        {
            "unified": "1F30A",
            "name": "WATER WAVE",
            "native": "🌊",
            "shortName": "ocean",
            "shortNames": [
                "ocean"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 6,
            "sheetY": 2,
            "tts": "water wave",
            "keywords": [
                "ocean",
                "water",
                "wave"
            ]
        },
        {
            "unified": "1F383",
            "name": "JACK-O-LANTERN",
            "native": "🎃",
            "shortName": "jack_o_lantern",
            "shortNames": [
                "jack_o_lantern"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 8,
            "sheetY": 17,
            "tts": "jack-o-lantern",
            "keywords": [
                "celebration",
                "halloween",
                "jack",
                "jack-o-lantern",
                "lantern"
            ]
        },
        {
            "unified": "1F384",
            "name": "CHRISTMAS TREE",
            "native": "🎄",
            "shortName": "christmas_tree",
            "shortNames": [
                "christmas_tree"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 8,
            "sheetY": 18,
            "tts": "Christmas tree",
            "keywords": [
                "celebration",
                "Christmas",
                "tree"
            ]
        },
        {
            "unified": "1F386",
            "name": "FIREWORKS",
            "native": "🎆",
            "shortName": "fireworks",
            "shortNames": [
                "fireworks"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 8,
            "sheetY": 25,
            "tts": "fireworks",
            "keywords": [
                "celebration",
                "fireworks"
            ]
        },
        {
            "unified": "1F387",
            "name": "FIREWORK SPARKLER",
            "native": "🎇",
            "shortName": "sparkler",
            "shortNames": [
                "sparkler"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 8,
            "sheetY": 26,
            "tts": "sparkler",
            "keywords": [
                "celebration",
                "fireworks",
                "sparkle",
                "sparkler"
            ]
        },
        {
            "unified": "2728",
            "name": "SPARKLES",
            "native": "✨",
            "shortName": "sparkles",
            "shortNames": [
                "sparkles"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 49,
            "sheetY": 48,
            "tts": "sparkles",
            "keywords": [
                "sparkle",
                "sparkles",
                "star"
            ]
        },
        {
            "unified": "1F388",
            "name": "BALLOON",
            "native": "🎈",
            "shortName": "balloon",
            "shortNames": [
                "balloon"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 8,
            "sheetY": 27,
            "tts": "balloon",
            "keywords": [
                "balloon",
                "celebration"
            ]
        },
        {
            "unified": "1F389",
            "name": "PARTY POPPER",
            "native": "🎉",
            "shortName": "tada",
            "shortNames": [
                "tada"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 8,
            "sheetY": 28,
            "tts": "party popper",
            "keywords": [
                "celebration",
                "party",
                "popper",
                "tada"
            ]
        },
        {
            "unified": "1F38A",
            "name": "CONFETTI BALL",
            "native": "🎊",
            "shortName": "confetti_ball",
            "shortNames": [
                "confetti_ball"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 8,
            "sheetY": 29,
            "tts": "confetti ball",
            "keywords": [
                "ball",
                "celebration",
                "confetti"
            ]
        },
        {
            "unified": "1F38B",
            "name": "TANABATA TREE",
            "native": "🎋",
            "shortName": "tanabata_tree",
            "shortNames": [
                "tanabata_tree"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 8,
            "sheetY": 30,
            "tts": "tanabata tree",
            "keywords": [
                "banner",
                "celebration",
                "Japanese",
                "tanabata tree",
                "tree"
            ]
        },
        {
            "unified": "1F38D",
            "name": "PINE DECORATION",
            "native": "🎍",
            "shortName": "bamboo",
            "shortNames": [
                "bamboo"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 8,
            "sheetY": 32,
            "tts": "pine decoration",
            "keywords": [
                "bamboo",
                "celebration",
                "Japanese",
                "pine",
                "pine decoration"
            ]
        },
        {
            "unified": "1F38E",
            "name": "JAPANESE DOLLS",
            "native": "🎎",
            "shortName": "dolls",
            "shortNames": [
                "dolls"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 8,
            "sheetY": 33,
            "tts": "Japanese dolls",
            "keywords": [
                "celebration",
                "doll",
                "festival",
                "Japanese",
                "Japanese dolls"
            ]
        },
        {
            "unified": "1F38F",
            "name": "CARP STREAMER",
            "native": "🎏",
            "shortName": "flags",
            "shortNames": [
                "flags"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 8,
            "sheetY": 34,
            "tts": "carp streamer",
            "keywords": [
                "carp",
                "celebration",
                "streamer"
            ]
        },
        {
            "unified": "1F390",
            "name": "WIND CHIME",
            "native": "🎐",
            "shortName": "wind_chime",
            "shortNames": [
                "wind_chime"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 8,
            "sheetY": 35,
            "tts": "wind chime",
            "keywords": [
                "bell",
                "celebration",
                "chime",
                "wind"
            ]
        },
        {
            "unified": "1F391",
            "name": "MOON VIEWING CEREMONY",
            "native": "🎑",
            "shortName": "rice_scene",
            "shortNames": [
                "rice_scene"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 8,
            "sheetY": 36,
            "tts": "moon viewing ceremony",
            "keywords": [
                "celebration",
                "ceremony",
                "moon",
                "moon viewing ceremony"
            ]
        },
        {
            "unified": "1F380",
            "name": "RIBBON",
            "native": "🎀",
            "shortName": "ribbon",
            "shortNames": [
                "ribbon"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 8,
            "sheetY": 14,
            "tts": "ribbon",
            "keywords": [
                "celebration",
                "ribbon"
            ]
        },
        {
            "unified": "1F381",
            "name": "WRAPPED PRESENT",
            "native": "🎁",
            "shortName": "gift",
            "shortNames": [
                "gift"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 8,
            "sheetY": 15,
            "tts": "wrapped gift",
            "keywords": [
                "box",
                "celebration",
                "gift",
                "present",
                "wrapped"
            ]
        },
        {
            "unified": "1F397-FE0F",
            "name": null,
            "native": "🎗️",
            "shortName": "reminder_ribbon",
            "shortNames": [
                "reminder_ribbon"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 8,
            "sheetY": 40
        },
        {
            "unified": "1F39F-FE0F",
            "name": null,
            "native": "🎟️",
            "shortName": "admission_tickets",
            "shortNames": [
                "admission_tickets"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 8,
            "sheetY": 45
        },
        {
            "unified": "1F3AB",
            "name": "TICKET",
            "native": "🎫",
            "shortName": "ticket",
            "shortNames": [
                "ticket"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 9,
            "sheetY": 5,
            "tts": "ticket",
            "keywords": [
                "admission",
                "ticket"
            ]
        },
        {
            "unified": "1F396-FE0F",
            "name": null,
            "native": "🎖️",
            "shortName": "medal",
            "shortNames": [
                "medal"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 8,
            "sheetY": 39
        },
        {
            "unified": "1F3C6",
            "name": "TROPHY",
            "native": "🏆",
            "shortName": "trophy",
            "shortNames": [
                "trophy"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 10,
            "sheetY": 19,
            "tts": "trophy",
            "keywords": [
                "prize",
                "trophy"
            ]
        },
        {
            "unified": "1F3C5",
            "name": "SPORTS MEDAL",
            "native": "🏅",
            "shortName": "sports_medal",
            "shortNames": [
                "sports_medal"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 10,
            "sheetY": 18,
            "tts": "sports medal",
            "keywords": [
                "medal",
                "sports medal"
            ]
        },
        {
            "unified": "1F947",
            "name": "FIRST PLACE MEDAL",
            "native": "🥇",
            "shortName": "first_place_medal",
            "shortNames": [
                "first_place_medal"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 41,
            "sheetY": 42,
            "tts": "1st place medal",
            "keywords": [
                "1st place medal",
                "first",
                "gold",
                "medal"
            ]
        },
        {
            "unified": "1F948",
            "name": "SECOND PLACE MEDAL",
            "native": "🥈",
            "shortName": "second_place_medal",
            "shortNames": [
                "second_place_medal"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 41,
            "sheetY": 43,
            "tts": "2nd place medal",
            "keywords": [
                "2nd place medal",
                "medal",
                "second",
                "silver"
            ]
        },
        {
            "unified": "1F949",
            "name": "THIRD PLACE MEDAL",
            "native": "🥉",
            "shortName": "third_place_medal",
            "shortNames": [
                "third_place_medal"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 41,
            "sheetY": 44,
            "tts": "3rd place medal",
            "keywords": [
                "3rd place medal",
                "bronze",
                "medal",
                "third"
            ]
        },
        {
            "unified": "26BD",
            "name": "SOCCER BALL",
            "native": "⚽",
            "shortName": "soccer",
            "shortNames": [
                "soccer"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 48,
            "sheetY": 26,
            "tts": "soccer ball",
            "keywords": [
                "ball",
                "football",
                "soccer"
            ]
        },
        {
            "unified": "26BE",
            "name": "BASEBALL",
            "native": "⚾",
            "shortName": "baseball",
            "shortNames": [
                "baseball"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 48,
            "sheetY": 27,
            "tts": "baseball",
            "keywords": [
                "ball",
                "baseball"
            ]
        },
        {
            "unified": "1F3C0",
            "name": "BASKETBALL AND HOOP",
            "native": "🏀",
            "shortName": "basketball",
            "shortNames": [
                "basketball"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 9,
            "sheetY": 26,
            "tts": "basketball",
            "keywords": [
                "ball",
                "basketball",
                "hoop"
            ]
        },
        {
            "unified": "1F3D0",
            "name": "VOLLEYBALL",
            "native": "🏐",
            "shortName": "volleyball",
            "shortNames": [
                "volleyball"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 11,
            "sheetY": 33,
            "tts": "volleyball",
            "keywords": [
                "ball",
                "game",
                "volleyball"
            ]
        },
        {
            "unified": "1F3C8",
            "name": "AMERICAN FOOTBALL",
            "native": "🏈",
            "shortName": "football",
            "shortNames": [
                "football"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 10,
            "sheetY": 26,
            "tts": "american football",
            "keywords": [
                "american",
                "ball",
                "football"
            ]
        },
        {
            "unified": "1F3C9",
            "name": "RUGBY FOOTBALL",
            "native": "🏉",
            "shortName": "rugby_football",
            "shortNames": [
                "rugby_football"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 10,
            "sheetY": 27,
            "tts": "rugby football",
            "keywords": [
                "ball",
                "football",
                "rugby"
            ]
        },
        {
            "unified": "1F3BE",
            "name": "TENNIS RACQUET AND BALL",
            "native": "🎾",
            "shortName": "tennis",
            "shortNames": [
                "tennis"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 9,
            "sheetY": 24,
            "tts": "tennis",
            "keywords": [
                "ball",
                "racquet",
                "tennis"
            ]
        },
        {
            "unified": "1F3B3",
            "name": "BOWLING",
            "native": "🎳",
            "shortName": "bowling",
            "shortNames": [
                "bowling"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 9,
            "sheetY": 13,
            "tts": "bowling",
            "keywords": [
                "ball",
                "bowling",
                "game"
            ]
        },
        {
            "unified": "1F3CF",
            "name": "CRICKET BAT AND BALL",
            "native": "🏏",
            "shortName": "cricket_bat_and_ball",
            "shortNames": [
                "cricket_bat_and_ball"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 11,
            "sheetY": 32,
            "tts": "cricket game",
            "keywords": [
                "ball",
                "bat",
                "cricket game",
                "game"
            ]
        },
        {
            "unified": "1F3D1",
            "name": "FIELD HOCKEY STICK AND BALL",
            "native": "🏑",
            "shortName": "field_hockey_stick_and_ball",
            "shortNames": [
                "field_hockey_stick_and_ball"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 11,
            "sheetY": 34,
            "tts": "field hockey",
            "keywords": [
                "ball",
                "field",
                "game",
                "hockey",
                "stick"
            ]
        },
        {
            "unified": "1F3D2",
            "name": "ICE HOCKEY STICK AND PUCK",
            "native": "🏒",
            "shortName": "ice_hockey_stick_and_puck",
            "shortNames": [
                "ice_hockey_stick_and_puck"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 11,
            "sheetY": 35,
            "tts": "ice hockey",
            "keywords": [
                "game",
                "hockey",
                "ice",
                "puck",
                "stick"
            ]
        },
        {
            "unified": "1F3D3",
            "name": "TABLE TENNIS PADDLE AND BALL",
            "native": "🏓",
            "shortName": "table_tennis_paddle_and_ball",
            "shortNames": [
                "table_tennis_paddle_and_ball"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 11,
            "sheetY": 36,
            "tts": "ping pong",
            "keywords": [
                "ball",
                "bat",
                "game",
                "paddle",
                "ping pong",
                "table tennis"
            ]
        },
        {
            "unified": "1F3F8",
            "name": "BADMINTON RACQUET AND SHUTTLECOCK",
            "native": "🏸",
            "shortName": "badminton_racquet_and_shuttlecock",
            "shortNames": [
                "badminton_racquet_and_shuttlecock"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 12,
            "sheetY": 22,
            "tts": "badminton",
            "keywords": [
                "badminton",
                "birdie",
                "game",
                "racquet",
                "shuttlecock"
            ]
        },
        {
            "unified": "1F94A",
            "name": "BOXING GLOVE",
            "native": "🥊",
            "shortName": "boxing_glove",
            "shortNames": [
                "boxing_glove"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 41,
            "sheetY": 45,
            "tts": "boxing glove",
            "keywords": [
                "boxing",
                "glove"
            ]
        },
        {
            "unified": "1F94B",
            "name": "MARTIAL ARTS UNIFORM",
            "native": "🥋",
            "shortName": "martial_arts_uniform",
            "shortNames": [
                "martial_arts_uniform"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 41,
            "sheetY": 46,
            "tts": "martial arts uniform",
            "keywords": [
                "judo",
                "karate",
                "martial arts",
                "martial arts uniform",
                "taekwondo",
                "uniform"
            ]
        },
        {
            "unified": "1F945",
            "name": "GOAL NET",
            "native": "🥅",
            "shortName": "goal_net",
            "shortNames": [
                "goal_net"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 41,
            "sheetY": 41,
            "tts": "goal net",
            "keywords": [
                "goal",
                "net"
            ]
        },
        {
            "unified": "26F3",
            "name": "FLAG IN HOLE",
            "native": "⛳",
            "shortName": "golf",
            "shortNames": [
                "golf"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 48,
            "sheetY": 41,
            "tts": "flag in hole",
            "keywords": [
                "flag in hole",
                "golf",
                "hole"
            ]
        },
        {
            "unified": "26F8-FE0F",
            "name": null,
            "native": "⛸️",
            "shortName": "ice_skate",
            "shortNames": [
                "ice_skate"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 48,
            "sheetY": 45
        },
        {
            "unified": "1F3A3",
            "name": "FISHING POLE AND FISH",
            "native": "🎣",
            "shortName": "fishing_pole_and_fish",
            "shortNames": [
                "fishing_pole_and_fish"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 8,
            "sheetY": 49,
            "tts": "fishing pole",
            "keywords": [
                "fish",
                "fishing pole",
                "pole"
            ]
        },
        {
            "unified": "1F3BD",
            "name": "RUNNING SHIRT WITH SASH",
            "native": "🎽",
            "shortName": "running_shirt_with_sash",
            "shortNames": [
                "running_shirt_with_sash"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 9,
            "sheetY": 23,
            "tts": "running shirt",
            "keywords": [
                "athletics",
                "running",
                "sash",
                "shirt"
            ]
        },
        {
            "unified": "1F3BF",
            "name": "SKI AND SKI BOOT",
            "native": "🎿",
            "shortName": "ski",
            "shortNames": [
                "ski"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 9,
            "sheetY": 25,
            "tts": "skis",
            "keywords": [
                "ski",
                "skis",
                "snow"
            ]
        },
        {
            "unified": "1F6F7",
            "name": "SLED",
            "native": "🛷",
            "shortName": "sled",
            "shortNames": [
                "sled"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 37,
            "sheetY": 22,
            "tts": "sled",
            "keywords": [
                "sled",
                "sledge",
                "sleigh"
            ]
        },
        {
            "unified": "1F94C",
            "name": "CURLING STONE",
            "native": "🥌",
            "shortName": "curling_stone",
            "shortNames": [
                "curling_stone"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 41,
            "sheetY": 47,
            "tts": "curling stone",
            "keywords": [
                "curling stone",
                "game",
                "rock"
            ]
        },
        {
            "unified": "1F3AF",
            "name": "DIRECT HIT",
            "native": "🎯",
            "shortName": "dart",
            "shortNames": [
                "dart"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 9,
            "sheetY": 9,
            "tts": "direct hit",
            "keywords": [
                "bull",
                "bullseye",
                "dart",
                "direct hit",
                "eye",
                "game",
                "hit",
                "target"
            ]
        },
        {
            "unified": "1F3B1",
            "name": "BILLIARDS",
            "native": "🎱",
            "shortName": "8ball",
            "shortNames": [
                "8ball"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 9,
            "sheetY": 11,
            "tts": "pool 8 ball",
            "keywords": [
                "8",
                "ball",
                "billiard",
                "eight",
                "game",
                "pool 8 ball"
            ]
        },
        {
            "unified": "1F52E",
            "name": "CRYSTAL BALL",
            "native": "🔮",
            "shortName": "crystal_ball",
            "shortNames": [
                "crystal_ball"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 48,
            "tts": "crystal ball",
            "keywords": [
                "ball",
                "crystal",
                "fairy tale",
                "fantasy",
                "fortune",
                "tool"
            ]
        },
        {
            "unified": "1F3AE",
            "name": "VIDEO GAME",
            "native": "🎮",
            "shortName": "video_game",
            "shortNames": [
                "video_game"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 9,
            "sheetY": 8,
            "tts": "video game",
            "keywords": [
                "controller",
                "game",
                "video game"
            ]
        },
        {
            "unified": "1F579-FE0F",
            "name": null,
            "native": "🕹️",
            "shortName": "joystick",
            "shortNames": [
                "joystick"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 29,
            "sheetY": 20
        },
        {
            "unified": "1F3B0",
            "name": "SLOT MACHINE",
            "native": "🎰",
            "shortName": "slot_machine",
            "shortNames": [
                "slot_machine"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 9,
            "sheetY": 10,
            "tts": "slot machine",
            "keywords": [
                "game",
                "slot",
                "slot machine"
            ]
        },
        {
            "unified": "1F3B2",
            "name": "GAME DIE",
            "native": "🎲",
            "shortName": "game_die",
            "shortNames": [
                "game_die"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 9,
            "sheetY": 12,
            "tts": "game die",
            "keywords": [
                "dice",
                "die",
                "game"
            ]
        },
        {
            "unified": "2660-FE0F",
            "name": "BLACK SPADE SUIT",
            "native": "♠️",
            "shortName": "spades",
            "shortNames": [
                "spades"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 48,
            "sheetY": 4
        },
        {
            "unified": "2665-FE0F",
            "name": "BLACK HEART SUIT",
            "native": "♥️",
            "shortName": "hearts",
            "shortNames": [
                "hearts"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 48,
            "sheetY": 6
        },
        {
            "unified": "2666-FE0F",
            "name": "BLACK DIAMOND SUIT",
            "native": "♦️",
            "shortName": "diamonds",
            "shortNames": [
                "diamonds"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 48,
            "sheetY": 7
        },
        {
            "unified": "2663-FE0F",
            "name": "BLACK CLUB SUIT",
            "native": "♣️",
            "shortName": "clubs",
            "shortNames": [
                "clubs"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 48,
            "sheetY": 5
        },
        {
            "unified": "1F0CF",
            "name": "PLAYING CARD BLACK JOKER",
            "native": "🃏",
            "shortName": "black_joker",
            "shortNames": [
                "black_joker"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 0,
            "sheetY": 15,
            "tts": "joker",
            "keywords": [
                "card",
                "game",
                "joker",
                "wildcard"
            ]
        },
        {
            "unified": "1F004",
            "name": "MAHJONG TILE RED DRAGON",
            "native": "🀄",
            "shortName": "mahjong",
            "shortNames": [
                "mahjong"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 0,
            "sheetY": 14,
            "tts": "mahjong red dragon",
            "keywords": [
                "game",
                "mahjong",
                "mahjong red dragon",
                "red"
            ]
        },
        {
            "unified": "1F3B4",
            "name": "FLOWER PLAYING CARDS",
            "native": "🎴",
            "shortName": "flower_playing_cards",
            "shortNames": [
                "flower_playing_cards"
            ],
            "text": null,
            "texts": null,
            "category": "Activities",
            "sheetX": 9,
            "sheetY": 14,
            "tts": "flower playing cards",
            "keywords": [
                "card",
                "flower",
                "flower playing cards",
                "game",
                "Japanese",
                "playing"
            ]
        },
        {
            "unified": "1F3AD",
            "name": "PERFORMING ARTS",
            "native": "🎭",
            "shortName": "performing_arts",
            "shortNames": [
                "performing_arts"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 9,
            "sheetY": 7,
            "tts": "performing arts",
            "keywords": [
                "art",
                "mask",
                "performing",
                "performing arts",
                "theater",
                "theatre"
            ]
        },
        {
            "unified": "1F5BC-FE0F",
            "name": null,
            "native": "🖼️",
            "shortName": "frame_with_picture",
            "shortNames": [
                "frame_with_picture"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 30,
            "sheetY": 3
        },
        {
            "unified": "1F3A8",
            "name": "ARTIST PALETTE",
            "native": "🎨",
            "shortName": "art",
            "shortNames": [
                "art"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 9,
            "sheetY": 2,
            "tts": "artist palette",
            "keywords": [
                "art",
                "artist palette",
                "museum",
                "painting",
                "palette"
            ]
        },
        {
            "unified": "1F507",
            "name": "SPEAKER WITH CANCELLATION STROKE",
            "native": "🔇",
            "shortName": "mute",
            "shortNames": [
                "mute"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 9,
            "tts": "muted speaker",
            "keywords": [
                "mute",
                "muted speaker",
                "quiet",
                "silent",
                "speaker"
            ]
        },
        {
            "unified": "1F508",
            "name": "SPEAKER",
            "native": "🔈",
            "shortName": "speaker",
            "shortNames": [
                "speaker"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 10,
            "tts": "speaker low volume",
            "keywords": [
                "soft",
                "speaker low volume"
            ]
        },
        {
            "unified": "1F509",
            "name": "SPEAKER WITH ONE SOUND WAVE",
            "native": "🔉",
            "shortName": "sound",
            "shortNames": [
                "sound"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 11,
            "tts": "speaker medium volume",
            "keywords": [
                "medium",
                "speaker medium volume"
            ]
        },
        {
            "unified": "1F50A",
            "name": "SPEAKER WITH THREE SOUND WAVES",
            "native": "🔊",
            "shortName": "loud_sound",
            "shortNames": [
                "loud_sound"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 12,
            "tts": "speaker high volume",
            "keywords": [
                "loud",
                "speaker high volume"
            ]
        },
        {
            "unified": "1F4E2",
            "name": "PUBLIC ADDRESS LOUDSPEAKER",
            "native": "📢",
            "shortName": "loudspeaker",
            "shortNames": [
                "loudspeaker"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 25,
            "tts": "loudspeaker",
            "keywords": [
                "loud",
                "loudspeaker",
                "public address"
            ]
        },
        {
            "unified": "1F4E3",
            "name": "CHEERING MEGAPHONE",
            "native": "📣",
            "shortName": "mega",
            "shortNames": [
                "mega"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 26,
            "tts": "megaphone",
            "keywords": [
                "cheering",
                "megaphone"
            ]
        },
        {
            "unified": "1F4EF",
            "name": "POSTAL HORN",
            "native": "📯",
            "shortName": "postal_horn",
            "shortNames": [
                "postal_horn"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 38,
            "tts": "postal horn",
            "keywords": [
                "horn",
                "post",
                "postal"
            ]
        },
        {
            "unified": "1F514",
            "name": "BELL",
            "native": "🔔",
            "shortName": "bell",
            "shortNames": [
                "bell"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 22,
            "tts": "bell",
            "keywords": [
                "bell"
            ]
        },
        {
            "unified": "1F515",
            "name": "BELL WITH CANCELLATION STROKE",
            "native": "🔕",
            "shortName": "no_bell",
            "shortNames": [
                "no_bell"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 23,
            "tts": "bell with slash",
            "keywords": [
                "bell",
                "bell with slash",
                "forbidden",
                "mute",
                "no",
                "not",
                "prohibited",
                "quiet",
                "silent"
            ]
        },
        {
            "unified": "1F3BC",
            "name": "MUSICAL SCORE",
            "native": "🎼",
            "shortName": "musical_score",
            "shortNames": [
                "musical_score"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 9,
            "sheetY": 22,
            "tts": "musical score",
            "keywords": [
                "music",
                "musical score",
                "score"
            ]
        },
        {
            "unified": "1F3B5",
            "name": "MUSICAL NOTE",
            "native": "🎵",
            "shortName": "musical_note",
            "shortNames": [
                "musical_note"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 9,
            "sheetY": 15,
            "tts": "musical note",
            "keywords": [
                "music",
                "musical note",
                "note"
            ]
        },
        {
            "unified": "1F3B6",
            "name": "MULTIPLE MUSICAL NOTES",
            "native": "🎶",
            "shortName": "notes",
            "shortNames": [
                "notes"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 9,
            "sheetY": 16,
            "tts": "musical notes",
            "keywords": [
                "music",
                "musical notes",
                "note",
                "notes"
            ]
        },
        {
            "unified": "1F399-FE0F",
            "name": null,
            "native": "🎙️",
            "shortName": "studio_microphone",
            "shortNames": [
                "studio_microphone"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 8,
            "sheetY": 41
        },
        {
            "unified": "1F39A-FE0F",
            "name": null,
            "native": "🎚️",
            "shortName": "level_slider",
            "shortNames": [
                "level_slider"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 8,
            "sheetY": 42
        },
        {
            "unified": "1F39B-FE0F",
            "name": null,
            "native": "🎛️",
            "shortName": "control_knobs",
            "shortNames": [
                "control_knobs"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 8,
            "sheetY": 43
        },
        {
            "unified": "1F3A4",
            "name": "MICROPHONE",
            "native": "🎤",
            "shortName": "microphone",
            "shortNames": [
                "microphone"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 8,
            "sheetY": 50,
            "tts": "microphone",
            "keywords": [
                "karaoke",
                "mic",
                "microphone"
            ]
        },
        {
            "unified": "1F3A7",
            "name": "HEADPHONE",
            "native": "🎧",
            "shortName": "headphones",
            "shortNames": [
                "headphones"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 9,
            "sheetY": 1,
            "tts": "headphone",
            "keywords": [
                "earbud",
                "headphone"
            ]
        },
        {
            "unified": "1F4FB",
            "name": "RADIO",
            "native": "📻",
            "shortName": "radio",
            "shortNames": [
                "radio"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 50,
            "tts": "radio",
            "keywords": [
                "radio",
                "video"
            ]
        },
        {
            "unified": "1F3B7",
            "name": "SAXOPHONE",
            "native": "🎷",
            "shortName": "saxophone",
            "shortNames": [
                "saxophone"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 9,
            "sheetY": 17,
            "tts": "saxophone",
            "keywords": [
                "instrument",
                "music",
                "sax",
                "saxophone"
            ]
        },
        {
            "unified": "1F3B8",
            "name": "GUITAR",
            "native": "🎸",
            "shortName": "guitar",
            "shortNames": [
                "guitar"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 9,
            "sheetY": 18,
            "tts": "guitar",
            "keywords": [
                "guitar",
                "instrument",
                "music"
            ]
        },
        {
            "unified": "1F3B9",
            "name": "MUSICAL KEYBOARD",
            "native": "🎹",
            "shortName": "musical_keyboard",
            "shortNames": [
                "musical_keyboard"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 9,
            "sheetY": 19,
            "tts": "musical keyboard",
            "keywords": [
                "instrument",
                "keyboard",
                "music",
                "musical keyboard",
                "piano"
            ]
        },
        {
            "unified": "1F3BA",
            "name": "TRUMPET",
            "native": "🎺",
            "shortName": "trumpet",
            "shortNames": [
                "trumpet"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 9,
            "sheetY": 20,
            "tts": "trumpet",
            "keywords": [
                "instrument",
                "music",
                "trumpet"
            ]
        },
        {
            "unified": "1F3BB",
            "name": "VIOLIN",
            "native": "🎻",
            "shortName": "violin",
            "shortNames": [
                "violin"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 9,
            "sheetY": 21,
            "tts": "violin",
            "keywords": [
                "instrument",
                "music",
                "violin"
            ]
        },
        {
            "unified": "1F941",
            "name": "DRUM WITH DRUMSTICKS",
            "native": "🥁",
            "shortName": "drum_with_drumsticks",
            "shortNames": [
                "drum_with_drumsticks"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 41,
            "sheetY": 37,
            "tts": "drum",
            "keywords": [
                "drum",
                "drumsticks",
                "music"
            ]
        },
        {
            "unified": "1F4F1",
            "name": "MOBILE PHONE",
            "native": "📱",
            "shortName": "iphone",
            "shortNames": [
                "iphone"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 40,
            "tts": "mobile phone",
            "keywords": [
                "cell",
                "mobile",
                "phone",
                "telephone"
            ]
        },
        {
            "unified": "1F4F2",
            "name": "MOBILE PHONE WITH RIGHTWARDS ARROW AT LEFT",
            "native": "📲",
            "shortName": "calling",
            "shortNames": [
                "calling"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 41,
            "tts": "mobile phone with arrow",
            "keywords": [
                "arrow",
                "call",
                "cell",
                "mobile",
                "mobile phone with arrow",
                "phone",
                "receive",
                "telephone"
            ]
        },
        {
            "unified": "260E-FE0F",
            "name": "BLACK TELEPHONE",
            "native": "☎️",
            "shortName": "phone",
            "shortNames": [
                "phone",
                "telephone"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 47,
            "sheetY": 21
        },
        {
            "unified": "1F4DE",
            "name": "TELEPHONE RECEIVER",
            "native": "📞",
            "shortName": "telephone_receiver",
            "shortNames": [
                "telephone_receiver"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 21,
            "tts": "telephone receiver",
            "keywords": [
                "phone",
                "receiver",
                "telephone"
            ]
        },
        {
            "unified": "1F4DF",
            "name": "PAGER",
            "native": "📟",
            "shortName": "pager",
            "shortNames": [
                "pager"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 22,
            "tts": "pager",
            "keywords": [
                "pager"
            ]
        },
        {
            "unified": "1F4E0",
            "name": "FAX MACHINE",
            "native": "📠",
            "shortName": "fax",
            "shortNames": [
                "fax"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 23,
            "tts": "fax machine",
            "keywords": [
                "fax",
                "fax machine"
            ]
        },
        {
            "unified": "1F50B",
            "name": "BATTERY",
            "native": "🔋",
            "shortName": "battery",
            "shortNames": [
                "battery"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 13,
            "tts": "battery",
            "keywords": [
                "battery"
            ]
        },
        {
            "unified": "1F50C",
            "name": "ELECTRIC PLUG",
            "native": "🔌",
            "shortName": "electric_plug",
            "shortNames": [
                "electric_plug"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 14,
            "tts": "electric plug",
            "keywords": [
                "electric",
                "electricity",
                "plug"
            ]
        },
        {
            "unified": "1F4BB",
            "name": "PERSONAL COMPUTER",
            "native": "💻",
            "shortName": "computer",
            "shortNames": [
                "computer"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 38,
            "tts": "laptop computer",
            "keywords": [
                "computer",
                "laptop computer",
                "pc",
                "personal"
            ]
        },
        {
            "unified": "1F5A5-FE0F",
            "name": null,
            "native": "🖥️",
            "shortName": "desktop_computer",
            "shortNames": [
                "desktop_computer"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 29,
            "sheetY": 51
        },
        {
            "unified": "1F5A8-FE0F",
            "name": null,
            "native": "🖨️",
            "shortName": "printer",
            "shortNames": [
                "printer"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 30,
            "sheetY": 0
        },
        {
            "unified": "2328-FE0F",
            "name": null,
            "native": "⌨️",
            "shortName": "keyboard",
            "shortNames": [
                "keyboard"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 46,
            "sheetY": 43
        },
        {
            "unified": "1F5B1-FE0F",
            "name": null,
            "native": "🖱️",
            "shortName": "three_button_mouse",
            "shortNames": [
                "three_button_mouse"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 30,
            "sheetY": 1
        },
        {
            "unified": "1F5B2-FE0F",
            "name": null,
            "native": "🖲️",
            "shortName": "trackball",
            "shortNames": [
                "trackball"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 30,
            "sheetY": 2
        },
        {
            "unified": "1F4BD",
            "name": "MINIDISC",
            "native": "💽",
            "shortName": "minidisc",
            "shortNames": [
                "minidisc"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 40,
            "tts": "computer disk",
            "keywords": [
                "computer",
                "disk",
                "minidisk",
                "optical"
            ]
        },
        {
            "unified": "1F4BE",
            "name": "FLOPPY DISK",
            "native": "💾",
            "shortName": "floppy_disk",
            "shortNames": [
                "floppy_disk"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 41,
            "tts": "floppy disk",
            "keywords": [
                "computer",
                "disk",
                "floppy"
            ]
        },
        {
            "unified": "1F4BF",
            "name": "OPTICAL DISC",
            "native": "💿",
            "shortName": "cd",
            "shortNames": [
                "cd"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 42,
            "tts": "optical disk",
            "keywords": [
                "cd",
                "computer",
                "disk",
                "optical"
            ]
        },
        {
            "unified": "1F4C0",
            "name": "DVD",
            "native": "📀",
            "shortName": "dvd",
            "shortNames": [
                "dvd"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 43,
            "tts": "dvd",
            "keywords": [
                "blu-ray",
                "computer",
                "disk",
                "dvd",
                "optical"
            ]
        },
        {
            "unified": "1F3A5",
            "name": "MOVIE CAMERA",
            "native": "🎥",
            "shortName": "movie_camera",
            "shortNames": [
                "movie_camera"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 8,
            "sheetY": 51,
            "tts": "movie camera",
            "keywords": [
                "camera",
                "cinema",
                "movie"
            ]
        },
        {
            "unified": "1F39E-FE0F",
            "name": null,
            "native": "🎞️",
            "shortName": "film_frames",
            "shortNames": [
                "film_frames"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 8,
            "sheetY": 44
        },
        {
            "unified": "1F4FD-FE0F",
            "name": null,
            "native": "📽️",
            "shortName": "film_projector",
            "shortNames": [
                "film_projector"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 0
        },
        {
            "unified": "1F3AC",
            "name": "CLAPPER BOARD",
            "native": "🎬",
            "shortName": "clapper",
            "shortNames": [
                "clapper"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 9,
            "sheetY": 6,
            "tts": "clapper board",
            "keywords": [
                "clapper",
                "clapper board",
                "movie"
            ]
        },
        {
            "unified": "1F4FA",
            "name": "TELEVISION",
            "native": "📺",
            "shortName": "tv",
            "shortNames": [
                "tv"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 49,
            "tts": "television",
            "keywords": [
                "television",
                "tv",
                "video"
            ]
        },
        {
            "unified": "1F4F7",
            "name": "CAMERA",
            "native": "📷",
            "shortName": "camera",
            "shortNames": [
                "camera"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 46,
            "tts": "camera",
            "keywords": [
                "camera",
                "video"
            ]
        },
        {
            "unified": "1F4F8",
            "name": "CAMERA WITH FLASH",
            "native": "📸",
            "shortName": "camera_with_flash",
            "shortNames": [
                "camera_with_flash"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 47,
            "tts": "camera with flash",
            "keywords": [
                "camera",
                "camera with flash",
                "flash",
                "video"
            ]
        },
        {
            "unified": "1F4F9",
            "name": "VIDEO CAMERA",
            "native": "📹",
            "shortName": "video_camera",
            "shortNames": [
                "video_camera"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 48,
            "tts": "video camera",
            "keywords": [
                "camera",
                "video"
            ]
        },
        {
            "unified": "1F4FC",
            "name": "VIDEOCASSETTE",
            "native": "📼",
            "shortName": "vhs",
            "shortNames": [
                "vhs"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 51,
            "tts": "videocassette",
            "keywords": [
                "tape",
                "vhs",
                "video",
                "videocassette"
            ]
        },
        {
            "unified": "1F50D",
            "name": "LEFT-POINTING MAGNIFYING GLASS",
            "native": "🔍",
            "shortName": "mag",
            "shortNames": [
                "mag"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 15,
            "tts": "magnifying glass tilted left",
            "keywords": [
                "glass",
                "magnifying",
                "magnifying glass tilted left",
                "search",
                "tool"
            ]
        },
        {
            "unified": "1F50E",
            "name": "RIGHT-POINTING MAGNIFYING GLASS",
            "native": "🔎",
            "shortName": "mag_right",
            "shortNames": [
                "mag_right"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 16,
            "tts": "magnifying glass tilted right",
            "keywords": [
                "glass",
                "magnifying",
                "magnifying glass tilted right",
                "search",
                "tool"
            ]
        },
        {
            "unified": "1F56F-FE0F",
            "name": null,
            "native": "🕯️",
            "shortName": "candle",
            "shortNames": [
                "candle"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 28,
            "sheetY": 42
        },
        {
            "unified": "1F4A1",
            "name": "ELECTRIC LIGHT BULB",
            "native": "💡",
            "shortName": "bulb",
            "shortNames": [
                "bulb"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 7,
            "tts": "light bulb",
            "keywords": [
                "bulb",
                "comic",
                "electric",
                "idea",
                "light"
            ]
        },
        {
            "unified": "1F526",
            "name": "ELECTRIC TORCH",
            "native": "🔦",
            "shortName": "flashlight",
            "shortNames": [
                "flashlight"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 40,
            "tts": "flashlight",
            "keywords": [
                "electric",
                "flashlight",
                "light",
                "tool",
                "torch"
            ]
        },
        {
            "unified": "1F3EE",
            "name": "IZAKAYA LANTERN",
            "native": "🏮",
            "shortName": "izakaya_lantern",
            "shortNames": [
                "izakaya_lantern",
                "lantern"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 12,
            "sheetY": 11,
            "tts": "red paper lantern",
            "keywords": [
                "bar",
                "lantern",
                "light",
                "red",
                "red paper lantern"
            ]
        },
        {
            "unified": "1F4D4",
            "name": "NOTEBOOK WITH DECORATIVE COVER",
            "native": "📔",
            "shortName": "notebook_with_decorative_cover",
            "shortNames": [
                "notebook_with_decorative_cover"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 11,
            "tts": "notebook with decorative cover",
            "keywords": [
                "book",
                "cover",
                "decorated",
                "notebook",
                "notebook with decorative cover"
            ]
        },
        {
            "unified": "1F4D5",
            "name": "CLOSED BOOK",
            "native": "📕",
            "shortName": "closed_book",
            "shortNames": [
                "closed_book"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 12,
            "tts": "closed book",
            "keywords": [
                "book",
                "closed"
            ]
        },
        {
            "unified": "1F4D6",
            "name": "OPEN BOOK",
            "native": "📖",
            "shortName": "book",
            "shortNames": [
                "book",
                "open_book"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 13,
            "tts": "open book",
            "keywords": [
                "book",
                "open"
            ]
        },
        {
            "unified": "1F4D7",
            "name": "GREEN BOOK",
            "native": "📗",
            "shortName": "green_book",
            "shortNames": [
                "green_book"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 14,
            "tts": "green book",
            "keywords": [
                "book",
                "green"
            ]
        },
        {
            "unified": "1F4D8",
            "name": "BLUE BOOK",
            "native": "📘",
            "shortName": "blue_book",
            "shortNames": [
                "blue_book"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 15,
            "tts": "blue book",
            "keywords": [
                "blue",
                "book"
            ]
        },
        {
            "unified": "1F4D9",
            "name": "ORANGE BOOK",
            "native": "📙",
            "shortName": "orange_book",
            "shortNames": [
                "orange_book"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 16,
            "tts": "orange book",
            "keywords": [
                "book",
                "orange"
            ]
        },
        {
            "unified": "1F4DA",
            "name": "BOOKS",
            "native": "📚",
            "shortName": "books",
            "shortNames": [
                "books"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 17,
            "tts": "books",
            "keywords": [
                "book",
                "books"
            ]
        },
        {
            "unified": "1F4D3",
            "name": "NOTEBOOK",
            "native": "📓",
            "shortName": "notebook",
            "shortNames": [
                "notebook"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 10,
            "tts": "notebook",
            "keywords": [
                "notebook"
            ]
        },
        {
            "unified": "1F4D2",
            "name": "LEDGER",
            "native": "📒",
            "shortName": "ledger",
            "shortNames": [
                "ledger"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 9,
            "tts": "ledger",
            "keywords": [
                "ledger",
                "notebook"
            ]
        },
        {
            "unified": "1F4C3",
            "name": "PAGE WITH CURL",
            "native": "📃",
            "shortName": "page_with_curl",
            "shortNames": [
                "page_with_curl"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 46,
            "tts": "page with curl",
            "keywords": [
                "curl",
                "document",
                "page",
                "page with curl"
            ]
        },
        {
            "unified": "1F4DC",
            "name": "SCROLL",
            "native": "📜",
            "shortName": "scroll",
            "shortNames": [
                "scroll"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 19,
            "tts": "scroll",
            "keywords": [
                "paper",
                "scroll"
            ]
        },
        {
            "unified": "1F4C4",
            "name": "PAGE FACING UP",
            "native": "📄",
            "shortName": "page_facing_up",
            "shortNames": [
                "page_facing_up"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 47,
            "tts": "page facing up",
            "keywords": [
                "document",
                "page",
                "page facing up"
            ]
        },
        {
            "unified": "1F4F0",
            "name": "NEWSPAPER",
            "native": "📰",
            "shortName": "newspaper",
            "shortNames": [
                "newspaper"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 39,
            "tts": "newspaper",
            "keywords": [
                "news",
                "newspaper",
                "paper"
            ]
        },
        {
            "unified": "1F5DE-FE0F",
            "name": null,
            "native": "🗞️",
            "shortName": "rolled_up_newspaper",
            "shortNames": [
                "rolled_up_newspaper"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 30,
            "sheetY": 12
        },
        {
            "unified": "1F4D1",
            "name": "BOOKMARK TABS",
            "native": "📑",
            "shortName": "bookmark_tabs",
            "shortNames": [
                "bookmark_tabs"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 8,
            "tts": "bookmark tabs",
            "keywords": [
                "bookmark",
                "mark",
                "marker",
                "tabs"
            ]
        },
        {
            "unified": "1F516",
            "name": "BOOKMARK",
            "native": "🔖",
            "shortName": "bookmark",
            "shortNames": [
                "bookmark"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 24,
            "tts": "bookmark",
            "keywords": [
                "bookmark",
                "mark"
            ]
        },
        {
            "unified": "1F3F7-FE0F",
            "name": null,
            "native": "🏷️",
            "shortName": "label",
            "shortNames": [
                "label"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 12,
            "sheetY": 21
        },
        {
            "unified": "1F4B0",
            "name": "MONEY BAG",
            "native": "💰",
            "shortName": "moneybag",
            "shortNames": [
                "moneybag"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 27,
            "tts": "money bag",
            "keywords": [
                "bag",
                "dollar",
                "money",
                "moneybag"
            ]
        },
        {
            "unified": "1F4B4",
            "name": "BANKNOTE WITH YEN SIGN",
            "native": "💴",
            "shortName": "yen",
            "shortNames": [
                "yen"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 31,
            "tts": "yen banknote",
            "keywords": [
                "bank",
                "banknote",
                "bill",
                "currency",
                "money",
                "note",
                "yen"
            ]
        },
        {
            "unified": "1F4B5",
            "name": "BANKNOTE WITH DOLLAR SIGN",
            "native": "💵",
            "shortName": "dollar",
            "shortNames": [
                "dollar"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 32,
            "tts": "dollar banknote",
            "keywords": [
                "bank",
                "banknote",
                "bill",
                "currency",
                "dollar",
                "money",
                "note"
            ]
        },
        {
            "unified": "1F4B6",
            "name": "BANKNOTE WITH EURO SIGN",
            "native": "💶",
            "shortName": "euro",
            "shortNames": [
                "euro"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 33,
            "tts": "euro banknote",
            "keywords": [
                "bank",
                "banknote",
                "bill",
                "currency",
                "euro",
                "money",
                "note"
            ]
        },
        {
            "unified": "1F4B7",
            "name": "BANKNOTE WITH POUND SIGN",
            "native": "💷",
            "shortName": "pound",
            "shortNames": [
                "pound"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 34,
            "tts": "pound banknote",
            "keywords": [
                "bank",
                "banknote",
                "bill",
                "currency",
                "money",
                "note",
                "pound"
            ]
        },
        {
            "unified": "1F4B8",
            "name": "MONEY WITH WINGS",
            "native": "💸",
            "shortName": "money_with_wings",
            "shortNames": [
                "money_with_wings"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 35,
            "tts": "money with wings",
            "keywords": [
                "bank",
                "banknote",
                "bill",
                "dollar",
                "fly",
                "money",
                "money with wings",
                "note",
                "wings"
            ]
        },
        {
            "unified": "1F4B3",
            "name": "CREDIT CARD",
            "native": "💳",
            "shortName": "credit_card",
            "shortNames": [
                "credit_card"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 30,
            "tts": "credit card",
            "keywords": [
                "bank",
                "card",
                "credit",
                "money"
            ]
        },
        {
            "unified": "1F4B9",
            "name": "CHART WITH UPWARDS TREND AND YEN SIGN",
            "native": "💹",
            "shortName": "chart",
            "shortNames": [
                "chart"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 36,
            "tts": "chart increasing with yen",
            "keywords": [
                "bank",
                "chart",
                "chart increasing with yen",
                "currency",
                "graph",
                "growth",
                "market",
                "money",
                "rise",
                "trend",
                "upward",
                "yen"
            ]
        },
        {
            "unified": "1F4B1",
            "name": "CURRENCY EXCHANGE",
            "native": "💱",
            "shortName": "currency_exchange",
            "shortNames": [
                "currency_exchange"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 28,
            "tts": "currency exchange",
            "keywords": [
                "bank",
                "currency",
                "exchange",
                "money"
            ]
        },
        {
            "unified": "1F4B2",
            "name": "HEAVY DOLLAR SIGN",
            "native": "💲",
            "shortName": "heavy_dollar_sign",
            "shortNames": [
                "heavy_dollar_sign"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 29,
            "tts": "heavy dollar sign",
            "keywords": [
                "currency",
                "dollar",
                "heavy dollar sign",
                "money"
            ]
        },
        {
            "unified": "2709-FE0F",
            "name": "ENVELOPE",
            "native": "✉️",
            "shortName": "email",
            "shortNames": [
                "email",
                "envelope"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 49,
            "sheetY": 17
        },
        {
            "unified": "1F4E7",
            "name": "E-MAIL SYMBOL",
            "native": "📧",
            "shortName": "e-mail",
            "shortNames": [
                "e-mail"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 30,
            "tts": "e-mail",
            "keywords": [
                "e-mail",
                "email",
                "letter",
                "mail"
            ]
        },
        {
            "unified": "1F4E8",
            "name": "INCOMING ENVELOPE",
            "native": "📨",
            "shortName": "incoming_envelope",
            "shortNames": [
                "incoming_envelope"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 31,
            "tts": "incoming envelope",
            "keywords": [
                "e-mail",
                "email",
                "envelope",
                "incoming",
                "letter",
                "mail",
                "receive"
            ]
        },
        {
            "unified": "1F4E9",
            "name": "ENVELOPE WITH DOWNWARDS ARROW ABOVE",
            "native": "📩",
            "shortName": "envelope_with_arrow",
            "shortNames": [
                "envelope_with_arrow"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 32,
            "tts": "envelope with arrow",
            "keywords": [
                "arrow",
                "down",
                "e-mail",
                "email",
                "envelope",
                "envelope with arrow",
                "letter",
                "mail",
                "outgoing",
                "sent"
            ]
        },
        {
            "unified": "1F4E4",
            "name": "OUTBOX TRAY",
            "native": "📤",
            "shortName": "outbox_tray",
            "shortNames": [
                "outbox_tray"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 27,
            "tts": "outbox tray",
            "keywords": [
                "box",
                "letter",
                "mail",
                "outbox",
                "sent",
                "tray"
            ]
        },
        {
            "unified": "1F4E5",
            "name": "INBOX TRAY",
            "native": "📥",
            "shortName": "inbox_tray",
            "shortNames": [
                "inbox_tray"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 28,
            "tts": "inbox tray",
            "keywords": [
                "box",
                "inbox",
                "letter",
                "mail",
                "receive",
                "tray"
            ]
        },
        {
            "unified": "1F4E6",
            "name": "PACKAGE",
            "native": "📦",
            "shortName": "package",
            "shortNames": [
                "package"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 29,
            "tts": "package",
            "keywords": [
                "box",
                "package",
                "parcel"
            ]
        },
        {
            "unified": "1F4EB",
            "name": "CLOSED MAILBOX WITH RAISED FLAG",
            "native": "📫",
            "shortName": "mailbox",
            "shortNames": [
                "mailbox"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 34,
            "tts": "closed mailbox with raised flag",
            "keywords": [
                "closed",
                "closed mailbox with raised flag",
                "mail",
                "mailbox",
                "postbox"
            ]
        },
        {
            "unified": "1F4EA",
            "name": "CLOSED MAILBOX WITH LOWERED FLAG",
            "native": "📪",
            "shortName": "mailbox_closed",
            "shortNames": [
                "mailbox_closed"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 33,
            "tts": "closed mailbox with lowered flag",
            "keywords": [
                "closed",
                "closed mailbox with lowered flag",
                "lowered",
                "mail",
                "mailbox",
                "postbox"
            ]
        },
        {
            "unified": "1F4EC",
            "name": "OPEN MAILBOX WITH RAISED FLAG",
            "native": "📬",
            "shortName": "mailbox_with_mail",
            "shortNames": [
                "mailbox_with_mail"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 35,
            "tts": "open mailbox with raised flag",
            "keywords": [
                "mail",
                "mailbox",
                "open",
                "open mailbox with raised flag",
                "postbox"
            ]
        },
        {
            "unified": "1F4ED",
            "name": "OPEN MAILBOX WITH LOWERED FLAG",
            "native": "📭",
            "shortName": "mailbox_with_no_mail",
            "shortNames": [
                "mailbox_with_no_mail"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 36,
            "tts": "open mailbox with lowered flag",
            "keywords": [
                "lowered",
                "mail",
                "mailbox",
                "open",
                "open mailbox with lowered flag",
                "postbox"
            ]
        },
        {
            "unified": "1F4EE",
            "name": "POSTBOX",
            "native": "📮",
            "shortName": "postbox",
            "shortNames": [
                "postbox"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 37,
            "tts": "postbox",
            "keywords": [
                "mail",
                "mailbox",
                "postbox"
            ]
        },
        {
            "unified": "1F5F3-FE0F",
            "name": null,
            "native": "🗳️",
            "shortName": "ballot_box_with_ballot",
            "shortNames": [
                "ballot_box_with_ballot"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 30,
            "sheetY": 17
        },
        {
            "unified": "270F-FE0F",
            "name": "PENCIL",
            "native": "✏️",
            "shortName": "pencil2",
            "shortNames": [
                "pencil2"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 49,
            "sheetY": 42
        },
        {
            "unified": "2712-FE0F",
            "name": "BLACK NIB",
            "native": "✒️",
            "shortName": "black_nib",
            "shortNames": [
                "black_nib"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 49,
            "sheetY": 43
        },
        {
            "unified": "1F58B-FE0F",
            "name": null,
            "native": "🖋️",
            "shortName": "lower_left_fountain_pen",
            "shortNames": [
                "lower_left_fountain_pen"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 29,
            "sheetY": 29
        },
        {
            "unified": "1F58A-FE0F",
            "name": null,
            "native": "🖊️",
            "shortName": "lower_left_ballpoint_pen",
            "shortNames": [
                "lower_left_ballpoint_pen"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 29,
            "sheetY": 28
        },
        {
            "unified": "1F58C-FE0F",
            "name": null,
            "native": "🖌️",
            "shortName": "lower_left_paintbrush",
            "shortNames": [
                "lower_left_paintbrush"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 29,
            "sheetY": 30
        },
        {
            "unified": "1F58D-FE0F",
            "name": null,
            "native": "🖍️",
            "shortName": "lower_left_crayon",
            "shortNames": [
                "lower_left_crayon"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 29,
            "sheetY": 31
        },
        {
            "unified": "1F4DD",
            "name": "MEMO",
            "native": "📝",
            "shortName": "memo",
            "shortNames": [
                "memo",
                "pencil"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 20,
            "tts": "memo",
            "keywords": [
                "memo",
                "pencil"
            ]
        },
        {
            "unified": "1F4BC",
            "name": "BRIEFCASE",
            "native": "💼",
            "shortName": "briefcase",
            "shortNames": [
                "briefcase"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 39,
            "tts": "briefcase",
            "keywords": [
                "briefcase"
            ]
        },
        {
            "unified": "1F4C1",
            "name": "FILE FOLDER",
            "native": "📁",
            "shortName": "file_folder",
            "shortNames": [
                "file_folder"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 44,
            "tts": "file folder",
            "keywords": [
                "file",
                "folder"
            ]
        },
        {
            "unified": "1F4C2",
            "name": "OPEN FILE FOLDER",
            "native": "📂",
            "shortName": "open_file_folder",
            "shortNames": [
                "open_file_folder"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 45,
            "tts": "open file folder",
            "keywords": [
                "file",
                "folder",
                "open"
            ]
        },
        {
            "unified": "1F5C2-FE0F",
            "name": null,
            "native": "🗂️",
            "shortName": "card_index_dividers",
            "shortNames": [
                "card_index_dividers"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 30,
            "sheetY": 4
        },
        {
            "unified": "1F4C5",
            "name": "CALENDAR",
            "native": "📅",
            "shortName": "date",
            "shortNames": [
                "date"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 48,
            "tts": "calendar",
            "keywords": [
                "calendar",
                "date"
            ]
        },
        {
            "unified": "1F4C6",
            "name": "TEAR-OFF CALENDAR",
            "native": "📆",
            "shortName": "calendar",
            "shortNames": [
                "calendar"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 49,
            "tts": "tear-off calendar",
            "keywords": [
                "calendar",
                "tear-off calendar"
            ]
        },
        {
            "unified": "1F5D2-FE0F",
            "name": null,
            "native": "🗒️",
            "shortName": "spiral_note_pad",
            "shortNames": [
                "spiral_note_pad"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 30,
            "sheetY": 8
        },
        {
            "unified": "1F5D3-FE0F",
            "name": null,
            "native": "🗓️",
            "shortName": "spiral_calendar_pad",
            "shortNames": [
                "spiral_calendar_pad"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 30,
            "sheetY": 9
        },
        {
            "unified": "1F4C7",
            "name": "CARD INDEX",
            "native": "📇",
            "shortName": "card_index",
            "shortNames": [
                "card_index"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 50,
            "tts": "card index",
            "keywords": [
                "card",
                "index",
                "rolodex"
            ]
        },
        {
            "unified": "1F4C8",
            "name": "CHART WITH UPWARDS TREND",
            "native": "📈",
            "shortName": "chart_with_upwards_trend",
            "shortNames": [
                "chart_with_upwards_trend"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 25,
            "sheetY": 51,
            "tts": "chart increasing",
            "keywords": [
                "chart",
                "chart increasing",
                "graph",
                "growth",
                "trend",
                "upward"
            ]
        },
        {
            "unified": "1F4C9",
            "name": "CHART WITH DOWNWARDS TREND",
            "native": "📉",
            "shortName": "chart_with_downwards_trend",
            "shortNames": [
                "chart_with_downwards_trend"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 0,
            "tts": "chart decreasing",
            "keywords": [
                "chart",
                "chart decreasing",
                "down",
                "graph",
                "trend"
            ]
        },
        {
            "unified": "1F4CA",
            "name": "BAR CHART",
            "native": "📊",
            "shortName": "bar_chart",
            "shortNames": [
                "bar_chart"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 1,
            "tts": "bar chart",
            "keywords": [
                "bar",
                "chart",
                "graph"
            ]
        },
        {
            "unified": "1F4CB",
            "name": "CLIPBOARD",
            "native": "📋",
            "shortName": "clipboard",
            "shortNames": [
                "clipboard"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 2,
            "tts": "clipboard",
            "keywords": [
                "clipboard"
            ]
        },
        {
            "unified": "1F4CC",
            "name": "PUSHPIN",
            "native": "📌",
            "shortName": "pushpin",
            "shortNames": [
                "pushpin"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 3,
            "tts": "pushpin",
            "keywords": [
                "pin",
                "pushpin"
            ]
        },
        {
            "unified": "1F4CD",
            "name": "ROUND PUSHPIN",
            "native": "📍",
            "shortName": "round_pushpin",
            "shortNames": [
                "round_pushpin"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 4,
            "tts": "round pushpin",
            "keywords": [
                "pin",
                "pushpin",
                "round pushpin"
            ]
        },
        {
            "unified": "1F4CE",
            "name": "PAPERCLIP",
            "native": "📎",
            "shortName": "paperclip",
            "shortNames": [
                "paperclip"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 5,
            "tts": "paperclip",
            "keywords": [
                "paperclip"
            ]
        },
        {
            "unified": "1F587-FE0F",
            "name": null,
            "native": "🖇️",
            "shortName": "linked_paperclips",
            "shortNames": [
                "linked_paperclips"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 29,
            "sheetY": 27
        },
        {
            "unified": "1F4CF",
            "name": "STRAIGHT RULER",
            "native": "📏",
            "shortName": "straight_ruler",
            "shortNames": [
                "straight_ruler"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 6,
            "tts": "straight ruler",
            "keywords": [
                "ruler",
                "straight edge",
                "straight ruler"
            ]
        },
        {
            "unified": "1F4D0",
            "name": "TRIANGULAR RULER",
            "native": "📐",
            "shortName": "triangular_ruler",
            "shortNames": [
                "triangular_ruler"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 7,
            "tts": "triangular ruler",
            "keywords": [
                "ruler",
                "set",
                "triangle",
                "triangular ruler"
            ]
        },
        {
            "unified": "2702-FE0F",
            "name": "BLACK SCISSORS",
            "native": "✂️",
            "shortName": "scissors",
            "shortNames": [
                "scissors"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 49,
            "sheetY": 14
        },
        {
            "unified": "1F5C3-FE0F",
            "name": null,
            "native": "🗃️",
            "shortName": "card_file_box",
            "shortNames": [
                "card_file_box"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 30,
            "sheetY": 5
        },
        {
            "unified": "1F5C4-FE0F",
            "name": null,
            "native": "🗄️",
            "shortName": "file_cabinet",
            "shortNames": [
                "file_cabinet"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 30,
            "sheetY": 6
        },
        {
            "unified": "1F5D1-FE0F",
            "name": null,
            "native": "🗑️",
            "shortName": "wastebasket",
            "shortNames": [
                "wastebasket"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 30,
            "sheetY": 7
        },
        {
            "unified": "1F512",
            "name": "LOCK",
            "native": "🔒",
            "shortName": "lock",
            "shortNames": [
                "lock"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 20,
            "tts": "locked",
            "keywords": [
                "closed",
                "locked"
            ]
        },
        {
            "unified": "1F513",
            "name": "OPEN LOCK",
            "native": "🔓",
            "shortName": "unlock",
            "shortNames": [
                "unlock"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 21,
            "tts": "unlocked",
            "keywords": [
                "lock",
                "open",
                "unlock",
                "unlocked"
            ]
        },
        {
            "unified": "1F50F",
            "name": "LOCK WITH INK PEN",
            "native": "🔏",
            "shortName": "lock_with_ink_pen",
            "shortNames": [
                "lock_with_ink_pen"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 17,
            "tts": "locked with pen",
            "keywords": [
                "ink",
                "lock",
                "locked with pen",
                "nib",
                "pen",
                "privacy"
            ]
        },
        {
            "unified": "1F510",
            "name": "CLOSED LOCK WITH KEY",
            "native": "🔐",
            "shortName": "closed_lock_with_key",
            "shortNames": [
                "closed_lock_with_key"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 18,
            "tts": "locked with key",
            "keywords": [
                "closed",
                "key",
                "lock",
                "locked with key",
                "secure"
            ]
        },
        {
            "unified": "1F511",
            "name": "KEY",
            "native": "🔑",
            "shortName": "key",
            "shortNames": [
                "key"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 19,
            "tts": "key",
            "keywords": [
                "key",
                "lock",
                "password"
            ]
        },
        {
            "unified": "1F5DD-FE0F",
            "name": null,
            "native": "🗝️",
            "shortName": "old_key",
            "shortNames": [
                "old_key"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 30,
            "sheetY": 11
        },
        {
            "unified": "1F528",
            "name": "HAMMER",
            "native": "🔨",
            "shortName": "hammer",
            "shortNames": [
                "hammer"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 42,
            "tts": "hammer",
            "keywords": [
                "hammer",
                "tool"
            ]
        },
        {
            "unified": "26CF-FE0F",
            "name": null,
            "native": "⛏️",
            "shortName": "pick",
            "shortNames": [
                "pick"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 48,
            "sheetY": 32
        },
        {
            "unified": "2692-FE0F",
            "name": null,
            "native": "⚒️",
            "shortName": "hammer_and_pick",
            "shortNames": [
                "hammer_and_pick"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 48,
            "sheetY": 11
        },
        {
            "unified": "1F6E0-FE0F",
            "name": null,
            "native": "🛠️",
            "shortName": "hammer_and_wrench",
            "shortNames": [
                "hammer_and_wrench"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 37,
            "sheetY": 8
        },
        {
            "unified": "1F5E1-FE0F",
            "name": null,
            "native": "🗡️",
            "shortName": "dagger_knife",
            "shortNames": [
                "dagger_knife"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 30,
            "sheetY": 13
        },
        {
            "unified": "2694-FE0F",
            "name": null,
            "native": "⚔️",
            "shortName": "crossed_swords",
            "shortNames": [
                "crossed_swords"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 48,
            "sheetY": 13
        },
        {
            "unified": "1F52B",
            "name": "PISTOL",
            "native": "🔫",
            "shortName": "gun",
            "shortNames": [
                "gun"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 45,
            "tts": "pistol",
            "keywords": [
                "gun",
                "handgun",
                "pistol",
                "revolver",
                "tool",
                "weapon"
            ]
        },
        {
            "unified": "1F3F9",
            "name": "BOW AND ARROW",
            "native": "🏹",
            "shortName": "bow_and_arrow",
            "shortNames": [
                "bow_and_arrow"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 12,
            "sheetY": 23,
            "tts": "bow and arrow",
            "keywords": [
                "archer",
                "archery",
                "arrow",
                "bow",
                "bow and arrow",
                "Sagittarius",
                "tool",
                "weapon",
                "zodiac"
            ]
        },
        {
            "unified": "1F6E1-FE0F",
            "name": null,
            "native": "🛡️",
            "shortName": "shield",
            "shortNames": [
                "shield"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 37,
            "sheetY": 9
        },
        {
            "unified": "1F527",
            "name": "WRENCH",
            "native": "🔧",
            "shortName": "wrench",
            "shortNames": [
                "wrench"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 41,
            "tts": "wrench",
            "keywords": [
                "spanner",
                "tool",
                "wrench"
            ]
        },
        {
            "unified": "1F529",
            "name": "NUT AND BOLT",
            "native": "🔩",
            "shortName": "nut_and_bolt",
            "shortNames": [
                "nut_and_bolt"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 43,
            "tts": "nut and bolt",
            "keywords": [
                "bolt",
                "nut",
                "nut and bolt",
                "tool"
            ]
        },
        {
            "unified": "2699-FE0F",
            "name": null,
            "native": "⚙️",
            "shortName": "gear",
            "shortNames": [
                "gear"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 48,
            "sheetY": 17
        },
        {
            "unified": "1F5DC-FE0F",
            "name": null,
            "native": "🗜️",
            "shortName": "compression",
            "shortNames": [
                "compression"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 30,
            "sheetY": 10
        },
        {
            "unified": "2696-FE0F",
            "name": null,
            "native": "⚖️",
            "shortName": "scales",
            "shortNames": [
                "scales"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 48,
            "sheetY": 15
        },
        {
            "unified": "1F517",
            "name": "LINK SYMBOL",
            "native": "🔗",
            "shortName": "link",
            "shortNames": [
                "link"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 25,
            "tts": "link",
            "keywords": [
                "link"
            ]
        },
        {
            "unified": "26D3-FE0F",
            "name": null,
            "native": "⛓️",
            "shortName": "chains",
            "shortNames": [
                "chains"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 48,
            "sheetY": 34
        },
        {
            "unified": "2697-FE0F",
            "name": null,
            "native": "⚗️",
            "shortName": "alembic",
            "shortNames": [
                "alembic"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 48,
            "sheetY": 16
        },
        {
            "unified": "1F52C",
            "name": "MICROSCOPE",
            "native": "🔬",
            "shortName": "microscope",
            "shortNames": [
                "microscope"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 46,
            "tts": "microscope",
            "keywords": [
                "microscope",
                "science",
                "tool"
            ]
        },
        {
            "unified": "1F52D",
            "name": "TELESCOPE",
            "native": "🔭",
            "shortName": "telescope",
            "shortNames": [
                "telescope"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 27,
            "sheetY": 47,
            "tts": "telescope",
            "keywords": [
                "science",
                "telescope",
                "tool"
            ]
        },
        {
            "unified": "1F4E1",
            "name": "SATELLITE ANTENNA",
            "native": "📡",
            "shortName": "satellite_antenna",
            "shortNames": [
                "satellite_antenna"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 26,
            "sheetY": 24,
            "tts": "satellite antenna",
            "keywords": [
                "antenna",
                "dish",
                "satellite"
            ]
        },
        {
            "unified": "1F489",
            "name": "SYRINGE",
            "native": "💉",
            "shortName": "syringe",
            "shortNames": [
                "syringe"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 24,
            "sheetY": 35,
            "tts": "syringe",
            "keywords": [
                "doctor",
                "medicine",
                "needle",
                "shot",
                "sick",
                "syringe",
                "tool"
            ]
        },
        {
            "unified": "1F48A",
            "name": "PILL",
            "native": "💊",
            "shortName": "pill",
            "shortNames": [
                "pill"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 24,
            "sheetY": 36,
            "tts": "pill",
            "keywords": [
                "doctor",
                "medicine",
                "pill",
                "sick"
            ]
        },
        {
            "unified": "1F6AA",
            "name": "DOOR",
            "native": "🚪",
            "shortName": "door",
            "shortNames": [
                "door"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 35,
            "sheetY": 15,
            "tts": "door",
            "keywords": [
                "door"
            ]
        },
        {
            "unified": "1F6CF-FE0F",
            "name": null,
            "native": "🛏️",
            "shortName": "bed",
            "shortNames": [
                "bed"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 37,
            "sheetY": 4
        },
        {
            "unified": "1F6CB-FE0F",
            "name": null,
            "native": "🛋️",
            "shortName": "couch_and_lamp",
            "shortNames": [
                "couch_and_lamp"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 36,
            "sheetY": 47
        },
        {
            "unified": "1F6BD",
            "name": "TOILET",
            "native": "🚽",
            "shortName": "toilet",
            "shortNames": [
                "toilet"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 36,
            "sheetY": 33,
            "tts": "toilet",
            "keywords": [
                "toilet"
            ]
        },
        {
            "unified": "1F6BF",
            "name": "SHOWER",
            "native": "🚿",
            "shortName": "shower",
            "shortNames": [
                "shower"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 36,
            "sheetY": 35,
            "tts": "shower",
            "keywords": [
                "shower",
                "water"
            ]
        },
        {
            "unified": "1F6C1",
            "name": "BATHTUB",
            "native": "🛁",
            "shortName": "bathtub",
            "shortNames": [
                "bathtub"
            ],
            "text": null,
            "texts": null,
            "category": "Travel & Places",
            "sheetX": 36,
            "sheetY": 42,
            "tts": "bathtub",
            "keywords": [
                "bath",
                "bathtub"
            ]
        },
        {
            "unified": "1F6D2",
            "name": "SHOPPING TROLLEY",
            "native": "🛒",
            "shortName": "shopping_trolley",
            "shortNames": [
                "shopping_trolley"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 37,
            "sheetY": 7,
            "tts": "shopping cart",
            "keywords": [
                "cart",
                "shopping",
                "trolley"
            ]
        },
        {
            "unified": "1F6AC",
            "name": "SMOKING SYMBOL",
            "native": "🚬",
            "shortName": "smoking",
            "shortNames": [
                "smoking"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 35,
            "sheetY": 17,
            "tts": "cigarette",
            "keywords": [
                "cigarette",
                "smoking"
            ]
        },
        {
            "unified": "26B0-FE0F",
            "name": null,
            "native": "⚰️",
            "shortName": "coffin",
            "shortNames": [
                "coffin"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 48,
            "sheetY": 24
        },
        {
            "unified": "26B1-FE0F",
            "name": null,
            "native": "⚱️",
            "shortName": "funeral_urn",
            "shortNames": [
                "funeral_urn"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 48,
            "sheetY": 25
        },
        {
            "unified": "1F5FF",
            "name": "MOYAI",
            "native": "🗿",
            "shortName": "moyai",
            "shortNames": [
                "moyai"
            ],
            "text": null,
            "texts": null,
            "category": "Objects",
            "sheetX": 30,
            "sheetY": 23,
            "tts": "moai",
            "keywords": [
                "face",
                "moai",
                "moyai",
                "statue"
            ]
        },
        {
            "unified": "1F3E7",
            "name": "AUTOMATED TELLER MACHINE",
            "native": "🏧",
            "shortName": "atm",
            "shortNames": [
                "atm"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 12,
            "sheetY": 4,
            "tts": "ATM sign",
            "keywords": [
                "atm",
                "ATM sign",
                "automated",
                "bank",
                "teller"
            ]
        },
        {
            "unified": "1F6AE",
            "name": "PUT LITTER IN ITS PLACE SYMBOL",
            "native": "🚮",
            "shortName": "put_litter_in_its_place",
            "shortNames": [
                "put_litter_in_its_place"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 35,
            "sheetY": 19,
            "tts": "litter in bin sign",
            "keywords": [
                "litter",
                "litter bin",
                "litter in bin sign"
            ]
        },
        {
            "unified": "1F6B0",
            "name": "POTABLE WATER SYMBOL",
            "native": "🚰",
            "shortName": "potable_water",
            "shortNames": [
                "potable_water"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 35,
            "sheetY": 21,
            "tts": "potable water",
            "keywords": [
                "drinking",
                "potable",
                "water"
            ]
        },
        {
            "unified": "267F",
            "name": "WHEELCHAIR SYMBOL",
            "native": "♿",
            "shortName": "wheelchair",
            "shortNames": [
                "wheelchair"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 48,
            "sheetY": 10,
            "tts": "wheelchair symbol",
            "keywords": [
                "access",
                "wheelchair symbol"
            ]
        },
        {
            "unified": "1F6B9",
            "name": "MENS SYMBOL",
            "native": "🚹",
            "shortName": "mens",
            "shortNames": [
                "mens"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 36,
            "sheetY": 29,
            "tts": "men’s room",
            "keywords": [
                "lavatory",
                "man",
                "men’s room",
                "restroom",
                "wc"
            ]
        },
        {
            "unified": "1F6BA",
            "name": "WOMENS SYMBOL",
            "native": "🚺",
            "shortName": "womens",
            "shortNames": [
                "womens"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 36,
            "sheetY": 30,
            "tts": "women’s room",
            "keywords": [
                "lavatory",
                "restroom",
                "wc",
                "woman",
                "women’s room"
            ]
        },
        {
            "unified": "1F6BB",
            "name": "RESTROOM",
            "native": "🚻",
            "shortName": "restroom",
            "shortNames": [
                "restroom"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 36,
            "sheetY": 31,
            "tts": "restroom",
            "keywords": [
                "lavatory",
                "restroom",
                "WC"
            ]
        },
        {
            "unified": "1F6BC",
            "name": "BABY SYMBOL",
            "native": "🚼",
            "shortName": "baby_symbol",
            "shortNames": [
                "baby_symbol"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 36,
            "sheetY": 32,
            "tts": "baby symbol",
            "keywords": [
                "baby",
                "baby symbol",
                "changing"
            ]
        },
        {
            "unified": "1F6BE",
            "name": "WATER CLOSET",
            "native": "🚾",
            "shortName": "wc",
            "shortNames": [
                "wc"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 36,
            "sheetY": 34,
            "tts": "water closet",
            "keywords": [
                "closet",
                "lavatory",
                "restroom",
                "water",
                "wc"
            ]
        },
        {
            "unified": "1F6C2",
            "name": "PASSPORT CONTROL",
            "native": "🛂",
            "shortName": "passport_control",
            "shortNames": [
                "passport_control"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 36,
            "sheetY": 43,
            "tts": "passport control",
            "keywords": [
                "control",
                "passport"
            ]
        },
        {
            "unified": "1F6C3",
            "name": "CUSTOMS",
            "native": "🛃",
            "shortName": "customs",
            "shortNames": [
                "customs"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 36,
            "sheetY": 44,
            "tts": "customs",
            "keywords": [
                "customs"
            ]
        },
        {
            "unified": "1F6C4",
            "name": "BAGGAGE CLAIM",
            "native": "🛄",
            "shortName": "baggage_claim",
            "shortNames": [
                "baggage_claim"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 36,
            "sheetY": 45,
            "tts": "baggage claim",
            "keywords": [
                "baggage",
                "claim"
            ]
        },
        {
            "unified": "1F6C5",
            "name": "LEFT LUGGAGE",
            "native": "🛅",
            "shortName": "left_luggage",
            "shortNames": [
                "left_luggage"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 36,
            "sheetY": 46,
            "tts": "left luggage",
            "keywords": [
                "baggage",
                "left luggage",
                "locker",
                "luggage"
            ]
        },
        {
            "unified": "26A0-FE0F",
            "name": "WARNING SIGN",
            "native": "⚠️",
            "shortName": "warning",
            "shortNames": [
                "warning"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 48,
            "sheetY": 20
        },
        {
            "unified": "1F6B8",
            "name": "CHILDREN CROSSING",
            "native": "🚸",
            "shortName": "children_crossing",
            "shortNames": [
                "children_crossing"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 36,
            "sheetY": 28,
            "tts": "children crossing",
            "keywords": [
                "child",
                "children crossing",
                "crossing",
                "pedestrian",
                "traffic"
            ]
        },
        {
            "unified": "26D4",
            "name": "NO ENTRY",
            "native": "⛔",
            "shortName": "no_entry",
            "shortNames": [
                "no_entry"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 48,
            "sheetY": 35,
            "tts": "no entry",
            "keywords": [
                "entry",
                "forbidden",
                "no",
                "not",
                "prohibited",
                "traffic"
            ]
        },
        {
            "unified": "1F6AB",
            "name": "NO ENTRY SIGN",
            "native": "🚫",
            "shortName": "no_entry_sign",
            "shortNames": [
                "no_entry_sign"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 35,
            "sheetY": 16,
            "tts": "prohibited",
            "keywords": [
                "entry",
                "forbidden",
                "no",
                "not",
                "prohibited"
            ]
        },
        {
            "unified": "1F6B3",
            "name": "NO BICYCLES",
            "native": "🚳",
            "shortName": "no_bicycles",
            "shortNames": [
                "no_bicycles"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 35,
            "sheetY": 24,
            "tts": "no bicycles",
            "keywords": [
                "bicycle",
                "bike",
                "forbidden",
                "no",
                "no bicycles",
                "not",
                "prohibited"
            ]
        },
        {
            "unified": "1F6AD",
            "name": "NO SMOKING SYMBOL",
            "native": "🚭",
            "shortName": "no_smoking",
            "shortNames": [
                "no_smoking"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 35,
            "sheetY": 18,
            "tts": "no smoking",
            "keywords": [
                "forbidden",
                "no",
                "not",
                "prohibited",
                "smoking"
            ]
        },
        {
            "unified": "1F6AF",
            "name": "DO NOT LITTER SYMBOL",
            "native": "🚯",
            "shortName": "do_not_litter",
            "shortNames": [
                "do_not_litter"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 35,
            "sheetY": 20,
            "tts": "no littering",
            "keywords": [
                "forbidden",
                "litter",
                "no",
                "no littering",
                "not",
                "prohibited"
            ]
        },
        {
            "unified": "1F6B1",
            "name": "NON-POTABLE WATER SYMBOL",
            "native": "🚱",
            "shortName": "non-potable_water",
            "shortNames": [
                "non-potable_water"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 35,
            "sheetY": 22,
            "tts": "non-potable water",
            "keywords": [
                "non-drinking",
                "non-potable",
                "water"
            ]
        },
        {
            "unified": "1F6B7",
            "name": "NO PEDESTRIANS",
            "native": "🚷",
            "shortName": "no_pedestrians",
            "shortNames": [
                "no_pedestrians"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 36,
            "sheetY": 27,
            "tts": "no pedestrians",
            "keywords": [
                "forbidden",
                "no",
                "no pedestrians",
                "not",
                "pedestrian",
                "prohibited"
            ]
        },
        {
            "unified": "1F4F5",
            "name": "NO MOBILE PHONES",
            "native": "📵",
            "shortName": "no_mobile_phones",
            "shortNames": [
                "no_mobile_phones"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 26,
            "sheetY": 44,
            "tts": "no mobile phones",
            "keywords": [
                "cell",
                "forbidden",
                "mobile",
                "no",
                "no mobile phones",
                "not",
                "phone",
                "prohibited",
                "telephone"
            ]
        },
        {
            "unified": "1F51E",
            "name": "NO ONE UNDER EIGHTEEN SYMBOL",
            "native": "🔞",
            "shortName": "underage",
            "shortNames": [
                "underage"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 32,
            "tts": "no one under eighteen",
            "keywords": [
                "18",
                "age restriction",
                "eighteen",
                "forbidden",
                "no",
                "no one under eighteen",
                "not",
                "prohibited",
                "underage"
            ]
        },
        {
            "unified": "2622-FE0F",
            "name": null,
            "native": "☢️",
            "shortName": "radioactive_sign",
            "shortNames": [
                "radioactive_sign"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 33
        },
        {
            "unified": "2623-FE0F",
            "name": null,
            "native": "☣️",
            "shortName": "biohazard_sign",
            "shortNames": [
                "biohazard_sign"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 34
        },
        {
            "unified": "2B06-FE0F",
            "name": "UPWARDS BLACK ARROW",
            "native": "⬆️",
            "shortName": "arrow_up",
            "shortNames": [
                "arrow_up"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 18
        },
        {
            "unified": "2197-FE0F",
            "name": "NORTH EAST ARROW",
            "native": "↗️",
            "shortName": "arrow_upper_right",
            "shortNames": [
                "arrow_upper_right"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 46,
            "sheetY": 36
        },
        {
            "unified": "27A1-FE0F",
            "name": "BLACK RIGHTWARDS ARROW",
            "native": "➡️",
            "shortName": "arrow_right",
            "shortNames": [
                "arrow_right"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 12
        },
        {
            "unified": "2198-FE0F",
            "name": "SOUTH EAST ARROW",
            "native": "↘️",
            "shortName": "arrow_lower_right",
            "shortNames": [
                "arrow_lower_right"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 46,
            "sheetY": 37
        },
        {
            "unified": "2B07-FE0F",
            "name": "DOWNWARDS BLACK ARROW",
            "native": "⬇️",
            "shortName": "arrow_down",
            "shortNames": [
                "arrow_down"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 19
        },
        {
            "unified": "2199-FE0F",
            "name": "SOUTH WEST ARROW",
            "native": "↙️",
            "shortName": "arrow_lower_left",
            "shortNames": [
                "arrow_lower_left"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 46,
            "sheetY": 38
        },
        {
            "unified": "2B05-FE0F",
            "name": "LEFTWARDS BLACK ARROW",
            "native": "⬅️",
            "shortName": "arrow_left",
            "shortNames": [
                "arrow_left"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 17
        },
        {
            "unified": "2196-FE0F",
            "name": "NORTH WEST ARROW",
            "native": "↖️",
            "shortName": "arrow_upper_left",
            "shortNames": [
                "arrow_upper_left"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 46,
            "sheetY": 35
        },
        {
            "unified": "2195-FE0F",
            "name": "UP DOWN ARROW",
            "native": "↕️",
            "shortName": "arrow_up_down",
            "shortNames": [
                "arrow_up_down"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 46,
            "sheetY": 34
        },
        {
            "unified": "2194-FE0F",
            "name": "LEFT RIGHT ARROW",
            "native": "↔️",
            "shortName": "left_right_arrow",
            "shortNames": [
                "left_right_arrow"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 46,
            "sheetY": 33
        },
        {
            "unified": "21A9-FE0F",
            "name": "LEFTWARDS ARROW WITH HOOK",
            "native": "↩️",
            "shortName": "leftwards_arrow_with_hook",
            "shortNames": [
                "leftwards_arrow_with_hook"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 46,
            "sheetY": 39
        },
        {
            "unified": "21AA-FE0F",
            "name": "RIGHTWARDS ARROW WITH HOOK",
            "native": "↪️",
            "shortName": "arrow_right_hook",
            "shortNames": [
                "arrow_right_hook"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 46,
            "sheetY": 40
        },
        {
            "unified": "2934-FE0F",
            "name": "ARROW POINTING RIGHTWARDS THEN CURVING UPWARDS",
            "native": "⤴️",
            "shortName": "arrow_heading_up",
            "shortNames": [
                "arrow_heading_up"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 15
        },
        {
            "unified": "2935-FE0F",
            "name": "ARROW POINTING RIGHTWARDS THEN CURVING DOWNWARDS",
            "native": "⤵️",
            "shortName": "arrow_heading_down",
            "shortNames": [
                "arrow_heading_down"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 16
        },
        {
            "unified": "1F503",
            "name": "CLOCKWISE DOWNWARDS AND UPWARDS OPEN CIRCLE ARROWS",
            "native": "🔃",
            "shortName": "arrows_clockwise",
            "shortNames": [
                "arrows_clockwise"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 5,
            "tts": "clockwise vertical arrows",
            "keywords": [
                "arrow",
                "clockwise",
                "clockwise vertical arrows",
                "reload"
            ]
        },
        {
            "unified": "1F504",
            "name": "ANTICLOCKWISE DOWNWARDS AND UPWARDS OPEN CIRCLE ARROWS",
            "native": "🔄",
            "shortName": "arrows_counterclockwise",
            "shortNames": [
                "arrows_counterclockwise"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 6,
            "tts": "counterclockwise arrows button",
            "keywords": [
                "anticlockwise",
                "arrow",
                "counterclockwise",
                "counterclockwise arrows button",
                "withershins"
            ]
        },
        {
            "unified": "1F519",
            "name": "BACK WITH LEFTWARDS ARROW ABOVE",
            "native": "🔙",
            "shortName": "back",
            "shortNames": [
                "back"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 27,
            "tts": "BACK arrow",
            "keywords": [
                "arrow",
                "back",
                "BACK arrow"
            ]
        },
        {
            "unified": "1F51A",
            "name": "END WITH LEFTWARDS ARROW ABOVE",
            "native": "🔚",
            "shortName": "end",
            "shortNames": [
                "end"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 28,
            "tts": "END arrow",
            "keywords": [
                "arrow",
                "end",
                "END arrow"
            ]
        },
        {
            "unified": "1F51B",
            "name": "ON WITH EXCLAMATION MARK WITH LEFT RIGHT ARROW ABOVE",
            "native": "🔛",
            "shortName": "on",
            "shortNames": [
                "on"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 29,
            "tts": "ON! arrow",
            "keywords": [
                "arrow",
                "mark",
                "on",
                "ON! arrow"
            ]
        },
        {
            "unified": "1F51C",
            "name": "SOON WITH RIGHTWARDS ARROW ABOVE",
            "native": "🔜",
            "shortName": "soon",
            "shortNames": [
                "soon"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 30,
            "tts": "SOON arrow",
            "keywords": [
                "arrow",
                "soon",
                "SOON arrow"
            ]
        },
        {
            "unified": "1F51D",
            "name": "TOP WITH UPWARDS ARROW ABOVE",
            "native": "🔝",
            "shortName": "top",
            "shortNames": [
                "top"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 31,
            "tts": "TOP arrow",
            "keywords": [
                "arrow",
                "top",
                "TOP arrow",
                "up"
            ]
        },
        {
            "unified": "1F6D0",
            "name": "PLACE OF WORSHIP",
            "native": "🛐",
            "shortName": "place_of_worship",
            "shortNames": [
                "place_of_worship"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 37,
            "sheetY": 5,
            "tts": "place of worship",
            "keywords": [
                "place of worship",
                "religion",
                "worship"
            ]
        },
        {
            "unified": "269B-FE0F",
            "name": null,
            "native": "⚛️",
            "shortName": "atom_symbol",
            "shortNames": [
                "atom_symbol"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 48,
            "sheetY": 18
        },
        {
            "unified": "1F549-FE0F",
            "name": null,
            "native": "🕉️",
            "shortName": "om_symbol",
            "shortNames": [
                "om_symbol"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 28,
            "sheetY": 12
        },
        {
            "unified": "2721-FE0F",
            "name": null,
            "native": "✡️",
            "shortName": "star_of_david",
            "shortNames": [
                "star_of_david"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 49,
            "sheetY": 47
        },
        {
            "unified": "2638-FE0F",
            "name": null,
            "native": "☸️",
            "shortName": "wheel_of_dharma",
            "shortNames": [
                "wheel_of_dharma"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 39
        },
        {
            "unified": "262F-FE0F",
            "name": null,
            "native": "☯️",
            "shortName": "yin_yang",
            "shortNames": [
                "yin_yang"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 38
        },
        {
            "unified": "271D-FE0F",
            "name": null,
            "native": "✝️",
            "shortName": "latin_cross",
            "shortNames": [
                "latin_cross"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 49,
            "sheetY": 46
        },
        {
            "unified": "2626-FE0F",
            "name": null,
            "native": "☦️",
            "shortName": "orthodox_cross",
            "shortNames": [
                "orthodox_cross"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 35
        },
        {
            "unified": "262A-FE0F",
            "name": null,
            "native": "☪️",
            "shortName": "star_and_crescent",
            "shortNames": [
                "star_and_crescent"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 36
        },
        {
            "unified": "262E-FE0F",
            "name": null,
            "native": "☮️",
            "shortName": "peace_symbol",
            "shortNames": [
                "peace_symbol"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 37
        },
        {
            "unified": "1F54E",
            "name": "MENORAH WITH NINE BRANCHES",
            "native": "🕎",
            "shortName": "menorah_with_nine_branches",
            "shortNames": [
                "menorah_with_nine_branches"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 28,
            "sheetY": 17,
            "tts": "menorah",
            "keywords": [
                "candelabrum",
                "candlestick",
                "menorah",
                "religion"
            ]
        },
        {
            "unified": "1F52F",
            "name": "SIX POINTED STAR WITH MIDDLE DOT",
            "native": "🔯",
            "shortName": "six_pointed_star",
            "shortNames": [
                "six_pointed_star"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 49,
            "tts": "dotted six-pointed star",
            "keywords": [
                "dotted six-pointed star",
                "fortune",
                "star"
            ]
        },
        {
            "unified": "2648",
            "name": "ARIES",
            "native": "♈",
            "shortName": "aries",
            "shortNames": [
                "aries"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 44,
            "tts": "Aries",
            "keywords": [
                "Aries",
                "ram",
                "zodiac"
            ]
        },
        {
            "unified": "2649",
            "name": "TAURUS",
            "native": "♉",
            "shortName": "taurus",
            "shortNames": [
                "taurus"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 45,
            "tts": "Taurus",
            "keywords": [
                "bull",
                "ox",
                "Taurus",
                "zodiac"
            ]
        },
        {
            "unified": "264A",
            "name": "GEMINI",
            "native": "♊",
            "shortName": "gemini",
            "shortNames": [
                "gemini"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 46,
            "tts": "Gemini",
            "keywords": [
                "Gemini",
                "twins",
                "zodiac"
            ]
        },
        {
            "unified": "264B",
            "name": "CANCER",
            "native": "♋",
            "shortName": "cancer",
            "shortNames": [
                "cancer"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 47,
            "tts": "Cancer",
            "keywords": [
                "Cancer",
                "crab",
                "zodiac"
            ]
        },
        {
            "unified": "264C",
            "name": "LEO",
            "native": "♌",
            "shortName": "leo",
            "shortNames": [
                "leo"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 48,
            "tts": "Leo",
            "keywords": [
                "Leo",
                "lion",
                "zodiac"
            ]
        },
        {
            "unified": "264D",
            "name": "VIRGO",
            "native": "♍",
            "shortName": "virgo",
            "shortNames": [
                "virgo"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 49,
            "tts": "Virgo",
            "keywords": [
                "Virgo",
                "zodiac"
            ]
        },
        {
            "unified": "264E",
            "name": "LIBRA",
            "native": "♎",
            "shortName": "libra",
            "shortNames": [
                "libra"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 50,
            "tts": "Libra",
            "keywords": [
                "balance",
                "justice",
                "Libra",
                "scales",
                "zodiac"
            ]
        },
        {
            "unified": "264F",
            "name": "SCORPIUS",
            "native": "♏",
            "shortName": "scorpius",
            "shortNames": [
                "scorpius"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 51,
            "tts": "Scorpio",
            "keywords": [
                "Scorpio",
                "scorpion",
                "scorpius",
                "zodiac"
            ]
        },
        {
            "unified": "2650",
            "name": "SAGITTARIUS",
            "native": "♐",
            "shortName": "sagittarius",
            "shortNames": [
                "sagittarius"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 48,
            "sheetY": 0,
            "tts": "Sagittarius",
            "keywords": [
                "archer",
                "Sagittarius",
                "zodiac"
            ]
        },
        {
            "unified": "2651",
            "name": "CAPRICORN",
            "native": "♑",
            "shortName": "capricorn",
            "shortNames": [
                "capricorn"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 48,
            "sheetY": 1,
            "tts": "Capricorn",
            "keywords": [
                "Capricorn",
                "goat",
                "zodiac"
            ]
        },
        {
            "unified": "2652",
            "name": "AQUARIUS",
            "native": "♒",
            "shortName": "aquarius",
            "shortNames": [
                "aquarius"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 48,
            "sheetY": 2,
            "tts": "Aquarius",
            "keywords": [
                "Aquarius",
                "bearer",
                "water",
                "zodiac"
            ]
        },
        {
            "unified": "2653",
            "name": "PISCES",
            "native": "♓",
            "shortName": "pisces",
            "shortNames": [
                "pisces"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 48,
            "sheetY": 3,
            "tts": "Pisces",
            "keywords": [
                "fish",
                "Pisces",
                "zodiac"
            ]
        },
        {
            "unified": "26CE",
            "name": "OPHIUCHUS",
            "native": "⛎",
            "shortName": "ophiuchus",
            "shortNames": [
                "ophiuchus"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 48,
            "sheetY": 31,
            "tts": "Ophiuchus",
            "keywords": [
                "bearer",
                "Ophiuchus",
                "serpent",
                "snake",
                "zodiac"
            ]
        },
        {
            "unified": "1F500",
            "name": "TWISTED RIGHTWARDS ARROWS",
            "native": "🔀",
            "shortName": "twisted_rightwards_arrows",
            "shortNames": [
                "twisted_rightwards_arrows"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 2,
            "tts": "shuffle tracks button",
            "keywords": [
                "arrow",
                "crossed",
                "shuffle tracks button"
            ]
        },
        {
            "unified": "1F501",
            "name": "CLOCKWISE RIGHTWARDS AND LEFTWARDS OPEN CIRCLE ARROWS",
            "native": "🔁",
            "shortName": "repeat",
            "shortNames": [
                "repeat"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 3,
            "tts": "repeat button",
            "keywords": [
                "arrow",
                "clockwise",
                "repeat",
                "repeat button"
            ]
        },
        {
            "unified": "1F502",
            "name": "CLOCKWISE RIGHTWARDS AND LEFTWARDS OPEN CIRCLE ARROWS WITH CIRCLED ONE OVERLAY",
            "native": "🔂",
            "shortName": "repeat_one",
            "shortNames": [
                "repeat_one"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 4,
            "tts": "repeat single button",
            "keywords": [
                "arrow",
                "clockwise",
                "once",
                "repeat single button"
            ]
        },
        {
            "unified": "25B6-FE0F",
            "name": "BLACK RIGHT-POINTING TRIANGLE",
            "native": "▶️",
            "shortName": "arrow_forward",
            "shortNames": [
                "arrow_forward"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 10
        },
        {
            "unified": "23E9",
            "name": "BLACK RIGHT-POINTING DOUBLE TRIANGLE",
            "native": "⏩",
            "shortName": "fast_forward",
            "shortNames": [
                "fast_forward"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 46,
            "sheetY": 45,
            "tts": "fast-forward button",
            "keywords": [
                "arrow",
                "double",
                "fast",
                "fast-forward button",
                "forward"
            ]
        },
        {
            "unified": "23ED-FE0F",
            "name": null,
            "native": "⏭️",
            "shortName": "black_right_pointing_double_triangle_with_vertical_bar",
            "shortNames": [
                "black_right_pointing_double_triangle_with_vertical_bar"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 46,
            "sheetY": 49
        },
        {
            "unified": "23EF-FE0F",
            "name": null,
            "native": "⏯️",
            "shortName": "black_right_pointing_triangle_with_double_vertical_bar",
            "shortNames": [
                "black_right_pointing_triangle_with_double_vertical_bar"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 46,
            "sheetY": 51
        },
        {
            "unified": "25C0-FE0F",
            "name": "BLACK LEFT-POINTING TRIANGLE",
            "native": "◀️",
            "shortName": "arrow_backward",
            "shortNames": [
                "arrow_backward"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 11
        },
        {
            "unified": "23EA",
            "name": "BLACK LEFT-POINTING DOUBLE TRIANGLE",
            "native": "⏪",
            "shortName": "rewind",
            "shortNames": [
                "rewind"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 46,
            "sheetY": 46,
            "tts": "fast reverse button",
            "keywords": [
                "arrow",
                "double",
                "fast reverse button",
                "rewind"
            ]
        },
        {
            "unified": "23EE-FE0F",
            "name": null,
            "native": "⏮️",
            "shortName": "black_left_pointing_double_triangle_with_vertical_bar",
            "shortNames": [
                "black_left_pointing_double_triangle_with_vertical_bar"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 46,
            "sheetY": 50
        },
        {
            "unified": "1F53C",
            "name": "UP-POINTING SMALL RED TRIANGLE",
            "native": "🔼",
            "shortName": "arrow_up_small",
            "shortNames": [
                "arrow_up_small"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 28,
            "sheetY": 10,
            "tts": "upwards button",
            "keywords": [
                "arrow",
                "button",
                "red",
                "upwards button"
            ]
        },
        {
            "unified": "23EB",
            "name": "BLACK UP-POINTING DOUBLE TRIANGLE",
            "native": "⏫",
            "shortName": "arrow_double_up",
            "shortNames": [
                "arrow_double_up"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 46,
            "sheetY": 47,
            "tts": "fast up button",
            "keywords": [
                "arrow",
                "double",
                "fast up button"
            ]
        },
        {
            "unified": "1F53D",
            "name": "DOWN-POINTING SMALL RED TRIANGLE",
            "native": "🔽",
            "shortName": "arrow_down_small",
            "shortNames": [
                "arrow_down_small"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 28,
            "sheetY": 11,
            "tts": "downwards button",
            "keywords": [
                "arrow",
                "button",
                "down",
                "downwards button",
                "red"
            ]
        },
        {
            "unified": "23EC",
            "name": "BLACK DOWN-POINTING DOUBLE TRIANGLE",
            "native": "⏬",
            "shortName": "arrow_double_down",
            "shortNames": [
                "arrow_double_down"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 46,
            "sheetY": 48,
            "tts": "fast down button",
            "keywords": [
                "arrow",
                "double",
                "down",
                "fast down button"
            ]
        },
        {
            "unified": "23F8-FE0F",
            "name": null,
            "native": "⏸️",
            "shortName": "double_vertical_bar",
            "shortNames": [
                "double_vertical_bar"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 4
        },
        {
            "unified": "23F9-FE0F",
            "name": null,
            "native": "⏹️",
            "shortName": "black_square_for_stop",
            "shortNames": [
                "black_square_for_stop"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 5
        },
        {
            "unified": "23FA-FE0F",
            "name": null,
            "native": "⏺️",
            "shortName": "black_circle_for_record",
            "shortNames": [
                "black_circle_for_record"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 6
        },
        {
            "unified": "23CF-FE0F",
            "name": null,
            "native": "⏏️",
            "shortName": "eject",
            "shortNames": [
                "eject"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 46,
            "sheetY": 44
        },
        {
            "unified": "1F3A6",
            "name": "CINEMA",
            "native": "🎦",
            "shortName": "cinema",
            "shortNames": [
                "cinema"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 9,
            "sheetY": 0,
            "tts": "cinema",
            "keywords": [
                "camera",
                "cinema",
                "film",
                "movie"
            ]
        },
        {
            "unified": "1F505",
            "name": "LOW BRIGHTNESS SYMBOL",
            "native": "🔅",
            "shortName": "low_brightness",
            "shortNames": [
                "low_brightness"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 7,
            "tts": "dim button",
            "keywords": [
                "brightness",
                "dim",
                "dim button",
                "low"
            ]
        },
        {
            "unified": "1F506",
            "name": "HIGH BRIGHTNESS SYMBOL",
            "native": "🔆",
            "shortName": "high_brightness",
            "shortNames": [
                "high_brightness"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 8,
            "tts": "bright button",
            "keywords": [
                "bright",
                "bright button",
                "brightness"
            ]
        },
        {
            "unified": "1F4F6",
            "name": "ANTENNA WITH BARS",
            "native": "📶",
            "shortName": "signal_strength",
            "shortNames": [
                "signal_strength"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 26,
            "sheetY": 45,
            "tts": "antenna bars",
            "keywords": [
                "antenna",
                "antenna bars",
                "bar",
                "cell",
                "mobile",
                "phone",
                "signal",
                "telephone"
            ]
        },
        {
            "unified": "1F4F3",
            "name": "VIBRATION MODE",
            "native": "📳",
            "shortName": "vibration_mode",
            "shortNames": [
                "vibration_mode"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 26,
            "sheetY": 42,
            "tts": "vibration mode",
            "keywords": [
                "cell",
                "mobile",
                "mode",
                "phone",
                "telephone",
                "vibration"
            ]
        },
        {
            "unified": "1F4F4",
            "name": "MOBILE PHONE OFF",
            "native": "📴",
            "shortName": "mobile_phone_off",
            "shortNames": [
                "mobile_phone_off"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 26,
            "sheetY": 43,
            "tts": "mobile phone off",
            "keywords": [
                "cell",
                "mobile",
                "off",
                "phone",
                "telephone"
            ]
        },
        {
            "unified": "2640-FE0F",
            "name": null,
            "native": "♀️",
            "shortName": "female_sign",
            "shortNames": [
                "female_sign"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 42
        },
        {
            "unified": "2642-FE0F",
            "name": null,
            "native": "♂️",
            "shortName": "male_sign",
            "shortNames": [
                "male_sign"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 43
        },
        {
            "unified": "2695-FE0F",
            "name": null,
            "native": "⚕️",
            "shortName": "medical_symbol",
            "shortNames": [
                "medical_symbol",
                "staff_of_aesculapius"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 48,
            "sheetY": 14
        },
        {
            "unified": "267B-FE0F",
            "name": "BLACK UNIVERSAL RECYCLING SYMBOL",
            "native": "♻️",
            "shortName": "recycle",
            "shortNames": [
                "recycle"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 48,
            "sheetY": 9
        },
        {
            "unified": "269C-FE0F",
            "name": null,
            "native": "⚜️",
            "shortName": "fleur_de_lis",
            "shortNames": [
                "fleur_de_lis"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 48,
            "sheetY": 19
        },
        {
            "unified": "1F531",
            "name": "TRIDENT EMBLEM",
            "native": "🔱",
            "shortName": "trident",
            "shortNames": [
                "trident"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 51,
            "tts": "trident emblem",
            "keywords": [
                "anchor",
                "emblem",
                "ship",
                "tool",
                "trident"
            ]
        },
        {
            "unified": "1F4DB",
            "name": "NAME BADGE",
            "native": "📛",
            "shortName": "name_badge",
            "shortNames": [
                "name_badge"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 26,
            "sheetY": 18,
            "tts": "name badge",
            "keywords": [
                "badge",
                "name"
            ]
        },
        {
            "unified": "1F530",
            "name": "JAPANESE SYMBOL FOR BEGINNER",
            "native": "🔰",
            "shortName": "beginner",
            "shortNames": [
                "beginner"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 50,
            "tts": "Japanese symbol for beginner",
            "keywords": [
                "beginner",
                "chevron",
                "green",
                "Japanese",
                "Japanese symbol for beginner",
                "leaf",
                "tool",
                "yellow"
            ]
        },
        {
            "unified": "2B55",
            "name": "HEAVY LARGE CIRCLE",
            "native": "⭕",
            "shortName": "o",
            "shortNames": [
                "o"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 23,
            "tts": "heavy large circle",
            "keywords": [
                "circle",
                "heavy large circle",
                "o"
            ]
        },
        {
            "unified": "2705",
            "name": "WHITE HEAVY CHECK MARK",
            "native": "✅",
            "shortName": "white_check_mark",
            "shortNames": [
                "white_check_mark"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 49,
            "sheetY": 15,
            "tts": "white heavy check mark",
            "keywords": [
                "check",
                "mark",
                "white heavy check mark"
            ]
        },
        {
            "unified": "2611-FE0F",
            "name": "BALLOT BOX WITH CHECK",
            "native": "☑️",
            "shortName": "ballot_box_with_check",
            "shortNames": [
                "ballot_box_with_check"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 22
        },
        {
            "unified": "2714-FE0F",
            "name": "HEAVY CHECK MARK",
            "native": "✔️",
            "shortName": "heavy_check_mark",
            "shortNames": [
                "heavy_check_mark"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 49,
            "sheetY": 44
        },
        {
            "unified": "2716-FE0F",
            "name": "HEAVY MULTIPLICATION X",
            "native": "✖️",
            "shortName": "heavy_multiplication_x",
            "shortNames": [
                "heavy_multiplication_x"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 49,
            "sheetY": 45
        },
        {
            "unified": "274C",
            "name": "CROSS MARK",
            "native": "❌",
            "shortName": "x",
            "shortNames": [
                "x"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 1,
            "tts": "cross mark",
            "keywords": [
                "cancel",
                "cross mark",
                "mark",
                "multiplication",
                "multiply",
                "x"
            ]
        },
        {
            "unified": "274E",
            "name": "NEGATIVE SQUARED CROSS MARK",
            "native": "❎",
            "shortName": "negative_squared_cross_mark",
            "shortNames": [
                "negative_squared_cross_mark"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 2,
            "tts": "cross mark button",
            "keywords": [
                "cross mark button",
                "mark",
                "square"
            ]
        },
        {
            "unified": "2795",
            "name": "HEAVY PLUS SIGN",
            "native": "➕",
            "shortName": "heavy_plus_sign",
            "shortNames": [
                "heavy_plus_sign"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 9,
            "tts": "heavy plus sign",
            "keywords": [
                "heavy plus sign",
                "math",
                "plus"
            ]
        },
        {
            "unified": "2796",
            "name": "HEAVY MINUS SIGN",
            "native": "➖",
            "shortName": "heavy_minus_sign",
            "shortNames": [
                "heavy_minus_sign"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 10,
            "tts": "heavy minus sign",
            "keywords": [
                "heavy minus sign",
                "math",
                "minus"
            ]
        },
        {
            "unified": "2797",
            "name": "HEAVY DIVISION SIGN",
            "native": "➗",
            "shortName": "heavy_division_sign",
            "shortNames": [
                "heavy_division_sign"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 11,
            "tts": "heavy division sign",
            "keywords": [
                "division",
                "heavy division sign",
                "math"
            ]
        },
        {
            "unified": "27B0",
            "name": "CURLY LOOP",
            "native": "➰",
            "shortName": "curly_loop",
            "shortNames": [
                "curly_loop"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 13,
            "tts": "curly loop",
            "keywords": [
                "curl",
                "curly loop",
                "loop"
            ]
        },
        {
            "unified": "27BF",
            "name": "DOUBLE CURLY LOOP",
            "native": "➿",
            "shortName": "loop",
            "shortNames": [
                "loop"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 14,
            "tts": "double curly loop",
            "keywords": [
                "curl",
                "double",
                "double curly loop",
                "loop"
            ]
        },
        {
            "unified": "303D-FE0F",
            "name": "PART ALTERNATION MARK",
            "native": "〽️",
            "shortName": "part_alternation_mark",
            "shortNames": [
                "part_alternation_mark"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 25
        },
        {
            "unified": "2733-FE0F",
            "name": "EIGHT SPOKED ASTERISK",
            "native": "✳️",
            "shortName": "eight_spoked_asterisk",
            "shortNames": [
                "eight_spoked_asterisk"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 49,
            "sheetY": 49
        },
        {
            "unified": "2734-FE0F",
            "name": "EIGHT POINTED BLACK STAR",
            "native": "✴️",
            "shortName": "eight_pointed_black_star",
            "shortNames": [
                "eight_pointed_black_star"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 49,
            "sheetY": 50
        },
        {
            "unified": "2747-FE0F",
            "name": "SPARKLE",
            "native": "❇️",
            "shortName": "sparkle",
            "shortNames": [
                "sparkle"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 0
        },
        {
            "unified": "203C-FE0F",
            "name": "DOUBLE EXCLAMATION MARK",
            "native": "‼️",
            "shortName": "bangbang",
            "shortNames": [
                "bangbang"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 46,
            "sheetY": 29
        },
        {
            "unified": "2049-FE0F",
            "name": "EXCLAMATION QUESTION MARK",
            "native": "⁉️",
            "shortName": "interrobang",
            "shortNames": [
                "interrobang"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 46,
            "sheetY": 30
        },
        {
            "unified": "2753",
            "name": "BLACK QUESTION MARK ORNAMENT",
            "native": "❓",
            "shortName": "question",
            "shortNames": [
                "question"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 3,
            "tts": "question mark",
            "keywords": [
                "mark",
                "punctuation",
                "question"
            ]
        },
        {
            "unified": "2754",
            "name": "WHITE QUESTION MARK ORNAMENT",
            "native": "❔",
            "shortName": "grey_question",
            "shortNames": [
                "grey_question"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 4,
            "tts": "white question mark",
            "keywords": [
                "mark",
                "outlined",
                "punctuation",
                "question",
                "white question mark"
            ]
        },
        {
            "unified": "2755",
            "name": "WHITE EXCLAMATION MARK ORNAMENT",
            "native": "❕",
            "shortName": "grey_exclamation",
            "shortNames": [
                "grey_exclamation"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 5,
            "tts": "white exclamation mark",
            "keywords": [
                "exclamation",
                "mark",
                "outlined",
                "punctuation",
                "white exclamation mark"
            ]
        },
        {
            "unified": "2757",
            "name": "HEAVY EXCLAMATION MARK SYMBOL",
            "native": "❗",
            "shortName": "exclamation",
            "shortNames": [
                "exclamation",
                "heavy_exclamation_mark"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 6,
            "tts": "exclamation mark",
            "keywords": [
                "exclamation",
                "mark",
                "punctuation"
            ]
        },
        {
            "unified": "3030-FE0F",
            "name": "WAVY DASH",
            "native": "〰️",
            "shortName": "wavy_dash",
            "shortNames": [
                "wavy_dash"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 24
        },
        {
            "unified": "00A9-FE0F",
            "name": "COPYRIGHT SIGN",
            "native": "©️",
            "shortName": "copyright",
            "shortNames": [
                "copyright"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 12
        },
        {
            "unified": "00AE-FE0F",
            "name": "REGISTERED SIGN",
            "native": "®️",
            "shortName": "registered",
            "shortNames": [
                "registered"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 13
        },
        {
            "unified": "2122-FE0F",
            "name": "TRADE MARK SIGN",
            "native": "™️",
            "shortName": "tm",
            "shortNames": [
                "tm"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 46,
            "sheetY": 31
        },
        {
            "unified": "0023-FE0F-20E3",
            "name": "HASH KEY",
            "native": "#️⃣",
            "shortName": "hash",
            "shortNames": [
                "hash"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 0
        },
        {
            "unified": "002A-FE0F-20E3",
            "name": null,
            "native": "*️⃣",
            "shortName": "keycap_star",
            "shortNames": [
                "keycap_star"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 1
        },
        {
            "unified": "0030-FE0F-20E3",
            "name": "KEYCAP 0",
            "native": "0️⃣",
            "shortName": "zero",
            "shortNames": [
                "zero"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 2
        },
        {
            "unified": "0031-FE0F-20E3",
            "name": "KEYCAP 1",
            "native": "1️⃣",
            "shortName": "one",
            "shortNames": [
                "one"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 3
        },
        {
            "unified": "0032-FE0F-20E3",
            "name": "KEYCAP 2",
            "native": "2️⃣",
            "shortName": "two",
            "shortNames": [
                "two"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 4
        },
        {
            "unified": "0033-FE0F-20E3",
            "name": "KEYCAP 3",
            "native": "3️⃣",
            "shortName": "three",
            "shortNames": [
                "three"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 5
        },
        {
            "unified": "0034-FE0F-20E3",
            "name": "KEYCAP 4",
            "native": "4️⃣",
            "shortName": "four",
            "shortNames": [
                "four"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 6
        },
        {
            "unified": "0035-FE0F-20E3",
            "name": "KEYCAP 5",
            "native": "5️⃣",
            "shortName": "five",
            "shortNames": [
                "five"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 7
        },
        {
            "unified": "0036-FE0F-20E3",
            "name": "KEYCAP 6",
            "native": "6️⃣",
            "shortName": "six",
            "shortNames": [
                "six"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 8
        },
        {
            "unified": "0037-FE0F-20E3",
            "name": "KEYCAP 7",
            "native": "7️⃣",
            "shortName": "seven",
            "shortNames": [
                "seven"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 9
        },
        {
            "unified": "0038-FE0F-20E3",
            "name": "KEYCAP 8",
            "native": "8️⃣",
            "shortName": "eight",
            "shortNames": [
                "eight"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 10
        },
        {
            "unified": "0039-FE0F-20E3",
            "name": "KEYCAP 9",
            "native": "9️⃣",
            "shortName": "nine",
            "shortNames": [
                "nine"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 11
        },
        {
            "unified": "1F51F",
            "name": "KEYCAP TEN",
            "native": "🔟",
            "shortName": "keycap_ten",
            "shortNames": [
                "keycap_ten"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 33
        },
        {
            "unified": "1F4AF",
            "name": "HUNDRED POINTS SYMBOL",
            "native": "💯",
            "shortName": "100",
            "shortNames": [
                "100"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 25,
            "sheetY": 26,
            "tts": "hundred points",
            "keywords": [
                "100",
                "full",
                "hundred",
                "hundred points",
                "score"
            ]
        },
        {
            "unified": "1F520",
            "name": "INPUT SYMBOL FOR LATIN CAPITAL LETTERS",
            "native": "🔠",
            "shortName": "capital_abcd",
            "shortNames": [
                "capital_abcd"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 34,
            "tts": "input latin uppercase",
            "keywords": [
                "ABCD",
                "input",
                "latin",
                "letters",
                "uppercase"
            ]
        },
        {
            "unified": "1F521",
            "name": "INPUT SYMBOL FOR LATIN SMALL LETTERS",
            "native": "🔡",
            "shortName": "abcd",
            "shortNames": [
                "abcd"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 35,
            "tts": "input latin lowercase",
            "keywords": [
                "abcd",
                "input",
                "latin",
                "letters",
                "lowercase"
            ]
        },
        {
            "unified": "1F522",
            "name": "INPUT SYMBOL FOR NUMBERS",
            "native": "🔢",
            "shortName": "1234",
            "shortNames": [
                "1234"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 36,
            "tts": "input numbers",
            "keywords": [
                "1234",
                "input",
                "numbers"
            ]
        },
        {
            "unified": "1F523",
            "name": "INPUT SYMBOL FOR SYMBOLS",
            "native": "🔣",
            "shortName": "symbols",
            "shortNames": [
                "symbols"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 37,
            "tts": "input symbols",
            "keywords": [
                "〒♪&%",
                "input",
                "input symbols"
            ]
        },
        {
            "unified": "1F524",
            "name": "INPUT SYMBOL FOR LATIN LETTERS",
            "native": "🔤",
            "shortName": "abc",
            "shortNames": [
                "abc"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 38,
            "tts": "input latin letters",
            "keywords": [
                "abc",
                "alphabet",
                "input",
                "latin",
                "letters"
            ]
        },
        {
            "unified": "1F170-FE0F",
            "name": "NEGATIVE SQUARED LATIN CAPITAL LETTER A",
            "native": "🅰️",
            "shortName": "a",
            "shortNames": [
                "a"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 16
        },
        {
            "unified": "1F18E",
            "name": "NEGATIVE SQUARED AB",
            "native": "🆎",
            "shortName": "ab",
            "shortNames": [
                "ab"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 20,
            "tts": "AB button (blood type)",
            "keywords": [
                "ab",
                "AB button (blood type)",
                "blood type"
            ]
        },
        {
            "unified": "1F171-FE0F",
            "name": "NEGATIVE SQUARED LATIN CAPITAL LETTER B",
            "native": "🅱️",
            "shortName": "b",
            "shortNames": [
                "b"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 17
        },
        {
            "unified": "1F191",
            "name": "SQUARED CL",
            "native": "🆑",
            "shortName": "cl",
            "shortNames": [
                "cl"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 21,
            "tts": "CL button",
            "keywords": [
                "cl",
                "CL button"
            ]
        },
        {
            "unified": "1F192",
            "name": "SQUARED COOL",
            "native": "🆒",
            "shortName": "cool",
            "shortNames": [
                "cool"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 22,
            "tts": "COOL button",
            "keywords": [
                "cool",
                "COOL button"
            ]
        },
        {
            "unified": "1F193",
            "name": "SQUARED FREE",
            "native": "🆓",
            "shortName": "free",
            "shortNames": [
                "free"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 23,
            "tts": "FREE button",
            "keywords": [
                "free",
                "FREE button"
            ]
        },
        {
            "unified": "2139-FE0F",
            "name": "INFORMATION SOURCE",
            "native": "ℹ️",
            "shortName": "information_source",
            "shortNames": [
                "information_source"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 46,
            "sheetY": 32
        },
        {
            "unified": "1F194",
            "name": "SQUARED ID",
            "native": "🆔",
            "shortName": "id",
            "shortNames": [
                "id"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 24,
            "tts": "ID button",
            "keywords": [
                "id",
                "ID button",
                "identity"
            ]
        },
        {
            "unified": "24C2-FE0F",
            "name": "CIRCLED LATIN CAPITAL LETTER M",
            "native": "Ⓜ️",
            "shortName": "m",
            "shortNames": [
                "m"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 7
        },
        {
            "unified": "1F195",
            "name": "SQUARED NEW",
            "native": "🆕",
            "shortName": "new",
            "shortNames": [
                "new"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 25,
            "tts": "NEW button",
            "keywords": [
                "new",
                "NEW button"
            ]
        },
        {
            "unified": "1F196",
            "name": "SQUARED NG",
            "native": "🆖",
            "shortName": "ng",
            "shortNames": [
                "ng"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 26,
            "tts": "NG button",
            "keywords": [
                "ng",
                "NG button"
            ]
        },
        {
            "unified": "1F17E-FE0F",
            "name": "NEGATIVE SQUARED LATIN CAPITAL LETTER O",
            "native": "🅾️",
            "shortName": "o2",
            "shortNames": [
                "o2"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 18
        },
        {
            "unified": "1F197",
            "name": "SQUARED OK",
            "native": "🆗",
            "shortName": "ok",
            "shortNames": [
                "ok"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 27,
            "tts": "OK button",
            "keywords": [
                "OK",
                "OK button"
            ]
        },
        {
            "unified": "1F17F-FE0F",
            "name": "NEGATIVE SQUARED LATIN CAPITAL LETTER P",
            "native": "🅿️",
            "shortName": "parking",
            "shortNames": [
                "parking"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 19
        },
        {
            "unified": "1F198",
            "name": "SQUARED SOS",
            "native": "🆘",
            "shortName": "sos",
            "shortNames": [
                "sos"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 28,
            "tts": "SOS button",
            "keywords": [
                "help",
                "sos",
                "SOS button"
            ]
        },
        {
            "unified": "1F199",
            "name": "SQUARED UP WITH EXCLAMATION MARK",
            "native": "🆙",
            "shortName": "up",
            "shortNames": [
                "up"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 29,
            "tts": "UP! button",
            "keywords": [
                "mark",
                "up",
                "UP! button"
            ]
        },
        {
            "unified": "1F19A",
            "name": "SQUARED VS",
            "native": "🆚",
            "shortName": "vs",
            "shortNames": [
                "vs"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 0,
            "sheetY": 30,
            "tts": "VS button",
            "keywords": [
                "versus",
                "vs",
                "VS button"
            ]
        },
        {
            "unified": "1F201",
            "name": "SQUARED KATAKANA KOKO",
            "native": "🈁",
            "shortName": "koko",
            "shortNames": [
                "koko"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 5,
            "sheetY": 29,
            "tts": "Japanese “here” button",
            "keywords": [
                "“here”",
                "Japanese",
                "Japanese “here” button",
                "katakana",
                "ココ"
            ]
        },
        {
            "unified": "1F202-FE0F",
            "name": "SQUARED KATAKANA SA",
            "native": "🈂️",
            "shortName": "sa",
            "shortNames": [
                "sa"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 5,
            "sheetY": 30
        },
        {
            "unified": "1F237-FE0F",
            "name": "SQUARED CJK UNIFIED IDEOGRAPH-6708",
            "native": "🈷️",
            "shortName": "u6708",
            "shortNames": [
                "u6708"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 5,
            "sheetY": 38
        },
        {
            "unified": "1F236",
            "name": "SQUARED CJK UNIFIED IDEOGRAPH-6709",
            "native": "🈶",
            "shortName": "u6709",
            "shortNames": [
                "u6709"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 5,
            "sheetY": 37,
            "tts": "Japanese “not free of charge” button",
            "keywords": [
                "“not free of charge”",
                "ideograph",
                "Japanese",
                "Japanese “not free of charge” button",
                "有"
            ]
        },
        {
            "unified": "1F22F",
            "name": "SQUARED CJK UNIFIED IDEOGRAPH-6307",
            "native": "🈯",
            "shortName": "u6307",
            "shortNames": [
                "u6307"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 5,
            "sheetY": 32,
            "tts": "Japanese “reserved” button",
            "keywords": [
                "“reserved”",
                "ideograph",
                "Japanese",
                "Japanese “reserved” button",
                "指"
            ]
        },
        {
            "unified": "1F250",
            "name": "CIRCLED IDEOGRAPH ADVANTAGE",
            "native": "🉐",
            "shortName": "ideograph_advantage",
            "shortNames": [
                "ideograph_advantage"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 5,
            "sheetY": 42,
            "tts": "Japanese “bargain” button",
            "keywords": [
                "“bargain”",
                "ideograph",
                "Japanese",
                "Japanese “bargain” button",
                "得"
            ]
        },
        {
            "unified": "1F239",
            "name": "SQUARED CJK UNIFIED IDEOGRAPH-5272",
            "native": "🈹",
            "shortName": "u5272",
            "shortNames": [
                "u5272"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 5,
            "sheetY": 40,
            "tts": "Japanese “discount” button",
            "keywords": [
                "“discount”",
                "ideograph",
                "Japanese",
                "Japanese “discount” button",
                "割"
            ]
        },
        {
            "unified": "1F21A",
            "name": "SQUARED CJK UNIFIED IDEOGRAPH-7121",
            "native": "🈚",
            "shortName": "u7121",
            "shortNames": [
                "u7121"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 5,
            "sheetY": 31,
            "tts": "Japanese “free of charge” button",
            "keywords": [
                "“free of charge”",
                "ideograph",
                "Japanese",
                "Japanese “free of charge” button",
                "無"
            ]
        },
        {
            "unified": "1F232",
            "name": "SQUARED CJK UNIFIED IDEOGRAPH-7981",
            "native": "🈲",
            "shortName": "u7981",
            "shortNames": [
                "u7981"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 5,
            "sheetY": 33,
            "tts": "Japanese “prohibited” button",
            "keywords": [
                "“prohibited”",
                "ideograph",
                "Japanese",
                "Japanese “prohibited” button",
                "禁"
            ]
        },
        {
            "unified": "1F251",
            "name": "CIRCLED IDEOGRAPH ACCEPT",
            "native": "🉑",
            "shortName": "accept",
            "shortNames": [
                "accept"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 5,
            "sheetY": 43,
            "tts": "Japanese “acceptable” button",
            "keywords": [
                "“acceptable”",
                "ideograph",
                "Japanese",
                "Japanese “acceptable” button",
                "可"
            ]
        },
        {
            "unified": "1F238",
            "name": "SQUARED CJK UNIFIED IDEOGRAPH-7533",
            "native": "🈸",
            "shortName": "u7533",
            "shortNames": [
                "u7533"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 5,
            "sheetY": 39,
            "tts": "Japanese “application” button",
            "keywords": [
                "“application”",
                "ideograph",
                "Japanese",
                "Japanese “application” button",
                "申"
            ]
        },
        {
            "unified": "1F234",
            "name": "SQUARED CJK UNIFIED IDEOGRAPH-5408",
            "native": "🈴",
            "shortName": "u5408",
            "shortNames": [
                "u5408"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 5,
            "sheetY": 35,
            "tts": "Japanese “passing grade” button",
            "keywords": [
                "“passing grade”",
                "ideograph",
                "Japanese",
                "Japanese “passing grade” button",
                "合"
            ]
        },
        {
            "unified": "1F233",
            "name": "SQUARED CJK UNIFIED IDEOGRAPH-7A7A",
            "native": "🈳",
            "shortName": "u7a7a",
            "shortNames": [
                "u7a7a"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 5,
            "sheetY": 34,
            "tts": "Japanese “vacancy” button",
            "keywords": [
                "“vacancy”",
                "ideograph",
                "Japanese",
                "Japanese “vacancy” button",
                "空"
            ]
        },
        {
            "unified": "3297-FE0F",
            "name": "CIRCLED IDEOGRAPH CONGRATULATION",
            "native": "㊗️",
            "shortName": "congratulations",
            "shortNames": [
                "congratulations"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 26
        },
        {
            "unified": "3299-FE0F",
            "name": "CIRCLED IDEOGRAPH SECRET",
            "native": "㊙️",
            "shortName": "secret",
            "shortNames": [
                "secret"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 27
        },
        {
            "unified": "1F23A",
            "name": "SQUARED CJK UNIFIED IDEOGRAPH-55B6",
            "native": "🈺",
            "shortName": "u55b6",
            "shortNames": [
                "u55b6"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 5,
            "sheetY": 41,
            "tts": "Japanese “open for business” button",
            "keywords": [
                "“open for business”",
                "ideograph",
                "Japanese",
                "Japanese “open for business” button",
                "営"
            ]
        },
        {
            "unified": "1F235",
            "name": "SQUARED CJK UNIFIED IDEOGRAPH-6E80",
            "native": "🈵",
            "shortName": "u6e80",
            "shortNames": [
                "u6e80"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 5,
            "sheetY": 36,
            "tts": "Japanese “no vacancy” button",
            "keywords": [
                "“no vacancy”",
                "ideograph",
                "Japanese",
                "Japanese “no vacancy” button",
                "満"
            ]
        },
        {
            "unified": "1F534",
            "name": "LARGE RED CIRCLE",
            "native": "🔴",
            "shortName": "red_circle",
            "shortNames": [
                "red_circle"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 28,
            "sheetY": 2,
            "tts": "red circle",
            "keywords": [
                "circle",
                "geometric",
                "red"
            ]
        },
        {
            "unified": "1F535",
            "name": "LARGE BLUE CIRCLE",
            "native": "🔵",
            "shortName": "large_blue_circle",
            "shortNames": [
                "large_blue_circle"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 28,
            "sheetY": 3,
            "tts": "blue circle",
            "keywords": [
                "blue",
                "circle",
                "geometric"
            ]
        },
        {
            "unified": "26AA",
            "name": "MEDIUM WHITE CIRCLE",
            "native": "⚪",
            "shortName": "white_circle",
            "shortNames": [
                "white_circle"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 48,
            "sheetY": 22,
            "tts": "white circle",
            "keywords": [
                "circle",
                "geometric",
                "white circle"
            ]
        },
        {
            "unified": "26AB",
            "name": "MEDIUM BLACK CIRCLE",
            "native": "⚫",
            "shortName": "black_circle",
            "shortNames": [
                "black_circle"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 48,
            "sheetY": 23,
            "tts": "black circle",
            "keywords": [
                "black circle",
                "circle",
                "geometric"
            ]
        },
        {
            "unified": "2B1C",
            "name": "WHITE LARGE SQUARE",
            "native": "⬜",
            "shortName": "white_large_square",
            "shortNames": [
                "white_large_square"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 21,
            "tts": "white large square",
            "keywords": [
                "geometric",
                "square",
                "white large square"
            ]
        },
        {
            "unified": "2B1B",
            "name": "BLACK LARGE SQUARE",
            "native": "⬛",
            "shortName": "black_large_square",
            "shortNames": [
                "black_large_square"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 50,
            "sheetY": 20,
            "tts": "black large square",
            "keywords": [
                "black large square",
                "geometric",
                "square"
            ]
        },
        {
            "unified": "25FC-FE0F",
            "name": "BLACK MEDIUM SQUARE",
            "native": "◼️",
            "shortName": "black_medium_square",
            "shortNames": [
                "black_medium_square"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 13
        },
        {
            "unified": "25FB-FE0F",
            "name": "WHITE MEDIUM SQUARE",
            "native": "◻️",
            "shortName": "white_medium_square",
            "shortNames": [
                "white_medium_square"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 12
        },
        {
            "unified": "25FD",
            "name": "WHITE MEDIUM SMALL SQUARE",
            "native": "◽",
            "shortName": "white_medium_small_square",
            "shortNames": [
                "white_medium_small_square"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 14,
            "tts": "white medium-small square",
            "keywords": [
                "geometric",
                "square",
                "white medium-small square"
            ]
        },
        {
            "unified": "25FE",
            "name": "BLACK MEDIUM SMALL SQUARE",
            "native": "◾",
            "shortName": "black_medium_small_square",
            "shortNames": [
                "black_medium_small_square"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 15,
            "tts": "black medium-small square",
            "keywords": [
                "black medium-small square",
                "geometric",
                "square"
            ]
        },
        {
            "unified": "25AB-FE0F",
            "name": "WHITE SMALL SQUARE",
            "native": "▫️",
            "shortName": "white_small_square",
            "shortNames": [
                "white_small_square"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 9
        },
        {
            "unified": "25AA-FE0F",
            "name": "BLACK SMALL SQUARE",
            "native": "▪️",
            "shortName": "black_small_square",
            "shortNames": [
                "black_small_square"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 47,
            "sheetY": 8
        },
        {
            "unified": "1F536",
            "name": "LARGE ORANGE DIAMOND",
            "native": "🔶",
            "shortName": "large_orange_diamond",
            "shortNames": [
                "large_orange_diamond"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 28,
            "sheetY": 4,
            "tts": "large orange diamond",
            "keywords": [
                "diamond",
                "geometric",
                "large orange diamond",
                "orange"
            ]
        },
        {
            "unified": "1F537",
            "name": "LARGE BLUE DIAMOND",
            "native": "🔷",
            "shortName": "large_blue_diamond",
            "shortNames": [
                "large_blue_diamond"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 28,
            "sheetY": 5,
            "tts": "large blue diamond",
            "keywords": [
                "blue",
                "diamond",
                "geometric",
                "large blue diamond"
            ]
        },
        {
            "unified": "1F538",
            "name": "SMALL ORANGE DIAMOND",
            "native": "🔸",
            "shortName": "small_orange_diamond",
            "shortNames": [
                "small_orange_diamond"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 28,
            "sheetY": 6,
            "tts": "small orange diamond",
            "keywords": [
                "diamond",
                "geometric",
                "orange",
                "small orange diamond"
            ]
        },
        {
            "unified": "1F539",
            "name": "SMALL BLUE DIAMOND",
            "native": "🔹",
            "shortName": "small_blue_diamond",
            "shortNames": [
                "small_blue_diamond"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 28,
            "sheetY": 7,
            "tts": "small blue diamond",
            "keywords": [
                "blue",
                "diamond",
                "geometric",
                "small blue diamond"
            ]
        },
        {
            "unified": "1F53A",
            "name": "UP-POINTING RED TRIANGLE",
            "native": "🔺",
            "shortName": "small_red_triangle",
            "shortNames": [
                "small_red_triangle"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 28,
            "sheetY": 8,
            "tts": "red triangle pointed up",
            "keywords": [
                "geometric",
                "red",
                "red triangle pointed up"
            ]
        },
        {
            "unified": "1F53B",
            "name": "DOWN-POINTING RED TRIANGLE",
            "native": "🔻",
            "shortName": "small_red_triangle_down",
            "shortNames": [
                "small_red_triangle_down"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 28,
            "sheetY": 9,
            "tts": "red triangle pointed down",
            "keywords": [
                "down",
                "geometric",
                "red",
                "red triangle pointed down"
            ]
        },
        {
            "unified": "1F4A0",
            "name": "DIAMOND SHAPE WITH A DOT INSIDE",
            "native": "💠",
            "shortName": "diamond_shape_with_a_dot_inside",
            "shortNames": [
                "diamond_shape_with_a_dot_inside"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 25,
            "sheetY": 6,
            "tts": "diamond with a dot",
            "keywords": [
                "comic",
                "diamond",
                "diamond with a dot",
                "geometric",
                "inside"
            ]
        },
        {
            "unified": "1F518",
            "name": "RADIO BUTTON",
            "native": "🔘",
            "shortName": "radio_button",
            "shortNames": [
                "radio_button"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 27,
            "sheetY": 26,
            "tts": "radio button",
            "keywords": [
                "button",
                "geometric",
                "radio"
            ]
        },
        {
            "unified": "1F532",
            "name": "BLACK SQUARE BUTTON",
            "native": "🔲",
            "shortName": "black_square_button",
            "shortNames": [
                "black_square_button"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 28,
            "sheetY": 0,
            "tts": "black square button",
            "keywords": [
                "black square button",
                "button",
                "geometric",
                "square"
            ]
        },
        {
            "unified": "1F533",
            "name": "WHITE SQUARE BUTTON",
            "native": "🔳",
            "shortName": "white_square_button",
            "shortNames": [
                "white_square_button"
            ],
            "text": null,
            "texts": null,
            "category": "Symbols",
            "sheetX": 28,
            "sheetY": 1,
            "tts": "white square button",
            "keywords": [
                "button",
                "geometric",
                "outlined",
                "square",
                "white square button"
            ]
        },
        {
            "unified": "1F3C1",
            "name": "CHEQUERED FLAG",
            "native": "🏁",
            "shortName": "checkered_flag",
            "shortNames": [
                "checkered_flag"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 9,
            "sheetY": 27,
            "tts": "chequered flag",
            "keywords": [
                "checkered",
                "chequered",
                "chequered flag",
                "racing"
            ]
        },
        {
            "unified": "1F6A9",
            "name": "TRIANGULAR FLAG ON POST",
            "native": "🚩",
            "shortName": "triangular_flag_on_post",
            "shortNames": [
                "triangular_flag_on_post"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 35,
            "sheetY": 14,
            "tts": "triangular flag",
            "keywords": [
                "post",
                "triangular flag"
            ]
        },
        {
            "unified": "1F38C",
            "name": "CROSSED FLAGS",
            "native": "🎌",
            "shortName": "crossed_flags",
            "shortNames": [
                "crossed_flags"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 8,
            "sheetY": 31,
            "tts": "crossed flags",
            "keywords": [
                "celebration",
                "cross",
                "crossed",
                "crossed flags",
                "Japanese"
            ]
        },
        {
            "unified": "1F3F4",
            "name": "WAVING BLACK FLAG",
            "native": "🏴",
            "shortName": "waving_black_flag",
            "shortNames": [
                "waving_black_flag"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 12,
            "sheetY": 19,
            "tts": "black flag",
            "keywords": [
                "black flag",
                "waving"
            ]
        },
        {
            "unified": "1F3F3-FE0F",
            "name": null,
            "native": "🏳️",
            "shortName": "waving_white_flag",
            "shortNames": [
                "waving_white_flag"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 12,
            "sheetY": 15
        },
        {
            "unified": "1F3F3-FE0F-200D-1F308",
            "name": null,
            "native": "🏳️‍🌈",
            "shortName": "rainbow-flag",
            "shortNames": [
                "rainbow-flag"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 12,
            "sheetY": 14
        },
        {
            "unified": "1F1E6-1F1E8",
            "name": "Ascension Island Flag",
            "native": "🇦🇨",
            "shortName": "flag-ac",
            "shortNames": [
                "flag-ac"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 0,
            "sheetY": 31
        },
        {
            "unified": "1F1E6-1F1E9",
            "name": "Andorra Flag",
            "native": "🇦🇩",
            "shortName": "flag-ad",
            "shortNames": [
                "flag-ad"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 0,
            "sheetY": 32
        },
        {
            "unified": "1F1E6-1F1EA",
            "name": "United Arab Emirates Flag",
            "native": "🇦🇪",
            "shortName": "flag-ae",
            "shortNames": [
                "flag-ae"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 0,
            "sheetY": 33
        },
        {
            "unified": "1F1E6-1F1EB",
            "name": "Afghanistan Flag",
            "native": "🇦🇫",
            "shortName": "flag-af",
            "shortNames": [
                "flag-af"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 0,
            "sheetY": 34
        },
        {
            "unified": "1F1E6-1F1EC",
            "name": "Antigua & Barbuda Flag",
            "native": "🇦🇬",
            "shortName": "flag-ag",
            "shortNames": [
                "flag-ag"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 0,
            "sheetY": 35
        },
        {
            "unified": "1F1E6-1F1EE",
            "name": "Anguilla Flag",
            "native": "🇦🇮",
            "shortName": "flag-ai",
            "shortNames": [
                "flag-ai"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 0,
            "sheetY": 36
        },
        {
            "unified": "1F1E6-1F1F1",
            "name": "Albania Flag",
            "native": "🇦🇱",
            "shortName": "flag-al",
            "shortNames": [
                "flag-al"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 0,
            "sheetY": 37
        },
        {
            "unified": "1F1E6-1F1F2",
            "name": "Armenia Flag",
            "native": "🇦🇲",
            "shortName": "flag-am",
            "shortNames": [
                "flag-am"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 0,
            "sheetY": 38
        },
        {
            "unified": "1F1E6-1F1F4",
            "name": "Angola Flag",
            "native": "🇦🇴",
            "shortName": "flag-ao",
            "shortNames": [
                "flag-ao"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 0,
            "sheetY": 39
        },
        {
            "unified": "1F1E6-1F1F6",
            "name": "Antarctica Flag",
            "native": "🇦🇶",
            "shortName": "flag-aq",
            "shortNames": [
                "flag-aq"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 0,
            "sheetY": 40
        },
        {
            "unified": "1F1E6-1F1F7",
            "name": "Argentina Flag",
            "native": "🇦🇷",
            "shortName": "flag-ar",
            "shortNames": [
                "flag-ar"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 0,
            "sheetY": 41
        },
        {
            "unified": "1F1E6-1F1F8",
            "name": "American Samoa Flag",
            "native": "🇦🇸",
            "shortName": "flag-as",
            "shortNames": [
                "flag-as"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 0,
            "sheetY": 42
        },
        {
            "unified": "1F1E6-1F1F9",
            "name": "Austria Flag",
            "native": "🇦🇹",
            "shortName": "flag-at",
            "shortNames": [
                "flag-at"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 0,
            "sheetY": 43
        },
        {
            "unified": "1F1E6-1F1FA",
            "name": "Australia Flag",
            "native": "🇦🇺",
            "shortName": "flag-au",
            "shortNames": [
                "flag-au"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 0,
            "sheetY": 44
        },
        {
            "unified": "1F1E6-1F1FC",
            "name": "Aruba Flag",
            "native": "🇦🇼",
            "shortName": "flag-aw",
            "shortNames": [
                "flag-aw"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 0,
            "sheetY": 45
        },
        {
            "unified": "1F1E6-1F1FD",
            "name": "Åland Islands Flag",
            "native": "🇦🇽",
            "shortName": "flag-ax",
            "shortNames": [
                "flag-ax"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 0,
            "sheetY": 46
        },
        {
            "unified": "1F1E6-1F1FF",
            "name": "Azerbaijan Flag",
            "native": "🇦🇿",
            "shortName": "flag-az",
            "shortNames": [
                "flag-az"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 0,
            "sheetY": 47
        },
        {
            "unified": "1F1E7-1F1E6",
            "name": "Bosnia & Herzegovina Flag",
            "native": "🇧🇦",
            "shortName": "flag-ba",
            "shortNames": [
                "flag-ba"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 0,
            "sheetY": 48
        },
        {
            "unified": "1F1E7-1F1E7",
            "name": "Barbados Flag",
            "native": "🇧🇧",
            "shortName": "flag-bb",
            "shortNames": [
                "flag-bb"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 0,
            "sheetY": 49
        },
        {
            "unified": "1F1E7-1F1E9",
            "name": "Bangladesh Flag",
            "native": "🇧🇩",
            "shortName": "flag-bd",
            "shortNames": [
                "flag-bd"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 0,
            "sheetY": 50
        },
        {
            "unified": "1F1E7-1F1EA",
            "name": "Belgium Flag",
            "native": "🇧🇪",
            "shortName": "flag-be",
            "shortNames": [
                "flag-be"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 0,
            "sheetY": 51
        },
        {
            "unified": "1F1E7-1F1EB",
            "name": "Burkina Faso Flag",
            "native": "🇧🇫",
            "shortName": "flag-bf",
            "shortNames": [
                "flag-bf"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 0
        },
        {
            "unified": "1F1E7-1F1EC",
            "name": "Bulgaria Flag",
            "native": "🇧🇬",
            "shortName": "flag-bg",
            "shortNames": [
                "flag-bg"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 1
        },
        {
            "unified": "1F1E7-1F1ED",
            "name": "Bahrain Flag",
            "native": "🇧🇭",
            "shortName": "flag-bh",
            "shortNames": [
                "flag-bh"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 2
        },
        {
            "unified": "1F1E7-1F1EE",
            "name": "Burundi Flag",
            "native": "🇧🇮",
            "shortName": "flag-bi",
            "shortNames": [
                "flag-bi"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 3
        },
        {
            "unified": "1F1E7-1F1EF",
            "name": "Benin Flag",
            "native": "🇧🇯",
            "shortName": "flag-bj",
            "shortNames": [
                "flag-bj"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 4
        },
        {
            "unified": "1F1E7-1F1F1",
            "name": "St. Barthélemy Flag",
            "native": "🇧🇱",
            "shortName": "flag-bl",
            "shortNames": [
                "flag-bl"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 5
        },
        {
            "unified": "1F1E7-1F1F2",
            "name": "Bermuda Flag",
            "native": "🇧🇲",
            "shortName": "flag-bm",
            "shortNames": [
                "flag-bm"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 6
        },
        {
            "unified": "1F1E7-1F1F3",
            "name": "Brunei Flag",
            "native": "🇧🇳",
            "shortName": "flag-bn",
            "shortNames": [
                "flag-bn"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 7
        },
        {
            "unified": "1F1E7-1F1F4",
            "name": "Bolivia Flag",
            "native": "🇧🇴",
            "shortName": "flag-bo",
            "shortNames": [
                "flag-bo"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 8
        },
        {
            "unified": "1F1E7-1F1F6",
            "name": "Caribbean Netherlands Flag",
            "native": "🇧🇶",
            "shortName": "flag-bq",
            "shortNames": [
                "flag-bq"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 9
        },
        {
            "unified": "1F1E7-1F1F7",
            "name": "Brazil Flag",
            "native": "🇧🇷",
            "shortName": "flag-br",
            "shortNames": [
                "flag-br"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 10
        },
        {
            "unified": "1F1E7-1F1F8",
            "name": "Bahamas Flag",
            "native": "🇧🇸",
            "shortName": "flag-bs",
            "shortNames": [
                "flag-bs"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 11
        },
        {
            "unified": "1F1E7-1F1F9",
            "name": "Bhutan Flag",
            "native": "🇧🇹",
            "shortName": "flag-bt",
            "shortNames": [
                "flag-bt"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 12
        },
        {
            "unified": "1F1E7-1F1FB",
            "name": "Bouvet Island Flag",
            "native": "🇧🇻",
            "shortName": "flag-bv",
            "shortNames": [
                "flag-bv"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 13
        },
        {
            "unified": "1F1E7-1F1FC",
            "name": "Botswana Flag",
            "native": "🇧🇼",
            "shortName": "flag-bw",
            "shortNames": [
                "flag-bw"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 14
        },
        {
            "unified": "1F1E7-1F1FE",
            "name": "Belarus Flag",
            "native": "🇧🇾",
            "shortName": "flag-by",
            "shortNames": [
                "flag-by"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 15
        },
        {
            "unified": "1F1E7-1F1FF",
            "name": "Belize Flag",
            "native": "🇧🇿",
            "shortName": "flag-bz",
            "shortNames": [
                "flag-bz"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 16
        },
        {
            "unified": "1F1E8-1F1E6",
            "name": "Canada Flag",
            "native": "🇨🇦",
            "shortName": "flag-ca",
            "shortNames": [
                "flag-ca"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 17
        },
        {
            "unified": "1F1E8-1F1E8",
            "name": "Cocos (Keeling) Islands Flag",
            "native": "🇨🇨",
            "shortName": "flag-cc",
            "shortNames": [
                "flag-cc"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 18
        },
        {
            "unified": "1F1E8-1F1E9",
            "name": "Congo - Kinshasa Flag",
            "native": "🇨🇩",
            "shortName": "flag-cd",
            "shortNames": [
                "flag-cd"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 19
        },
        {
            "unified": "1F1E8-1F1EB",
            "name": "Central African Republic Flag",
            "native": "🇨🇫",
            "shortName": "flag-cf",
            "shortNames": [
                "flag-cf"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 20
        },
        {
            "unified": "1F1E8-1F1EC",
            "name": "Congo - Brazzaville Flag",
            "native": "🇨🇬",
            "shortName": "flag-cg",
            "shortNames": [
                "flag-cg"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 21
        },
        {
            "unified": "1F1E8-1F1ED",
            "name": "Switzerland Flag",
            "native": "🇨🇭",
            "shortName": "flag-ch",
            "shortNames": [
                "flag-ch"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 22
        },
        {
            "unified": "1F1E8-1F1EE",
            "name": "Côte d’Ivoire Flag",
            "native": "🇨🇮",
            "shortName": "flag-ci",
            "shortNames": [
                "flag-ci"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 23
        },
        {
            "unified": "1F1E8-1F1F0",
            "name": "Cook Islands Flag",
            "native": "🇨🇰",
            "shortName": "flag-ck",
            "shortNames": [
                "flag-ck"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 24
        },
        {
            "unified": "1F1E8-1F1F1",
            "name": "Chile Flag",
            "native": "🇨🇱",
            "shortName": "flag-cl",
            "shortNames": [
                "flag-cl"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 25
        },
        {
            "unified": "1F1E8-1F1F2",
            "name": "Cameroon Flag",
            "native": "🇨🇲",
            "shortName": "flag-cm",
            "shortNames": [
                "flag-cm"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 26
        },
        {
            "unified": "1F1E8-1F1F3",
            "name": "China Flag",
            "native": "🇨🇳",
            "shortName": "cn",
            "shortNames": [
                "cn",
                "flag-cn"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 27
        },
        {
            "unified": "1F1E8-1F1F4",
            "name": "Colombia Flag",
            "native": "🇨🇴",
            "shortName": "flag-co",
            "shortNames": [
                "flag-co"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 28
        },
        {
            "unified": "1F1E8-1F1F5",
            "name": "Clipperton Island Flag",
            "native": "🇨🇵",
            "shortName": "flag-cp",
            "shortNames": [
                "flag-cp"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 29
        },
        {
            "unified": "1F1E8-1F1F7",
            "name": "Costa Rica Flag",
            "native": "🇨🇷",
            "shortName": "flag-cr",
            "shortNames": [
                "flag-cr"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 30
        },
        {
            "unified": "1F1E8-1F1FA",
            "name": "Cuba Flag",
            "native": "🇨🇺",
            "shortName": "flag-cu",
            "shortNames": [
                "flag-cu"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 31
        },
        {
            "unified": "1F1E8-1F1FB",
            "name": "Cape Verde Flag",
            "native": "🇨🇻",
            "shortName": "flag-cv",
            "shortNames": [
                "flag-cv"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 32
        },
        {
            "unified": "1F1E8-1F1FC",
            "name": "Curaçao Flag",
            "native": "🇨🇼",
            "shortName": "flag-cw",
            "shortNames": [
                "flag-cw"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 33
        },
        {
            "unified": "1F1E8-1F1FD",
            "name": "Christmas Island Flag",
            "native": "🇨🇽",
            "shortName": "flag-cx",
            "shortNames": [
                "flag-cx"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 34
        },
        {
            "unified": "1F1E8-1F1FE",
            "name": "Cyprus Flag",
            "native": "🇨🇾",
            "shortName": "flag-cy",
            "shortNames": [
                "flag-cy"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 35
        },
        {
            "unified": "1F1E8-1F1FF",
            "name": "Czechia Flag",
            "native": "🇨🇿",
            "shortName": "flag-cz",
            "shortNames": [
                "flag-cz"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 36
        },
        {
            "unified": "1F1E9-1F1EA",
            "name": "Germany Flag",
            "native": "🇩🇪",
            "shortName": "de",
            "shortNames": [
                "de",
                "flag-de"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 37
        },
        {
            "unified": "1F1E9-1F1EC",
            "name": "Diego Garcia Flag",
            "native": "🇩🇬",
            "shortName": "flag-dg",
            "shortNames": [
                "flag-dg"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 38
        },
        {
            "unified": "1F1E9-1F1EF",
            "name": "Djibouti Flag",
            "native": "🇩🇯",
            "shortName": "flag-dj",
            "shortNames": [
                "flag-dj"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 39
        },
        {
            "unified": "1F1E9-1F1F0",
            "name": "Denmark Flag",
            "native": "🇩🇰",
            "shortName": "flag-dk",
            "shortNames": [
                "flag-dk"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 40
        },
        {
            "unified": "1F1E9-1F1F2",
            "name": "Dominica Flag",
            "native": "🇩🇲",
            "shortName": "flag-dm",
            "shortNames": [
                "flag-dm"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 41
        },
        {
            "unified": "1F1E9-1F1F4",
            "name": "Dominican Republic Flag",
            "native": "🇩🇴",
            "shortName": "flag-do",
            "shortNames": [
                "flag-do"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 42
        },
        {
            "unified": "1F1E9-1F1FF",
            "name": "Algeria Flag",
            "native": "🇩🇿",
            "shortName": "flag-dz",
            "shortNames": [
                "flag-dz"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 43
        },
        {
            "unified": "1F1EA-1F1E6",
            "name": "Ceuta & Melilla Flag",
            "native": "🇪🇦",
            "shortName": "flag-ea",
            "shortNames": [
                "flag-ea"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 44
        },
        {
            "unified": "1F1EA-1F1E8",
            "name": "Ecuador Flag",
            "native": "🇪🇨",
            "shortName": "flag-ec",
            "shortNames": [
                "flag-ec"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 45
        },
        {
            "unified": "1F1EA-1F1EA",
            "name": "Estonia Flag",
            "native": "🇪🇪",
            "shortName": "flag-ee",
            "shortNames": [
                "flag-ee"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 46
        },
        {
            "unified": "1F1EA-1F1EC",
            "name": "Egypt Flag",
            "native": "🇪🇬",
            "shortName": "flag-eg",
            "shortNames": [
                "flag-eg"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 47
        },
        {
            "unified": "1F1EA-1F1ED",
            "name": "Western Sahara Flag",
            "native": "🇪🇭",
            "shortName": "flag-eh",
            "shortNames": [
                "flag-eh"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 48
        },
        {
            "unified": "1F1EA-1F1F7",
            "name": "Eritrea Flag",
            "native": "🇪🇷",
            "shortName": "flag-er",
            "shortNames": [
                "flag-er"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 49
        },
        {
            "unified": "1F1EA-1F1F8",
            "name": "Spain Flag",
            "native": "🇪🇸",
            "shortName": "es",
            "shortNames": [
                "es",
                "flag-es"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 50
        },
        {
            "unified": "1F1EA-1F1F9",
            "name": "Ethiopia Flag",
            "native": "🇪🇹",
            "shortName": "flag-et",
            "shortNames": [
                "flag-et"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 1,
            "sheetY": 51
        },
        {
            "unified": "1F1EA-1F1FA",
            "name": "European Union Flag",
            "native": "🇪🇺",
            "shortName": "flag-eu",
            "shortNames": [
                "flag-eu"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 0
        },
        {
            "unified": "1F1EB-1F1EE",
            "name": "Finland Flag",
            "native": "🇫🇮",
            "shortName": "flag-fi",
            "shortNames": [
                "flag-fi"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 1
        },
        {
            "unified": "1F1EB-1F1EF",
            "name": "Fiji Flag",
            "native": "🇫🇯",
            "shortName": "flag-fj",
            "shortNames": [
                "flag-fj"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 2
        },
        {
            "unified": "1F1EB-1F1F0",
            "name": "Falkland Islands Flag",
            "native": "🇫🇰",
            "shortName": "flag-fk",
            "shortNames": [
                "flag-fk"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 3
        },
        {
            "unified": "1F1EB-1F1F2",
            "name": "Micronesia Flag",
            "native": "🇫🇲",
            "shortName": "flag-fm",
            "shortNames": [
                "flag-fm"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 4
        },
        {
            "unified": "1F1EB-1F1F4",
            "name": "Faroe Islands Flag",
            "native": "🇫🇴",
            "shortName": "flag-fo",
            "shortNames": [
                "flag-fo"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 5
        },
        {
            "unified": "1F1EB-1F1F7",
            "name": "France Flag",
            "native": "🇫🇷",
            "shortName": "fr",
            "shortNames": [
                "fr",
                "flag-fr"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 6
        },
        {
            "unified": "1F1EC-1F1E6",
            "name": "Gabon Flag",
            "native": "🇬🇦",
            "shortName": "flag-ga",
            "shortNames": [
                "flag-ga"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 7
        },
        {
            "unified": "1F1EC-1F1E7",
            "name": "United Kingdom Flag",
            "native": "🇬🇧",
            "shortName": "gb",
            "shortNames": [
                "gb",
                "uk",
                "flag-gb"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 8
        },
        {
            "unified": "1F1EC-1F1E9",
            "name": "Grenada Flag",
            "native": "🇬🇩",
            "shortName": "flag-gd",
            "shortNames": [
                "flag-gd"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 9
        },
        {
            "unified": "1F1EC-1F1EA",
            "name": "Georgia Flag",
            "native": "🇬🇪",
            "shortName": "flag-ge",
            "shortNames": [
                "flag-ge"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 10
        },
        {
            "unified": "1F1EC-1F1EB",
            "name": "French Guiana Flag",
            "native": "🇬🇫",
            "shortName": "flag-gf",
            "shortNames": [
                "flag-gf"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 11
        },
        {
            "unified": "1F1EC-1F1EC",
            "name": "Guernsey Flag",
            "native": "🇬🇬",
            "shortName": "flag-gg",
            "shortNames": [
                "flag-gg"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 12
        },
        {
            "unified": "1F1EC-1F1ED",
            "name": "Ghana Flag",
            "native": "🇬🇭",
            "shortName": "flag-gh",
            "shortNames": [
                "flag-gh"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 13
        },
        {
            "unified": "1F1EC-1F1EE",
            "name": "Gibraltar Flag",
            "native": "🇬🇮",
            "shortName": "flag-gi",
            "shortNames": [
                "flag-gi"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 14
        },
        {
            "unified": "1F1EC-1F1F1",
            "name": "Greenland Flag",
            "native": "🇬🇱",
            "shortName": "flag-gl",
            "shortNames": [
                "flag-gl"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 15
        },
        {
            "unified": "1F1EC-1F1F2",
            "name": "Gambia Flag",
            "native": "🇬🇲",
            "shortName": "flag-gm",
            "shortNames": [
                "flag-gm"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 16
        },
        {
            "unified": "1F1EC-1F1F3",
            "name": "Guinea Flag",
            "native": "🇬🇳",
            "shortName": "flag-gn",
            "shortNames": [
                "flag-gn"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 17
        },
        {
            "unified": "1F1EC-1F1F5",
            "name": "Guadeloupe Flag",
            "native": "🇬🇵",
            "shortName": "flag-gp",
            "shortNames": [
                "flag-gp"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 18
        },
        {
            "unified": "1F1EC-1F1F6",
            "name": "Equatorial Guinea Flag",
            "native": "🇬🇶",
            "shortName": "flag-gq",
            "shortNames": [
                "flag-gq"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 19
        },
        {
            "unified": "1F1EC-1F1F7",
            "name": "Greece Flag",
            "native": "🇬🇷",
            "shortName": "flag-gr",
            "shortNames": [
                "flag-gr"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 20
        },
        {
            "unified": "1F1EC-1F1F8",
            "name": "South Georgia & South Sandwich Islands Flag",
            "native": "🇬🇸",
            "shortName": "flag-gs",
            "shortNames": [
                "flag-gs"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 21
        },
        {
            "unified": "1F1EC-1F1F9",
            "name": "Guatemala Flag",
            "native": "🇬🇹",
            "shortName": "flag-gt",
            "shortNames": [
                "flag-gt"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 22
        },
        {
            "unified": "1F1EC-1F1FA",
            "name": "Guam Flag",
            "native": "🇬🇺",
            "shortName": "flag-gu",
            "shortNames": [
                "flag-gu"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 23
        },
        {
            "unified": "1F1EC-1F1FC",
            "name": "Guinea-Bissau Flag",
            "native": "🇬🇼",
            "shortName": "flag-gw",
            "shortNames": [
                "flag-gw"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 24
        },
        {
            "unified": "1F1EC-1F1FE",
            "name": "Guyana Flag",
            "native": "🇬🇾",
            "shortName": "flag-gy",
            "shortNames": [
                "flag-gy"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 25
        },
        {
            "unified": "1F1ED-1F1F0",
            "name": "Hong Kong SAR China Flag",
            "native": "🇭🇰",
            "shortName": "flag-hk",
            "shortNames": [
                "flag-hk"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 26
        },
        {
            "unified": "1F1ED-1F1F2",
            "name": "Heard & McDonald Islands Flag",
            "native": "🇭🇲",
            "shortName": "flag-hm",
            "shortNames": [
                "flag-hm"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 27
        },
        {
            "unified": "1F1ED-1F1F3",
            "name": "Honduras Flag",
            "native": "🇭🇳",
            "shortName": "flag-hn",
            "shortNames": [
                "flag-hn"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 28
        },
        {
            "unified": "1F1ED-1F1F7",
            "name": "Croatia Flag",
            "native": "🇭🇷",
            "shortName": "flag-hr",
            "shortNames": [
                "flag-hr"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 29
        },
        {
            "unified": "1F1ED-1F1F9",
            "name": "Haiti Flag",
            "native": "🇭🇹",
            "shortName": "flag-ht",
            "shortNames": [
                "flag-ht"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 30
        },
        {
            "unified": "1F1ED-1F1FA",
            "name": "Hungary Flag",
            "native": "🇭🇺",
            "shortName": "flag-hu",
            "shortNames": [
                "flag-hu"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 31
        },
        {
            "unified": "1F1EE-1F1E8",
            "name": "Canary Islands Flag",
            "native": "🇮🇨",
            "shortName": "flag-ic",
            "shortNames": [
                "flag-ic"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 32
        },
        {
            "unified": "1F1EE-1F1E9",
            "name": "Indonesia Flag",
            "native": "🇮🇩",
            "shortName": "flag-id",
            "shortNames": [
                "flag-id"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 33
        },
        {
            "unified": "1F1EE-1F1EA",
            "name": "Ireland Flag",
            "native": "🇮🇪",
            "shortName": "flag-ie",
            "shortNames": [
                "flag-ie"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 34
        },
        {
            "unified": "1F1EE-1F1F1",
            "name": "Israel Flag",
            "native": "🇮🇱",
            "shortName": "flag-il",
            "shortNames": [
                "flag-il"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 35
        },
        {
            "unified": "1F1EE-1F1F2",
            "name": "Isle of Man Flag",
            "native": "🇮🇲",
            "shortName": "flag-im",
            "shortNames": [
                "flag-im"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 36
        },
        {
            "unified": "1F1EE-1F1F3",
            "name": "India Flag",
            "native": "🇮🇳",
            "shortName": "flag-in",
            "shortNames": [
                "flag-in"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 37
        },
        {
            "unified": "1F1EE-1F1F4",
            "name": "British Indian Ocean Territory Flag",
            "native": "🇮🇴",
            "shortName": "flag-io",
            "shortNames": [
                "flag-io"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 38
        },
        {
            "unified": "1F1EE-1F1F6",
            "name": "Iraq Flag",
            "native": "🇮🇶",
            "shortName": "flag-iq",
            "shortNames": [
                "flag-iq"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 39
        },
        {
            "unified": "1F1EE-1F1F7",
            "name": "Iran Flag",
            "native": "🇮🇷",
            "shortName": "flag-ir",
            "shortNames": [
                "flag-ir"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 40
        },
        {
            "unified": "1F1EE-1F1F8",
            "name": "Iceland Flag",
            "native": "🇮🇸",
            "shortName": "flag-is",
            "shortNames": [
                "flag-is"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 41
        },
        {
            "unified": "1F1EE-1F1F9",
            "name": "Italy Flag",
            "native": "🇮🇹",
            "shortName": "it",
            "shortNames": [
                "it",
                "flag-it"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 42
        },
        {
            "unified": "1F1EF-1F1EA",
            "name": "Jersey Flag",
            "native": "🇯🇪",
            "shortName": "flag-je",
            "shortNames": [
                "flag-je"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 43
        },
        {
            "unified": "1F1EF-1F1F2",
            "name": "Jamaica Flag",
            "native": "🇯🇲",
            "shortName": "flag-jm",
            "shortNames": [
                "flag-jm"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 44
        },
        {
            "unified": "1F1EF-1F1F4",
            "name": "Jordan Flag",
            "native": "🇯🇴",
            "shortName": "flag-jo",
            "shortNames": [
                "flag-jo"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 45
        },
        {
            "unified": "1F1EF-1F1F5",
            "name": "Japan Flag",
            "native": "🇯🇵",
            "shortName": "jp",
            "shortNames": [
                "jp",
                "flag-jp"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 46
        },
        {
            "unified": "1F1F0-1F1EA",
            "name": "Kenya Flag",
            "native": "🇰🇪",
            "shortName": "flag-ke",
            "shortNames": [
                "flag-ke"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 47
        },
        {
            "unified": "1F1F0-1F1EC",
            "name": "Kyrgyzstan Flag",
            "native": "🇰🇬",
            "shortName": "flag-kg",
            "shortNames": [
                "flag-kg"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 48
        },
        {
            "unified": "1F1F0-1F1ED",
            "name": "Cambodia Flag",
            "native": "🇰🇭",
            "shortName": "flag-kh",
            "shortNames": [
                "flag-kh"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 49
        },
        {
            "unified": "1F1F0-1F1EE",
            "name": "Kiribati Flag",
            "native": "🇰🇮",
            "shortName": "flag-ki",
            "shortNames": [
                "flag-ki"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 50
        },
        {
            "unified": "1F1F0-1F1F2",
            "name": "Comoros Flag",
            "native": "🇰🇲",
            "shortName": "flag-km",
            "shortNames": [
                "flag-km"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 2,
            "sheetY": 51
        },
        {
            "unified": "1F1F0-1F1F3",
            "name": "St. Kitts & Nevis Flag",
            "native": "🇰🇳",
            "shortName": "flag-kn",
            "shortNames": [
                "flag-kn"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 0
        },
        {
            "unified": "1F1F0-1F1F5",
            "name": "North Korea Flag",
            "native": "🇰🇵",
            "shortName": "flag-kp",
            "shortNames": [
                "flag-kp"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 1
        },
        {
            "unified": "1F1F0-1F1F7",
            "name": "South Korea Flag",
            "native": "🇰🇷",
            "shortName": "kr",
            "shortNames": [
                "kr",
                "flag-kr"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 2
        },
        {
            "unified": "1F1F0-1F1FC",
            "name": "Kuwait Flag",
            "native": "🇰🇼",
            "shortName": "flag-kw",
            "shortNames": [
                "flag-kw"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 3
        },
        {
            "unified": "1F1F0-1F1FE",
            "name": "Cayman Islands Flag",
            "native": "🇰🇾",
            "shortName": "flag-ky",
            "shortNames": [
                "flag-ky"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 4
        },
        {
            "unified": "1F1F0-1F1FF",
            "name": "Kazakhstan Flag",
            "native": "🇰🇿",
            "shortName": "flag-kz",
            "shortNames": [
                "flag-kz"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 5
        },
        {
            "unified": "1F1F1-1F1E6",
            "name": "Laos Flag",
            "native": "🇱🇦",
            "shortName": "flag-la",
            "shortNames": [
                "flag-la"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 6
        },
        {
            "unified": "1F1F1-1F1E7",
            "name": "Lebanon Flag",
            "native": "🇱🇧",
            "shortName": "flag-lb",
            "shortNames": [
                "flag-lb"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 7
        },
        {
            "unified": "1F1F1-1F1E8",
            "name": "St. Lucia Flag",
            "native": "🇱🇨",
            "shortName": "flag-lc",
            "shortNames": [
                "flag-lc"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 8
        },
        {
            "unified": "1F1F1-1F1EE",
            "name": "Liechtenstein Flag",
            "native": "🇱🇮",
            "shortName": "flag-li",
            "shortNames": [
                "flag-li"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 9
        },
        {
            "unified": "1F1F1-1F1F0",
            "name": "Sri Lanka Flag",
            "native": "🇱🇰",
            "shortName": "flag-lk",
            "shortNames": [
                "flag-lk"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 10
        },
        {
            "unified": "1F1F1-1F1F7",
            "name": "Liberia Flag",
            "native": "🇱🇷",
            "shortName": "flag-lr",
            "shortNames": [
                "flag-lr"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 11
        },
        {
            "unified": "1F1F1-1F1F8",
            "name": "Lesotho Flag",
            "native": "🇱🇸",
            "shortName": "flag-ls",
            "shortNames": [
                "flag-ls"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 12
        },
        {
            "unified": "1F1F1-1F1F9",
            "name": "Lithuania Flag",
            "native": "🇱🇹",
            "shortName": "flag-lt",
            "shortNames": [
                "flag-lt"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 13
        },
        {
            "unified": "1F1F1-1F1FA",
            "name": "Luxembourg Flag",
            "native": "🇱🇺",
            "shortName": "flag-lu",
            "shortNames": [
                "flag-lu"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 14
        },
        {
            "unified": "1F1F1-1F1FB",
            "name": "Latvia Flag",
            "native": "🇱🇻",
            "shortName": "flag-lv",
            "shortNames": [
                "flag-lv"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 15
        },
        {
            "unified": "1F1F1-1F1FE",
            "name": "Libya Flag",
            "native": "🇱🇾",
            "shortName": "flag-ly",
            "shortNames": [
                "flag-ly"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 16
        },
        {
            "unified": "1F1F2-1F1E6",
            "name": "Morocco Flag",
            "native": "🇲🇦",
            "shortName": "flag-ma",
            "shortNames": [
                "flag-ma"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 17
        },
        {
            "unified": "1F1F2-1F1E8",
            "name": "Monaco Flag",
            "native": "🇲🇨",
            "shortName": "flag-mc",
            "shortNames": [
                "flag-mc"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 18
        },
        {
            "unified": "1F1F2-1F1E9",
            "name": "Moldova Flag",
            "native": "🇲🇩",
            "shortName": "flag-md",
            "shortNames": [
                "flag-md"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 19
        },
        {
            "unified": "1F1F2-1F1EA",
            "name": "Montenegro Flag",
            "native": "🇲🇪",
            "shortName": "flag-me",
            "shortNames": [
                "flag-me"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 20
        },
        {
            "unified": "1F1F2-1F1EB",
            "name": "St. Martin Flag",
            "native": "🇲🇫",
            "shortName": "flag-mf",
            "shortNames": [
                "flag-mf"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 21
        },
        {
            "unified": "1F1F2-1F1EC",
            "name": "Madagascar Flag",
            "native": "🇲🇬",
            "shortName": "flag-mg",
            "shortNames": [
                "flag-mg"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 22
        },
        {
            "unified": "1F1F2-1F1ED",
            "name": "Marshall Islands Flag",
            "native": "🇲🇭",
            "shortName": "flag-mh",
            "shortNames": [
                "flag-mh"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 23
        },
        {
            "unified": "1F1F2-1F1F0",
            "name": "Macedonia Flag",
            "native": "🇲🇰",
            "shortName": "flag-mk",
            "shortNames": [
                "flag-mk"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 24
        },
        {
            "unified": "1F1F2-1F1F1",
            "name": "Mali Flag",
            "native": "🇲🇱",
            "shortName": "flag-ml",
            "shortNames": [
                "flag-ml"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 25
        },
        {
            "unified": "1F1F2-1F1F2",
            "name": "Myanmar (Burma) Flag",
            "native": "🇲🇲",
            "shortName": "flag-mm",
            "shortNames": [
                "flag-mm"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 26
        },
        {
            "unified": "1F1F2-1F1F3",
            "name": "Mongolia Flag",
            "native": "🇲🇳",
            "shortName": "flag-mn",
            "shortNames": [
                "flag-mn"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 27
        },
        {
            "unified": "1F1F2-1F1F4",
            "name": "Macau SAR China Flag",
            "native": "🇲🇴",
            "shortName": "flag-mo",
            "shortNames": [
                "flag-mo"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 28
        },
        {
            "unified": "1F1F2-1F1F5",
            "name": "Northern Mariana Islands Flag",
            "native": "🇲🇵",
            "shortName": "flag-mp",
            "shortNames": [
                "flag-mp"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 29
        },
        {
            "unified": "1F1F2-1F1F6",
            "name": "Martinique Flag",
            "native": "🇲🇶",
            "shortName": "flag-mq",
            "shortNames": [
                "flag-mq"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 30
        },
        {
            "unified": "1F1F2-1F1F7",
            "name": "Mauritania Flag",
            "native": "🇲🇷",
            "shortName": "flag-mr",
            "shortNames": [
                "flag-mr"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 31
        },
        {
            "unified": "1F1F2-1F1F8",
            "name": "Montserrat Flag",
            "native": "🇲🇸",
            "shortName": "flag-ms",
            "shortNames": [
                "flag-ms"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 32
        },
        {
            "unified": "1F1F2-1F1F9",
            "name": "Malta Flag",
            "native": "🇲🇹",
            "shortName": "flag-mt",
            "shortNames": [
                "flag-mt"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 33
        },
        {
            "unified": "1F1F2-1F1FA",
            "name": "Mauritius Flag",
            "native": "🇲🇺",
            "shortName": "flag-mu",
            "shortNames": [
                "flag-mu"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 34
        },
        {
            "unified": "1F1F2-1F1FB",
            "name": "Maldives Flag",
            "native": "🇲🇻",
            "shortName": "flag-mv",
            "shortNames": [
                "flag-mv"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 35
        },
        {
            "unified": "1F1F2-1F1FC",
            "name": "Malawi Flag",
            "native": "🇲🇼",
            "shortName": "flag-mw",
            "shortNames": [
                "flag-mw"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 36
        },
        {
            "unified": "1F1F2-1F1FD",
            "name": "Mexico Flag",
            "native": "🇲🇽",
            "shortName": "flag-mx",
            "shortNames": [
                "flag-mx"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 37
        },
        {
            "unified": "1F1F2-1F1FE",
            "name": "Malaysia Flag",
            "native": "🇲🇾",
            "shortName": "flag-my",
            "shortNames": [
                "flag-my"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 38
        },
        {
            "unified": "1F1F2-1F1FF",
            "name": "Mozambique Flag",
            "native": "🇲🇿",
            "shortName": "flag-mz",
            "shortNames": [
                "flag-mz"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 39
        },
        {
            "unified": "1F1F3-1F1E6",
            "name": "Namibia Flag",
            "native": "🇳🇦",
            "shortName": "flag-na",
            "shortNames": [
                "flag-na"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 40
        },
        {
            "unified": "1F1F3-1F1E8",
            "name": "New Caledonia Flag",
            "native": "🇳🇨",
            "shortName": "flag-nc",
            "shortNames": [
                "flag-nc"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 41
        },
        {
            "unified": "1F1F3-1F1EA",
            "name": "Niger Flag",
            "native": "🇳🇪",
            "shortName": "flag-ne",
            "shortNames": [
                "flag-ne"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 42
        },
        {
            "unified": "1F1F3-1F1EB",
            "name": "Norfolk Island Flag",
            "native": "🇳🇫",
            "shortName": "flag-nf",
            "shortNames": [
                "flag-nf"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 43
        },
        {
            "unified": "1F1F3-1F1EC",
            "name": "Nigeria Flag",
            "native": "🇳🇬",
            "shortName": "flag-ng",
            "shortNames": [
                "flag-ng"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 44
        },
        {
            "unified": "1F1F3-1F1EE",
            "name": "Nicaragua Flag",
            "native": "🇳🇮",
            "shortName": "flag-ni",
            "shortNames": [
                "flag-ni"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 45
        },
        {
            "unified": "1F1F3-1F1F1",
            "name": "Netherlands Flag",
            "native": "🇳🇱",
            "shortName": "flag-nl",
            "shortNames": [
                "flag-nl"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 46
        },
        {
            "unified": "1F1F3-1F1F4",
            "name": "Norway Flag",
            "native": "🇳🇴",
            "shortName": "flag-no",
            "shortNames": [
                "flag-no"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 47
        },
        {
            "unified": "1F1F3-1F1F5",
            "name": "Nepal Flag",
            "native": "🇳🇵",
            "shortName": "flag-np",
            "shortNames": [
                "flag-np"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 48
        },
        {
            "unified": "1F1F3-1F1F7",
            "name": "Nauru Flag",
            "native": "🇳🇷",
            "shortName": "flag-nr",
            "shortNames": [
                "flag-nr"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 49
        },
        {
            "unified": "1F1F3-1F1FA",
            "name": "Niue Flag",
            "native": "🇳🇺",
            "shortName": "flag-nu",
            "shortNames": [
                "flag-nu"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 50
        },
        {
            "unified": "1F1F3-1F1FF",
            "name": "New Zealand Flag",
            "native": "🇳🇿",
            "shortName": "flag-nz",
            "shortNames": [
                "flag-nz"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 3,
            "sheetY": 51
        },
        {
            "unified": "1F1F4-1F1F2",
            "name": "Oman Flag",
            "native": "🇴🇲",
            "shortName": "flag-om",
            "shortNames": [
                "flag-om"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 0
        },
        {
            "unified": "1F1F5-1F1E6",
            "name": "Panama Flag",
            "native": "🇵🇦",
            "shortName": "flag-pa",
            "shortNames": [
                "flag-pa"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 1
        },
        {
            "unified": "1F1F5-1F1EA",
            "name": "Peru Flag",
            "native": "🇵🇪",
            "shortName": "flag-pe",
            "shortNames": [
                "flag-pe"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 2
        },
        {
            "unified": "1F1F5-1F1EB",
            "name": "French Polynesia Flag",
            "native": "🇵🇫",
            "shortName": "flag-pf",
            "shortNames": [
                "flag-pf"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 3
        },
        {
            "unified": "1F1F5-1F1EC",
            "name": "Papua New Guinea Flag",
            "native": "🇵🇬",
            "shortName": "flag-pg",
            "shortNames": [
                "flag-pg"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 4
        },
        {
            "unified": "1F1F5-1F1ED",
            "name": "Philippines Flag",
            "native": "🇵🇭",
            "shortName": "flag-ph",
            "shortNames": [
                "flag-ph"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 5
        },
        {
            "unified": "1F1F5-1F1F0",
            "name": "Pakistan Flag",
            "native": "🇵🇰",
            "shortName": "flag-pk",
            "shortNames": [
                "flag-pk"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 6
        },
        {
            "unified": "1F1F5-1F1F1",
            "name": "Poland Flag",
            "native": "🇵🇱",
            "shortName": "flag-pl",
            "shortNames": [
                "flag-pl"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 7
        },
        {
            "unified": "1F1F5-1F1F2",
            "name": "St. Pierre & Miquelon Flag",
            "native": "🇵🇲",
            "shortName": "flag-pm",
            "shortNames": [
                "flag-pm"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 8
        },
        {
            "unified": "1F1F5-1F1F3",
            "name": "Pitcairn Islands Flag",
            "native": "🇵🇳",
            "shortName": "flag-pn",
            "shortNames": [
                "flag-pn"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 9
        },
        {
            "unified": "1F1F5-1F1F7",
            "name": "Puerto Rico Flag",
            "native": "🇵🇷",
            "shortName": "flag-pr",
            "shortNames": [
                "flag-pr"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 10
        },
        {
            "unified": "1F1F5-1F1F8",
            "name": "Palestinian Territories Flag",
            "native": "🇵🇸",
            "shortName": "flag-ps",
            "shortNames": [
                "flag-ps"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 11
        },
        {
            "unified": "1F1F5-1F1F9",
            "name": "Portugal Flag",
            "native": "🇵🇹",
            "shortName": "flag-pt",
            "shortNames": [
                "flag-pt"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 12
        },
        {
            "unified": "1F1F5-1F1FC",
            "name": "Palau Flag",
            "native": "🇵🇼",
            "shortName": "flag-pw",
            "shortNames": [
                "flag-pw"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 13
        },
        {
            "unified": "1F1F5-1F1FE",
            "name": "Paraguay Flag",
            "native": "🇵🇾",
            "shortName": "flag-py",
            "shortNames": [
                "flag-py"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 14
        },
        {
            "unified": "1F1F6-1F1E6",
            "name": "Qatar Flag",
            "native": "🇶🇦",
            "shortName": "flag-qa",
            "shortNames": [
                "flag-qa"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 15
        },
        {
            "unified": "1F1F7-1F1EA",
            "name": "Réunion Flag",
            "native": "🇷🇪",
            "shortName": "flag-re",
            "shortNames": [
                "flag-re"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 16
        },
        {
            "unified": "1F1F7-1F1F4",
            "name": "Romania Flag",
            "native": "🇷🇴",
            "shortName": "flag-ro",
            "shortNames": [
                "flag-ro"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 17
        },
        {
            "unified": "1F1F7-1F1F8",
            "name": "Serbia Flag",
            "native": "🇷🇸",
            "shortName": "flag-rs",
            "shortNames": [
                "flag-rs"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 18
        },
        {
            "unified": "1F1F7-1F1FA",
            "name": "Russia Flag",
            "native": "🇷🇺",
            "shortName": "ru",
            "shortNames": [
                "ru",
                "flag-ru"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 19
        },
        {
            "unified": "1F1F7-1F1FC",
            "name": "Rwanda Flag",
            "native": "🇷🇼",
            "shortName": "flag-rw",
            "shortNames": [
                "flag-rw"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 20
        },
        {
            "unified": "1F1F8-1F1E6",
            "name": "Saudi Arabia Flag",
            "native": "🇸🇦",
            "shortName": "flag-sa",
            "shortNames": [
                "flag-sa"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 21
        },
        {
            "unified": "1F1F8-1F1E7",
            "name": "Solomon Islands Flag",
            "native": "🇸🇧",
            "shortName": "flag-sb",
            "shortNames": [
                "flag-sb"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 22
        },
        {
            "unified": "1F1F8-1F1E8",
            "name": "Seychelles Flag",
            "native": "🇸🇨",
            "shortName": "flag-sc",
            "shortNames": [
                "flag-sc"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 23
        },
        {
            "unified": "1F1F8-1F1E9",
            "name": "Sudan Flag",
            "native": "🇸🇩",
            "shortName": "flag-sd",
            "shortNames": [
                "flag-sd"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 24
        },
        {
            "unified": "1F1F8-1F1EA",
            "name": "Sweden Flag",
            "native": "🇸🇪",
            "shortName": "flag-se",
            "shortNames": [
                "flag-se"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 25
        },
        {
            "unified": "1F1F8-1F1EC",
            "name": "Singapore Flag",
            "native": "🇸🇬",
            "shortName": "flag-sg",
            "shortNames": [
                "flag-sg"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 26
        },
        {
            "unified": "1F1F8-1F1ED",
            "name": "St. Helena Flag",
            "native": "🇸🇭",
            "shortName": "flag-sh",
            "shortNames": [
                "flag-sh"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 27
        },
        {
            "unified": "1F1F8-1F1EE",
            "name": "Slovenia Flag",
            "native": "🇸🇮",
            "shortName": "flag-si",
            "shortNames": [
                "flag-si"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 28
        },
        {
            "unified": "1F1F8-1F1EF",
            "name": "Svalbard & Jan Mayen Flag",
            "native": "🇸🇯",
            "shortName": "flag-sj",
            "shortNames": [
                "flag-sj"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 29
        },
        {
            "unified": "1F1F8-1F1F0",
            "name": "Slovakia Flag",
            "native": "🇸🇰",
            "shortName": "flag-sk",
            "shortNames": [
                "flag-sk"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 30
        },
        {
            "unified": "1F1F8-1F1F1",
            "name": "Sierra Leone Flag",
            "native": "🇸🇱",
            "shortName": "flag-sl",
            "shortNames": [
                "flag-sl"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 31
        },
        {
            "unified": "1F1F8-1F1F2",
            "name": "San Marino Flag",
            "native": "🇸🇲",
            "shortName": "flag-sm",
            "shortNames": [
                "flag-sm"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 32
        },
        {
            "unified": "1F1F8-1F1F3",
            "name": "Senegal Flag",
            "native": "🇸🇳",
            "shortName": "flag-sn",
            "shortNames": [
                "flag-sn"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 33
        },
        {
            "unified": "1F1F8-1F1F4",
            "name": "Somalia Flag",
            "native": "🇸🇴",
            "shortName": "flag-so",
            "shortNames": [
                "flag-so"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 34
        },
        {
            "unified": "1F1F8-1F1F7",
            "name": "Suriname Flag",
            "native": "🇸🇷",
            "shortName": "flag-sr",
            "shortNames": [
                "flag-sr"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 35
        },
        {
            "unified": "1F1F8-1F1F8",
            "name": "South Sudan Flag",
            "native": "🇸🇸",
            "shortName": "flag-ss",
            "shortNames": [
                "flag-ss"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 36
        },
        {
            "unified": "1F1F8-1F1F9",
            "name": "São Tomé & Príncipe Flag",
            "native": "🇸🇹",
            "shortName": "flag-st",
            "shortNames": [
                "flag-st"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 37
        },
        {
            "unified": "1F1F8-1F1FB",
            "name": "El Salvador Flag",
            "native": "🇸🇻",
            "shortName": "flag-sv",
            "shortNames": [
                "flag-sv"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 38
        },
        {
            "unified": "1F1F8-1F1FD",
            "name": "Sint Maarten Flag",
            "native": "🇸🇽",
            "shortName": "flag-sx",
            "shortNames": [
                "flag-sx"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 39
        },
        {
            "unified": "1F1F8-1F1FE",
            "name": "Syria Flag",
            "native": "🇸🇾",
            "shortName": "flag-sy",
            "shortNames": [
                "flag-sy"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 40
        },
        {
            "unified": "1F1F8-1F1FF",
            "name": "Swaziland Flag",
            "native": "🇸🇿",
            "shortName": "flag-sz",
            "shortNames": [
                "flag-sz"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 41
        },
        {
            "unified": "1F1F9-1F1E6",
            "name": "Tristan da Cunha Flag",
            "native": "🇹🇦",
            "shortName": "flag-ta",
            "shortNames": [
                "flag-ta"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 42
        },
        {
            "unified": "1F1F9-1F1E8",
            "name": "Turks & Caicos Islands Flag",
            "native": "🇹🇨",
            "shortName": "flag-tc",
            "shortNames": [
                "flag-tc"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 43
        },
        {
            "unified": "1F1F9-1F1E9",
            "name": "Chad Flag",
            "native": "🇹🇩",
            "shortName": "flag-td",
            "shortNames": [
                "flag-td"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 44
        },
        {
            "unified": "1F1F9-1F1EB",
            "name": "French Southern Territories Flag",
            "native": "🇹🇫",
            "shortName": "flag-tf",
            "shortNames": [
                "flag-tf"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 45
        },
        {
            "unified": "1F1F9-1F1EC",
            "name": "Togo Flag",
            "native": "🇹🇬",
            "shortName": "flag-tg",
            "shortNames": [
                "flag-tg"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 46
        },
        {
            "unified": "1F1F9-1F1ED",
            "name": "Thailand Flag",
            "native": "🇹🇭",
            "shortName": "flag-th",
            "shortNames": [
                "flag-th"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 47
        },
        {
            "unified": "1F1F9-1F1EF",
            "name": "Tajikistan Flag",
            "native": "🇹🇯",
            "shortName": "flag-tj",
            "shortNames": [
                "flag-tj"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 48
        },
        {
            "unified": "1F1F9-1F1F0",
            "name": "Tokelau Flag",
            "native": "🇹🇰",
            "shortName": "flag-tk",
            "shortNames": [
                "flag-tk"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 49
        },
        {
            "unified": "1F1F9-1F1F1",
            "name": "Timor-Leste Flag",
            "native": "🇹🇱",
            "shortName": "flag-tl",
            "shortNames": [
                "flag-tl"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 50
        },
        {
            "unified": "1F1F9-1F1F2",
            "name": "Turkmenistan Flag",
            "native": "🇹🇲",
            "shortName": "flag-tm",
            "shortNames": [
                "flag-tm"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 4,
            "sheetY": 51
        },
        {
            "unified": "1F1F9-1F1F3",
            "name": "Tunisia Flag",
            "native": "🇹🇳",
            "shortName": "flag-tn",
            "shortNames": [
                "flag-tn"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 0
        },
        {
            "unified": "1F1F9-1F1F4",
            "name": "Tonga Flag",
            "native": "🇹🇴",
            "shortName": "flag-to",
            "shortNames": [
                "flag-to"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 1
        },
        {
            "unified": "1F1F9-1F1F7",
            "name": "Turkey Flag",
            "native": "🇹🇷",
            "shortName": "flag-tr",
            "shortNames": [
                "flag-tr"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 2
        },
        {
            "unified": "1F1F9-1F1F9",
            "name": "Trinidad & Tobago Flag",
            "native": "🇹🇹",
            "shortName": "flag-tt",
            "shortNames": [
                "flag-tt"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 3
        },
        {
            "unified": "1F1F9-1F1FB",
            "name": "Tuvalu Flag",
            "native": "🇹🇻",
            "shortName": "flag-tv",
            "shortNames": [
                "flag-tv"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 4
        },
        {
            "unified": "1F1F9-1F1FC",
            "name": "Taiwan Flag",
            "native": "🇹🇼",
            "shortName": "flag-tw",
            "shortNames": [
                "flag-tw"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 5
        },
        {
            "unified": "1F1F9-1F1FF",
            "name": "Tanzania Flag",
            "native": "🇹🇿",
            "shortName": "flag-tz",
            "shortNames": [
                "flag-tz"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 6
        },
        {
            "unified": "1F1FA-1F1E6",
            "name": "Ukraine Flag",
            "native": "🇺🇦",
            "shortName": "flag-ua",
            "shortNames": [
                "flag-ua"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 7
        },
        {
            "unified": "1F1FA-1F1EC",
            "name": "Uganda Flag",
            "native": "🇺🇬",
            "shortName": "flag-ug",
            "shortNames": [
                "flag-ug"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 8
        },
        {
            "unified": "1F1FA-1F1F3",
            "name": "United Nations Flag",
            "native": "🇺🇳",
            "shortName": "flag-un",
            "shortNames": [
                "flag-un"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 10
        },
        {
            "unified": "1F1FA-1F1F8",
            "name": "United States Flag",
            "native": "🇺🇸",
            "shortName": "us",
            "shortNames": [
                "us",
                "flag-us"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 11
        },
        {
            "unified": "1F1FA-1F1FE",
            "name": "Uruguay Flag",
            "native": "🇺🇾",
            "shortName": "flag-uy",
            "shortNames": [
                "flag-uy"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 12
        },
        {
            "unified": "1F1FA-1F1FF",
            "name": "Uzbekistan Flag",
            "native": "🇺🇿",
            "shortName": "flag-uz",
            "shortNames": [
                "flag-uz"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 13
        },
        {
            "unified": "1F1FB-1F1E6",
            "name": "Vatican City Flag",
            "native": "🇻🇦",
            "shortName": "flag-va",
            "shortNames": [
                "flag-va"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 14
        },
        {
            "unified": "1F1FB-1F1E8",
            "name": "St. Vincent & Grenadines Flag",
            "native": "🇻🇨",
            "shortName": "flag-vc",
            "shortNames": [
                "flag-vc"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 15
        },
        {
            "unified": "1F1FB-1F1EA",
            "name": "Venezuela Flag",
            "native": "🇻🇪",
            "shortName": "flag-ve",
            "shortNames": [
                "flag-ve"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 16
        },
        {
            "unified": "1F1FB-1F1EC",
            "name": "British Virgin Islands Flag",
            "native": "🇻🇬",
            "shortName": "flag-vg",
            "shortNames": [
                "flag-vg"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 17
        },
        {
            "unified": "1F1FB-1F1EE",
            "name": "U.S. Virgin Islands Flag",
            "native": "🇻🇮",
            "shortName": "flag-vi",
            "shortNames": [
                "flag-vi"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 18
        },
        {
            "unified": "1F1FB-1F1F3",
            "name": "Vietnam Flag",
            "native": "🇻🇳",
            "shortName": "flag-vn",
            "shortNames": [
                "flag-vn"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 19
        },
        {
            "unified": "1F1FB-1F1FA",
            "name": "Vanuatu Flag",
            "native": "🇻🇺",
            "shortName": "flag-vu",
            "shortNames": [
                "flag-vu"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 20
        },
        {
            "unified": "1F1FC-1F1EB",
            "name": "Wallis & Futuna Flag",
            "native": "🇼🇫",
            "shortName": "flag-wf",
            "shortNames": [
                "flag-wf"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 21
        },
        {
            "unified": "1F1FC-1F1F8",
            "name": "Samoa Flag",
            "native": "🇼🇸",
            "shortName": "flag-ws",
            "shortNames": [
                "flag-ws"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 22
        },
        {
            "unified": "1F1FD-1F1F0",
            "name": "Kosovo Flag",
            "native": "🇽🇰",
            "shortName": "flag-xk",
            "shortNames": [
                "flag-xk"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 23
        },
        {
            "unified": "1F1FE-1F1EA",
            "name": "Yemen Flag",
            "native": "🇾🇪",
            "shortName": "flag-ye",
            "shortNames": [
                "flag-ye"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 24
        },
        {
            "unified": "1F1FE-1F1F9",
            "name": "Mayotte Flag",
            "native": "🇾🇹",
            "shortName": "flag-yt",
            "shortNames": [
                "flag-yt"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 25
        },
        {
            "unified": "1F1FF-1F1E6",
            "name": "South Africa Flag",
            "native": "🇿🇦",
            "shortName": "flag-za",
            "shortNames": [
                "flag-za"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 26
        },
        {
            "unified": "1F1FF-1F1F2",
            "name": "Zambia Flag",
            "native": "🇿🇲",
            "shortName": "flag-zm",
            "shortNames": [
                "flag-zm"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 27
        },
        {
            "unified": "1F1FF-1F1FC",
            "name": "Zimbabwe Flag",
            "native": "🇿🇼",
            "shortName": "flag-zw",
            "shortNames": [
                "flag-zw"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 5,
            "sheetY": 28
        },
        {
            "unified": "1F3F4-E0067-E0062-E0065-E006E-E0067-E007F",
            "name": "England Flag",
            "native": "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
            "shortName": "flag-england",
            "shortNames": [
                "flag-england"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 12,
            "sheetY": 16
        },
        {
            "unified": "1F3F4-E0067-E0062-E0073-E0063-E0074-E007F",
            "name": "Scotland Flag",
            "native": "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
            "shortName": "flag-scotland",
            "shortNames": [
                "flag-scotland"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 12,
            "sheetY": 17
        },
        {
            "unified": "1F3F4-E0067-E0062-E0077-E006C-E0073-E007F",
            "name": "Wales Flag",
            "native": "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
            "shortName": "flag-wales",
            "shortNames": [
                "flag-wales"
            ],
            "text": null,
            "texts": null,
            "category": "Flags",
            "sheetX": 12,
            "sheetY": 18
        }
    ]
    const newList = [];
    list.forEach((item) => {
        if (!newList.some(cat => cat.category === item.category)) {
            newList.push({
                category: item.category,
                emojis: [item]
            })
        } else {
            const category = newList.find(cat => cat.category === item.category)
            category.emojis.push(item)
        }
    })
    return  withOutCat ? list : newList;
};