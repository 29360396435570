import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const ComparativesDashboard = Loadable(lazy(() => import('./ComparativesDashboard')));

const comparativesRoutes = [
  {
    path: '/comparatives',
    element: (
      <ComparativesDashboard />
    )
  }
];

export default comparativesRoutes;