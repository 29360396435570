import { CircularProgress, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import logo from '../../patrasLogos/Asset_334x.png';
import { useWindowWidth } from '@react-hook/window-size';

const StyledLoading = styled('div')(() => ({
  width: '100%',
  height: '80vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '-5rem',
  '& img': {
    width: 'auto',
    height: '25px'
  },
  '& .circleProgress': {
    position: 'absolute',
    left: -7,
    right: 0,
    top: 'calc(50% - 23px)'
  }
}));

const Loading = ({ text }) => {
  const onlyWidth = useWindowWidth();

  return (
    <StyledLoading>
      <Typography sx={{ mb: 4, fontSize: onlyWidth > 800 ? '1.2rem' : '1rem', fontWeight: 500 }}>
        {
          text ?
            text
            :
            `Estamos consultando sus emails, le notificaremos cuando tengamos sus métricas.`
        }
      </Typography>
      <Box position="relative">
        <img src={logo} alt="loading" />
        <CircularProgress className="circleProgress" />
      </Box>
    </StyledLoading>
  );
};

export default Loading;
