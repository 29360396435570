import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const MatxManagerCalendar = Loadable(lazy(() => import('./MatxManagerCalendar')));

const managerCalendarsRoutes = [
    {
        path: '/managerCalendars',
        element: (
            <MatxManagerCalendar />
        )
    }
];

export default managerCalendarsRoutes;
