/* eslint-disable no-undef */
import axios from 'axios';
import { getMonday, addDays, addMonth } from 'app/utils/calendarUtils/DatesFunctions';

const calendarActions = {
  addCalendar: (userId, calendarEmail) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(`${process.env.REACT_APP_URI_CALENDAR}/calendar/${userId}`, {
          calendarType: 'primary',
          calendarEmail: calendarEmail
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  deleteCalendar: (calendarId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.delete(`${process.env.REACT_APP_URI_CALENDAR}/calendar/${calendarId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  assignCalendarToUser: (userId, newEmail, calendarType, calendarEmail) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(`${process.env.REACT_APP_URI_CALENDAR}/calendar/${userId}`, {
          calendarType,
          calendarEmail,
          newEmail
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getUserEvents: ({ userId, till, from }) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_CALENDAR}/events/all/${userId}?from=${from}&till=${till}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'getCalendar', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getWeeklyEvents: ({ userId }) => {
    const mondayDay = getMonday(new Date());
    const nextMonday = addDays(7, new Date(mondayDay))
    const from = new Date(mondayDay).toISOString();
    const till = new Date(nextMonday).toISOString();

    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_CALENDAR}/events/all/${userId}?from=${from}&till=${till}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'weeklyEvents', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getTenDaysEvents: ({ userId }) => {
    const mondayDay = getMonday(new Date());
    const tenDaysPlus = new Date(new Date(mondayDay).setDate(mondayDay.getDate() + 10));
    const from = new Date(mondayDay).toISOString();
    const till = new Date(tenDaysPlus).toISOString();

    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_CALENDAR}/events/all/${userId}?from=${from}&till=${till}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'tenDaysEvents', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getMonthlyEvents: ({ userId }) => {
    const from = new Date(addMonth(-1)).toISOString();
    const till = new Date().toISOString();

    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_CALENDAR}/events/all/${userId}?from=${from}&till=${till}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'monthlyEvents', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getUserAllEvents: (userId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_CALENDAR}/events/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'allEvents', payload: res.data.response.allEvents });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getUserCalendars: (userId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_CALENDAR}/calendar/user/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'getUserCalendars', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getCalendar: (calendarId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_CALENDAR}/calendar/${calendarId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  addAnualCalendar: (userId, calendarEmail) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(`${process.env.REACT_APP_URI_CALENDAR}/calendar/${userId}`, {
          calendarType: 'primary',
          calendarEmail: calendarEmail
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getAnualCalendar: (userId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_CALENDAR}/events/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getAnualCalendar', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getPublicEvents: (userId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_PUBLIC}/report/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getPublicEvents', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  setPublicEvents: (userId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.put(`${process.env.REACT_APP_URI_CALENDAR}/calendar/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'setPublicEvents', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  assignTimeGoalToEvent: (id, TimeGoalId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.put(`${process.env.REACT_APP_URI_CALENDAR}/timeGoal/assign/${id}`, {
          TimeGoalId
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getTimeGoalByUser: (calendarId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_CALENDAR}/timeGoal/${calendarId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getTimeGoalByUser', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getTGAnalitycs: ({ userId, from, till }) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_CALENDAR}/events/analitycs/${userId}?from=${from}&till=${till}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'getTGAnalitycs', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  addTimeGoal: (calendarId, color, value, name) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_URI_CALENDAR}/timeGoal/${calendarId}`,
          {
            name: name,
            value: value,
            color: color
          }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  updateTimeGoal: (taskId, color, value, name) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_URI_CALENDAR}/timeGoal/${taskId}`,
          name
            ? {
              color: color,
              value: value,
              name: name
            }
            : {
              color: color,
              value: value
            }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  deleteTimeGoal: (taskId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.delete(`${process.env.REACT_APP_URI_CALENDAR}/timeGoal/${taskId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getWorkingHours: (calendarId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_CALENDAR}/calendar/workingHours/${calendarId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'getWorkingHours', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  setWorkingHours: (calendarId, workingHours) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_URI_CALENDAR}/calendar/workingHours/${calendarId}`,
          { workingHours }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  addRecurrence: (option) => {
    return async (dispatch) => {
      try {
        const res = dispatch({ type: 'addRecurrence', payload: option });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  findOrCreateCalendarAverage: (month, year) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_URI_CALENDAR}/average`, { month: month, year: year }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'findOrCreateCalendarAverage', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  createEventShare: (eventId, ownerName) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_URI_CALENDAR}/eventShare/${eventId}`, { ownerName: ownerName }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'createEventShare', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getMyBusyTime: (userId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_CALENDAR}/busy/me/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getCalendarView: (value) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'getCalendarView', payload: value });
      } catch (error) {
        return error;
      }
    };
  },
  getFilterEvents: (calendarSelected, typeSelected) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'getFilterEvents', payload: { calendarSelected, typeSelected } });
      } catch (error) {
        return error;
      }
    };
  },
  getUsersStats: ({ from, till }) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token;
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_CALENDAR}/manager/stats/?from=${from}&till=${till}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getUsersStats', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getMeetingAttendance: (conferenceId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token;
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_CALENDAR}/meetingInfo/?conferenceId=${conferenceId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getMeetingAttendance', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
};
export default calendarActions;
