/* eslint-disable no-undef */
import axios from 'axios';

const publicActions = {
  getAllCalendarEventsShared: (userId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_CALENDAR}/calendarAvailability/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'getMessageByUserID', payload: res.data.response.messages });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  sharePublicCalendar: (calendarId, publicEvent) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {

        const res = await axios.post(
          `${process.env.REACT_APP_URI_CALENDAR}/calendarAvailability/${calendarId}`,
          publicEvent, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getPublicCalendarAvailability: (availabilityId) => {
    return async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_PUBLIC}/calendar/${availabilityId}`
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  createPublicEvent: (availabilityId, start, end, attendees) => {
    return async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_URI_PUBLIC}/calendar/${availabilityId}`,
          {
            start: start,
            end: end,
            attendees: attendees
          }
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getEventSharePublicData: (sharePublicDataId) => {
    return async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_PUBLIC}/event/${sharePublicDataId}`
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  addGuestFromEventShare: (shareEventId, attendee) => {
    return async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_URI_PUBLIC}/event/${shareEventId}`, { attendee: attendee }
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getSharedPdf: (sharedPdfId) => {
    return async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_PUBLIC}/pdf/${sharedPdfId}`
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  },
};

export default publicActions;
