/* eslint-disable no-undef */
import axios from 'axios';
import { urlUserService } from '../../App';

const teamActions = {
  getTeamByCompany: () => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${urlUserService}/teamByCompany`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getTeamByCompany', payload: res.data.response.teams });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getTeam: (id) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${urlUserService}/team/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getTeam', payload: res.data.response.team });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  addTeam: (newTeam) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(`${urlUserService}/team`, newTeam, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'addTeam', payload: res.data.response.team });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  updateTeam: (id, teamData) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.put(
          `${urlUserService}/team/${id}`, teamData, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  removeTeam: (id) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.delete(`${urlUserService}/team/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'removeTeam', payload: res.data.response.team });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  teamSelected: (team) => {
    return (dispatch) => {
      dispatch({ type: 'teamSelected', payload: team });
    };
  },
};

export default teamActions;
