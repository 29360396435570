/* eslint-disable no-undef */
import axios from 'axios';

const taskActions = {
  getTask: (taskId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_TASK}/task/${taskId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getTask', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getDeletedTasks: (userId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_TASK}/task/user/${userId}?deleted=yes`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'getDeletedTasks', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getTasksByUser: (userId, completed) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_TASK}/task/user/${userId}?${completed && 'completed=yes'}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'getTasksByUser', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getTasksByResource: (resourceId, completed) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_TASK}/task/resource/${resourceId}?${completed && 'completed=yes'}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'getTasksByResource', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getRecurrenceById: (recurrenceId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_TASK}/recurrence/${recurrenceId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  assignTaskToResource: (resourceId, data) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.patch(`${process.env.REACT_APP_URI_TASK}/task/resource/${resourceId}`, data, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  tasksToRemove: (resourceId, cardId, ids) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.put(`${process.env.REACT_APP_URI_TASK}/task/resource/${resourceId}?${cardId && `cardId=${cardId}`}`, ids, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  addTask: (projectId, newTask, recurrence) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_URI_TASK}/task/${projectId}?${recurrence && 'recurrence=true'}`,
          newTask, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'updateTask', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  assignProject: (taskId, projectId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.patch(`${process.env.REACT_APP_URI_TASK}/task/project/${taskId}`, {
          ProjectId: projectId
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'updateTask', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  updateTask: (taskId, taskUpdated, all) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_URI_TASK}/task/${taskId}?${all && 'all=true'}`,
          taskUpdated, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'updateTask', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  undoDeleteTask: (taskId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.patch(`${process.env.REACT_APP_URI_TASK}/task/${taskId}`, {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  deleteTask: (taskId, all, app) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.delete(`${process.env.REACT_APP_URI_TASK}/task/${taskId}?${all && 'all=true'}&${app && 'app=true'}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'deleteTask', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getOrCreateAverage: (month, year) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_URI_TASK}/average`, { month: month, year: year }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        dispatch({ type: 'getOrCreateAverage', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  activateConfetti: () => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'activateConfetti', payload: true });

        const startTime = performance.now();

        const animateConfetti = (timestamp) => {
          const elapsedTime = timestamp - startTime;

          if (elapsedTime < 7000) {
            requestAnimationFrame(animateConfetti);
          } else {
            dispatch({ type: 'activateConfetti', payload: false });
          }
        };

        requestAnimationFrame(animateConfetti);
      } catch (error) {
        return error;
      }
    };
  },
  openEmojiDialog: (choice) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'openEmojiDialog', payload: choice });
      } catch (error) {
        return error
      }
    }
  },
  emojiSelected: (emoji) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'emojiSelected', payload: emoji });
      } catch (error) {
        return error
      }
    }
  },
  sortChoice: (choice) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'sortChoice', payload: choice });
      } catch (error) {
        return error
      }
    }
  },
  getTaskView: (value) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'getTaskView', payload: value });
      } catch (error) {
        return error;
      }
    };
  },
  addClickUp: (code) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_URI_TASK}/integration/clickup?code=${code}`,
          {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getClickUpStatuses: (taskId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_TASK}/integration/clickup/${taskId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  getTaskIntegrations: () => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(`${process.env.REACT_APP_URI_TASK}/integrations`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'getTaskIntegrations', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  recurrenceDataChoicen: (rec) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'recurrenceDataChoicen', payload: rec });
      } catch (error) {
        return error
      }
    }
  },
  addTaskRecurrence: (option) => {
    return async (dispatch) => {
      try {
        const res = dispatch({ type: 'addTaskRecurrence', payload: option });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  taskWithPreDate: (date) => {
    return async (dispatch) => {
      try {
        const res = dispatch({ type: 'taskWithPreDate', payload: date });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  deleteIntegration: (integrationId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.delete(`${process.env.REACT_APP_URI_TASK}/integration/${integrationId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  addTrello: () => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URI_TASK}/integration/trello/login`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  addCommentTrello: (taskId, comment) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_URI_TASK}/integration/trello/comments/${taskId}`, { comment: comment },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        dispatch({ type: 'addCommentTrello', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  updateCommentTrello: (taskId, comment) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_URI_TASK}/integration/trello/comments/${taskId}`,
          { comment: comment },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        dispatch({ type: 'updateCommentTrello', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  deleteCommentTrello: (taskId, commentId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.delete(`${process.env.REACT_APP_URI_TASK}/integration/trello/comments/${taskId}?${commentId && `commentId=${commentId}`}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'deleteCommentTrello', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  addChecklistTrello: (taskId, checklistName) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_URI_TASK}/integration/trello/checklist/${taskId}`, { checklistName: checklistName },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        dispatch({ type: 'addChecklistTrello', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  updateChecklistTrello: (taskId, checklist) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_URI_TASK}/integration/trello/checklist/${taskId}`,
          { checklist: checklist },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        dispatch({ type: 'updateChecklistTrello', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  deleteChecklistTrello: (taskId, checklistId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.delete(`${process.env.REACT_APP_URI_TASK}/integration/trello/checklist/${taskId}?${checklistId && `checklistId=${checklistId}`}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'deleteChecklistTrello', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  addCheckitemTrello: (taskId, checklistId, checkItem) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_URI_TASK}/integration/trello/checkitem/${taskId}?${checklistId && `checklistId=${checklistId}`}`, { checkitem: checkItem },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        dispatch({ type: 'addCheckitemTrello', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  updateCheckitemTrello: (taskId, checkItem) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_URI_TASK}/integration/trello/checkitem/${taskId}`,
          { checkitem: checkItem },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        dispatch({ type: 'updateCheckitemTrello', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  deleteCheckitemTrello: (taskId, checkitemId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.delete(`${process.env.REACT_APP_URI_TASK}/integration/trello/checkitem/${taskId}?${checkitemId && `checkitemId=${checkitemId}`}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'deleteCheckitemTrello', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  addReactionTrello: (taskId, commentId, reaction) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_URI_TASK}/integration/trello/reaction/${taskId}?${commentId && `commentId=${commentId}`}`, { reaction: reaction },
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        dispatch({ type: 'addReactionTrello', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  deleteReactionTrello: (taskId, reactionId, commentId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.delete(`${process.env.REACT_APP_URI_TASK}/integration/trello/reaction/${taskId}?${reactionId && `reactionId=${reactionId}`}&${commentId && `commentId=${commentId}`}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'deleteReactionTrello', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  resyncTask: (taskId) => {
    return async (dispatch, getState) => {
      const token = getState().authReducer.token
      try {
        const res = await axios.patch(`${process.env.REACT_APP_URI_TASK}/integration/trello/card/${taskId}`, {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        dispatch({ type: 'resyncTask', payload: res.data.response });
        return res;
      } catch (error) {
        return error;
      }
    };
  },
  tasksWithClickUpStatuses: (tasks) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'tasksWithClickUpStatuses', payload: tasks });
      } catch (error) {
        return error;
      }
    };
  }
};

export default taskActions;
