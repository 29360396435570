import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const TasksHome = Loadable(lazy(() => import('./TasksHome')));
const Projects = Loadable(lazy(() => import('./projects/ProjectsHome')));

const TasksRoutesMobile = [
  {
    path: '/tasksManagement',
    element: (
      <TasksHome />
    )
  },
  {
    path: '/projects',
    element: (
      <Projects />
    )
  }
];

export default TasksRoutesMobile;