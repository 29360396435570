import { CircularProgress } from '@mui/material';
import { Box, styled } from '@mui/system';
import logo from '../../patrasLogos/Asset_334x.png';

const StyledLoading = styled('div')(() => ({
  width: '100%',
  height: '80vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& img': {
    width: 'auto',
    height: '25px'
  },
  '& .circleProgress': {
    position: 'absolute',
    left: -7,
    right: 0,
    top: 'calc(50% - 23px)'
  }
}));

const Loading = ({ width, height, alignItems, pt, ml }) => {
  return (
    <StyledLoading sx={{ height: height && height, width: width && width, alignItems: alignItems && alignItems, pt: pt && pt, ml: ml && ml}}>
      <Box position="relative">
        <img src={logo} alt="loading" />
        <CircularProgress className="circleProgress" />
      </Box>
    </StyledLoading>
  );
};

export default Loading;
