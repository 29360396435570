import React from 'react';
import { useWindowWidth, useWindowHeight } from '@react-hook/window-size';
import Confetti from 'react-confetti';

// eslint-disable-next-line
export default () => {
  const onlyWidth = useWindowWidth();
  const onlyHeight = useWindowHeight() - 100;
  return (
    <Confetti
      numberOfPieces={350}
      width={onlyWidth}
      height={onlyHeight}
      style={{ zIndex: 20000 }}
    />
  );
};
