import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const HealthDashboard = Loadable(lazy(() => import('./HealthDashboard')));

const healthRoutes = [
  {
    path: '/health',
    element: (
      <HealthDashboard />
    )
  }
];

export default healthRoutes;