import publicActions from 'app/redux/actions/publicActions';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MatxLoading } from '.';

const SharedPdf = () => {

    const dispatch = useDispatch();
    const { pdfId } = useParams();

    useEffect(() => {
        dispatch(publicActions.getSharedPdf(pdfId)).then(res => {
            if (res.data) {
                window.location.replace(res.data.response?.link);
            }
        });
        // eslint-disable-next-line
    }, []);

    return (
        <MatxLoading />
    );
};

export default SharedPdf;