import React, { useState, useEffect } from 'react';
import { styled, Typography, Box, TextField } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import userActions from 'app/redux/actions/userActions';
import toast from 'react-hot-toast';
import { errorsResponse } from '@patras/patras-npm';
import { PrimaryButton } from 'app/utils/ButtonsPatras';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import moment from 'moment';

const Title = styled(Typography)(() => ({
    fontSize: '16px',
    textAlign: 'start',
    fontWeight: 500,
    color: '#424142'
}));

export default function UserInfo() {
    const dispatch = useDispatch();
    const user = useSelector(store => store.userReducer.user);
    const loggedUser = useSelector((store) => store.userReducer.user);
    const [state, setState] = useState({});
    const [birthdate, setBirthdate] = useState(null);
    const { firstName, lastName } = state;

    const handleChange = (event) => {
        event.persist();
        setState({ ...state, [event.target.name]: event.target.value });
    };

    useEffect(() => {
        setState({
            ...state,
            firstName: user.User.firstName,
            lastName: user.User.lastName,
        });
        //eslint-disable-next-line
    }, [user]);

    const updateUser = () => {
        if (birthdate && state.lastName && state.firstName) {
            dispatch(
                userActions.modifyUser(user.UserId, {
                    firstName: state.firstName,
                    lastName: state.lastName,
                    birthdate: new Date(birthdate).toISOString(),
                })
            ).then((res) => {
                if (!res.data) {
                    res.response.data.error.map((err) => toast.error(errorsResponse.getError(err)));
                } else {
                    const handleUserMetadataUpdate = async () => {
                        let userMetadata = {
                            firstLogin: false,
                            userId: loggedUser?.UserId
                        };
                        await dispatch(userActions.updateUserMetadata(userMetadata));
                        window.location.reload();
                    }
                    handleUserMetadataUpdate();
                }
            });
        } else {
            toast.error('Todos los campos con asterisco(*) tienen que ser completados')
        }
    };

    return (
        <Box>
            <Title>Configura tu cuenta</Title>
            {
                user &&
                <Box display='flex' flexDirection={'column'} gap={1} mt={2}>
                    <TextField
                        required
                        label="Nombre"
                        color='neutral'
                        name='firstName'
                        fullWidth
                        value={firstName || ''}
                        onChange={handleChange}
                        sx={{ mb: 1, minWidth: '250px', width: '100%' }}
                    />

                    <TextField
                        required
                        label="Apellido"
                        color='neutral'
                        name='lastName'
                        fullWidth
                        onChange={handleChange}
                        value={lastName || ''}
                        sx={{ mb: 1, minWidth: '250px', width: '100%' }}
                    />

                    <LocalizationProvider dateAdapter={AdapterMoment} >
                        <MobileDatePicker
                            color='neutral'
                            format='DD/MM/YYYY'
                            value={moment(birthdate)}
                            maxDate={moment(new Date().setFullYear(new Date().getFullYear() - 16))}
                            onChange={(newValue) => {
                                setBirthdate(newValue);
                            }}
                            label="Fecha de nacimiento"
                            slotProps={{
                                textField: {
                                    required: true,
                                    minWidth: '250px',
                                    width: '100%',
                                    color: 'neutral',
                                }
                            }}
                        />
                    </LocalizationProvider>

                </Box>
            }
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 5 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <PrimaryButton onClick={updateUser}>
                    Finalizar
                </PrimaryButton>
            </Box>
        </Box>
    );
}
