import { Box, ButtonBase, Icon, Typography } from '@mui/material';
import useSettings from 'app/hooks/useSettings';
import React, { Fragment, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MatxVerticalNavExpansionPanel from './MatxVerticalNavExpansionPanel';
import { useWindowWidth } from '@react-hook/window-size';
import { FlexBetween } from '../FlexBox';
import {
  pageSelectedConvert,
  ListLabel,
  ExternalLink,
  InternalLink,
  BulletIcon,
  BadgeValue,
  StyledText
} from 'app/utils/navigationUtils';

const MatxVerticalNav = ({ items, pageByURL }) => {
  const loggedUser = useSelector((store) => store.userReducer.user);
  const company = useSelector((store) => store.companyReducer.company);
  const { settings } = useSettings();
  const { mode } = settings.layout1Settings.leftSidebar;
  const [pageSelected, setPageSelected] = useState('Principal');
  const onlyWidth = useWindowWidth();
  const [expandedPanels, setExpandedPanels] = useState([]);

  const handlePanelChange = (panelIndex) => {
    if (expandedPanels.includes(panelIndex)) {
      setExpandedPanels((prevExpandedPanels) =>
        prevExpandedPanels.filter((index) => index !== panelIndex)
      );
    } else {
      setExpandedPanels([panelIndex]);
    }
  };

  useEffect(() => {
    pageByURL && setPageSelected(pageSelectedConvert(pageByURL, company));
    //eslint-disable-next-line
  }, [pageByURL]);

  const renderLevels = (data) => {
    // eslint-disable-next-line
    return data.map((item, index) => {
      if (item.auth?.includes(loggedUser.User.role)) {
        if (item.type === 'label')
          return (
            <ListLabel key={index} mode={mode} className="sidenavHoverShow">
              {item.label}
            </ListLabel>
          );
        if (item.children) {
          return (
            <MatxVerticalNavExpansionPanel
              mode={mode}
              item={item}
              key={index}
              expanded={expandedPanels.includes(index)}
              onPanelChange={() => handlePanelChange(index)}
            >
              {item.children && renderLevels(item.children)}
            </MatxVerticalNavExpansionPanel>
          );
        } else if (item.type === 'extLink') {
          return (
            <ExternalLink
              key={index}
              href={item.path}
              className={`${mode === 'compact' && 'compactNavItem'}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <ButtonBase key={item.name} name="child" sx={{ width: '100%' }}>
                {(() => {
                  if (item.icon) {
                    return <Icon className="icon">{item.icon}</Icon>;
                  } else {
                    return <span className="item-icon icon-text">{item.iconText}</span>;
                  }
                })()}
                <StyledText mode={mode} className="sidenavHoverShow">
                  {item.name}
                </StyledText>
                <Box mx="auto"></Box>
                {item.badge && <BadgeValue>{item.badge.value}</BadgeValue>}
              </ButtonBase>
            </ExternalLink>
          );
        } else {
          if (item.name === 'Post reunión') {
            if (pageSelected === 'Post reunión') {
              return (
                <InternalLink key={index}>
                  <NavLink
                    to={item.path}
                    className={({ isActive }) =>
                      isActive
                        ? `navItemActive ${mode === 'compact' && 'compactNavItem'}`
                        : `${mode === 'compact' && 'compactNavItem'}`
                    }
                  >
                    {pageSelected === item.name && onlyWidth >= 1200 ? (
                      <ButtonBase
                        key={item.name}
                        name="child"
                        sx={{ width: '100%', backgroundColor: '#FFF1F4' }}
                      >
                        {item?.icon ? (
                          <Icon className="icon" sx={{ width: 36, color: '#C62F4A' }}>
                            {item.icon}
                          </Icon>
                        ) : (
                          <Fragment>
                            <BulletIcon
                              className={`nav-bullet`}
                              sx={{ display: mode === 'compact' && 'none' }}
                            />
                            <Box
                              className="nav-bullet-text"
                              sx={{
                                ml: '20px',
                                fontSize: '11px',
                                display: mode !== 'compact' && 'none',
                                color: '#C62F4A'
                              }}
                            >
                              {item.iconText}
                            </Box>
                          </Fragment>
                        )}
                        <StyledText
                          mode={mode}
                          sx={{ color: '#C62F4A', fontWeight: '500' }}
                          className="sidenavHoverShow"
                        >
                          {item.name}
                        </StyledText>
                        <Box mx="auto" />

                        {item.badge && (
                          <BadgeValue className="sidenavHoverShow">{item.badge.value}</BadgeValue>
                        )}
                      </ButtonBase>
                    ) : (
                      <ButtonBase
                        key={item.name}
                        name="child"
                        sx={{ width: '100%' }}
                        onClick={() => setPageSelected(item.name)}
                      >
                        {item?.icon ? (
                          <Icon className="icon" sx={{ width: 36 }}>
                            {item.icon}
                          </Icon>
                        ) : (
                          <Fragment>
                            <BulletIcon
                              className={`nav-bullet`}
                              sx={{ display: mode === 'compact' && 'none' }}
                            />
                            <Box
                              className="nav-bullet-text"
                              sx={{
                                ml: '20px',
                                fontSize: '11px',
                                display: mode !== 'compact' && 'none'
                              }}
                            >
                              {item.iconText}
                            </Box>
                          </Fragment>
                        )}
                        <StyledText mode={mode} sx={{ fontWeight: '500' }} className="sidenavHoverShow">
                          {item.name}
                        </StyledText>
                        <Box mx="auto" />

                        {item.badge && (
                          <BadgeValue className="sidenavHoverShow">{item.badge.value}</BadgeValue>
                        )}
                      </ButtonBase>
                    )}
                  </NavLink>
                </InternalLink>
              );
            }
          } else {
            return (
              <InternalLink key={index}>
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    isActive
                      ? `navItemActive ${mode === 'compact' && 'compactNavItem'}`
                      : `${mode === 'compact' && 'compactNavItem'}`
                  }
                >
                  <ButtonBase
                    key={item.name}
                    name="child"
                    sx={{
                      width: '100%',
                      backgroundColor: (pageSelected === item.name && onlyWidth >= 1200) && '#FFF1F4',
                    }}
                    onClick={() => setPageSelected(item.name)}
                  >
                    {item?.icon ? (
                      <Icon className="icon" sx={{ width: 36 }}>
                        {item.icon}
                      </Icon>
                    ) : (
                      <Fragment>
                        <BulletIcon
                          className={`nav-bullet`}
                          sx={{ display: mode === 'compact' && 'none' }}
                        />
                        <Box
                          className="nav-bullet-text"
                          sx={{
                            ml: '20px',
                            fontSize: '11px',
                            display: mode !== 'compact' && 'none'
                          }}
                        >
                          {item.iconText}
                        </Box>
                      </Fragment>
                    )}
                    {
                      ((pageSelected === item.name && onlyWidth >= 1200) &&
                        (pageSelected === 'Salud')) ?
                        <FlexBetween sx={{ width: '70%' }}>
                          <StyledText
                            mode={mode}
                            sx={{ color: '#C62F4A', fontWeight: '500' }}
                            className="sidenavHoverShow"
                          >
                            {item.name}
                          </StyledText>
                          <Typography sx={{ fontWeight: 500 }} >Pronto</Typography>
                        </FlexBetween>
                        :
                        <StyledText mode={mode} sx={{ fontWeight: '500', color: (pageSelected === item.name && onlyWidth >= 1200) && '#C62F4A' }} className="sidenavHoverShow">
                          {item.name}
                        </StyledText>
                    }
                    <Box mx="auto" />

                    {item.badge && (
                      <BadgeValue className="sidenavHoverShow">{item.badge.value}</BadgeValue>
                    )}
                  </ButtonBase>
                </NavLink>
              </InternalLink>
            );
          }

        }
      }
    });
  };

  return <div className="navigation">{renderLevels(items)}</div>;
};

export default React.memo(MatxVerticalNav);
