import Loadable from "app/components/Loadable";
import React, { lazy } from "react";

const AppScrumBoard = Loadable(lazy(() => import("./AppScrumBoard")));

const scrumBoardRoutes = [
  {
    path: "/retro-board",
    element: <AppScrumBoard />,
  },
];

export default scrumBoardRoutes;
