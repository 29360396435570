import React from 'react';
import { styled, Typography, Button } from '@mui/material';
import logo from '../../patrasLogos/logo-negro-color.png';

const WaitingContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '2rem'
}));

const LogoContainer = styled('img')(() => ({
  width: '40%',
  marginTop: '10px'
}));

const Span = styled('span')(() => ({
  textTransform: 'capitalize',
  fontWeight: 600
}));

const SpanEmail = styled('span')(() => ({
  textTransform: 'capitaize',
  fontWeight: 600
}));

const StyledButton = styled(Button)(() => ({
  margin: '2rem',
  paddingLeft: '24px',
  paddingRight: '24px',
  overflow: 'hidden',
  borderRadius: '300px',
  borderColor: 'black',
  transition: 'all 250ms',
  color: 'black',
  '&.Btn': {
    '&:hover': {
      color: '#ffffff',
      borderColor: '#ed1d25',
      background: `#ed1d25 !important`,
      backgroundColor: `#ed1d25 !important`,
      fallbacks: [{ color: 'white !important' }]
    }
  }
}));

function WaitingVerification({ user }) {
  const handleVerify = () => {
    window.location.reload(false);
  };
  return (
    <WaitingContainer>
      <LogoContainer src={logo} />
      <Typography sx={{ margin: '3rem auto', fontSize: '1.5rem' }}>
        Hi <Span>{user?.nickname}</Span>! Please check your <SpanEmail>{user?.email}</SpanEmail>{' '}
        inbox and verify your account.
      </Typography>
      <StyledButton className="Btn" variant="outlined" onClick={handleVerify}>
        Get in
      </StyledButton>
    </WaitingContainer>
  );
}

export default WaitingVerification;
