import React from 'react';
import { Box, Typography, styled, Icon } from '@mui/material';
import logo from '../../patrasLogos/errorImage.png';
import { FlexBetween } from './FlexBox';

const ErrorContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: '#FBFAFB',
  position: 'relative',
  top: '-10%'
}));

const LogoContainer = styled('img')(() => ({
  width: '35%',
  position: 'absolute',
  top: '58%',
  left: '45%'
}));

function ErrorFallback({ error, resetErrorBoundary }) {
  const handleError = () => {
    resetErrorBoundary();
  };

  return (
    <ErrorContainer role="alert">
      <FlexBetween sx={{ alignItems: 'flex-start !important' }}>
        <Box
          sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', width: '100%' }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
              width: '500px'
            }}
          >
            <Typography sx={{ fontSize: '48px', fontWeight: '700' }}>Ooops...</Typography>
            <Typography sx={{ fontSize: '48px', fontWeight: '400' }}>Algo salió mal</Typography>
            <Typography sx={{ fontSize: '18px', fontWeight: '400', mt: 3 }}>
              Te pedimos disculpas. Analizaremos qué ocurrió.
            </Typography>
            <Typography sx={{ fontSize: '18px', fontWeight: '400', mt: 1 }}>
              Mientas tanto, podés realizar las siguientes acciones:
            </Typography>
          </Box>
          <FlexBetween sx={{ mt: 6 }}>
            <FlexBetween
              sx={{
                backgroundColor: '#C62F4A',
                borderRadius: '4px',
                padding: '6px 16px',
                width: '140px',
                height: '40px',
                cursor: 'pointer'
              }}
            >
              <Icon sx={{ color: 'white', fontSize: '18px !important' }}>replay</Icon>
              <Typography
                onClick={handleError}
                sx={{ color: 'white', fontSize: '14px', fontWeight: 500 }}
              >
                REINTENTAR
              </Typography>
            </FlexBetween>
          </FlexBetween>
          <Typography
            sx={{ mt: 1, fontSize: '18px', fontWeight: 600, display: 'flex', alignItems: 'center' }}
          >
            ERROR:{' '}
            {error.response?.data.error.map((err, ind) => (
              <p style={{ marginLeft: '.6rem' }} key={ind}>
                {err}
              </p>
            ))}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: '240px',
            color: '#E2E2E2',
            position: 'relative',
            top: '-15%',
            left: '-5%'
          }}
        >
          {error.response?.status}
        </Typography>
      </FlexBetween>
      <LogoContainer src={logo} alt="logo" />
    </ErrorContainer>
  );
}

export default ErrorFallback;
