/* eslint-disable no-case-declarations */
import { mapEvent } from "app/utils/calendarUtils/calendarReducerUtils";

const initialState = {
  calendar: [],
  calendars: [],
  calendarByWeek: [],
  timeGoal: [],
  tgAnalitycs: null,
  anualCalendar: [],
  calendarWeekly: [],
  calendarMonthly: [],
  calendarTenDays: [],
  allEvents: [],
  filterEvents: [],
  addRecurrence: [],
  calendarAverage: [],
  calendarView: '',
  workingHours: [],
  usersStats: [],
  meetingAttendance: null
};

const calendarReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'getCalendar':
      return {
        ...state,
        calendar: action.payload
      };
    case 'weeklyEvents':
      return {
        ...state,
        calendarWeekly: action.payload
      };
    case 'tenDaysEvents':
      return {
        ...state,
        calendarTenDays: action.payload
      };
    case 'monthlyEvents':
      return {
        ...state,
        calendarMonthly: action.payload
      };
    case 'allEvents':
      const allEvents = action.payload.map(mapEvent);
      return {
        ...state,
        filterEvents: allEvents,
        allEvents
      };
    case 'getFilterEvents':
      const calendarSelected = action.payload.calendarSelected;
      const typeSelected = action.payload.typeSelected;

      let eventsFilter = state.allEvents;

      if (calendarSelected.length > 0) {
        eventsFilter = eventsFilter.filter(event => calendarSelected.includes(event.calendarId));
      }

      if (typeSelected.length > 0) {
        eventsFilter = eventsFilter.filter(event => typeSelected.includes(event.timeGoalId));
      }

      return {
        ...state,
        filterEvents: eventsFilter
      };
    case 'getCalendarByWeek':
      return {
        ...state,
        calendarByWeek: action.payload
      };
    case 'getTimeGoalByUser':
      return {
        ...state,
        timeGoal: action.payload
      };
    case 'getTGAnalitycs':
      return {
        ...state,
        tgAnalitycs: action.payload
      };
    case 'addTimeGoal':
      return {
        ...state,
        timeGoal: action.payload
      };
    case 'getUserCalendars':
      return {
        ...state,
        calendars: action.payload
      };
    case 'addAnualCalendar':
      return {
        ...state,
        anualCalendar: action.payload
      };
    case 'addRecurrence':
      return {
        ...state,
        addRecurrence: action.payload
      };
    case 'findOrCreateCalendarAverage':
      return {
        ...state,
        calendarAverage: action.payload
      };
    case 'getCalendarView':
      return {
        ...state,
        calendarView: action.payload
      };
    case 'getWorkingHours':
      return {
        ...state,
        workingHours: action.payload
      };
    case 'getUsersStats':
      return {
        ...state,
        usersStats: action.payload
      };
    case 'getMeetingAttendance':
      return {
        ...state,
        meetingAttendance: action.payload
      };
    default:
      return state;
  }
};

export default calendarReducer;
