/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import emailMetricsActions from 'app/redux/actions/emailMetricsActions';
import notificationsActions from 'app/redux/actions/notificationsActions';
import axios from 'axios';
import React, { createContext, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

const reducer = (state, action) => {
  switch (action.type) {
    case 'EVENT_SOURCE': {
      return { ...state, eventNotificationSource: action.payload };
    }
    case 'LOAD_NOTIFICATIONS': {
      if (state.notifications) {
        return { ...state, notifications: [...state.notifications, action.payload] };
      } else {
        return { ...state, notifications: [action.payload] };
      }
    }
    case 'CLEAN_NOTIFICATIONS': {
      return { ...state, notifications: action.payload };
    }
    case 'DELETE_NOTIFICATION': {
      return { ...state, notifications: action.payload };
    }
    case 'CLEAR_NOTIFICATIONS': {
      return { ...state, notifications: action.payload };
    }
    default: {
      return state;
    }
  }
};

const NotificationContext = createContext({
  notifications: [],
  eventNotificationSource: {},
  getNotifications: () => { },
  deleteNotification: () => { },
  clearNotifications: () => { },
  cleanNotifications: () => { },
  createNotification: () => { }
});

const id = uuidv4();

export const NotificationProvider = ({ settings, children }) => {
  const [state, dispatch] = useReducer(reducer, []);
  const loggedUser = useSelector((store) => store.userReducer.user);
  const [reconnect, setReconnect] = useState(false);
  const [reconnectFrequency, setReconnectFrequency] = useState(1000);
  const dispatchRedux = useDispatch();

  useEffect(() => {
    if (loggedUser) {
      dispatchRedux(notificationsActions.openConnection(id)).then(res => {
        if (res.status === 204) {

          const eventNotification = new EventSource(
            `${process.env.REACT_APP_URI_NOTIFICATIONS}/sse?connectionID=${id}`);
          dispatch({ type: 'EVENT_SOURCE', payload: eventNotification });
          dispatch({ type: 'CLEAN_NOTIFICATIONS', payload: [] });
          eventNotification.onmessage = (event) => {
            const parsedData = JSON.parse(event.data);
            if (parsedData.type === 'weeklySummary') {
              dispatchRedux(notificationsActions.notiWeeklySummary(parsedData.id));
            } else if (parsedData.type === 'satisfactionFeedback') {
              dispatchRedux(notificationsActions.notiFridayQuiz(parsedData.id));
            } else if (parsedData.type === 'maintenance') {
              dispatchRedux(notificationsActions.maintenanceActive({ data: parsedData, status: 'active' }));
            } else {
              dispatchRedux(notificationsActions.maintenanceActive({ data: null, status: 'disabled' }));
              dispatch({ type: 'LOAD_NOTIFICATIONS', payload: parsedData });
              if (parsedData.type === 'github' && parsedData.title === 'Analitica lista') {
                dispatchRedux(emailMetricsActions.githubDataCreating(false));
              }
              if (parsedData.subtitle === 'Ya esta listo el reporte de tu casilla de correo') {
                dispatchRedux(emailMetricsActions.emailMetricsAvailable(Math.random()));
                dispatchRedux(emailMetricsActions.getEmailsByUser(loggedUser?.UserId));
              }
            }
          };

          eventNotification.addEventListener('notificationViewed', (event) => {
            dispatch({ type: 'CLEAN_NOTIFICATIONS', payload: [] });
          });

          eventNotification.onerror = (event) => {
            try {
              eventNotification.close();
              const newReconnectFrequency = reconnectFrequency >= 640000 ? 64000 : reconnectFrequency * 2;
              setReconnectFrequency(newReconnectFrequency);
              _.delay(() => {
                setReconnect(!reconnect);
              }, newReconnectFrequency);
              dispatch({ type: 'CLEAN_NOTIFICATIONS', payload: [] });
            } catch (error) {
              console.error('Error handling eventNotification.onerror:', error);
            }
          };
        } else {
          const newReconnectFrequency = reconnectFrequency >= 640000 ? 64000 : reconnectFrequency * 2;
          setReconnectFrequency(newReconnectFrequency);
          _.delay(() => {
            setReconnect(!reconnect);
          }, newReconnectFrequency);
        }
      })
    }
    // eslint-disable-next-line
  }, [loggedUser, reconnect]);

  const deleteNotification = async (notificationID) => {
    try {
      const { data } = await axios.post('/api/notification/delete', {
        id: notificationID
      });

      dispatch({ type: 'DELETE_NOTIFICATION', payload: data });
    } catch (e) {
      console.error(e);
    }
  };

  const clearNotifications = async () => {
    try {
      const { data } = await axios.post('/api/notification/delete-all');
      dispatch({ type: 'CLEAR_NOTIFICATIONS', payload: data });
    } catch (e) {
      console.error(e);
    }
  };

  const cleanNotifications = async () => {
    dispatch({ type: 'CLEAN_NOTIFICATIONS', payload: [] });
  };

  const createNotification = async (notification) => {
    try {
      const { data } = await axios.post('/api/notification/add', {
        notification
      });

      dispatch({ type: 'CREATE_NOTIFICATION', payload: data });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        deleteNotification,
        clearNotifications,
        createNotification,
        cleanNotifications,
        notifications: state.notifications,
        eventNotificationSource: state.eventNotificationSource
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
